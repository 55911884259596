<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <b-button type="is-primary is-light" icon-left="arrow-left" @click="handleGoToProducts">Productos</b-button>
        <Button pulled="right" type="is-primary" :onClickFn="fetchCategories" icon="refresh"></Button>
        <b-button data-cy="new-category" @click="handleNewCategory">Nueva Categoría</b-button>
      </div>
    </hero-bar>
    <Section>
      <div class="columns">
        <div class="column is-half">
          <LoadingTable :active="!fetched" />
          <b-table v-if="categories.length && fetched" :data="categories" :striped="true" :loading="loading"
            :narrowed="true" :selected.sync="selected" :row-class="(row, index) => row.status === false && 'is-danger'">
            <b-table-column field="name" label="#" width="100" centered sortable searchable v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="section" label="#" width="100" sortable searchable centered v-slot="props">
              {{ props.row.section }}
            </b-table-column>
            <b-table-column field="splitPackage" label="#" width="100" sortable searchable centered v-slot="props">
              {{ props.row.splitPackage ? 'Separado' : '' }}
            </b-table-column></b-table>
        </div>
        <div v-if="selected" class="column is-half">
          <CategoryInfo :category="selected" :sections="sections" @reload="fetchCategories" />
        </div>
      </div>
    </Section>
    <NewCategory :active="newCategoryModalIsActive" :sections="sections" @dismiss="newCategoryModalIsActive = false"
      @created="handleCategoryCreated"></NewCategory>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import CategoryInfo from '../components/Category/Info.vue';
import NewCategory from '../components/Category/NewCategory.vue';
import ApiService from '../services/ApiService';
import CmsService from '../services/cmsServices';
import { columns } from '../ui/config/tables/categories';
import Section from '../components/Section.vue';

const { CategoryService } = ApiService;

export default {
  name: 'Categories',
  components: {
    HeroBar,
    CategoryInfo,
    NewCategory,
    Section,
  },
  data() {
    return {
      categories: [],
      sections: [],
      selected: null,
      columns,
      newCategoryModalIsActive: false,
      hasActions: true,
      loading: false,
      fetched: false,
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => { };
    await this.fetchCategories();
    await this.fetchSections();
    this.fetched = true;
  },
  methods: {
    async fetchSections() {
      this.loading = true;
      const {
        data: { data: sections },
      } = await CmsService.get('/sections');
      this.sections = sections;
      this.loading = false;
    },
    async fetchCategories() {
      this.loading = true;
      const { data: categories } = await CategoryService.find();
      this.categories = categories;
      this.loading = false;
    },
    handleGoToProducts() {
      this.$router.push('/products');
    },
    handleNewCategory() {
      this.newCategoryModalIsActive = true;
    },
    handleCategoryCreated() {
      this.newCategoryModalIsActive = false;
      // TODO: Avoid this call refactoring service response
      this.fetchCategories();
    },
  },
};
</script>

<style>
tr.is-danger {
  background: #ffffff;
  color: #f14668;
}
</style>
