<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <div class="columns">
        <div class="column">
          <LoadingTable :active="loading" />
          <DraftTable
            v-if="!loading && drafts.length"
            :drafts="drafts"
            :loading="loading"
          ></DraftTable>
          <div v-if="!drafts.length && fetched" style="text-align: center">
            <div class="columns is-vcentered">
              <div class="column is-6">
                <p class="title is-3">Cargá tu primer pedido</p>
                <b-button
                  data-cy="new-order"
                  class="mt-4"
                  size="is-large"
                  type="is-primary"
                  @click="handleCreateNewOrder"
                  >Nuevo Pedido</b-button
                >
              </div>
              <div class="column is-6">
                <img :src="require('../assets/empty.svg')" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import ApiService from '../services/ApiService';
import DraftTable from '../components/Order/DraftTable.vue';
import Section from '../components/Section.vue';

const { OrderService } = ApiService;

export default {
  name: 'MyOrders',
  components: {
    Section,
    HeroBar,
    DraftTable,
  },
  data() {
    return {
      orders: [],
      drafts: [],
      selected: null,
      pagination: {},
      loading: true,
      fetched: false,
      hasActions: false,
    };
  },
  computed: {
    currentCustomer() {
      return this.$store.getters['Auth/currentCustomer'] || {};
    },
  },
  async mounted() {
    this.$store.dispatch('Ui/showBars');
    await this.fetchOrders();
    await this.fetchDrafts();
    this.fetched = true;
  },
  methods: {
    async fetchOrders() {
      // eslint-disable-next-line no-underscore-dangle
      const { _id: customer } = this.currentCustomer;
      this.loading = true;
      const { data: pagination } = await OrderService.find({
        noAdmin: true,
        customer,
        limit: 999999,
      });
      this.orders = pagination.docs;
      this.loading = false;
    },

    async fetchDrafts() {
      // eslint-disable-next-line no-underscore-dangle
      this.loading = true;
      const { data: drafts } = await OrderService.findDraft();
      this.drafts = drafts;
      this.loading = false;
    },
    handleCreateNewOrder() {
      this.$router.push('/draft');
    },
  },
};
</script>
