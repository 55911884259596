<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportReceipts',
  props: {
    receipts: {
      type: Array,
      default: () => [],
    },
    all: Array,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    analyticColumns() {
      return [
        {
          label: 'Fecha',
          value: (receipt) => new Date(receipt.date),
          format: 'DD/MM/YYYY',
        },
        {
          label: 'Periodo',
          value: (receipt) => new Date(receipt.date)
            .toLocaleDateString('es-ES', {
              month: 'long',
            })
            .toUpperCase(),
        },
        {
          label: 'Año',
          value: (receipt) => new Date(receipt.date).getFullYear(),
        },
        { label: 'Proveedor', value: (receipt) => receipt.provider.name },
        { label: 'Tipo', value: 'type' },
        { label: 'X', value: (receipt) => (receipt.isBudget ? 'P' : 'F') },
        { label: '#', value: 'code' },
        { label: 'Total', value: 'total', format: '$0.00' },
        { label: 'BI', value: 'grossTotal', format: '$0.00' },
        ...this.uniqueTaxes().map((tax) => ({
          label: tax.name,
          value: (receipt) => {
            const taxed = receipt.taxes.find(
              (receiptTax) => receiptTax._id === tax._id,
            );
            return taxed ? taxed.value : 0;
          },
          format: '$0.00',
        })),
        {
          label: 'Orden de compra',
          value: (receipt) => (receipt.buy && receipt.buy.number) || '-',
        },
      ];
    },
  },
  methods: {
    getType(receipt) {
      return (
        {
          NC: ((description) => {
            if (description && description.includes('Logística')) return 'NCL';
            return 'NC';
          })(receipt.description),
        }[receipt.type] || receipt.type
      );
    },
    uniqueTaxes() {
      return [
        ...new Map(
          [...this.receipts].flatMap((entry) => entry.taxes.map((tax) => [tax.name, tax])),
        ).values(),
      ].sort((a, b) => a._id.localeCompare(b._id));
    },
    uniqueAllTaxes() {
      return [
        ...new Map(
          [...this.all].flatMap((entry) => (entry.taxes ? entry.taxes.map((tax) => [tax.name, tax]) : [])),
        ).values(),
      ].sort((a, b) => a._id.localeCompare(b._id));
    },

    async downloadFile() {
      const data = [
        {
          sheet: 'Comprobantes',
          columns: this.analyticColumns,
          content: this.receipts,
        },
        {
          sheet: 'Comprobantes +',
          columns: [
            {
              label: 'Fecha',
              value: (receipt) => new Date(receipt.date),
              format: 'DD/MM/YYYY',
            },
            {
              label: 'Periodo',
              value: (receipt) => new Date(receipt.date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: (receipt) => new Date(receipt.date).getFullYear(),
            },
            { label: 'Proveedor', value: (receipt) => receipt.provider.name },
            { label: 'Tipo', value: (receipt) => this.getType(receipt) },
            { label: 'X', value: (receipt) => (receipt.isBudget ? 'P' : 'F') },
            { label: '#', value: 'code' },
            { label: 'Total', value: 'total', format: '$0.00' },
            { label: 'BI', value: 'grossTotal', format: '$0.00' },
            ...this.uniqueAllTaxes().map((tax) => ({
              label: tax.name,
              value: (receipt) => {
                const taxed = receipt.taxes
                  ? receipt.taxes.find(
                    (receiptTax) => receiptTax._id === tax._id,
                  )
                  : { value: 0 };
                return taxed ? taxed.value : 0;
              },
              format: '$0.00',
            })),
            {
              label: 'Orden de compra',
              value: (receipt) => (receipt.buy && receipt.buy.number) || '-',
            },
          ],
          content: [...this.all].reverse(),
        },
      ];
      const settings = {
        fileName: `Comprobantes - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
