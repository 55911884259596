/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import {
  ToastProgrammatic as Toast,
  NotificationProgrammatic as Notification,
} from 'buefy';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user
  ? {
    isNavBarVisible: true,
    isFooterBarVisible: true,
    isAsideVisible: true,
    isAsideMobileExpanded: false,
    isDarkModeActive: false,
  }
  : {
    isNavBarVisible: false,
    isFooterBarVisible: false,
    isAsideVisible: false,
    isAsideMobileExpanded: false,
    isDarkModeActive: false,
  };

const state = initialState;

const mutations = {
  asideMobileStateToggle(state, payload = null) {
    const htmlClassName = 'has-aside-mobile-expanded';

    let isShow;

    if (payload !== null) {
      isShow = payload;
    } else {
      isShow = !state.isAsideMobileExpanded;
    }

    if (isShow) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }

    state.isAsideMobileExpanded = isShow;
  },

  darkModeToggle(state) {
    const htmlClassName = 'is-dark-mode-active';
    Toast.open('Toasty!');

    state.isDarkModeActive = !state.isDarkModeActive;

    if (state.isDarkModeActive) {
      document.documentElement.classList.add(htmlClassName);
    } else {
      document.documentElement.classList.remove(htmlClassName);
    }
  },

  showBars(state) {
    state.isNavBarVisible = true;
    state.isFooterBarVisible = true;
    state.isAsideVisible = true;
  },

  hideBars(state) {
    state.isNavBarVisible = false;
    state.isFooterBarVisible = false;
    state.isAsideVisible = false;
  },

  showErrorMessage(state, { message }) {
    Notification.open({
      duration: 7000,
      message,
      position: 'is-bottom',
      type: 'is-danger',
      queue: false,
      hasIcon: true,
    });
  },

  showSuccessMessage(state, { message }) {
    Toast.open({
      duration: 3000,
      message,
      position: 'is-bottom',
      type: 'is-success',
      queue: false,
    });
  },
};

const actions = {
  toggleAsideMobile({ commit }, payload) {
    commit('asideMobileStateToggle', payload);
  },
  showBars({ commit }) {
    commit('showBars');
  },
  hideBars({ commit }) {
    commit('hideBars');
  },

  errorMessage({ commit }, payload) {
    commit('showErrorMessage', payload);
  },

  entityCreated({ commit }, payload) {
    commit('showSuccessMessage', payload);
  },
};

const getters = {
  isAsideVisible(state) {
    return state.isAsideVisible;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
