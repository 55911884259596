const Joi = require('@hapi/joi');

const schema = Joi.object({
  name: Joi.string()
    .min(1)
    .max(30)
    .required(),
  address: Joi.object({
    street: Joi.string().required(),
    city: Joi.string().required(),
    province: Joi.string().required(),
  }).optional(),
  cuit: Joi.string()
    .alphanum()
    .min(1)
    .max(11)
    .optional(),
  phone: Joi.string()
    .min(1)
    .max(30)
    .required(),
  logisticPrice: Joi.number().optional(),
  minimumPurchase: Joi.number().optional(),
  account: Joi.boolean().optional(),
  notes: Joi.string().optional().allow(null, ''),
});

const defaultData = {
  name: null,
  address: {
    street: null,
    city: null,
    province: 'Buenos Aires',
  },
  phone: null,
  cuit: null,
  logisticPrice: 0,
  minimumPurchase: 0,
  account: false,
  notes: null,
};

module.exports = {
  schema,
  defaultData,
};
