<template>
  <div>
    <div id="consignments" style="display: none">
      <div
        v-for="addressId in Object.keys(consignments)"
        :key="addressId"
        class="printable-item"
      >
        <div class="flex flex-col">
          <div class="p-8">
            <div class="my-2">
              <div class="flex items-center justify-between font-bold">
                <p
                  :style="{ fontSize: '32px' }"
                  class="flex items-center justify-start w-16 h-16 text-center justify-self-start"
                >
                  <span v-if="!consignments[addressId].delivery.isDropOff"
                    >#{{ consignments[addressId].number }}</span
                  >
                </p>
                <p
                  :style="{ fontSize: '48px' }"
                  class="flex items-center justify-center w-16 h-16 text-center border-2 border-black justify-self-center"
                >
                  R
                </p>
                <p></p>
              </div>

              <p v-if="!consignments[addressId].delivery.isDropOff">
                <span class="font-bold" :style="{ fontSize: '48px' }">
                  {{ consignments[addressId].customer.name }}</span
                >
              </p>
              <p v-if="consignments[addressId].delivery.isDropOff">
                <span class="font-bold" :style="{ fontSize: '48px' }"
                  >NODO - {{ consignments[addressId].partner.username }}</span
                >
              </p>
              <p>
                <span class="font-bold">Fecha de entrega:</span>
                {{ dayOfWeek(new Date(consignments[addressId].date)) }} {{ consignments[addressId].date | shortDate }}
              </p>
              <p>
                <span class="font-bold">Dirección:</span>
                {{ shortAddress(consignments[addressId].address) }}
              </p>
              <p>
                <span class="font-bold">Pedidos:</span>
                {{ consignments[addressId].orders.length }}
              </p>
            </div>

            <div class="flex justify-between gap-8 space-x-4">
              <div>
                <div
                  class="flex flex-col"
                  v-for="category of Object.keys(
                    groupItemsByCategory(
                      Object.values(
                        getSplittedPackagesFromOrders(
                          consignments[addressId].orders
                        )
                      )
                    )
                  ).sort((a, b) =>
                    a.toLowerCase() < b.toLowerCase() ? -1 : 1
                  )"
                  :key="category"
                >
                  <p class="font-bold">{{ category | capitalize }}</p>

                  <div
                    class="ml-6"
                    v-for="aggregate of groupItemsByCategory(
                      Object.values(
                        getSplittedPackagesFromOrders(
                          consignments[addressId].orders
                        )
                      )
                    )[category].sort((a, b) =>
                      a.product.name.toLowerCase() <
                      b.product.name.toLowerCase()
                        ? -1
                        : 1
                    )"
                    :key="aggregate.product._id"
                  >
                    <div
                      class="flex items-center justify-between gap-2 text-xs"
                    >
                      <div class="flex items-center gap-2">
                        <p class="self-start flex-none font-bold">
                          {{ aggregate | friendlyUnity }}
                        </p>
                        <p>
                          <span v-if="aggregate.product.title"
                            >{{ aggregate.product.title }} -
                            {{ aggregate.product.subtitle }}</span
                          >
                          <span v-if="!aggregate.product.title">{{
                            aggregate.product.name
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex gap-2">
                <div class="flex flex-col gap-2 text-xs">
                  <p class="font-bold uppercase text-md">Almacen</p>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Cajas
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bolsas
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Conservadoras / Frío
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Maples
                  </div>
                </div>
                <div class="flex flex-col gap-2 text-xs">
                  <p class="font-bold uppercase text-md">Verdulería</p>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Cajones
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bolsas
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bultos
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bolsón Verdura
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bolsón Frutas
                  </div>
                  <div
                    class="w-32 h-16 text-center border-2 border-black border-dashed"
                  >
                    Bolsón Pesado
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex mt-4 text-xs text-center border-2 border-black"
              v-show="consignments[addressId].delivery.isDropOff"
            >
              <p class="px-1 border-r-2 border-black">Pedidos</p>
              <div
                v-for="order of [...consignments[addressId].orders].reverse()"
                :key="order.number"
                :style="{ fontSize: '12px' }"
                class="flex flex-wrap gap-2 font-bold"
              >
                <p class="px-1 border-r-2 border-black">#{{ order.number }}</p>
              </div>
            </div>
            <!-- <div class="p-2 rounded-lg shadow">
              <div
                v-for="item of [...consignment.cart.items].sort((a, b) => (a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1))"
                :key="item.number"
                class="grid grid-cols-4 text-sm rounded-md"
                style="grid-template-columns: 56px 2fr 144px 72px"
              >
                <span>{{ item | friendlyUnity }}</span>
                <div>
                  {{ item.product.name }}
                </div>
              </div>

            </div> -->
          </div>
        </div>
      </div>
    </div>

    <b-button type="is-primary is-light" @click="print">
      <b-icon icon="alpha-r-box-outline" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
// import Consignment from './Consignment2.vue';
import { shortAddress, ordersByDeliveryAddress, dayOfWeek } from '../../util/helpers';

export default {
  name: 'PrintConsignments',
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // Consignment,
  },
  data() {
    return {};
  },
  mounted() {
    // add a page break before each item except the first one
    const printableItems = document.querySelectorAll('.printable-item');
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < printableItems.length; i++) {
      printableItems[i].style.pageBreakBefore = 'always';
      printableItems[i].style.pageBreakAfter = 'always';
      printableItems[i].style.clear = 'both';
    }
  },
  computed: {
    ordersByDeliveryAddress() {
      return ordersByDeliveryAddress(this.orders);
    },
    consignments() {
      return this.ordersByDeliveryAddress;
    },
  },
  methods: {
    dayOfWeek,
    groupItemsByCategory(items) {
      return items.reduce((acc, item) => {
        acc[item.product.category.name] = acc[item.product.category.name] || [];
        acc[item.product.category.name].push(item);

        return acc;
      }, {});
    },
    shortAddress,
    getSplittedPackagesFromOrders(orders) {
      return orders.reduce((acc, order) => {
        const splittedItems = order.cart.items.filter(
          ({ product }) => product.splitPackage,
        );

        splittedItems.forEach((item) => {
          const { product } = item;
          const { quantity } = item;

          acc[product._id] = acc[product._id] || {
            quantity: 0,
            amount: 0,
            product: null,
          };
          acc[product._id].quantity += quantity;
          acc[product._id].amount += 1;
          acc[product._id].product = product;
        }, {});

        return acc;
      }, {});
    },
    async print() {
      await this.$htmlToPaper('consignments');
    },
  },
};
</script>

<style scoped>
@media print {
  .printable-item {
    page-break-inside: avoid;
  }
}
</style>
