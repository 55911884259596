/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
exports.mapGoogleAddress = ({ address_components, geometry, place_id }) => {
  if (!address_components) return;
  const ShouldBeComponent = {
    houseNumber: ['street_number'],
    street: ['street_address', 'route'],
    province: ['administrative_area_level_1'],
    administrativeArea: [
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'sublocality_level_1',
      'sublocality',
      'locality',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const address = {
    houseNumber: '',
    street: '',
    province: '',
    city: '',
    administrativeArea: '',
    country: '',
    label: '',
    coordinates: JSON.parse(JSON.stringify(geometry.location)),
    place_id,
  };
  address_components.forEach((component) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const shouldBe in ShouldBeComponent) {
      if (
        ShouldBeComponent.hasOwnProperty(shouldBe)
        && ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1
      ) {
        if (address[shouldBe]) break;
        address[shouldBe] = component.short_name;
      }
    }
  });
  address.label = `${address.street} ${address.houseNumber}, ${address.city}`;

  console.log('address', address);
  // eslint-disable-next-line consistent-return
  return address;
};


exports.parseGoogleAddressToBvitxAddress = (
  googleAddress,
  unit,
) => {
  const {
    street, city, administrativeArea, houseNumber, place_id,
  } = googleAddress;

  return {
    unit,
    label: `${street} ${houseNumber}, ${city}`,
    street: `${street} ${houseNumber}`,
    city,
    province: googleAddress?.province,
    administrativeArea,
    ...googleAddress?.coordinates,
    place_id,
  };
};
