<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <ExportAccounts :accounts="accounts" />
        <!-- <b-button data-cy="new-customer" class="ml-4" @click="handleNewCustomer"
          >Nuevo Cliente</b-button
        > -->
        <Button class="ml-4" :onClickFn="performCustomerAudit"
          >Actualizar</Button
        >
      </div>
    </hero-bar>
    <Section>
      <!-- <SearchBar :store="customerStore"></SearchBar> -->
      <div>
        <div class="my-2">
          <input
            v-model="customerToSearch"
            type="email"
            name="email"
            id="email"
            class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Buscar cuenta"
          />
        </div>
      </div>
      <Layout>
        <List :loading="isLoading.accounts">
          <div v-for="account in filteredAccounts" :key="account._id">
            <template>
              <AccountCard
                :segments="segments"
                :account="account"
                :loading="isLoading.accounts"
                :operationPermission="true"
              ></AccountCard>
            </template>
          </div>
        </List>
      </Layout>
    </Section>
    <NewCustomer
      :active="newCustomerModalIsActive"
      @dismiss="newCustomerModalIsActive = false"
      @created="handleCustomerCreated"
    ></NewCustomer>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../components/HeroBar.vue';
import NewCustomer from '../components/Customer/NewCustomer.vue';
import ExportAccounts from '../components/Customer/ExportAccounts.vue';
// import CustomerCard from '../components/Customer/mobile/CustomerCard.vue';
// import SearchBar from '../components/SearchBar.vue';
import useCustomerStore from '../store/customer';
import useAccountStore from '../store/account';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import Section from '../components/Section.vue';
import AccountCard from '../components/Customer/mobile/AccountCard.vue';
import ApiService from '../services/ApiService';

const { SegmentService } = ApiService;
export default {
  name: 'Accounts',
  components: {
    Section,
    HeroBar,
    NewCustomer,
    ExportAccounts,
    // CustomerCard,
    AccountCard,
    // SearchBar,
    Layout,
    List,
  },
  data() {
    return {
      customerStore: useCustomerStore(),
      accountStore: useAccountStore(),
      query: {},
      segments: [],
      customerToSearch: '',
      newCustomerModalIsActive: false,
      hasActions: true,
    };
  },
  computed: {
    filteredAccounts() {
      return [...this.accounts].filter(
        ({ customer: { name, email } }) => name.toLowerCase().indexOf(this.customerToSearch.toLowerCase())
            >= 0
          || (email
            && email.toLowerCase().indexOf(this.customerToSearch.toLowerCase())
              >= 0),
      );
    },
    // ...mapState(useCustomerStore, ['options']),
    // ...mapState(useCustomerStore, ['pagination']),
    ...mapState(useAccountStore, ['isLoading']),
    ...mapState(useAccountStore, ['isFetched']),
    // ...mapState(useCustomerStore, ['unreceipted']),
    // ...mapState(useCustomerStore, ['customers']),
    ...mapState(useAccountStore, ['accounts']),
    // ...mapState(useCustomerStore, ['selected']),
  },
  async mounted() {
    this.getNext();
    // await this.customerStore.find();
    await this.accountStore.find({ populatePartner: true, populateCustomer: true });
    this.fetchSegments();
  },
  methods: {
    async fetchSegments() {
      const { data: segments } = await SegmentService.find();
      this.segments = segments;
    },
    async performCustomerAudit() {
      await this.accountStore.performCustomerAudit({
        populatePartner: true,
        populateCustomer: true,
      });
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.accounts
          && this.pagination.hasNextPage
        ) {
          this.customerStore.changePage();
        }
      };
    },
    handleNewCustomer() {
      this.newCustomerModalIsActive = true;
    },
    handleCustomerCreated() {
      this.newCustomerModalIsActive = false;
      // TODO: Avoid this call refactoring service response
      // this.fetchCustomers();
    },
  },
};
</script>
