<template>
  <div>
    <div
      :class="`grid grid-cols-1 divide-y-2 divide-${
        open ? 'yellow' : 'purple'
      }-${open ? '500' : '400'} mt-5`"
    >
      <span></span>
      <span></span>
    </div>
    <div @click="open = !open" class="mt-4 is-clearfix">
      <b-icon
        style="padding-bottom: 7px"
        class="is-pulled-right"
        :icon="open ? 'chevron-up' : 'chevron-down'"
      ></b-icon>
      <p class="title is-6">Detalle</p>
    </div>
    <div v-if="open" class="is-clearfix">
      <section class="mt-4 mr-2">
        <!-------------------------------------------->
        <div
          v-if="hasDiscount"
          class="p-2 mb-2 text-lg font-medium text-white rounded-lg bg-gradient-to-br from-purple-500 to-pink-500"
        >
          <p class="font-bold is-pulled-right">
            - {{ discountAmount | currency }}
          </p>
          <p>
            <b>Descuento de aportes</b>
          </p>
        </div>
        <!-------------------------------------------->
        <p class="is-pulled-right">{{ costs | currency }}</p>
        <p>
          <b>Costo:</b>
        </p>
        <!-------------------------------------------->
        <p class="is-pulled-right">
          {{ (contributions - discountAmount) | currency }}
        </p>
        <p>
          <b>Aportes Activate:</b>
        </p>
        <!-------------------------------------------->
        <p class="is-pulled-right">{{ taxes | currency }}</p>
        <p>
          <b>Impuestos:</b>
        </p>
        <!-------------------------------------------->
        <p class="is-pulled-right">{{ logistic | currency }}</p>
        <p>
          <b>Logística:</b>
        </p>
        <!-------------------------------------------->
        <p class="is-pulled-right">
          {{ (toPay - discountAmount) | currency }}
        </p>
        <p>
          <b>Total rendición:</b>
        </p>
        <!-------------------------------------------->
        <p class="is-pulled-right">{{ profits | currency }}</p>

        <p>
          <b>Ganancia:</b>
        </p>
        <!-------------------------------------------->
        <div class="mt-2 is-clearfix">
          <p class="title is-pulled-right">
            {{ (total - discountAmount) | currency }}
          </p>
          <p class="title is-pulled-left">Total</p>
        </div>
        <!-------------------------------------------->
      </section>
    </div>
    <div v-if="open" class="grid grid-cols-1 divide-y-2 divide-purple-400">
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { round, sum } from '../../util/helpers';

export default {
  name: 'OrderDetails',
  props: {
    cart: Object,
    delivery: Object,
    discount: Number,
  },
  components: {},
  data() {
    return {
      open: true,
    };
  },
  watch: {},
  computed: {
    discountAmount() {
      return this.contributions * (this.discount / 100) || 0;
    },
    hasDiscount() {
      return this.discountAmount > 0;
    },
    costs() {
      return round(
        sum(
          this.cart.items.map((i) => i.detail),
          'taxBase',
        ),
      );
    },
    contributions() {
      return round(
        sum(
          this.cart.items.map((i) => i.detail),
          'contribution',
        ),
      );
    },
    taxes() {
      return round(
        sum(
          this.cart.items,
          null,
          (i) => (i.detail && i.detail.taxes && i.detail.taxes.total) || 0,
        ),
      );
    },
    logistic() {
      const { contributes, customerPrice, partnerPrice } = this.delivery;
      return round(contributes ? partnerPrice + customerPrice : partnerPrice);
    },
    toPay() {
      return round(
        this.costs + this.contributions + this.taxes + this.logistic,
      );
    },
    profits() {
      return round(this.total - this.toPay);
    },
    total() {
      const { charged, customerPrice } = this.delivery;
      const logisticPrice = charged ? customerPrice : 0;
      return round(sum(this.cart.items, 'price') + logisticPrice);
    },
  },
  methods: {
    round(value) {
      return round(value);
    },
    handleContinue() {
      this.$emit('continue');
    },
  },
  mounted() {},
};
</script>

<style lang="css">
.divider {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
</style>
