/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import router from '../../router';
import ApiService from '../../services/ApiService';

const { AuthService } = ApiService;

const user = JSON.parse(localStorage.getItem('user'));
const customer = JSON.parse(localStorage.getItem('customer'));
const token = JSON.parse(localStorage.getItem('token'));

const initialState = token
  ? {
    user,
    customer,
    token,
  }
  : {
    user: null,
    customer: null,
    token: null,
  };

const state = initialState;
const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_pair(state) {
    state.status = 'loading';
  },
  auth_success(state, { token, user }) {
    state.status = 'success';
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.status = 'error';
  },
  pair_success(state, { token, customer }) {
    state.status = 'success';
    state.token = token;
    state.customer = customer;
  },
  pair_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.user = null;
    state.customer = null;
  },
  /* A fit-them-all commit */
  basic(state, payload) {
    state[payload.key] = payload.value;
  },

  /* User */
  user(state, payload) {
    if (payload.name) {
      state.userName = payload.name;
    }
    if (payload.email) {
      state.userEmail = payload.email;
    }
    if (payload.avatar) {
      state.userAvatar = payload.avatar;
    }
  },
};
const actions = {
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('auth_request');

      AuthService.login(payload)
        .then(({ data }) => {
          const { token, ...user } = data;
          // eslint-disable-next-line no-underscore-dangle
          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem('user', JSON.stringify(user));
          ApiService.refreshJwt(token);
          commit('auth_success', { token, user });
          resolve(data);
        })
        .catch((err) => {
          commit('auth_error');
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },

  setCustomerAuth({ commit }, { customer, token }) {
    return new Promise((resolve, reject) => {
      commit('auth_pair');

      if (!customer || !token) {
        commit('pair_error');
        localStorage.removeItem('customer');
        localStorage.removeItem('token');
        reject(new Error('Customer or token not provided'));
      }

      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('customer', JSON.stringify(customer));
      commit('pair_success', { token, customer });
      resolve({ token, customer });
    });
  },

  authPair({ commit }, key) {
    return new Promise((resolve, reject) => {
      commit('auth_pair');

      AuthService.getPair(key)
        .then(({ data }) => {
          const { token, customer } = data;
          localStorage.setItem('token', JSON.stringify(token));
          localStorage.setItem('customer', JSON.stringify(customer));
          commit('pair_success', { token, customer });
          resolve(data);
        })
        .catch((err) => {
          commit('pair_error');
          localStorage.removeItem('customer');
          localStorage.removeItem('token');
          reject(err);
        });
    });
  },
  expirePair({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('customer');
      localStorage.removeItem('token');

      this.dispatch('Ui/hideBars');
      router.push('/login');
      resolve();
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('customer');

      this.dispatch('Ui/hideBars');
      router.push('/login');
      resolve();
    });
  },
};
const getters = {
  isLoggedIn(state) {
    return !!state.token;
  },
  currentCustomer(state) {
    return state.customer;
  },
  currentUser(state) {
    return state.user;
  },
  authStatus(state) {
    return state.status;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
