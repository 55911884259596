<template>
  <div class="mt-4">
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="balances"
      :narrowed="true"
      :striped="true"
      :loading="loading"
      :show-detail-icon="false"
      detailed
    >
      <b-table-column
        field="paymentMethod"
        label="Metodo de pago"
        centered
        sortable
        v-slot="props"
      >
        {{ props.row.paymentMethod }}
      </b-table-column>
      <b-table-column
        field="balance"
        label="Balance"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.balance | rounded }}
      </b-table-column>

      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalBalance }}</div>
        </th>
      </template>
    </b-table>
  </div>
</template>

<script>
import { round, sum } from '../../util/helpers';

export default {
  name: 'CashBalances',
  props: {
    balances: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    totalBalance() {
      return round(sum(this.balances, 'balance'));
    },
  },
  methods: {},
  mounted() {},
};
</script>
