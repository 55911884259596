/* eslint-disable import/prefer-default-export */
export const columns = [
  {
    field: 'number',
    label: '#',
    numeric: true,
    searchable: false,
  },
  {
    field: 'name',
    label: 'Nombre',
    searchable: true,
  },
  {
    field: 'category',
    label: 'Categoría',
    searchable: true,
  },
  {
    field: 'provider',
    label: 'Proveedor',
    searchable: true,
  },
  {
    field: 'unit',
    label: 'Unidad',
    searchable: true,
  },
  {
    field: 'contributions',
    label: '%',
    searchable: false,
  },
];
