<template>
  <div
    v-if="selected.length"
    class="fixed px-4 py-2 bg-white border-2 border-purple-500 rounded-full bottom-5 right-3"
  >
    <div class="flex flex-row-reverse gap-2">
      <div
        class="flex items-center justify-center px-2 bg-purple-400 border-2 border-purple-500 rounded-full "
      >
        <span class="font-bold text-white">
          {{ selected.length }}
        </span>
      </div>
      <div v-if="selected.length">
        <b-button type="is-danger is-light" @click="handleDeselectAll">
          <b-icon icon="checkbox-blank-off-outline"></b-icon
        ></b-button>
      </div>

      <div v-if="selected.length">
        <b-button type="is-danger is-light" @click="handleRemoveAll">
          <b-icon icon="trash-can-outline"></b-icon
        ></b-button>
      </div>

      <ExportDraft
        v-if="selected.length && actions.export"
        :drafts="selected"
      ></ExportDraft>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import ExportDraft from './ExportDraft.vue';
import useDraftStore from '../../store/draft';
// import { DRAFT_STATES } from '../../util/constants';

// const { CONFIRMED } = DRAFT_STATES;
export default {
  name: 'DraftActions',
  props: {
    operationPermission: { type: Boolean, default: false },
    actions: {
      type: Object,
      default: () => ({
        export: true,
      }),
    },
  },
  components: {
    ExportDraft,
  },
  data() {
    return {
      draftStore: useDraftStore(),
      vertical: true,
    };
  },
  watch: {},
  computed: {
    ...mapState(useDraftStore, ['selected']),
  },
  methods: {
    handleDeselectAll() {
      this.draftStore.deselectAll();
      this.$emit('deselectAll');
    },
    handleRemoveAll() {
      this.$buefy.dialog.confirm({
        title: 'Eliminar pedido',
        message: `Estás por <b>eliminar</b> ${this.selected.length} pedido${
          this.selected.length > 1 ? 's' : ''
        }. Esta acción no se puede retroceder`,
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await Promise.all(
            this.selected.map((draft) => this.draftStore.remove(draft._id)),
          );
        },
      });
    },
  },
  mounted() {},
};
</script>
