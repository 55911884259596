<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary" /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import { translate } from '../../util/helpers';
import { BUY_STATES } from '../../util/constants';
import ApiService from '../../services/ApiService';

const { BuyService } = ApiService;
const { CANCELLED } = BUY_STATES;

export default {
  name: 'ExportBuys',
  props: {
    buys: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    analyticColumns() {
      return [
        {
          label: 'Día',
          value: (row) => new Date(row.date).toLocaleDateString('es-ES', {
            day: 'numeric',
          }),
        },
        {
          label: 'Mes',
          value: (row) => new Date(row.date)
            .toLocaleDateString('es-ES', {
              month: 'long',
            })
            .toUpperCase(),
        },
        {
          label: 'Año',
          value: (row) => new Date(row.date).toLocaleDateString('es-ES', {
            year: 'numeric',
          }),
        },
        { label: 'ID', value: 'number' },

        { label: 'Proveedor', value: ({ provider }) => provider.name },
        {
          label: 'Saldo',
          value: (buy) => (buy.state !== CANCELLED ? buy.total : 0),
          format: '$0.00',
        },
        {
          label: 'Tipo de envío',
          value: ({ withdrawal }) => withdrawal.name,
        },
        {
          label: 'Log. Activate',
          value: (buy) => (buy.state !== CANCELLED ? buy.withdrawal.internPrice : 0),
          format: '$0.00',
        },
        {
          label: 'Log. Proveedor',
          value: (buy) => (buy.state !== CANCELLED ? buy.withdrawal.fee : 0),
          format: '$0.00',
        },
        {
          label: 'Estado',
          value: (buy) => buy.state,
        },
      ];
    },
    content() {
      return this.buys
        .reduce((acc, buy) => {
          const {
            cart: { items },
          } = buy;
          acc.push(
            ...items.map((item) => ({
              number: buy.number,
              date: new Date(buy.date),
              product: item.product.name,
              unit: item.product.unit,
              quantity: item.quantity,
              price: item.price,
              provider: buy.provider.name,
              state: buy.state,
            })),
          );
          return acc;
        }, [])
        .sort((a, b) => a.number - b.number);
    },
  },
  methods: {
    async fetchLastBuys() {
      const { data: pagination } = await BuyService.find({
        page: 1,
        limit: 200,
      });

      return pagination.docs;
    },
    async downloadFile() {
      const lastBuys = await this.fetchLastBuys();
      const data = [
        {
          sheet: 'Compras',
          columns: [
            { label: 'ID', value: 'number' },
            {
              label: 'Fecha',
              value: 'date',
              format: 'DD/MM/YYYY',
            },

            { label: 'Producto', value: 'product' },
            {
              label: 'Unidad',
              value: (product) => translate('unit', product.unit),
            },
            { label: 'Cantidad', value: 'quantity' },
            { label: 'Precio', value: 'price' },
            { label: 'Proveedor', value: 'provider' },
            { label: 'Estado', value: 'state' },
          ],
          content: this.content,
        },
        {
          sheet: 'Analítico de compras',
          columns: this.analyticColumns,
          content: [...this.buys].sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Analítico de compras +',
          columns: this.analyticColumns,
          content: lastBuys.sort((a, b) => a.number - b.number),
        },
      ];
      const settings = {
        fileName: `Compras - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
