<template>
  <tiles>
    <card-widget
      class="tile is-child"
      type="is-primary"
      icon="account-multiple"
      :number="statistics.customers"
      label="Clientes"
      text="Es la cantidad total de clientes"
      :isFetching="isFetching"
    />
    <card-widget
      class="tile is-child"
      type="is-danger"
      icon="fire"
      :number="statistics.billed"
      prefix="$"
      label="Facturación Total"
      :monthly="statistics.monthly"
      monthlyKey="billed"
      text="Es la facturación total"
      :isFetching="isFetching"
    />
    <card-widget
      class="tile is-child"
      type="is-warning"
      icon="chart-multiline"
      :number="statistics.dueBalance"
      prefix="$"
      label="Saldo vencido"
      text="Es el saldo vencido de los socios"
      :isFetching="isFetching"
    />
  </tiles>
</template>

<script>
import Tiles from '../Tiles.vue';
import CardWidget from '../CardWidget.vue';
import ApiService from '../../services/ApiService';

const { StatisticsService } = ApiService;

export default {
  name: 'MainWidgets',
  components: {
    Tiles,
    CardWidget,
  },
  data() {
    return {
      statistics: {},
      isFetching: false,
    };
  },
  computed: {},
  mounted() {
    this.fetchStatistics();
  },
  methods: {
    async fetchStatistics() {
      this.isFetching = true;
      const { data: statistics } = await StatisticsService.main();
      this.statistics = statistics;
      this.isFetching = false;
    },
  },
};
</script>
