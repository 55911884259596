<template>
  <div>
    <Section>
      <div class="flex flex-col px-4 py-2 space-y-2 bg-white shadow sm:rounded-lg">
        <div class="grid items-center grid-cols-4 space-y-4" v-for="link in links.filter((link) => link.allowed)"
          :key="link.title">
          <div class="col-span-3">
            <p class="text-lg font-bold text-puple-400">{{ link.title }}</p>
          </div>
          <div class="flex flex-row-reverse gap-2 mt-1 sm:gap-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 h-8 text-purple-500" @click="handleShowQRCode(link)">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 h-8 text-purple-500" @click="handleCopyUrl(link.url)">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-8 h-8 text-purple-500" @click="handleOpenShop(link.url)">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
            </svg>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 p-4 mt-6">
          <div v-for="coupon of coupons" :key="coupon.id" class="p-2 border rounded-md cursor-pointer">
            <p class="text-lg font-semibold">{{ coupon.code }}</p>
            <div v-if="coupon.isWholesaler">
              <p>- Acceso canal mayorista</p>
            </div>
            <div v-if="coupon.isSupplier">
              <p>- Acceso canal socio</p>
            </div>
            <div v-if="coupon.delivery">
              <p>- Envío {{ coupon.delivery }}</p>
            </div>
            <div class="flex flex-row-reverse gap-2 mt-1 sm:gap-6">

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-8 h-8 text-purple-500" @click="handleCopyUrl(createCouponUrl(coupon))">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-8 h-8 text-purple-500" @click="handleOpenShop(createCouponUrl(coupon))">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
              </svg>
            </div>
          </div>

        </div>

      </div>
      <QRCode :link="linkSelected" :active="isQRCodeModalActive" @dismiss="isQRCodeModalActive = false"></QRCode>
      <Layout v-if="isFetched.drafts && drafts.length">
        <List :loading="isLoading.drafts">
          <p class="px-2 text-2xl font-bold">🤩 Tus pedidos por aprobar</p>
          <DraftCard v-for="draft in drafts" :key="draft._id" :draft="draft" :isCheckable="false" />
        </List>
      </Layout>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import DraftCard from '../components/Order/mobile/DraftCard.vue';
import useDraftStore from '../store/draft';
import { DRAFT_STATES } from '../util/constants';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import QRCode from '../components/QRCode.vue';
import Section from '../components/Section.vue';
import CmsService from '../services/cmsServices';

export default {
  name: 'MyShop',
  components: {
    QRCode,
    DraftCard,
    Layout,
    List,
    Section,
  },
  data() {
    return {
      draftStore: useDraftStore(),
      hasActions: false,
      qrcode: null,
      coupons: [],
      url: process.env.VUE_APP_SHOP_URL,
      linkSelected: {
        url: null,
        label: null,
      },
      isQRCodeModalActive: false,
      size: 84,

      urlSelected: null,
    };
  },
  computed: {
    ...mapState(useDraftStore, ['isFetched']),
    ...mapState(useDraftStore, ['isLoading']),
    ...mapState(useDraftStore, ['drafts']),
    partnerUrl() {
      const { username, slug } = this.$store.getters['Auth/currentUser'];
      const baseUrl = new URL(this.url);

      if (slug) {
        baseUrl.pathname = `somos/${slug}`;
      } else {
        baseUrl.searchParams.append('av', username);
      }

      return baseUrl.toString();
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    supplyUrl() {
      const partnerUrl = new URL(this.partnerUrl);
      partnerUrl.searchParams.append('v', 'socio');
      return partnerUrl.toString();
    },
    wholesalerUrl() {
      const partnerUrl = new URL(this.partnerUrl);
      partnerUrl.searchParams.append('v', 'mayorista');
      return partnerUrl.toString();
    },
    isWholesaler() {
      return this.$store.getters['Auth/currentUser'].wholesaler;
    },
    links() {
      return [
        {
          title: 'Mi tienda Minorista',
          url: this.partnerUrl,
          label: `Tienda Minorista de ${this.$store.getters['Auth/currentUser'].name}`,
          allowed: true,
        },
        {
          title: 'Mi tienda Mayorista',
          url: this.wholesalerUrl,
          label: `Tienda Mayorista de ${this.$store.getters['Auth/currentUser'].name}`,
          allowed: this.isWholesaler,
        },
        {
          title: 'Mi tienda de socio',
          url: this.supplyUrl,
          label: `Tienda de socio de ${this.$store.getters['Auth/currentUser'].name}`,
          allowed: this.isWholesaler,
        },
      ];
    },
  },
  async mounted() {
    window.onscroll = () => { };
    await this.draftStore.find({
      isPartner: true,
      state: JSON.stringify([DRAFT_STATES.OPEN]),
    });
    await this.fetchCoupons();
  },
  methods: {
    createCouponUrl(coupon) {
      if (coupon.isSupplier) {
        return `${this.supplyUrl}&c=${coupon.code}`;
      }
      if (coupon.isWholesaler) {
        return `${this.wholesalerUrl}&c=${coupon.code}`;
      }
      if (coupon.isRetailer) {
        return `${this.partnerUrl}&c=${coupon.code}`;
      }

      return '';
    },
    async fetchCoupons() {
      this.loading = true;
      const {
        data: coupons,
      } = await CmsService.get(`/coupon${this.isAdmin ? '/private' : ''}`);
      this.coupons = coupons;
      this.loading = false;
    },
    handleShowQRCode(link) {
      this.linkSelected = link;
      this.isQRCodeModalActive = true;
    },
    async handleCopyUrl(url) {
      await navigator.clipboard.writeText(url);
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Link copiado',
        position: 'is-bottom',
        type: 'is-primary',
        queue: false,
      });
    },

    handleCopyWholesaler() {
      window.open(this.wholesalerUrl, '_blank').focus();
    },
    async handleUpdateDraft() {
      await this.fetchDraft();
    },
    handleOpenShop(url) {
      window.open(url, '_blank').focus();
    },
  },
};
</script>
