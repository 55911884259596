import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { BUY_STATES } from '../util/constants';
import { mapPosition } from '../util/helpers';

const { BuyService } = ApiService;

const useBuyStore = defineStore('buy', {
  state: () => ({
    buys: [],
    unreceipted: [],
    due: [],
    pending: [],
    drafts: [],
    selected: [],
    isLoading: {
      buys: false,
      due: false,
      pending: [],
      drafts: false,
      unreceipted: false,
    },
    isFetched: {
      buys: false,
      pending: [],
      due: false,
      drafts: false,
      unreceipted: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false, key = 'buys' } = {}) {
      this.isLoading[key] = true;
      const { data: pagination } = await BuyService.find({
        query: this.query,
        page: this.options.page,
        limit: this.options.limit,
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = merge ? [...this[key], ...pagination.docs] : pagination.docs;
      this.pagination = pagination;
    },
    async findPending(params = {}) {
      this.isLoading.pending = true;
      const { data: pagination } = await BuyService.find({
        state: JSON.stringify([BUY_STATES.PENDING]),
        limit: 999,
        ...params,
      });
      this.isLoading.pending = false;
      this.isFetched.pending = true;
      this.pending = pagination.docs;
    },
    async findUnreceipted() {
      this.isLoading.unreceipted = true;
      const { data: pagination } = await BuyService.find({
        withoutReceipt: true,
        state: JSON.stringify([BUY_STATES.APPROVED]),
        toDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        fromDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
      });
      this.isLoading.unreceipted = false;
      this.isFetched.unreceipted = true;
      this.unreceipted = pagination.docs;
    },
    setQuery(value) {
      this.query = value;
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    set(buys) {
      this.buys = buys;
    },
    selectDay(day) {
      this.buys
        .filter(({ withdrawal }) => withdrawal.date === day)
        .forEach((buy) => this.select(buy));
    },
    deselectDay(day) {
      this.buys
        .filter(({ withdrawal }) => withdrawal.date === day)
        .forEach((buy) => this.deselect(buy));
    },
    select(buy) {
      this.selected.push(buy);
    },
    deselect(buy) {
      const index = this.selected.findIndex(({ _id }) => _id === buy._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(buy) {
      return this.buys.findIndex(({ _id }) => _id === buy._id);
    },
    updatePositions(buys, key) {
      this[key] = buys;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },
    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const buysCopy = [...this[key]];
        buysCopy[index] = element;
        this[key] = buysCopy;
      }
    },
    removeFrom(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this[key].splice(index, 1);
      }
    },
    addTo(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index === -1) {
        this[key].unshift(element);
      }
    },
    async updateWithdrawal(id, payload) {
      const { data: updated } = await BuyService.updateWithdrawal(id, payload);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async updateDriver(buy, payload) {
      const { data: updated } = await BuyService.updateDriver(buy._id, payload);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async commit(id, payload) {
      const { data: updated } = await BuyService.commit(id, payload);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async approve(id, payload) {
      const { data: updated } = await BuyService.approve(id, payload);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async patch(id, payload) {
      const { data: updated } = await BuyService.patch(id, payload);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async cancel(id) {
      const { data: updated } = await BuyService.cancel(id);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
    async rollbackApprove(id) {
      const { data: updated } = await BuyService.rollbackApprove(id);
      this.updateElement(updated, 'buys');
      this.updateElement(updated, 'pending');
      this.updateElement(updated, 'unreceipted');
      return updated;
    },
  },
});

export default useBuyStore;
