<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :width="640"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4" v-if="product">
        <section
          class="text-sm border-2 border-indigo-400 rounded-lg modal-card-body"
        >
          <div class="flex items-center justify-between mb-2">
            <span class="text-xl font-bold capitalize">{{ product.name }}</span>
          </div>

          <div
            class="flex items-center py-1 pr-1 mt-8 text-lg"
            v-if="product.status"
          >
            <div class="flex-grow font-bold text-green-600 truncate">
              <div class="flex flex-col">
                <span>Pago a productor</span>
                <span class="text-xs text-gray-700">(valor sin impuestos)</span>
              </div>
            </div>
            <div class="font-bold tracking-wide text-green-600">
              <span>{{ product.taxBase | currency }}</span>
            </div>
          </div>

          <div class="flex flex-col">
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 border-t-2 border-purple-500"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Precio de abastecimiento</span>
              </div>

              <div
                class="flex flex-col font-bold tracking-wide text-right text-red-500"
              >
                <span class="">{{
                  supplyBilledItem.price | currency
                }}</span>
                <span class="text-xs font-light text-black"
                  >{{
                    (supplyBilledItem.detail.unitCost -
                      supplyBilledItem.detail.taxes.total)
                      | currency
                  }}
                  + IVA</span
                >
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Margen</span>
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ (supplyBilledItem.detail.margin - 1) * 100 | rounded }}%
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 flx"
              v-if="product.status"
            >
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Activate se queda con</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ supplyBilledItem.detail.contribution | currency }}
                </span>
                <span class="text-xs">
                  ({{
                    ((supplyBilledItem.detail.contribution * 100) /
                    supplyBilledItem.price)
                      | rounded
                  }}%)</span
                >
              </div>
            </div>

            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Impuestos</span>
                <span class="text-xs">
                  ({{(( supplyBilledItem.detail.taxes.total / (supplyBilledItem.detail.taxBase + supplyBilledItem.detail.contribution + supplyBilledItem.detail.profit)) * 100) | rounded }}%)</span
                >
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ supplyBilledItem.detail.taxes.total | currency }}
                </span>

              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 flx"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Comisión del AV</span>
              </div>

              <div class="font-bold tracking-wide">
                <span>
                  {{
                    supplyBilledItem.detail.profit | currency
                  }}
                </span>
                <span class="text-xs">
                  ({{
                    (((supplyBilledItem.detail.profit) *
                      100) /
                      wholesalerBilledItem.price)
                      | rounded
                  }}%)</span
                >
              </div>
            </div>
          </div>

          <div class="flex flex-col">
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 border-t-2 border-purple-500"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Precio de venta mayorista</span>
              </div>

              <div
                class="flex flex-col font-bold tracking-wide text-right text-red-500"
              >
                <span class="">{{
                  wholesalerBilledItem.price | currency
                }}</span>
                <span class="text-xs font-light text-black"
                  >{{
                    (wholesalerBilledItem.detail.unitCost -
                      wholesalerBilledItem.detail.taxes.total)
                      | currency
                  }}
                  + IVA</span
                >
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Margen</span>
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ (wholesalerBilledItem.detail.margin - 1) * 100 | rounded }}%

                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 flx"
              v-if="product.status"
            >
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Activate se queda con</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ wholesalerBilledItem.detail.contribution | currency }}
                </span>
                <span class="text-xs">
                  ({{
                    ((wholesalerBilledItem.detail.contribution * 100) /
                      wholesalerBilledItem.price)
                      | rounded
                  }}%)</span
                >
              </div>
            </div>

            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Impuestos </span>
                <span class="text-xs">
                  ({{(( wholesalerBilledItem.detail.taxes.total / (wholesalerBilledItem.detail.taxBase + wholesalerBilledItem.detail.contribution + wholesalerBilledItem.detail.profit)) * 100) | rounded }}%)</span
                >
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ wholesalerBilledItem.detail.taxes.total | currency }}
                </span>
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2 flx"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Comisión del AV</span>
              </div>

              <div class="font-bold tracking-wide">
                <span>
                  {{
                    wholesalerBilledItem.detail.profit
                      | currency
                  }}
                </span>
                <span class="text-xs">
                  ({{
                    (((wholesalerBilledItem.detail.profit) *
                      100) /
                      wholesalerBilledItem.price)
                      | rounded
                  }}%)</span
                >
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-4 border-t-2 border-purple-500">
            <div
              class="flex items-center justify-center py-1 pr-1 mt-2"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Precio de venta minorista</span>
              </div>
              <div
                class="flex flex-col justify-end font-bold text-right text-red-500"
              >
                <span>{{ retailerBilledItem.price | currency }}</span>
                <span class="text-xs font-light text-black"
                  >{{
                    (retailerBilledItem.detail.unitCost -
                      retailerBilledItem.detail.taxes.total)
                      | currency
                  }}
                  + IVA</span
                >
              </div>
            </div>
            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Margen</span>
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ (retailerBilledItem.detail.margin - 1) * 100 | rounded  }}%
                </span>
              </div>
            </div>
            <div
              class="flex justify-start py-1 pr-1 mt-2"
              v-if="product.status"
            >
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Activate se queda con</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ retailerBilledItem.detail.contribution | currency }}
                </span>
                <span class="text-xs">
                  ({{ this.product.contributions | rounded }}%)</span
                >
              </div>
            </div>

            <div
              class="flex items-center justify-center py-1 pr-1 text-xs"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Impuestos</span>  <span class="text-xs">
                  ({{(( retailerBilledItem.detail.taxes.total / (retailerBilledItem.detail.taxBase + retailerBilledItem.detail.contribution + retailerBilledItem.detail.profit)) * 100) | rounded }}%)</span
                >
              </div>
              <div class="font-bold tracking-wide">
                <span>
                  {{ retailerBilledItem.detail.taxes.total | currency }}

                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-4">
            <div
              class="flex justify-start py-1 pr-1 mt-2"
              v-if="product.status"
            >
              <div class="flex-grow font-bold truncate">
                <span>Comisión del AV</span>
              </div>

              <div class="font-bold tracking-wide">
                <span>
                  {{
                    retailerBilledItem.detail.profit
                      | currency
                  }}
                </span>
                <span class="text-xs">
                  ({{
                    (((retailerBilledItem.detail.profit) *
                      100) /
                      retailerBilledItem.price)
                      | rounded
                  }}%)</span
                >
              </div>
            </div>
          </div>
          <RecordList v-if="product" :product="product"> </RecordList>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import { round } from '../../../util/helpers';
import RecordList from './RecordList.vue';

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'ProductDetails',
  components: {
    RecordList,
  },
  props: {
    active: Boolean,
    product: Object,
  },
  data() {
    return {
      details: [
        {
          label: 'Costo unitario',
          value: ({ unitCost }) => unitCost,
          prefix: '$',
        },
        {
          label: '% de aportes',
          value: ({ contributions }) => contributions,
          suffix: '%',
        },
        {
          label: 'Stock teórico',
          value: ({ inventory }) => inventory[0].theoretical,
        },
        {
          label: 'Stock real',
          value: ({ inventory }) => inventory[0].real,
        },
      ],
    };
  },
  watch: {},
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    supplyBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: true,
      }).billOrder({ isSupply: true });
    },
    wholesalerBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: true,
      });
    },
    retailerBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: false,
      });
    },
  },
  methods: {
    round,
    getMargin(item) {
      return item.detail.metadata.margins.find(({ quantity }) => {
        if (item.isWholesaler) return quantity === 0;
        if (this.product.unit === 'UNITY') {
          return quantity === 1;
        }
        return quantity === 1000;
      }).value;
    },
    item({ product, quantity, isWholesaler = false }) {
      return new Item({ product, quantity, isWholesaler }).billOrder();
    },
    price(product, quantity, isWholesaler) {
      return this.item({ product, quantity, isWholesaler }).price;
    },
    getUnit(unit, quantity) {
      if (!unit) return '';
      if (quantity) {
        return {
          UNITY: `${quantity} unid`,
          WEIGHT: `${quantity} grs`,
        }[unit];
      }
      return {
        UNITY: ' unid',
        WEIGHT: ' grs',
      }[unit];
    },
    porcentageMargin(value) {
      return round(value * 100 - 100);
    },
  },
};
</script>
