<template>
  <b-datepicker
    ref="datepicker"
    :mobile-native="false"
    :unselectable-days-of-week="!disabled ? [0] : [0, 1, 2, 3, 4, 5, 6]"
    v-model="newDate"
    :min-date="minDate"
    :nearby-selectable-month-days="true"
    placeholder="Fecha de entrega"
    icon="calendar-today"
    :close-on-click="false"
    trap-focus
    locale="es-ES"
  >
    <template v-slot:trigger>
      <slot></slot>
    </template>

    <div class="flex gap-2">
      <b-field label="Hora inicio" dis>
        <b-select placeholder="HH" v-model="startWithdrawalHour">
          <option
            v-for="option in [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]"
          
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
        <b-select placeholder="MM" v-model="startWithdrawalMinute">
          <option
            v-for="option in [0, 15, 30, 45]"
          
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Hora Fin">
        <b-select placeholder="HH" v-model="endWithdrawalHour">  
          <option
            v-for="option in [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]"
            :value="option"
            :key="option"
            :disabled="startWithdrawalHour > option"
          >
            {{ option }}
          </option>
        </b-select>
        <b-select placeholder="MM" v-model="endWithdrawalMinute">
          <option
            v-for="option in [0, 15, 30, 45]"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
      </b-field>
    </div>


    <div
      v-if="disabled"
      class="p-2 border-2 border-red-400 rounded-md bg-red-50"
    >
      <span class="text-sm"
        >No se puede modificar la fecha de retiro de esta compra</span
      >
    </div>
    <div class="flex flex-row-reverse gap-4">
      <Button
        v-if="newDate && !disabled"
        class="button is-primary is-light"
        :onClickFn="handleUpdateWithdrawal"
        :paramsFn="() => newDate"
      >
        <b-icon icon="check"></b-icon>
        <span>Confirmar</span>
      </Button>
    </div>
  </b-datepicker>
</template>

<script>
import useBuyStore from '../../store/buy';
import { shortDate, getRangeTime } from '../../util/helpers';

export default {
  name: 'UpdateWithdrawalDate',
  props: {
    buy: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    const today = new Date();
    return {
      buyStore: useBuyStore(),
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      newDate: null,
      startWithdrawalHour: this.buy.withdrawal.startTime && new Date(this.buy.withdrawal.startTime).getHours(),
      startWithdrawalMinute: this.buy.withdrawal.startTime && new Date(this.buy.withdrawal.startTime).getMinutes(),
      endWithdrawalHour: this.buy.withdrawal.endTime && new Date(this.buy.withdrawal.endTime).getHours(),
      endWithdrawalMinute: this.buy.withdrawal.endTime && new Date(this.buy.withdrawal.endTime).getMinutes(),
    };
  },
  computed: {
    startTime() {
      const date = new Date(this.newDate);

      date.setHours(this.startWithdrawalHour);
      date.setMinutes(this.startWithdrawalMinute);
      return date;
    },
    endTime() {
      const date = new Date(this.newDate);


      date.setHours(this.endWithdrawalHour);
      date.setMinutes(this.endWithdrawalMinute);
      
      return date;
    },
  },
  watch: {
    startWithdrawalHour() {
      if (this.endWithdrawalHour < this.startWithdrawalHour) {
        this.endWithdrawalHour = this.startWithdrawalHour + 2;
      }
      if (!this.endWithdrawalHour) {
        this.endWithdrawalHour = this.startWithdrawalHour + 2;
      }
      if (!this.startWithdrawalMinute) {
        this.startWithdrawalMinute = 0;
      }
    },
    startWithdrawalMinute() {
      if (!this.endWithdrawalMinute) {
        this.endWithdrawalMinute = this.startWithdrawalMinute;
      }
    },
  },
  methods: {
    async handleUpdateWithdrawal(date = this.newDate) {
      this.$buefy.dialog.confirm({
        title: 'Cambiar fecha/hora de retiro',
        message:
          `Confirma los siguientes cambios:</br></br><b>Fecha:</b> ${shortDate(date)} </br><b>Rango Horario:</b> ${getRangeTime({ delivery: { startTime: this.startTime, endTime: this.endTime } })}`,
        confirmText: 'Confirmar',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          const { _id: id } = this.buy;
          const payload = {
            date: this.newDate,
            startTime: this.startTime,
            endTime: this.endTime,
          };

          const updated = await this.buyStore.updateWithdrawal(id, payload);

          if (this.$refs.datepicker) {
            this.$refs.datepicker.toggle();
          }

          this.$emit('update', updated);
        },
      });
    },
    handleRemoveWithdrawalDate() {
      this.newDate = null;
    },
  },
  mounted() {
    if (this.buy && this.buy.withdrawal && this.buy.withdrawal.date) {
      this.newDate = new Date(this.buy.withdrawal.date);
    } else {
      this.newDate = new Date();
    }
  },
};
</script>
