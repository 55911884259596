/* eslint-disable import/prefer-default-export */
export const columns = [
  {
    field: 'real',
    label: 'Stock Real',
    centered: true,
  },
  {
    field: 'theoretical',
    label: 'Stock Teórico',
    centered: true,
  },
  {
    field: 'critical',
    label: 'Stock Crítico',
    centered: true,
  },
];
