<template>
  <li class="flex items-center mt-2 text-sm text-gray-600 dark:text-gray-300">
    <svg 
    v-if="element.roadmapNotes"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="flex-none inline w-10 h-10 text-red-500 align-center"
    >
    <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
    </svg>


    <svg v-if="!element.roadmapNotes" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-none inline w-6 h-6 align-center">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
</svg>


   

    <span v-if="element.roadmapNotes" class="ml-2 text-lg truncate">{{
      element.roadmapNotes
    }}</span>
    <b-tooltip
      type="is-light"
      :triggers="['click']"
      :auto-close="['outside', 'escape']"
      multilined
      position="is-bottom"
    >
      <template v-slot:content>
        <b-field>
          <b-input
            v-model="updatedNotes"
            pattern="[a-z]*"
            size="is-small"
            maxlength="200"
            type="textarea"
          ></b-input>
        </b-field>
        <b-button
          label="Guardar"
          @click="handleUpdateNotes"
          type="is-primary"
          size="is-small"
        />
      </template>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="inline w-4 h-4 mb-1 ml-2 text-indigo-600 cursor-pointer align-center"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
    </b-tooltip>
  </li>
</template>

<script>
import { formatAddress } from '../util/helpers';

export default {
  name: 'AddressComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {
      updatedNotes: this.element.roadmapNotes,
    };
  },
  computed: {},
  methods: {
    formatAddress,
    handleUpdateNotes() {
      this.$emit('update', this.updatedNotes);
    },
  },
  watch: {},
};
</script>
