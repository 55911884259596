<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :width="640"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4" v-if="element">
        <section class="border-2 border-indigo-400 rounded-lg modal-card-body">
          <!-- <div class="flex items-center justify-between mb-2">
            <span class="text-xl font-bold capitalize">{{ element.name }}</span>
          </div> -->
          <newCustomerPay
            :account="element"
            @created="handlePayCreated"
          ></newCustomerPay>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import NewCustomerPay from '../NewCustomerPay.vue';

export default {
  name: 'Details',
  components: {
    NewCustomerPay,
  },
  props: {
    active: Boolean,
    element: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    handlePayCreated(pay) {
      this.$emit('payCreated', pay, this.element);
      this.$emit('dismiss');
    },
  },
};
</script>
