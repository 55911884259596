<template>
  <b-modal
    :active="active"
    :has-modal-card="true"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <template>
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-input placeholder="Nombre" v-model="updated.name"></b-input>
          <footer class="modal-card-foot">
            <button class="button" @click="$emit('dismiss')">Cancelar</button>
            <Button
              data-cy="update-changeMethod"
              :once="true"
              type="is-success"
              :onClickFn="handleUpdateChargeMethod"
              >Aceptar</Button
            >
          </footer>
        </section>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ApiService from '../../services/ApiService';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';

const { ChargeMethodService } = ApiService;

export default {
  name: 'UpdateChargeMethodMethod',
  props: {
    active: Boolean,
    chargeMethod: Object,
  },
  components: {},
  data() {
    return {
      updated: this.chargeMethod || {},
    };
  },
  watch: {
    'chargeMethod._id': function () {
      if (this.chargeMethod) {
        this.updated = this.chargeMethod;
      }
    },
  },
  methods: {
    async handleUpdateChargeMethod() {
      const { _id: id } = this.chargeMethod;
      const payload = omit(immutableProperties.common, this.updated);

      await ChargeMethodService.update(id, payload);
      this.$emit('update', id);
      this.$emit('dismiss');
    },
  },
};
</script>
