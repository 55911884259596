<template>
  <div class="flex flex-col gap-4 p-1 divide-y-2 divide-yellow-500">
    <div class="flex flex-col">
      <b-field label="Stock Crítico">
        <b-numberinput
          :step="step"
          :min="0"
          controls-position="expanded"
          type="is-info is-light"
          v-model="critical"
        ></b-numberinput>
      </b-field>
      <Button :onClickFn="handleUpdateCriticalInventory"
        >Actualizar Stock Crítico</Button
      >
    </div>
    <div class="flex flex-col py-4">
      <b-field label="Ingreso">
        <b-numberinput
          :step="step"
          controls-position="expanded"
          :min="0"
          type="is-success is-light"
          v-model="adjustment.ingress"
          :disabled="adjustment.egress > 0"
        ></b-numberinput>
      </b-field>
      <b-field label="Egreso">
        <b-numberinput
          :step="step"
          controls-position="expanded"
          :min="0"
          type="is-danger is-light"
          v-model="adjustment.egress"
          :disabled="adjustment.ingress > 0"
        ></b-numberinput>
      </b-field>

      <Button :onClickFn="handleAdjustment">Ajustar</Button>
    </div>
    <div class="flex flex-col py-4">
      <b-field label="¿Qué stock físico hay? ">
        <b-numberinput
          :step="step"
          :min="0"
          controls-position="expanded"
          type="is-info is-light"
          v-model="forceAdjustment"
        ></b-numberinput>
      </b-field>

      <Button
        :disabled="this.forceAdjustment === product.inventory[0].real"
        :onClickFn="handleForceAdjustment"
        >Ajustar</Button
      >
    </div>
  </div>
</template>

<script>
import { columns } from '../../ui/config/tables/inventory';
import useProductStore from '../../store/product';

export default {
  name: 'Inventory',
  props: {
    product: Object,
  },
  components: {},
  data() {
    return {
      productStore: useProductStore(),
      columns,
      critical: this.product.inventory[0].critical,
      forceAdjustment: this.product.inventory[0].real,
      adjustment: {
        ingress: 0,
        egress: 0,
      },
    };
  },
  watch: {
    product() {
      if (!this.product) return;
      this.critical = this.product.inventory[0].critical;
    },
  },
  computed: {
    step() {
      if (!this.product) return '';
      return {
        UNITY: 1,
        WEIGHT: 50,
      }[this.product.unit];
    },
  },
  methods: {
    async handleUpdateCriticalInventory() {
      const { _id: id } = this.product;
      const { critical } = this;
      const updated = await this.productStore.updateCriticalInventory(id, {
        critical,
      });

      this.$emit('update', updated);
    },
    async handleForceAdjustment() {
      const { _id: id } = this.product;
      const updated = await this.productStore.forceAdjustment(id, {
        real: this.forceAdjustment,
      });
      this.$emit('update', updated);
    },
    async handleAdjustment() {
      const { ingress, egress } = this.adjustment;
      if (ingress === 0 && egress === 0) {
        this.$buefy.snackbar.open('Ingresar un valor válido');
        return;
      }
      if (ingress > 0 && egress > 0) {
        this.$buefy.snackbar.open('Realizar un ajuste a la vez');
        return;
      }
      const quantity = ingress > 0 ? ingress : -egress;

      const { _id: id } = this.product;
      const updated = await this.productStore.adjustInventory(id, {
        quantity,
      });
      this.$emit('update', updated);
      this.adjustment = {
        ingress: 0,
        egress: 0,
      };
    },
  },
};
</script>
