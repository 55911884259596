<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <b-dropdown aria-role="list" class="mr-2">
          <template #trigger="{ active }">
            <b-button
              label="Opciones"
              type="is-primary"
              :icon-right="active ? 'chevron-up' : 'chevron-down'"
            />
          </template>

          <b-dropdown-item aria-role="listitem" @click="handleAddNewThird">
            <div class="media">
              <b-icon class="media-left" icon="file-account"></b-icon>
              <div class="media-content">
                <h3 data-cy="new-third">Nuevo DA</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </hero-bar>
    <Section>
      <CashBalances ref="cashBalancesWidgets" />
      <NewCashFlow
        :partners="partners"
        :providers="providers"
        :thirds="thirds"
        @created="handleCashFlowCreated"
      />
      <CashFlow />
    </Section>
    <NewThird
      :active="isCreatingThird"
      @created="handleThirdCreated"
      @dismiss="isCreatingThird = false"
    />
  </div>
</template>

<script>
import CashBalances from '../components/Widgets/CashBalancesWidgets.vue';
import HeroBar from '../components/HeroBar.vue';
import ApiService from '../services/ApiService';
import NewCashFlow from '../components/Finances/NewCashFlow.vue';
import CashFlow from '../components/Finances/CashFlow.vue';
import NewThird from '../components/Finances/NewThird.vue';
import Section from '../components/Section.vue';

const {
  UserService,
  ThirdService,
  ProviderService,
  FinanceService,
} = ApiService;

export default {
  name: 'Finances',
  components: {
    Section,
    HeroBar,
    NewCashFlow,
    CashFlow,
    NewThird,
    CashBalances,
  },
  data() {
    return {
      isCreatingThird: false,
      selected: null,
      isFetched: false,
      partners: [],
      finances: [],
      thirds: [],
      providers: [],
      hasActions: true,
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => {};
    this.isFetched = false;
    await this.fetchPartnersBoard();
    await this.fetchPartners();
    await this.fetchProviders();
    await this.fetchThirds();
    this.isFetched = true;
  },
  methods: {
    handleThirdCreated(third, type) {
      this.isCreatingThird = false;

      if (type === 'partner') {
        this.partners.push(third);
      }
      if (type === 'provider') {
        this.providers.push(third);
      }
    },
    handleAddNewThird() {
      this.isCreatingThird = true;
    },
    async fetchPartnersBoard() {
      const { data: finances } = await FinanceService.partnersBoard();
      this.finances = finances;
    },

    async fetchPartners() {
      const { data: { docs: partners } } = await UserService.find({ status: true, limit: 99999 });
      this.partners = partners;
    },
    async fetchThirds() {
      const { data: { docs: thirds } } = await ThirdService.find({ status: true, limit: 99999 });
      this.thirds = thirds;
    },
    async fetchProviders() {
      const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
      this.providers = providers;
    },
    async handleCashFlowCreated({ partner }) {
      this.$refs.cashBalancesWidgets.fetchCashBalances();
      if (!partner) return;

      // TODO: Avoid this call refactoring service response
      const { data: updatedPartnerBoard } = await FinanceService.partnerBoard(
        partner,
      );

      const index = this.finances.findIndex(
        // eslint-disable-next-line no-underscore-dangle
        ({ partner: { _id } }) => _id === updatedPartnerBoard.partner._id,
      );
      const boardCopy = [...this.finances];
      boardCopy[index] = updatedPartnerBoard;
      this.finances = boardCopy;
    },
  },
};
</script>
