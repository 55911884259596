<template>
  <div class="flex items-center mb-4">
    <div class="container max-w-6xl mx-auto">
      <div
        class="grid bg-white divide-y divide-gray-100 shadow-sm sm:rounded-lg sm:divide-x lg:divide-y-0 sm:grid-cols-2 md:grid-cols-3"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widgets',
  props: {},
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
