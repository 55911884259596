const Joi = require('@hapi/joi');

const schema = Joi.object({
  name: Joi.string()
    .min(1)
    .max(100)
    .required(),
  icon: Joi.string().required(),
  slug: Joi.string().optional(),
  section: Joi.string()
    .min(1)
    .max(100)
    .required(),
});

const defaultData = {
  name: null,
  icon: null,
  slug: null,
  section: null,
};

module.exports = {
  schema,
  defaultData,
};
