<template>
  <b-field
    ><b-input
      :placeholder="placeholder"
      v-model="query"
      :loading="loading"
    ></b-input
  ></b-field>
</template>

<script>
export default {
  name: 'SearchBar',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: 'Buscar',
    },
    store: Object,
    params: Object,
    arrayParams: Array,
  },
  data() {
    return {
      query: null,
      loading: false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async search() {
      const query = this.query && this.query.length ? this.query : null;
      this.store.setQuery(query);
      this.store.setPage(1);
      if (this.arrayParams) {
        this.store.find(...this.arrayParams);
      } else {
        this.store.find({
          ...this.params,
        });
      }
    },
  },
  watch: {
    query: {
      handler() {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.search();
        }, 400);
      },
      deep: true,
    },
  },
};
</script>
