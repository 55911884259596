import { defineStore } from 'pinia';
import { startOfDay, subDays } from 'date-fns';
import ApiService from '../services/ApiService';
import { ORDER_STATES } from '../util/constants';
import { mapPosition } from '../util/helpers';

const { OrderService } = ApiService;

const useOrderStore = defineStore('order', {
  state: () => ({
    orders: [],
    due: [],
    pending: [],
    drafts: [],
    selected: [],
    isLoading: {
      orders: false,
      pending: false,
      due: false,
      drafts: false,
    },
    isFetched: {
      orders: false,
      pending: false,
      due: false,
      drafts: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false } = {}) {
      this.isLoading.orders = true;
      const { data: pagination } = await OrderService.find({
        query: this.query,
        page: this.options.page,
        limit: this.options.limit,
        ...params,
      });
      this.isLoading.orders = false;
      this.isFetched.orders = true;
      this.orders = merge
        ? [...this.orders, ...pagination.docs]
        : pagination.docs;
      this.pagination = pagination;
    },
    async findDue(params = {}) {
      this.isLoading.due = true;
      const { data: pagnination } = await OrderService.find({
        state: JSON.stringify([ORDER_STATES.CONFIRMED]),
        fromDate: startOfDay(subDays(new Date(), 365)),
        toDate: startOfDay(subDays(new Date(), 1)),
        limit: 999,
        ...params,
      });
      this.isLoading.due = false;
      this.isFetched.due = true;
      this.due = pagnination.docs;
    },
    async findPending(params = {}) {
      this.isLoading.pending = true;
      const { data: pagnination } = await OrderService.find({
        state: JSON.stringify([ORDER_STATES.PENDING]),
        limit: 999,
        ...params,
      });
      this.isLoading.pending = false;
      this.isFetched.pending = true;
      this.pending = pagnination.docs;
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(orders) {
      this.orders = orders;
    },
    selectDay(day) {
      this.orders
        .filter(({ delivery }) => delivery.date === day)
        .filter((order) => {
          const index = this.selected.findIndex(({ _id }) => _id === order._id);
          return index === -1;
        })
        .forEach((order) => this.select(order));
    },
    deselectDay(day) {
      this.orders
        .filter(({ delivery }) => delivery.date === day)
        .forEach((order) => this.deselect(order));
    },
    select(order) {
      this.selected.push(order);
    },
    deselect(order) {
      const index = this.selected.findIndex(({ _id }) => _id === order._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(order) {
      return this.orders.findIndex(({ _id }) => _id === order._id);
    },
    updatePositions(orders, key) {
      this[key] = orders;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      if (element) {
        const index = this[key].findIndex(({ _id }) => _id === element._id);
        if (index !== -1) {
          const ordersCopy = [...this[key]];
          ordersCopy[index] = element;
          this[key] = ordersCopy;
        }
      }
    },
    removeFrom(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this[key].splice(index, 1);
      }
    },
    addTo(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index === -1) {
        this[key].unshift(element);
      }
    },
    async updateDelivery(id, payload) {
      const { data: updated } = await OrderService.updateDelivery(id, payload);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      this.removeFrom(updated, 'pending');
      this.addTo(updated, 'orders');

      return updated;
    },

    async updateDriver(order, payload) {
      const { data: updated } = await OrderService.updateDriver(order._id, payload);
      this.updateElement(updated, 'orders');
      return updated;
    },
    async patch(id, payload) {
      const { data: updated } = await OrderService.patch(id, payload);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      this.updateElement(updated, 'pending');

      return updated;
    },
    async prepare(id, payload) {
      const { data: updated } = await OrderService.prepare(id, payload);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');

      return updated;
    },
    async deliver(id) {
      const { data: updated } = await OrderService.deliver(id);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      this.updateElement(updated, 'pending');
      return updated;
    },
    async rollbackDeliver(id) {
      const { data: updated } = await OrderService.rollbackDeliver(id);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      this.updateElement(updated, 'pending');
      return updated;
    },
    async cancel(id) {
      const { data: updated } = await OrderService.cancel(id);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      this.updateElement(updated, 'pending');
      return updated;
    },
    async suspend(id) {
      const { data: updated } = await OrderService.suspend(id);
      this.updateElement(updated, 'due');
      this.updateElement(updated, 'pending');
      this.removeFrom(updated, 'orders');
      this.addTo(updated, 'pending');
      return updated;
    },
    async rollbackPrepare(id) {
      const { data: updated } = await OrderService.rollbackPrepare(id);
      this.updateElement(updated, 'orders');
      this.updateElement(updated, 'due');
      return updated;
    },
  },
});

export default useOrderStore;
