<template>
  <div class="card" v-if="customer">
    <div class="card-content">
      <button
        class="is-pulled-right delete is-small"
        v-if="canRemove"
        @click="handleRemoveCustomer"
      ></button>
      <b class="is-pulled-right"></b>
      <p class="title is-5">{{ customer.name }}</p>
      <p>
        <b>Dirección principal:</b>
        {{ customer.address.street }} {{ customer.address.unit }},
        {{ customer.address.city }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerInfo',
  props: {
    customer: Object,
    canRemove: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    handleRemoveCustomer() {
      this.$emit('removeCustomer');
    },
  },
  mounted() {},
};
</script>
