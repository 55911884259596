<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <div class="px-4 pb-4 columns is-clearfix">
        <div class="column is-6">
          <b-field label="Nombre">
            <b-input v-model="user.name"></b-input>
          </b-field>

          <b-field label="Usuario">
            <b-input v-model="user.username"></b-input>
          </b-field>

          <b-field label="Contraseña">
            <b-input
              v-model="user.password"
              type="password"
              password-reveal
            ></b-input>
          </b-field>

          <b-field label="Email">
            <b-input v-model="user.email"></b-input>
          </b-field>
          <b-field label="Telefono">
            <PhoneInput v-model="user.phone" :phone="user.phone" />
          </b-field>
          <Button
            class="is-pulled-right"
            type="is-primary"
            :onClickFn="updateProfile"
            >Guardar</Button
          >
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import { schemas } from 'activate-schemas';
import HeroBar from '../components/HeroBar.vue';
import ApiService from '../services/ApiService';
import { omit, validatePayload } from '../util/helpers';
import { immutableProperties } from '../util/constants';
import Section from '../components/Section.vue';
import PhoneInput from '../components/PhoneInput.vue';

const { UserService } = ApiService;
const {
  user: { updateProfileSchema: schema },
} = schemas;

export default {
  name: 'Profile',
  components: {
    HeroBar,
    Section,
    PhoneInput,
  },
  data() {
    return {
      user: {},
      hasActions: false,
    };
  },
  computed: {},
  mounted() {
    window.onscroll = () => {};
    const user = { ...this.$store.getters['Auth/currentUser'] };
    this.user = user;
  },
  methods: {
    async updateProfile() {
      const payload = omit(immutableProperties.user, this.user);

      if (!validatePayload(payload, schema)) return;
      await UserService.updateProfile(payload).then(({ error }) => {
        if (error) return;
        const updatedUserData = (({ password, ...rest }) => rest)(this.user);
        localStorage.setItem('user', JSON.stringify(updatedUserData));
      });
    },
  },
};
</script>
