import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

import Login from '../views/Login.vue';
import Pair from '../views/Pair.vue';
import Draft from '../views/Draft.vue';
import Catalog from '../views/Catalog.vue';
import Home from '../views/Home.vue';
import Tracker from '../views/Tracker.vue';
import Products from '../views/Products.vue';
import Customers from '../views/Customers.vue';
import Accounts from '../views/Accounts.vue';
import Providers from '../views/Providers.vue';
import Cart from '../views/Cart.vue';
import BuyCart from '../views/BuyCart.vue';
import CustomerOrders from '../views/CustomerOrders.vue';
import Orders from '../views/Orders.vue';
import Drafts from '../views/Drafts.vue';
import MyCommunity from '../views/MyCommunity.vue';
import Buys from '../views/Buys.vue';
import MyDrafts from '../views/MyDrafts.vue';
import Finances from '../views/Finances.vue';
import Statistics from '../views/Statistics.vue';
import Categories from '../views/Categories.vue';
import MyOrders from '../views/MyOrders.vue';
import MyShop from '../views/MyShop.vue';
import Partners from '../views/Partners.vue';
import Prepare from '../views/Prepare.vue';
import Logistic from '../views/Logistic.vue';
import DriverLogistic from '../views/DriverLogistic.vue';
import Roadmaps from '../views/Roadmaps.vue';
import MyIncomes from '../views/MyIncomes.vue';
import MyDebt from '../views/MyDebt.vue';
import ProviderDashboard from '../views/provider/Dashboard.vue';
import ProviderInventory from '../views/provider/Inventory.vue';
import ProviderAccount from '../views/provider/Account.vue';
import ProviderOrders from '../views/provider/Orders.vue';
import Settings from '../views/Settings2.vue';
import Profile from '../views/Profile.vue';

Vue.use(VueRouter);

const routes = [
  {
    meta: {
      title: 'Login',
      context: 'public',
    },
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    meta: {
      title: 'Pair',
      context: 'public',
    },
    path: '/pair',
    name: 'pair',
    component: Pair,
  },
  {
    meta: {
      title: 'Pair',
      context: 'public',
    },
    path: '/pair/:key',
    name: 'validatePair',
    component: Pair,
  },
  {
    meta: {
      title: 'Pedido',
      context: 'public',
    },
    path: '/draft',
    name: 'draft',
    component: Draft,
  },
  {
    meta: {
      title: 'Catalogo',
      context: 'public',
    },
    path: '/catalog',
    name: 'catalog',
    component: Catalog,
  },
  {
    meta: {
      title: 'Mis pedidos de Activate',
      context: 'public',
    },
    path: '/customer/orders',
    name: 'customerOrders',
    component: CustomerOrders,
  },
  {
    meta: {
      title: 'Tablero',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/',
    name: 'Dashboard',
    component: Home,
  },
  {
    meta: {
      title: 'Variación de Precios',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/tracker',
    name: 'Tracker',
    component: Tracker,
  },
  {
    meta: {
      title: 'Tablero',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/index.html',
    name: 'index.html',
    component: Home,
  },
  {
    meta: {
      title: 'Productos',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    meta: {
      title: 'Preparar pedidos',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/prepare',
    name: 'Prepare',
    component: Prepare,
  },
  {
    meta: {
      title: 'Logística',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/logistic',
    name: 'Logistic',
    component: Logistic,
  },
  {
    meta: {
      title: 'Logística',
      requiresAuth: true,
      permission: ['AV', 'ADMIN'],
    },
    path: '/driver/logistic',
    name: 'DriverLogistic',
    component: DriverLogistic,
  },
  
  {
    meta: {
      title: 'Hojas de Ruta',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/roadmaps',
    name: 'Roadmaps',
    component: Roadmaps,
  },
  {
    meta: {
      title: 'Historial de pedidos',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/orders',
    name: 'Orders',
    component: Orders,
  },
  {
    meta: {
      title: 'Borradores',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/drafts',
    name: 'Drafts',
    component: Drafts,
  },
  {
    meta: {
      title: 'Clientes',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/customers',
    name: 'Customers',
    component: Customers,
  },
  {
    meta: {
      title: 'Cuentas',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
  },
  {
    meta: {
      title: 'Proveedores',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/providers',
    name: 'Providers',
    component: Providers,
  },
  {
    meta: {
      title: 'Categorías',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/categories',
    name: 'Categories',
    component: Categories,
  },
  {
    meta: {
      title: 'Compras',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/buys',
    name: 'Buys',
    component: Buys,
  },
  {
    meta: {
      hasCart: true,
      prefixNew: 'Nuevo ',
      prefixUpdate: 'Actualizando ',
      title: 'Pedido',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },

  {
    meta: {
      hasCart: true,
      prefixNew: 'Nueva ',
      prefixUpdate: 'Actualizando ',
      title: 'Compra',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/buy/cart',
    name: 'BuyCart',
    component: BuyCart,
  },
  {
    meta: {
      title: 'Historial de pedidos',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-orders',
    name: 'MyOrders',
    component: MyOrders,
  },
  {
    meta: {
      title: 'Mis pedidos',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-drafts',
    name: 'MyDrafts',
    component: MyDrafts,
  },

  {
    meta: {
      title: 'Mi comunidad',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-community',
    name: 'MyCommunity',
    component: MyCommunity,
  },
  {
    meta: {
      title: 'Estadísticas',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
  },
  {
    meta: {
      title: 'Finanzas',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/finances',
    name: 'Finances',
    component: Finances,
  },
  {
    meta: {
      title: 'Socios',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/partners',
    name: 'Partners',
    component: Partners,
  },
  {
    meta: {
      title: 'Mis números',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-statistics',
    name: 'MyIncomes',
    component: MyIncomes,
  },
  {
    meta: {
      title: 'A rendir',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-debt',
    name: 'MyDebt',
    component: MyDebt,
  },
  {
    meta: {
      title: 'Mi Tienda',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/my-shop',
    name: 'MyShop',
    component: MyShop,
  },
  {
    meta: {
      title: 'Mi tablero',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/provider/dashboard',
    name: 'ProviderDashboard',
    component: ProviderDashboard,
  },
  {
    meta: {
      title: 'Pedidos de activate',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/provider/orders',
    name: 'ProviderOrders',
    component: ProviderOrders,
  },
  {
    meta: {
      title: 'Mi Cuenta Corriente',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/provider/account',
    name: 'ProviderAccount',
    component: ProviderAccount,
  },
  {
    meta: {
      title: 'Mis Productos',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/provider/inventory',
    name: 'ProviderInventory',
    component: ProviderInventory,
  },
  {
    meta: {
      title: 'Configuración',
      requiresAuth: true,
      permission: ['ADMIN'],
    },
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
  {
    meta: {
      title: 'Mi Perfil',
      requiresAuth: true,
      permission: ['ADMIN', 'AV'],
    },
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

const defaultDocumentTitle = 'Activate';

router.afterEach(({ meta: { title } }) => {
  store.dispatch('Ui/toggleAsideMobile', false);

  if (title) {
    document.title = `${title} — ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

router.beforeEach((to, from, next) => {
  const routeRequireAuth = to.matched.some(
    (record) => record.meta.requiresAuth,
  );
  const routePermission = to.matched[0].meta.permission;
  const currentUser = store.getters['Auth/currentUser'];

  try {
    if (localStorage.token) {
      const decode = JSON.parse(atob(localStorage.token.split('.')[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        store.dispatch('Auth/logout');
      }
    }
  } catch (e) {
    console.log('UNEXPECTED ERROR. REVIEW ASAP', e);
    store.dispatch('Auth/logout');
  }

  if (to.path === '/') {
    if (currentUser) {
      if (currentUser.role === 'ADMIN') {
        next();
      }
      if (currentUser.role === 'AV') {
        if (currentUser.isDriver) {
          next('/driver/logistic');
          return;
        }

        // console.log(currentUser.isProvider);
        if (currentUser.isProvider) {
          next('/provider/dashboard');
          return;
        } 
        next('/my-shop');
        return;
      }
    }
  }

  if (routeRequireAuth) {
    if (store.getters['Auth/isLoggedIn']) {
      if (!routePermission.includes(currentUser.role)) {
        next('/');
      }

      next();
      return;
    }
    // console.log('returning to login to navigation guard');
    next('/login');
  } else {
    // console.log('continue');
    next();
  }
});

export default router;
