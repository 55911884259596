<template>
  <b-field :label="type" label-position="inside">
    <b-autocomplete
      v-model="thirdToSearch"
      ref="input"
      field="name"
      :keep-first="true"
      :append-to-body="false"
      :data="filteredThirds"
      @select="select"
      :loading="!thirds.length"
    >
      <template slot-scope="props">
        <div class="media">
          <div class="media-left"></div>
          <div class="media-content">
            {{ props.option.name }}
            <!-- <pre>{{ props.option }}</pre> -->
            <b-tag class="is-pulled-right" :type="parseTagType(props.option)">{{
              parseTag(props.option)
            }}</b-tag>
          </div>
        </div>
      </template></b-autocomplete
    >
  </b-field>
</template>

<script>
export default {
  name: 'ThirdLookup',
  props: {
    type: String,
    thirds: Array,
    third: Object,
  },
  components: {},
  data() {
    return {
      thirdToSearch: '',
    };
  },
  watch: {
    third() {
      if (this.third) return;
      // Third change and is null
      this.thirdToSearch = '';
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
  },
  computed: {
    filteredThirds() {
      return [...this.thirds]
        .reverse()
        .filter(
          ({ name }) => name.toLowerCase().indexOf(this.thirdToSearch.toLowerCase()) >= 0,
        );
    },
  },
  methods: {
    select(third) {
      this.$emit('select', third);
    },
    parseTagType({ isProvider, partner, tag }) {
      if (tag) return 'is-light';
      if (isProvider || partner) return 'is-danger is-light';
      return 'is-primary is-light';
    },
    parseTag({ isProvider, partner, tag }) {
      // if isProvider or is provider an has partner property
      if (tag) return 'Otros';
      if (isProvider || partner) return 'Proveedor';
      return 'Socio';
    },
  },
  mounted() {},
};
</script>
