<template>
  <b-modal :active="active" :has-modal-card="true" :can-cancel="['escape', 'outside']" @close="$emit('dismiss')">
    <div class="border-2 border-indigo-400 rounded-lg modal-card">
      <section class="modal-card-body" v-if="buy && buy.cart">
        <p class="title is-4">Aprobación compra {{ buy.number }}</p>

        <div class="shadow rounded-md px-2 py-0.5 my-1" v-for="(item, index) in items" :key="`control${item.number}`">
          <div class="flex flex-col justify-between my-4 space-y-2">
            <b-checkbox class="col-span-3" v-model="selected" type="is-success" :native-value="item"
              @input="handleSelectItem(item, index)">
              <span class="text-sm"><i>{{ item.number }} -</i> {{ item.name }}</span></b-checkbox>

            <b-field v-if="selected.includes(item)">
              <b-numberinput class="is-pulled-right" controls-position="compact" controls-rounded min="0"
                :step="item.unit === 'UNITY' ? 1 : 50" type="is-primary" v-model="item.quantity"
                @input="handleChangeQuantity(item, index)"></b-numberinput>
            </b-field>
          </div>
        </div>
        <div>
          <hr class="mt-4 divider" v-if="diffs.length" />
          <p class="mt-4 title is-4">Inventario faltante:</p>

          <p class="p-4 rounded-md shadow" v-if="diffs.every(({ diff }) => diff <= 0)">
            No hay faltantes <span class="ml-2">🎉</span>
          </p>

          <div v-for="({ item, diff }, index) in diffs" :key="`info${item.number}`">
            <div v-if="diff > 0" class="shadow rounded-md px-4 py-0.5">
              <div class="flex flex-col justify-between my-4 space-y-2">
                <p class="text-xs font-bold">
                  {{ diff }} {{ item | unit }} de
                  {{ item.name }}
                </p>
                <b-field>
                  <b-checkbox v-model="item.state" type="is-warning" true-value="pending" size="is-small"
                    false-value="shortage" @input="handleChangeState(item, index)">{{
    item.state === "pending" ? "Pendiente" : "Faltante"
  }}</b-checkbox>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <!-- <pre>{{itemsToApprove}}</pre> -->
      </section>

      <footer class="modal-card-foot">
        <button class="button" @click="$emit('dismiss')">Cancelar</button>
        <Button data-cy="approve-buy" :disabled="validPrepare" type="is-success"
          :onClickFn="handleApproveBuy">Aceptar</Button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import useBuyStore from '../../store/buy';

const {
  utils: {
    helpers: { inventoryDifference, friendlyUnity },
  },
} = ActivateSDK;

export default {
  name: 'Approve',
  props: {
    active: Boolean,
    buy: Object,
  },
  components: {},
  data() {
    return {
      buyStore: useBuyStore(),
      items: [],
      itemsToApprove: [],
      selected: [],
      maxQuantities: [],
    };
  },
  computed: {
    diffs() {
      return this.items.reduce((acc, item) => {
        const originalItem = JSON.parse(JSON.stringify(this.buy.cart.items)).find(
          ({ number }) => number === item.number,
        );

        const selectedItem = this.selected.find(
          ({ number }) => number === item.number,
        );

        const diff = selectedItem
          ? originalItem.quantity - item.quantity
          : item.originalQuantity;

        acc.push({
          item,
          diff,
        });
        return acc;
      }, []);
    },
    validPrepare() {
      return !this.selected.length;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'buy.number': function () {
      this.selected = [];
      if (!this.buy || !this.buy.cart) return;
      this.items = this.buy.cart.items;
      

      this.items = this.items.map(
        ({ number, product: { _id, name, unit }, quantity }) => ({
          _id,
          number,
          name,
          unit,
          quantity,
          state: 'pending',
          originalQuantity: quantity,
        }),
      );

      console.log('change number', this.items);
      this.itemsToApprove = this.items.map(({ quantity, ...rest }) => ({
        quantity: 0,
        ...rest,
      }));
    },
  },
  methods: {
    handleChangeState(item, index) {
      this.itemsToApprove[index].state = item.state;
    },
    handleChangeQuantity(item, index) {
      this.itemsToApprove[index].quantity = item.quantity;
    },
    handleSelectItem(item, index) {
      const isSelected = this.selected.findIndex(({ _id }) => _id === item._id) !== -1;
      if (isSelected) {
        this.itemsToApprove[index].quantity = item.quantity;
      } else {
        this.itemsToApprove[index].quantity = 0;
      }
    },
    async handleApproveBuy() {
      const { _id: id } = this.buy;
      const payload = this.itemsToApprove;

      const itemsDifference = inventoryDifference(this.buy.cart.items, this.itemsToApprove);

      const pendingItems = itemsDifference.filter((item) => item.state === 'pending' && item.diff > 0);

      if (pendingItems.length) {
        const pendingItemsHtml = pendingItems.map(({ name, unit, diff }) => `<b>${friendlyUnity({ product: { name, unit }, quantity: diff })}</b> - ${name}`).join('<br>');

        this.$buefy.dialog.confirm({
          title: 'Aprobar con pendientes',
          message:
            `¿Estás seguro de aprobar este pedido con pendientes?<br><br><b>ATENCIÓN!</b> Se va a generar una nueva compra con los siguientes items:<br><br>${pendingItemsHtml}`,
          confirmText: 'Preparar con pendientes',
          cancelText: 'Editar faltantes',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            this.$emit('dismiss');
            const updated = await this.buyStore.approve(id, payload);
            this.$emit('update', updated);
            return updated;
          },
        });
      } else {
        const updated = await this.buyStore.approve(id, payload);
        this.$emit('update', updated);
        this.$emit('dismiss');
      }
    },
  },
  mounted() { },
};
</script>
