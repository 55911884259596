<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary" /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import ApiService from '../../services/ApiService';
import { shortAddress } from '../../util/helpers';

const { CustomerService } = ApiService;

export default {
  name: 'ExportCustomers',
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    data() {
      return [];
    },
  },
  methods: {
    async downloadFile() {
      const { data: pagination } = await CustomerService.find();
      const customers = pagination.docs;

      const data = [
        {
          sheet: 'Clientes',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Nombre', value: 'name' },
            {
              label: 'Dirección',
              value: (customer) => shortAddress(customer.address),
            },
            { label: 'Ciudad', value: (customer) => customer.address?.city },
            { label: 'Area', value: (customer) => customer.address?.administrativeArea },
            { label: 'Telefono', value: 'phone' },
            { label: 'Email', value: 'email' },
            {
              label: 'Mayorista?',
              value: (customer) => (customer.isWholesaler ? 'M' : ''),
            },
            { label: 'av', value: (customer) => customer.main?.username },
            { label: 'segmento', value: (customer) => customer.segment?.name },
            { label: 'correo de contacto', value: (customer) => customer.contactEmail },
            { label: 'correo de autenticación', value: (customer) => customer.email },
            { label: 'verificado?', value: (customer) => (customer.verified ? 'SI' : 'NO') },
          ],
          content: customers,
        },
      ];
      const settings = {
        fileName: `Clientes - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>

