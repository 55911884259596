<template>
  <GenericModal :active="active" @dismiss="handleDismiss">
    <div v-if="getRoadmapsByDay(date).length">
      <b-field label="Hoja de ruta">
        <b-select
          v-model="componentRoadmap"
          placeholder="Seleccionar hoja de ruta"
        >
          <option
            v-for="roadmap in getRoadmapsByDay(date)"
            :value="roadmap._id"
            :key="roadmap._id"
          >
            #{{ roadmap.number }} - {{ roadmap.driver.name }} -
            {{ roadmap.notes }}
          </option>
        </b-select>
      </b-field>
      <Button type="is-success" :onClickFn="handleAddToRoadmap">Agregar</Button>
    </div>

    <div v-if="!getRoadmapsByDay(date).length">
      <p>No hay ninguna hoja de ruta creada para este día</p>
    </div>
  </GenericModal>
</template>

<script>
import { mapState } from 'pinia';
import { isSameDay } from 'date-fns';
import GenericModal from './GenericModal.vue';
import useRoadmapStore from '../store/roadmap';
import useDispatchStore from '../store/dispatch';

import { isOrder, isBuy } from '../util/helpers';

export default {
  name: 'AddToRoadmapModal',
  props: {
    active: Boolean,
    dispatch: Object,
    dispatchs: Array,
  },
  components: {
    GenericModal,
  },
  data() {
    return {
      dispatchStore: useDispatchStore(),
      roadmapStore: useRoadmapStore(),
      componentRoadmap: this.dispatch?.roadmap?._id,
    };
  },
  watch: {},
  computed: {
    ...mapState(useRoadmapStore, ['roadmaps']),
    isOrder() {
      return isOrder(this.dispatch);
    },
    isBuy() {
      return isBuy(this.dispatch);
    },
    date() {
      // eslint-disable-next-line no-nested-ternary
      return this.dispatch
        ? this.isOrder
          ? this.dispatch.delivery.date
          : this.dispatch.withdrawal.date
        : this.dispatchs
          ? this.dispatchs[0].delivery?.date || this.dispatchs[0].withdrawal?.date
          : new Date();
    },
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss');
    },
    async handleAddToRoadmap() {
      if (this.dispatch) {
        const updated = await this.dispatchStore.updateDriver(this.dispatch, {
          roadmap: this.componentRoadmap,
        });
        this.$emit('update', updated);
      } else if (this.dispatchs) {
        // eslint-disable-next-line no-restricted-syntax
        for (const dispatch of this.dispatchs) {
          // eslint-disable-next-line no-await-in-loop
          const updated = await this.dispatchStore.updateDriver(dispatch, {
            roadmap: this.componentRoadmap,
          });
          this.$emit('update', updated);
        }
      }

      this.handleDismiss();
    },
    handleSelect(driver) {
      this.driver = driver;
    },
    getRoadmapsByDay(day) {
      // eslint-disable-next-line array-callback-return
      return this.roadmaps.filter((roadmap) => {
        const date = new Date(roadmap.date);
        return isSameDay(date, new Date(day));
      });
    },
  },
  mounted() {},
};
</script>
