<template>
  <div>

    <Section>
      <div class="p-2 my-2 rounded shadow" v-if="!trackers.length">
        No hay variaciónes de precios pendientes de verificar
      </div>
      <div v-for="tracker in trackers" :key="tracker._id" class="grid p-2 my-2 rounded shadow ">
        <b-tag type="is-primary is-light">
          {{ tracker.date | shortDatetime }}
        </b-tag>
        <p class="font-bold grow" :class="[tracker.acknowledge ? 'text-purple-500' : 'text-red-500']"> {{
          tracker.product.name }}</p>
        <div class="grid grid-cols-2">
          <div>
            <p><span class="font-bold">Aportes:</span>
              {{  tracker.old && tracker.old.contributions }}% / {{ tracker.contributions }}%
            </p>
            <p><span class="font-bold">Costo:</span>{{  tracker.old && tracker.old.taxBase | currency }} / <span class="text-purple-400">{{ tracker.taxBase | currency }}</span></p>
            <p><span class="font-bold">Margenes:</span>

            <ul class="ml-4">
              <li v-for="margin of tracker.margins" :key="margin.quantity">
                {{ margin.quantity === 0 ? 'Mayorista' : `Minorista ${margin.quantity}` }} - {{ margin.value * 100 - 100 |
                  rounded }}%
              </li>
            </ul>
            </p>
            <p>
              <span class="font-bold">Usuario:</span> {{ tracker.partner.name }}
            </p>
          </div>
          <div class="flex items-center justify-center"><button v-if="!tracker.acknowledge"
              @click="handleAcknowledgeTracker(tracker._id)"
              class="w-16 h-16 border-2 border-purple-500 rounded-md"><b-icon size="is-large" class="text-purple-500 "
                icon="check"></b-icon></button></div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>

import ApiService from '../services/ApiService';
import Section from '../components/Section.vue';

const { TrackerService } = ApiService;

export default {
  name: 'Tracker',
  components: {
    Section,
  },
  data() {
    return {
      hasActions: false,
      trackers: [],
    };
  },
  computed: {
    currentPage() {
      return this.$route.meta.title;
    },
  },
  async mounted() {
    const { data: { docs: trackers } } = await TrackerService.find({ acknowledge: false, limit: 99999 });
    this.trackers = trackers;
  },
  methods: {
    async handleAcknowledgeTracker(id) {
      this.$buefy.dialog.confirm({
        title: 'Confirmar variación de precio',
        message:
          '¿Estás seguro de <b>confirmar</b> este variación?',
        confirmText: 'Está todo bien',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          await TrackerService.setAcknowledge(id);
          this.$buefy.notification.open({
            queue: false,
            message: 'Variación de precio confirmada',
            position: 'is-bottom',
            type: 'is-success',
            hasIcon: true,
          });
        },
      });
    },
  },
};
</script>
