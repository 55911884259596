<template>
  <div>
    <section class="py-4 sm:px-4">
      <Widgets>
        <!-- <Widget
          title="Facturación total"
          :value="statistics.billed"
          prefix="$"
        ></Widget> -->
        <Widget
          :title="
            dueBalance(statistics) < 0 && fetched ? 'Tenés a favor' : 'A rendir'
          "
          :value="Math.abs(dueBalance(statistics))"
          prefix="$"
        ></Widget>

        <Widget
          title="Ganancia Total"
          :value="statistics.profits"
          prefix="$"
        ></Widget>
        <Widget
          title="Pendiente de cobrar"
          :value="statistics.receivable"
          prefix="$"
        ></Widget>
      </Widgets>

      <div class="flex justify-end gap-2 mx-2">
        <ExportPartnerIncomes :entries="this.partnerAudit.transactions"></ExportPartnerIncomes>
        <Button class="" :onClickFn="performPartnerAudit"
          >Actualizar</Button
        >
      </div>
     <Layout>
        <AuditPartnerList :transactions="this.partnerAudit.transactions"></AuditPartnerList>
      </Layout>
    </section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import Widgets from '../components/Widgets/Widgets.vue';
import Widget from '../components/Widgets/Widget.vue';
import ApiService from '../services/ApiService';
import { round } from '../util/helpers';
import useAuditStore from '../store/audit';
import ExportPartnerIncomes from '../components/ExportPartnerIncomes.vue';
import AuditPartnerList from '../components/AuditPartnerList.vue';
import Layout from '../components/Layout.vue';

const { StatisticsService } = ApiService;

export default {
  name: 'MyIncomes',
  components: {
    Widgets,
    Widget,
    AuditPartnerList,
    ExportPartnerIncomes,
    Layout,
  },
  data() {
    return {
      auditStore: useAuditStore(),
      statistics: {},
      fetched: false,
    };
  },
  computed: {
    ...mapState(useAuditStore, ['partnerAudit']),
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  async mounted() {
    this.getNext();
    this.auditStore.getPartnerAudit({
      limit: 1,
      transactionsLimit: 99999,
      partner: this.currentUser._id,
    });

    await this.fetchStatistics();
    this.fetched = true;
  },

  methods: {
    async performPartnerAudit() {
      await this.auditStore.performPartnerAudit({
        partner: this.currentUser._id,
      });
      await this.fetchStatistics();
    },
 
    async fetchStatistics() {
      const { data: statistics } = await StatisticsService.myFinances();
      this.statistics = statistics;
    },
    dueBalance(partner) {
      return round(partner.dueBalance);
    },
    getNext() {},
  },
};
</script>

