<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <ExportAccounts
          v-if="accounts.length"
          :accounts="accounts"
        ></ExportAccounts>
        <Button class="ml-4" :onClickFn="performCustomerAudit"
          >Actualizar</Button
        >
        <b-button data-cy="new-customer" class="ml-4" @click="handleNewCustomer"
          >Nuevo Cliente</b-button
        >
      </div>
    </hero-bar>
    <Section>
      <Widgets>
        <Widget
          title="Pendiente de cobrar a tus clientes"
          :value="statistics.receivable"
          prefix="$"
        ></Widget>
      </Widgets>
      <!-- <SearchBar :store="accountStore" :localSearch="true"></SearchBar> -->
      <Layout>
        <List :componentClass="['gap-2']" :loading="isLoading.accounts">
          <p class="px-2 text-2xl font-bold">🫶🏽 Tu comunidad</p>

          <div>
            <div class="my-2">
              <input
                v-model="customerToSearch"
                type="email"
                name="email"
                id="email"
                class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Buscar cliente"
              />
            </div>
          </div>

          <!-- <pre>{{ filteredAccounts }}</pre> -->
          <div v-for="account in filteredAccounts" :key="account.customer._id">
            <template>
              <AccountCard
                :segments="segments"
                :account="account"
                :loading="isLoading.accounts"
              ></AccountCard>
            </template>
          </div>
        </List>
      </Layout>
    </Section>
    <NewCustomer
      :active="newCustomerModalIsActive"
      @dismiss="newCustomerModalIsActive = false"
      @created="handleCustomerCreated"
    ></NewCustomer>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../components/HeroBar.vue';
import AccountCard from '../components/Customer/mobile/AccountCard.vue';
import NewCustomer from '../components/Customer/NewCustomer.vue';
import useAccountStore from '../store/account';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
// import SearchBar from '../components/SearchBar.vue';
import Widget from '../components/Widgets/Widget.vue';
import Widgets from '../components/Widgets/Widgets.vue';
import ApiService from '../services/ApiService';
import Section from '../components/Section.vue';
import ExportAccounts from '../components/Customer/ExportAccounts.vue';

const { StatisticsService } = ApiService;
const { SegmentService } = ApiService;

export default {
  name: 'MyCommunity',
  components: {
    HeroBar,
    // SearchBar,
    Section,
    Widget,
    Widgets,
    NewCustomer,
    AccountCard,
    Layout,
    List,
    ExportAccounts,
  },
  data() {
    return {
      accountStore: useAccountStore(),
      newCustomerModalIsActive: false,
      newTransaction: {
        amount: 0,
      },
      segments: [],
      hasActions: true,
      statistics: {},
      customerToSearch: '',
    };
  },
  computed: {
    filteredAccounts() {
      return [...this.accounts].filter(
        ({ customer: { name, email } }) => name.toLowerCase().indexOf(this.customerToSearch.toLowerCase())
            >= 0
          || (email
            && email.toLowerCase().indexOf(this.customerToSearch.toLowerCase())
              >= 0),
      );
    },
    ...mapState(useAccountStore, ['options']),
    ...mapState(useAccountStore, ['pagination']),
    ...mapState(useAccountStore, ['isLoading']),
    ...mapState(useAccountStore, ['isFetched']),
    ...mapState(useAccountStore, ['accounts']),
    ...mapState(useAccountStore, ['selected']),
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  async mounted() {
    window.onscroll = () => {};
    this.accountStore.find({
      partner: this.currentUser._id,
      populatePartner: true,
      populateCustomer: true,
    });
    this.fetchStatistics();
    this.fetchSegments();
  },
  methods: {
    async fetchSegments() {
      const { data: segments } = await SegmentService.find();
      this.segments = segments;
    },
    async performCustomerAudit() {
      await this.accountStore.performCustomerAudit({
        partner: this.currentUser._id,
      });
      await this.fetchStatistics();
    },
    handleSelectAccount(account) {
      this.selectedCustomer = account.customer;
    },
    handleNewCustomer() {
      this.newCustomerModalIsActive = true;
    },
    handlePayCreated() {
      // this.accountStore.update()
    },
    handleCustomerCreated() {
      this.newCustomerModalIsActive = false;
      // TODO: We can refactor this.
      // TODO: Receiving customer from response and mocking an account with balance 0
      // this.fetchAccounts();
    },
    async fetchStatistics() {
      const { data: statistics } = await StatisticsService.myFinances();
      this.statistics = statistics;
    },
  },
};
</script>
