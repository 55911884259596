<template>
  <b-modal
    :active="active"
    :has-modal-card="true"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div class="border-2 border-indigo-400 rounded-lg modal-card">
      <section class="modal-card-body" v-if="provider">
        <p class="title is-4">Nueva compra a {{ provider.name }}</p>

        <div
          class="shadow rounded-md px-2 py-0.5 my-1"
          v-for="(item, index) in preCartItems"
          :key="`control${index}`"
        >
          <div class="flex flex-col justify-between my-4 space-y-2">
            <b-checkbox
              class="col-span-3"
              v-model="selected"
              type="is-success"
              :native-value="item"
            >
              <span class="text-sm"
                ><i>{{ index + 1 }} -</i> {{ item.product.name }}</span
              ></b-checkbox
            ><span
              class="ml-2 cursor-pointer has-text-danger"
              @click.stop="setCriticalDifference(index)"
              >(Crítico {{ item.product.inventory[0].critical }}
              {{ item.product | unit }})
            </span>
            <b-field v-if="selected.includes(item)">
              <b-numberinput
                class="is-pulled-right"
                controls-position="compact"
                controls-rounded
                min="0"
                :step="item.product.unit === 'UNITY' ? 1 : 50"
                type="is-primary"
                v-model="item.quantity"
              ></b-numberinput>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" :disabled="false" @click="$emit('dismiss')">
          Cancelar
        </button>
        <Button
          data-cy="accept-preCart"
          :once="true"
          type="is-success"
          :onClickFn="handleNewBuy"
          :disabled="!selected.length"
          >Aceptar</Button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PreCart',
  props: {
    provider: Object,
    active: Boolean,
    items: Array,
  },
  components: {},
  data() {
    return {
      preCartItems: [],
      selected: [],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'provider._id': function () {
      if (!this.provider) return null;
      this.preCartItems = this.items;
      return this.items;
    },
    items() {
      this.preCartItems = this.items;
      this.selected = this.items;
    },
  },
  computed: {
    isAbleToCreateBuy() {
      return this.selected.length;
    },
  },
  methods: {
    handleNewBuy() {
      this.preCartItems = this.selected.filter(
        ({ quantity }) => quantity !== 0,
      );
      this.$router.push({
        name: 'BuyCart',
        params: { items: this.selected, provider: this.provider },
      });
    },
    setCriticalDifference(index) {
      const item = this.preCartItems[index];
      const { critical } = item.product.inventory[0];
      const { theoretical } = item.product.inventory[0];
      const criticalDifference = critical - theoretical;
      this.preCartItems[index].quantity = criticalDifference;
    },
  },
  mounted() {},
};
</script>
