<template>
  <div
    class="p-5 mb-4 bg-white border-2 border-red-500 shadow-sm  rounded-xl lg:px-8"
    @click.stop="active = !active"
  >
    <p class="text-sm font-bold text-center text-red-500">
      Ups, tenemos stock reales negativos. Hay que ajustar stocks
    </p>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <div
            class="grid grid-cols-4 text-xs rounded-md place-content-between"
          >
            <div class="col-span-3 px-2 font-bold">Producto</div>
            <div class="col-span-1 font-bold tracking-tight text-right">
              Stock Real
            </div>
          </div>

          <div
            v-for="product of products"
            :key="product._id"
            class="grid grid-cols-4 text-xs rounded-md place-content-between"
          >
            <div class="col-span-3 px-2 font-medium truncate">
              {{ product.name }}
            </div>

            <div
              class="col-span-1 font-bold tracking-tight text-right"
              :class="
                product.inventory[0].theoretical >= 0
                  ? 'text-yellow-500'
                  : 'text-red-500'
              "
            >
              {{
                { product, quantity: product.inventory[0].real } | friendlyUnity
              }}
            </div>
          </div>
        </section>
        <!-- <footer class="modal-card-foot">
          <Button
            data-cy="approve-buy"
            :once="true"
            type="is-success"
            :onClickFn="() => {}"
            >Ajustar stocks</Button
          >
        </footer> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'NegativeRealAlert',
  components: {},
  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
  data() {
    return {
      active: false,
      formProps: {
        email: 'evan@you.com',
        password: 'testing',
      },
    };
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
