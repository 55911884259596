<template>
  <b-field :label="label">
    <b-autocomplete
      data-cy="products-lookup"
      v-model="productToSearch"
      ref="productNameInput"
      field="name"
      :data="filteredProducts"
      @select="select"
      :keep-first="true"
      icon="seed"
      :loading="!products.length"
    ></b-autocomplete>
  </b-field>
</template>

<script>
import ApiService from '../../services/ApiService';

const { ProductService } = ApiService;
export default {
  name: 'ProductLookup',
  props: {
    label: {
      type: String,
      default: 'Buscar un producto',
    },
    provider: String,
    selectedProduct: Object,
    unitFilter: String,
  },
  components: {},
  data() {
    return {
      productToSearch: '',
      products: [],
    };
  },

  computed: {
    filteredProducts() {
      if (!this.productToSearch) return [];
      return [...this.products]
        .filter(({ unit }) => {
          if (!this.unitFilter) return true;
          return unit === this.unitFilter;
        })
        .reverse()
        .filter(({ provider }) => {
          if (!this.provider) return true;

          // eslint-disable-next-line no-underscore-dangle
          return provider && provider._id === this.provider;
        })
        .filter(
          ({ name }) => name.toLowerCase().indexOf(this.productToSearch.toLowerCase()) >= 0,
        )
        .sort((a, b) => {
          const name1 = a.name.toLowerCase();
          const name2 = b.name.toLowerCase();
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        });
    },
  },
  watch: {
    selectedProduct() {
      if (!this.selectedProduct) {
        this.productToSearch = '';
        this.$nextTick(() => {
          this.$refs.productNameInput.focus();
        });
      }
    },
  },
  methods: {
    select(product) {
      if (product && this.productToSearch && this.filteredProducts.length) {
        this.$emit('select', JSON.parse(JSON.stringify(product)));
      }
    },
  },
  async mounted() {
    const { data: pagination } = await ProductService.find();
    this.products = pagination.docs;
  },
};
</script>
