<template>
  <button
    class="inline px-2 py-1 text-sm font-medium text-black border-2 border-indigo-400 rounded-lg "
    @click="handleViewDetails(element)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ƒ
      class="inline w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
      />
    </svg>
    <span class="ml-2">{{ element.cart.items.length }} items</span>
  </button>
</template>

<script>
export default {
  name: 'ItemsComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleViewDetails(element) {
      this.$emit('click', element);
    },
  },
  watch: {},
};
</script>
