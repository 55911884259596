<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :width="640"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4">
        <section
          class="grid border-2 border-indigo-400 rounded-lg  modal-card-body place-items-center"
        >
          <p class="mb-4 font-bold text-center">{{ link.label }}</p>
          <qrcode-vue :value="link.url" :size="256" level="H" />
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'QRCode',
  components: {
    QrcodeVue,
  },
  props: {
    active: Boolean,
    link: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
