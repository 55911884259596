<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportProviderCashFlows',
  props: {
    flows: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    data() {
      return [];
    },
    sortedData() {
      return [...this.flows].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
    },
  },
  methods: {
    isFlow({ doc }) {
      return doc === 'providercashflows';
    },
    getInvoiceLabel({ type }) {
      return {
        FC: 'Factura emitida',
        NC: 'Nota de crédito',
        ND: 'Nota de débito',
      }[type];
    },
    getFlowSymbol({ type, doc }) {
      if (this.isFlow({ doc })) return -1;
      return { FC: 1, ND: 1, NC: -1 }[type];
    },

    async downloadFile() {
      const data = [
        {
          sheet: 'Mi Cuenta Corriente',
          columns: [
            {
              label: 'Día',
              value: (row) => new Date(row.date),
              format: 'DD',
            },
            {
              label: 'Mes',
              value: (row) => new Date(row.date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: (row) => new Date(row.date),
              format: 'YYYY',
            },
            {
              label: 'Concepto',
       
              value: (element) => (this.isFlow(element)
                ? 'Recibiste un pago de Activate'
                : this.getInvoiceLabel(element)),
            },
            {
              label: 'Tipo',

              value: (element) => (!this.isFlow(element) && element.isBudget ? 'PRESUPUESTO' : ''),
            
            },
            {
              label: '#',
              value: (element) => element.code || element.paymentMethod,
            },
            {
              label: 'Importe',
              value: (element) => (element.total || element.amount || 0)
                * this.getFlowSymbol(element),
              format: '$0.00',
            },
            {
              label: '----',
              value: '',
            },
            {
              label: 'Observaciones',
              value: 'description',
            },
          ],
          content: this.sortedData,
        },
      ];
      const settings = {
        fileName: `Mi Cuenta Corriente - ${new Date().toLocaleDateString(
          'es',
        )}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
