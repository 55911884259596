<!-- eslint-disable max-len -->
<template>
  <div>
    <div class="p-4 my-4 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between">
        <h3 class="text-xl font-bold leading-none text-gray-900">
          Movimientos de Inventario
        </h3>
        <a
          href="#"
          @click="handleFetchRecords"
          class="text-sm font-medium text-purple-500 hover:underline"
        >
          <b-icon icon="refresh"></b-icon>
        </a>
      </div>
      <div v-if="isFetched && !records.length">No se registran movimientos</div>
      <div v-if="isFetched && records.length" class="grid grid-cols-2 gap-4">
        <ul
          role="list"
          class="mt-4 divide-y divide-gray-200 dark:divide-gray-700"
        >
          <li
            class="py-3 sm:py-4"
            v-for="element in theoreticalRecords"
            :key="element._id"
          >
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-xs text-gray-900 truncate">
                  {{ element.date | shortDate }}
                </p>
                <p class="text-sm font-bold text-gray-900 truncate">
                  {{ parseInventory(element.inventory) }}
                </p>
                <p class="text-sm font-medium text-gray-900 truncate">
                  <span
                    v-if="element.metadata"
                    class="is-clickable"
                    @click="
                      !element.third &&
                        fetchThirdDetail(element.metadata, element._id)
                    "
                    ><span class="text-purple-500">{{
                      !element.third ? 'Ver detalle' : element.third.name
                    }}</span>
                  </span>
                </p>

                <p class="text-sm text-gray-500 truncate">
                  {{ parseConcept(element.concept) }}
                  {{ parseMetadataNumber(element.metadata) }}
                </p>
              </div>
              <div
                class="inline-flex items-center text-base font-semibold text-gray-900 "
              >
                <p
                  class="text-lg font-bold"
                  :class="[
                    element.quantity >= 0 ? `text-green-500` : 'text-red-500',
                  ]"
                >
                  {{element.quantity >= 0 ? `+` : '',}}
                  {{ element.quantity }}
                  <span class="font-normal text-black"
                    >/ {{ element.current }}</span
                  >
                </p>
              </div>
            </div>
          </li>
        </ul>
        <ul
          role="list"
          class="mt-4 divide-y divide-gray-200 dark:divide-gray-700"
        >
          <li
            class="py-3 sm:py-4"
            v-for="element in realRecords"
            :key="element._id"
          >
            <div class="flex items-center space-x-4">
              <div class="flex-1 min-w-0">
                <p class="text-xs text-gray-900 truncate">
                  {{ element.date | shortDate }}
                </p>
                <p class="text-sm font-bold text-gray-900 truncate">
                  {{ parseInventory(element.inventory) }}
                </p>
                <p class="text-sm font-medium text-gray-900 truncate">
                  <span
                    v-if="element.metadata"
                    class="is-clickable"
                    @click="
                      !element.third &&
                        fetchThirdDetail(element.metadata, element._id)
                    "
                    ><span class="text-purple-500">{{
                      !element.third ? 'Ver detalle' : element.third.name
                    }}</span>
                  </span>
                </p>

                <p class="text-sm text-gray-500 truncate">
                  {{ parseConcept(element.concept) }}
                  {{ parseMetadataNumber(element.metadata) }}
                </p>
              </div>
              <div
                class="inline-flex items-center text-base font-semibold text-gray-900 "
              >
                <p
                  class="text-lg font-bold"
                  :class="[
                    element.quantity >= 0 ? `text-green-500` : 'text-red-500',
                  ]"
                >
                  {{element.quantity >= 0 ? `+` : '',}}
                  {{ element.quantity }}
                  <span class="font-normal text-black"
                    >/ {{ element.current }}</span
                  >
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../../services/ApiService';
import { parseConcept } from '../../../util/helpers';

const { RecordService } = ApiService;
const { ProviderService } = ApiService;
const { CustomerService } = ApiService;

export default {
  name: 'RecordList',
  props: {
    product: Object,
    loading: Boolean,
  },
  components: {},
  data() {
    return {
      records: [],
      isLoading: false,
      isFetched: false,
    };
  },
  computed: {
    theoreticalRecords() {
      return this.records.filter(
        ({ inventory }) => inventory === 'THEORETICAL',
      );
    },
    realRecords() {
      return this.records.filter(({ inventory }) => inventory === 'REAL');
    },
  },
  mounted() {},
  watch: {
    product() {
      if (!this.product) return;
      this.isFetched = false;
    },
  },
  methods: {
    isIngress(flow) {
      return flow === 'INGRESS';
    },
    parseConcept,
    async fetchRecords({ _id: id }) {
      this.isLoading = true;
      const { data: { docs: records } } = await RecordService.find({ product: id, limit: 1000 });
      this.isLoading = false;
      this.records = records;
    },
    async handleFetchRecords() {
      await this.fetchRecords(this.product);
      this.isFetched = true;
    },
    parseFlow(flow) {
      return {
        EGRESS: 'Egreso',
        INGRESS: 'Ingreso',
      }[flow];
    },
    parseInventory(inventory) {
      return {
        REAL: 'Real',
        THEORETICAL: 'Teórico',
      }[inventory];
    },
    parseMetadataNumber(metadata) {
      if (metadata && metadata.number) {
        return metadata.number;
      }
      return '';
    },
    parseMetadataThirdParty({ provider, customer } = {}) {
      if (provider) return provider;
      if (customer) return customer;
      return 'N/A';
    },

    async fetchProvider(id) {
      const { data: provider } = await ProviderService.findOne(id);
      return provider;
    },
    async fetchCustomer(id) {
      const { data: customer } = await CustomerService.findOne(id);
      return customer;
    },
    async fetchThirdDetail({ provider, customer } = {}, recordId) {
      if (
        (typeof provider === 'object' || typeof provider === 'function')
        && provider !== null
      ) {
        // eslint-disable-next-line no-param-reassign
        provider = provider._id;
      }
      if (
        (typeof customer === 'object' || typeof customer === 'function')
        && customer !== null
      ) {
        // eslint-disable-next-line no-param-reassign
        customer = customer._id;
      }
      this.isLoading = true;
      // eslint-disable-next-line no-param-reassign
      provider = provider ? await this.fetchProvider(provider) : null;
      // eslint-disable-next-line no-param-reassign
      customer = customer ? await this.fetchCustomer(customer) : null;

      this.isLoading = false;

      const index = this.records.findIndex(({ _id }) => _id === recordId);
      const recordsCopy = [...this.records];
      recordsCopy[index] = {
        ...recordsCopy[index],
        third: provider || customer,
      };
      this.records = recordsCopy;
    },
  },
};
</script>
