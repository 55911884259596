<template>
  <div>
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary" /></b-button>

  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import { pick } from 'lodash';
import ApiService from '../../services/ApiService';
import { ORDER_STATES } from '../../util/constants';
import { sum } from '../../util/helpers';

const { OrderService } = ApiService;

const { CANCELLED } = ORDER_STATES;

export default {
  name: 'ExportOrders',
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    analyticColumns() {
      return [
        { label: 'PEDIDO', value: (order) => this.getOrderType(order) },
        { label: 'ID', value: 'number' },
        {
          label: 'Fecha',
          value: (order) => new Date(order.date),
          format: 'DD',
        },
        {
          label: 'Periodo',
          value: (order) => new Date(order.date)
            .toLocaleDateString('es-ES', {
              month: 'long',
            })
            .toUpperCase(),
        },
        {
          label: 'AÑO',
          value: (order) => new Date(order.date).toLocaleDateString('es-ES', {
            year: 'numeric',
          }),
        },
        { label: 'Cliente', value: (order) => order.customer.name },
        { label: 'Vendedor', value: (order) => order.partner.username },
        {
          label: 'BI',
          value: (order) => this.setDefaultIfCancelled(order, 'cost'),
        },
        {
          label: 'Aportes',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            order.contribution - order.delivery.internPrice,
          ),
        },
        {
          label: 'Logística',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            order.logistic + order.delivery.internPrice,
          ),
        },

        {
          label: 'IMP',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            sum(order.cart.items, null, (item) => (item.detail.taxes ? item.detail.taxes.total : 0)),
          ),
        },

        {
          label: 'Total a rendir',
          value: (order) => this.setDefaultIfCancelled(order, 'toPay'),
        },
        {
          label: 'Ganancia',
          value: (order) => this.setDefaultIfCancelled(order, 'profit'),
        },
        {
          label: 'Total',
          value: (order) => this.setDefaultIfCancelled(order, 'total'),
        },
        { label: 'Tipo de envio', value: (order) => order.delivery.name },
      ];
    },
    daysWithMoreThan1OrdersPerAddress() {
      return pick(this.ordersByDeliveryAddress, Object.keys(this.ordersByDeliveryAddress).filter((address) => this.ordersByDeliveryAddress[address].length > 1));
    },
    ordersByDeliveryAddress() {
      const orders = this.orders.reduce((acc, order) => {
        acc[order.delivery.address.label] = acc[order.delivery.address.label] || [];
        acc[order.delivery.address.label].push(order);

        return acc;
      }, {});

      return orders;
    },
    content() {
      return this.parseOrdersToContent(this.orders);
    },
  },
  methods: {
    parseOrdersToContent(orders) {
      return orders.reduce((acc, order) => {
        const {
          cart: { items },
        } = order;
        acc.push(
          ...items.map((item) => ({
            type: this.getOrderType(order),
            number: order.number,
            date: new Date(order.date),
            customer: order.customer.name,
            product: item.product.name,
            category: item.product.category.name,
            section: item.product.category.section,
            raw: item.product,
            quantity: item.quantity,
            price: item.price,
            cost: item.detail.cost,
            taxBase: item.detail.taxBase,
            taxes: item.detail.taxes ? item.detail.taxes.total : 0,
            contribution: item.detail.contribution + order.delivery.internPrice,
            profit: item.detail.profit,
            logistic: order.logistic - order.delivery.internPrice,
            partner: order.partner.username,
            deliveryDate: new Date(order.delivery.date),
            deliveryMethod: order.delivery.name,
            total: order.total,
          })),
        );
        return acc;
      }, []);
    },
    async fetchLastOrders() {
      const { data: pagination } = await OrderService.find({
        page: 1,
        limit: 400,
      });

      return pagination.docs;
    },
    setDefaultIfCancelled(order, key, defaultValue = 0) {
      // eslint-disable-next-line no-nested-ternary
      return order.state === CANCELLED ? 0 : key ? order[key] : defaultValue;
    },
    getOrderType(order) {
      let type = '';

      if (order.isSupply) {
        type += 'AB-';
      }
      if (!order.isSupply && order.cart.items.some(({ isWholesaler }) => isWholesaler)) {
        type += 'MAY-';
      }
      if (!order.isSupply && order.cart.items.some(({ isWholesaler }) => !isWholesaler)) {
        type += 'MIN-';
      }
      if (order.isBudget) {
        type += 'PR';
      }
      if (!order.isBudget) {
        type += 'FC';
      }
      return type;
    },
    async downloadFile() {
      const lastOrders = await this.fetchLastOrders();
      const data = [
        {
          sheet: 'v1',
          columns: [
            { label: 'ID', value: 'number' },
            {
              label: 'Fecha',
              value: (row) => row.date,
              format: 'DD',
            },
            {
              label: 'Periodo',
              value: (row) => row.date
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'AÑO',
              value: (row) => row.date.toLocaleDateString('es-ES', {
                year: 'numeric',
              }),
            },
            { label: 'Cliente', value: 'customer' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
            { label: 'Precio', value: 'price' },
            { label: 'OK', value: '' },
            { label: 'Aportes', value: 'contribution' },
            { label: 'Ganancia', value: 'profit' },
            { label: 'Vendedor', value: 'partner' },
            {
              label: 'Fecha de entrega',
              value: (row) => row.deliveryDate
                .toLocaleDateString('es-ES', {
                  weekday: 'long',
                })
                .toUpperCase(),
            },
            { label: 'Metodo de entrega', value: 'deliveryMethod' },
          ],
          content: [...this.content].sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Analítico',
          columns: this.analyticColumns,
          content: [...this.orders].sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Analítico +',
          columns: this.analyticColumns,
          content: lastOrders.sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Preparar Z',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Cliente', value: 'customer' },
            { label: 'AV', value: 'partner' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
            { label: 'Categoría', value: 'category' },
          ],
          content: [...this.content].sort((a, b) => {
            // If customers are equal, then compare by product
            if (a.product < b.product) {
              return -1;
            }
            if (a.product > b.product) {
              return 1;
            }
            // Return 0 if both customer and product are the same
            return 0;
          }).sort((x, y) => Number(x.raw.splitPackage) - Number(y.raw.splitPackage)),
        },
        {
          sheet: 'Preparar A',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Cliente', value: 'customer' },
            { label: 'AV', value: 'partner' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
            { label: 'Categoría', value: 'category' },
          ],
          content: [...this.content].sort((a, b) => {
            // First compare by customer
            if (a.customer < b.customer) {
              return -1;
            }
            if (a.customer > b.customer) {
              return 1;
            }
            // If customers are equal, then compare by product
            if (a.product < b.product) {
              return -1;
            }
            if (a.product > b.product) {
              return 1;
            }
            // Return 0 if both customer and product are the same
            return 0;
          }),
        },
        {
          sheet: 'Identificador',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Cliente', value: (order) => order.customer.name },
            { label: 'Vendedor', value: (order) => order.partner.username },
            { label: 'Items', value: (order) => order.cart.items.length },
          ],
          content: [...this.orders].sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Control de Stock',
          columns: [
            { label: 'Producto', value: (product) => product.name },
            // { label: 'Proveedor', value: (product) => product.provider.name },
            { label: 'Cantidad', value: 'quantity' },
            {
              label: 'Stock real',
              value: (product) => product.inventory[0].real,
            },
            {
              label: 'Diferencia',
              value: (product) => product.inventory[0].real - product.quantity,
            },
          ],
          content: [
            ...this.content.reduce((acc, { raw: product, quantity }) => {
              // eslint-disable-next-line no-underscore-dangle
              const index = acc.findIndex(({ _id }) => _id === product._id);
              if (index === -1) {
                acc.push({
                  ...product,
                  quantity,
                });
              } else {
                acc[index].quantity += quantity;
              }
              return acc;
            }, []),
          ].sort((a, b) => (a.product < b.product ? -1 : 1)),
        },
        ...(Object.keys(this.daysWithMoreThan1OrdersPerAddress).map((addressId) => ({
          sheet: this.daysWithMoreThan1OrdersPerAddress[addressId][0].delivery.address.street.substring(0, 31), // Sheet names cannot exceed 31 chars
          columns: [
            { label: 'Producto', value: 'name' },
            { label: 'Categoría', value: (product) => product.category.name },
            { label: 'Cantidad', value: 'quantity' },

          ],
          content: [...this.parseOrdersToContent(this.daysWithMoreThan1OrdersPerAddress[addressId]).reduce((acc, { raw: product, quantity }) => {
            // eslint-disable-next-line no-underscore-dangle
            const index = acc.findIndex(({ _id }) => _id === product._id);
            if (index === -1) {
              acc.push({
                ...product,
                quantity,
              });
            } else {
              acc[index].quantity += quantity;
            }
            return acc;
          }, [])].sort((a, b) => (a.name < b.name ? -1 : 1)),
        }))),
      ];
      const settings = {
        fileName: `Pedidos - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
