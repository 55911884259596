/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

const cart = JSON.parse(localStorage.getItem('cart'));
if (!cart) {
  localStorage.setItem('cart', JSON.stringify({ items: [] }));
}
const initialState = cart || { items: [] };

const state = initialState;
const mutations = {
  /* A fit-them-all commit */
  basic(state, payload) {
    state[payload.key] = payload.value;
  },
  push(state, item) {
    state.items.push(item);
  },
  pop(state, index) {
    state.items.splice(index, 1);
  },
  update(state, payload) {
    const cart = payload || state;
    localStorage.setItem('cart', JSON.stringify(cart));
  },
  remove(state) {
    state = { items: [] };
    localStorage.setItem('cart', JSON.stringify(state));
  },
};

const actions = {
  push({ commit }, item) {
    commit('push', item);
    commit('update');
  },
  pop({ commit }, index) {
    commit('pop', index);
    commit('update');
  },
  update({ commit }, cart) {
    commit('update', cart);
  },
  remove({ commit }) {
    commit('remove');
  },
};

const getters = {
  currentCart(state) {
    return state;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
