<template>
  <b-field label="Proveedor">
    <b-autocomplete
      data-cy="provider-lookup"
      v-model="providerToSearch"
      :data="filteredProviders"
      :keep-first="true"
      placeholder="Proveedor"
      field="name"
      @select="select"
      :loading="!providers.length"
      :disabled="disabled"
    >
      <template slot-scope="props">
        <div class="media">
          <div class="media-left"></div>
          <div class="media-content" data-cy="provider-options">
            {{ props.option.name }}
            <br />
            <small v-if="props.option.address && props.option.phone">
              {{ props.option.address.street }},
              <b>{{ props.option.address.city }}</b>
              <br />
              {{ props.option.phone }}
            </small>
          </div>
        </div>
      </template>
      <template slot="empty">No results for {{ providerToSearch }}</template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import ApiService from '../../services/ApiService';

const { ProviderService } = ApiService;

export default {
  name: 'ProviderLookup',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    provider: {
      type: Object,
      default: () => {},
    },
  },
  components: {},
  data() {
    return {
      providerToSearch: (this.provider && this.provider.name) || '',
      providers: [],
    };
  },
  watch: {},
  computed: {
    filteredProviders() {
      return [...this.providers]
        .reverse()
        .filter(
          ({ name }) => name.toLowerCase().indexOf(this.providerToSearch.toLowerCase())
            >= 0,
        );
    },
  },
  methods: {
    select(provider) {
      this.$emit('select', provider);
      this.$emit('input', provider);
    },
    handleRemoveProvider() {
      this.providerToSearch = '';
      this.$emit('remove');
    },
  },
  async mounted() {
    const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
    this.providers = providers;
  },
};
</script>
