<template>
  <div v-if="active" class="grid p-8 place-items-center">
    <div class="dot-pulse"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadingTable',
  props: {
    active: Boolean,
    half: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
