<template>
  <div>
    <div class="card">
      <div class="card-content">
        <b-tag
          v-if="category.section"
          class="is-pulled-right"
          type="is-primary is-light"
        >
          {{ category.section }}</b-tag
        >
        <p class="title is-4">
          {{ category.name }}
        </p>
        <p v-if="splitPackage">Paquete separado</p>

      </div>
      <footer class="card-footer" v-if="!isUpdating">
        <a @click="handleUpdate()" class="card-footer-item">Editar</a>
      </footer>
      <footer class="card-footer" v-if="isUpdating">
        <a @click="handleCancel()" class="card-footer-item">Cancelar edición</a>
      </footer>
    </div>

    <UpdateForm
      :category="category"
      :sections="sections"
      :visible="isUpdating"
      @onUpdate="handleOnUpdate"
    />
  </div>
</template>

<script>
import UpdateForm from './UpdateForm.vue';

export default {
  name: 'CategoryInfo',
  props: {
    category: Object,
    sections: Array,
  },
  components: {
    UpdateForm,
  },
  data() {
    return {
      isUpdating: false,
      isMarginsActive: false,
      openPrices: false,
      updated: null,
    };
  },
  computed: {},
  methods: {
    handleOnUpdate(id) {
      this.$emit('reload', id);
      this.isUpdating = false;
    },
    handleCancel() {
      this.isUpdating = false;
    },
    handleUpdate() {
      this.isUpdating = true;
    },
  },
};
</script>
