<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :width="640"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4" v-if="product">
        <section class="border-2 border-indigo-400 rounded-lg modal-card-body">
          <div class="flex flex-col my-2">
            <div class="flex justify-start py-1 pr-1 mt-2">
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Stock para la venta</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ product.inventory[0].theoretical }}
                </span>
              </div>
            </div>
            <div class="flex justify-start py-1 pr-1 mt-2">
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Stock en deposito</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ product.inventory[0].real }}
                </span>
              </div>
            </div>
            <div class="flex justify-start py-1 pr-1 mt-2">
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Stock pendiente</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ product.inventory[0].pending }}
                </span>
              </div>
            </div>
            <div class="flex justify-start py-1 pr-1 mt-2">
              <div class="flex-grow font-bold text-purple-500 truncate">
                <span>Stock pre-aprobado</span>
              </div>
              <div class="font-bold tracking-wide text-purple-500">
                <span>
                  {{ product.inventory[0].committed  || 0}}
                </span>
              </div>
            </div>
          </div>

          <Inventory v-if="operationPermission" :product="product"></Inventory>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { round } from '../../../util/helpers';
import Inventory from '../Inventory.vue';

export default {
  name: 'ProductDetails',
  components: {
    Inventory,
  },
  props: {
    active: Boolean,
    product: Object,
    operationPermission: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      details: [
        {
          label: 'Costo unitario',
          value: ({ unitCost }) => unitCost,
          prefix: '$',
        },
        {
          label: '% de aportes',
          value: ({ contributions }) => contributions,
          suffix: '%',
        },
        {
          label: 'Stock teórico',
          value: ({ inventory }) => inventory[0].theoretical,
        },
        {
          label: 'Stock real',
          value: ({ inventory }) => inventory[0].real,
        },
      ],
    };
  },
  watch: {},
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
  },
  methods: {
    getUnit(unit, quantity) {
      if (!unit) return '';
      if (quantity) {
        return {
          UNITY: `${quantity} unid`,
          WEIGHT: `${quantity} grs`,
        }[unit];
      }
      return {
        UNITY: ' unid',
        WEIGHT: ' grs',
      }[unit];
    },
    porcentageMargin(value) {
      return round(value * 100 - 100);
    },
  },
};
</script>
