import { createPinia, PiniaVuePlugin } from 'pinia';
import './scss/main.scss';
import './css/tailwind.css';
import './css/threeDots.css';
import './css/custom.css';
import Vue from 'vue';
import JsonCSV from 'vue-json-csv';
import Buefy from 'buefy';

import VueHtmlToPaper from 'vue-html-to-paper';
import swipedEvents from 'swiped-events';
import VueLoadmore from 'vuejs-loadmore';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import router from './router';

import store from './store';

import './registerServiceWorker';
import './filters';
import App from './App.vue';

import SButton from './components/SButton.vue';

import LoadingTable from './components/LoadingTable.vue';

import PullToRefresh from './components/PullToRefresh.vue';


Vue.use(PiniaVuePlugin);
Vue.use(VueLoadmore);
Vue.component('PullToRefresh', PullToRefresh);

const pinia = createPinia();

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css',
    'https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css',
    './css/htmlToPaper.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);

Vue.component('Button', SButton);

Vue.component('LoadingTable', LoadingTable);
Vue.component('downloadCsv', JsonCSV);

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(swipedEvents);

if (process.env.VUE_APP_ENV !== 'local') {
  Sentry.init({
    Vue,
    dsn: 'https://fbfcf7b828ce4857b0cc0f976db3c702@o4504749601587200.ingest.sentry.io/4504749602308096',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,

  render: (h) => h(App),
  pinia,
}).$mount('#app');
