<template>
  <b-modal
    :active="active"
    :has-modal-card="true"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <template>
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-input placeholder="Nombre" v-model="updated.name"></b-input>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="update-payMethod"
            :once="true"
            type="is-success"
            :onClickFn="handleUpdatePayMethod"
            >Aceptar</Button
          >
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ApiService from '../../services/ApiService';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';

const { PayMethodService } = ApiService;

export default {
  name: 'UpdatePayMethodMethod',
  props: {
    active: Boolean,
    payMethod: Object,
  },
  components: {},
  data() {
    return {
      updated: this.payMethod || {},
    };
  },
  watch: {
    'payMethod._id': function () {
      if (this.payMethod) {
        this.updated = this.payMethod;
      }
    },
  },
  methods: {
    async handleUpdatePayMethod() {
      const { _id: id } = this.payMethod;
      const payload = omit(immutableProperties.common, this.updated);

      await PayMethodService.update(id, payload);
      this.$emit('update', id);
      this.$emit('dismiss');
    },
  },
};
</script>
