<template>
  <div class="col-span-4 mb-2 text-sm">
    <a
      v-if="showInstance"
      class="
        text-xs text-white-700
        font-bold
        dark:text-white
        hover:underline
        border-2
        rounded-md
        p-0.5
        mr-1
        pr-1
      "
      :class="[
        isOrder ? 'bg-green-50 border-green-500' : 'bg-red-50 border-red-500',
      ]"
      >{{ isOrder ? 'PEDIDO' : 'COMPRA' }}</a
    >
    <a
      class="
        text-md text-gray-900
        dark:text-white
        hover:underline
        border-2
        rounded-md
        p-0.5
        mr-1
        px-1
      "
      @click="handleViewDetails(element)"
      ># {{ element.number }}</a
    >
    <a
      v-if="!element.isSupply && element.cart.items.some(({ isWholesaler }) => isWholesaler)"
      class="
        text-xs text-white-700
        font-bold
        bg-yellow-200
        dark:text-white
        hover:underline
        border-2 border-yellow-500
        rounded-md
        p-0.5
        px-1
        mr-1
      "
      @click="handleViewDetails(element)"
      >M</a
    >
    <a
      v-if="element.isSupply"
      class="
      text-xs text-white-700
        font-bold
        bg-green-200
        dark:text-white
        hover:underline
        border-2 border-green-500
        rounded-md
        p-0.5
        px-1
        mr-1
      "
      @click="handleViewDetails(element)"
      >A</a
    >
    <a
      v-if="element.hasShortageItems"
      class="
        text-xs text-white-700
        font-bold
        bg-red-50
        dark:text-white
        hover:underline
        border-2 border-red-500
        rounded-md
        p-0.5
        px-1
        mr-1
      "
      @click="handleViewDetails(element)"
      >F</a
    >

    <a
      v-if="element.isBudget"
      class="
        text-xs text-white-700
        font-bold
        bg-purple-50
        dark:text-white
        hover:underline
        border-2 border-purple-500
        rounded-md
        p-0.5
        px-1
        mr-1
      "
      @click="handleViewDetails(element)"
      >P</a
    >
    <a
      v-if="element.committed"
      class="
      text-xs text-white-700
        font-bold
        bg-blue-200
        dark:text-white
        hover:underline
        border-2 border-blue-500
        rounded-md
        p-0.5
        px-1
        mr-1
      "
      @click="handleViewDetails(element)"
      >PRE-APROBADO</a
    >
  </div>
</template>

<script>
import { isOrder } from '../util/helpers';

export default {
  name: 'FlagsComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {
    isOrder() {
      return isOrder(this.element);
    },
  },
  methods: {
    handleViewDetails(element) {
      this.$emit('click', element);
    },
  },
  watch: {},
};
</script>
