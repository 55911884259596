import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { WithdrawalService } = ApiService;

const useWithdrawalStore = defineStore('withdrawal', {
  state: () => ({
    withdrawals: [],
    selected: [],
    isLoading: {
      withdrawals: false,
    },
    isFetched: {
      withdrawals: false,
    },
    query: null,
    options: {
      page: 1,
      limit: 12,
    },
  }),
  actions: {
    async find(params = {}, { merge = false } = {}) {
      this.isLoading.withdrawals = true;
      const { data: withdrawals } = await WithdrawalService.find({
        query: this.query,
        ...params,
      });
      this.isLoading.withdrawals = false;
      this.isFetched.withdrawals = true;
      this.withdrawals = merge
        ? [...this.withdrawals, ...withdrawals]
        : withdrawals;
    },

    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(withdrawals) {
      this.withdrawals = withdrawals;
    },
    select(account) {
      this.selected.push(account);
    },
    deselect(account) {
      const index = this.selected.findIndex(({ _id }) => _id === account._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(account) {
      return this.withdrawals.findIndex(({ _id }) => _id === account._id);
    },
    updatePositions(withdrawals, key) {
      this[key] = withdrawals;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const withdrawalsCopy = [...this[key]];
        withdrawalsCopy[index] = element;
        this[key] = withdrawalsCopy;
      }
    },
    async disable(id) {
      const { data: updated } = await WithdrawalService.disable(id);
      this.updateElement(updated, 'withdrawals');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await WithdrawalService.update(id, payload);
      this.updateElement(updated, 'withdrawals');
      return updated;
    },
  },
});

export default useWithdrawalStore;
