<template>
  <div class="p-4 bg-white">
    <div class="flex items-center justify-between my-1">
      <span class="text-lg font-bold"># {{ order.number }} - ({{ order.partner.username }})</span>
     <div class="flex flex-row gap-1">
      <span
              class="text-lg font-medium text-green-500"
              v-if="order.isSupply"
              >Socio</span
            >
      <span
        class="text-lg font-medium text-indigo-500"
        v-if="!order.isSupply && order.cart.items.some(({ isWholesaler }) => isWholesaler)"
        >Mayorista</span
      >
      <span
              v-if="order.isBudget"
              class="text-lg font-medium text-indigo-500"
              > / Presupuesto</span
            >
     </div>  
    </div>
    <div class="flex flex-col my-2">
      <p class="font-bold" :style=" { fontSize: '48px' }">{{ order.customer.name }}</p>
      <p><span class="font-bold">Fecha:</span> {{ order.date | shortDate }}</p>
      <p><span class="font-bold">Fecha de entrega:</span> {{ order.delivery.date | shortDate }}</p>
      <p><span class="font-bold">Dirección:</span> {{ shortAddress(order.delivery.address) }}</p>
    </div>

    <div class="pt-2">
      <div
        v-for="item of [...order.cart.items].sort((a, b) => (a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1))"
        :key="item.number"
        class="grid grid-cols-4 text-xs"
        style="grid-template-columns: 56px 2fr 144px 72px"
      >
        <span>{{ item | friendlyUnity }}</span>
        <div>
          <span v-if="item.product.title">{{ item.product.title }} - {{item.product.subtitle}}</span>
          <span v-if="!item.product.title">{{ item.product.name }}</span>
        </div>
        <div class="">
          {{ item.detail.unitCost | currency(0) }} x
          {{
            { ...item, quantity: item.detail.unitQuantity }
              | friendlyUnity
          }}
        </div>
        <div class="font-bold">
          {{ item.price | currency }}
        </div>
      </div>
      <div class="my-2">
        <div class="flex justify-between text-sm">
          <span class="font-bold ">Subtotal</span>
          <span class="font-bold">{{ order.cart.total | currency }}</span>
        </div>
        <div class="flex justify-between text-sm">
          <span class="font-bold ">Logística</span>
          <span v-if="order.delivery.charged" class="font-bold">{{
            order.delivery.customerPrice | currency
          }}</span>
          <span v-if="!order.delivery.charged" class="font-bold"
            >Bonificado</span
          >
        </div>
        <div class="flex justify-between text-sm" v-if="hasDiscount">
          <span class="font-bold ">Descuento</span>
          <span class="font-bold">{{ discountAmount | currency }}</span>
        </div>
        <div class="flex justify-between text-lg ">
          <span class="font-bold">Total</span>
          <div class="font-bold tracking-end">
            {{ order.total || order.cart.total | currency }}
          </div>
        </div>
      </div>
    </div>

    <div class="border-t-2" v-if="order.notes && order.notes.length">
      <div class="pt-2 text-md">
        <span class="text-lg font-bold">Notas</span>
        <div>{{ order.notes }}</div>
      </div>
    </div>
    <div
      v-if="order.metadata && Object.entries(order.metadata).length"
      class="pt-2 "
    >
      <p class="text-lg font-bold">Modificaciones</p>

      <div
        v-for="[event, items] in Object.entries(order.metadata)"
        :key="event"
        class="pt-2 "
      >
        <div class="text-sm">
          {{ event | shortDate }}
        </div>
        <p v-if="shortages(items).length" class="mt-1 text-sm font-bold">
          Faltantes
        </p>
        <div v-for="item of shortages(items)" :key="item._id">
          <div class="flex justify-start px-2 text-sm">
            <div class="flex-grow">
              {{ item.state ? item.name : item.product.name }}
            </div>
            <div
              class="font-bold tracking-wide"
              :class="[
                item.state === 'pending' && item.diff > 0
                  ? 'text-yellow-500'
                  : '',
                item.state === 'shortage' ? 'text-red-400' : '',
              ]"
            >
              <div v-if="item.state">{{ -item.diff }} {{ item | unit }}</div>
              <div v-if="!item.state">
                {{ item | friendlyUnity }}
              </div>
            </div>
          </div>
        </div>
        <p v-if="changes(items).length" class="mt-2 text-sm font-bold">
          Modificaciones
        </p>
        <div v-for="item of changes(items)" :key="item._id">
          <div class="flex justify-start px-2 text-sm">
            <div class="flex-grow">
              {{ item.state ? item.name : item.product.name }}
            </div>
            <div
              class="font-bold tracking-wide"
              :class="[
                item.state === 'pending' && item.diff > 0
                  ? 'text-yellow-400'
                  : '',
                item.state === 'shortage' ? 'text-red-400' : '',
                item.diff < 0 ? 'text-green-400' : '',
              ]"
            >
              <div v-if="item.state">{{ -item.diff }} {{ item | unit }}</div>
              <div v-if="!item.state">
                {{ item | friendlyUnity }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid items-center pt-2 my-2 overflow-hidden border-t-2 ">
      <div class="">
        <div class="bg-white ">
          <div class="flex flex-row gap-8 text-md">
            <p>
              Revisá tu pedido y por cualquier inquietud no dudes en contactarte
              con nosotrxs
            </p>

            <img
              src="../../assets/invoice-qr.jpeg"
              alt=""
              width="96px"
              height="96px"
            />
          </div>
        </div>
      </div>
      <!-- <div class=""><PieChart :order="order"></PieChart></div> -->
    </div>

    <!-- <pre>{{ metadata }}</pre> -->
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import {
  round, isOrder, isBuy, sum, shortAddress,
} from '../../util/helpers';
// import PieChart from '../Charts/PieChart.vue';


const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'Invoice',
  props: {
    order: Object,
  },
  components: {
    // PieChart,
  },
  data() {
    return {
      perPage: 5,
    };
  },
  watch: {},
  computed: {
    getCustomerCost() {
      return this.order.delivery && this.order.delivery.charged
        ? this.order.delivery.customerPrice
        : 0;
    },
    getTotal() {
      return round(this.order.cart.total + this.getCustomerCost);
    },
    isOrder() {
      return isOrder(this.order);
    },
    isBuy() {
      return isBuy(this.order);
    },
    discountAmount() {
      return round(this.contributions * (this.order.discount / 100) || 0);
    },
    hasDiscount() {
      return this.discountAmount > 0;
    },
    contributions() {
      return round(
        sum(
          this.order.cart.items.map((i) => i.detail),
          'contribution',
        ),
      );
    },
  },
  methods: {
    shortAddress,
    shortages(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff > 0 && quantity !== max,
      );
    },
    changes(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff < 0 || quantity === max,
      );
    },
    parsedMetadata(items) {
      return items.filter(
        ({ diff, quantity }) => (diff && diff !== 0)
          || (typeof diff === 'undefined' && quantity !== 0),
      );
    },
    billItem(item) {
      return new Item(item).billOrder({ isBudget: this.order.isBudget, isSupply: this.order.isSupply });
    },
  },
  mounted() {},
};
</script>
