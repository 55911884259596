var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"display":"none"},attrs:{"id":"consignments"}},_vm._l((Object.keys(_vm.consignments)),function(addressId){return _c('div',{key:addressId,staticClass:"printable-item"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"p-8"},[_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"flex items-center justify-between font-bold"},[_c('p',{staticClass:"flex items-center justify-start w-16 h-16 text-center justify-self-start",style:({ fontSize: '32px' })},[(!_vm.consignments[addressId].delivery.isDropOff)?_c('span',[_vm._v("#"+_vm._s(_vm.consignments[addressId].number))]):_vm._e()]),_c('p',{staticClass:"flex items-center justify-center w-16 h-16 text-center border-2 border-black justify-self-center",style:({ fontSize: '48px' })},[_vm._v(" R ")]),_c('p')]),(!_vm.consignments[addressId].delivery.isDropOff)?_c('p',[_c('span',{staticClass:"font-bold",style:({ fontSize: '48px' })},[_vm._v(" "+_vm._s(_vm.consignments[addressId].customer.name))])]):_vm._e(),(_vm.consignments[addressId].delivery.isDropOff)?_c('p',[_c('span',{staticClass:"font-bold",style:({ fontSize: '48px' })},[_vm._v("NODO - "+_vm._s(_vm.consignments[addressId].partner.username))])]):_vm._e(),_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v("Fecha de entrega:")]),_vm._v(" "+_vm._s(_vm.dayOfWeek(new Date(_vm.consignments[addressId].date)))+" "+_vm._s(_vm._f("shortDate")(_vm.consignments[addressId].date))+" ")]),_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v("Dirección:")]),_vm._v(" "+_vm._s(_vm.shortAddress(_vm.consignments[addressId].address))+" ")]),_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v("Pedidos:")]),_vm._v(" "+_vm._s(_vm.consignments[addressId].orders.length)+" ")])]),_c('div',{staticClass:"flex justify-between gap-8 space-x-4"},[_c('div',_vm._l((Object.keys(
                  _vm.groupItemsByCategory(
                    Object.values(
                      _vm.getSplittedPackagesFromOrders(
                        _vm.consignments[addressId].orders
                      )
                    )
                  )
                ).sort((a, b) =>
                  a.toLowerCase() < b.toLowerCase() ? -1 : 1
                )),function(category){return _c('div',{key:category,staticClass:"flex flex-col"},[_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm._f("capitalize")(category)))]),_vm._l((_vm.groupItemsByCategory(
                    Object.values(
                      _vm.getSplittedPackagesFromOrders(
                        _vm.consignments[addressId].orders
                      )
                    )
                  )[category].sort((a, b) =>
                    a.product.name.toLowerCase() <
                    b.product.name.toLowerCase()
                      ? -1
                      : 1
                  )),function(aggregate){return _c('div',{key:aggregate.product._id,staticClass:"ml-6"},[_c('div',{staticClass:"flex items-center justify-between gap-2 text-xs"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('p',{staticClass:"self-start flex-none font-bold"},[_vm._v(" "+_vm._s(_vm._f("friendlyUnity")(aggregate))+" ")]),_c('p',[(aggregate.product.title)?_c('span',[_vm._v(_vm._s(aggregate.product.title)+" - "+_vm._s(aggregate.product.subtitle))]):_vm._e(),(!aggregate.product.title)?_c('span',[_vm._v(_vm._s(aggregate.product.name))]):_vm._e()])])])])})],2)}),0),_vm._m(0,true)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.consignments[addressId].delivery.isDropOff),expression:"consignments[addressId].delivery.isDropOff"}],staticClass:"flex mt-4 text-xs text-center border-2 border-black"},[_c('p',{staticClass:"px-1 border-r-2 border-black"},[_vm._v("Pedidos")]),_vm._l(([..._vm.consignments[addressId].orders].reverse()),function(order){return _c('div',{key:order.number,staticClass:"flex flex-wrap gap-2 font-bold",style:({ fontSize: '12px' })},[_c('p',{staticClass:"px-1 border-r-2 border-black"},[_vm._v("#"+_vm._s(order.number))])])})],2)])])])}),0),_c('b-button',{attrs:{"type":"is-primary is-light"},on:{"click":_vm.print}},[_c('b-icon',{attrs:{"icon":"alpha-r-box-outline","type":"is-primary"}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex gap-2"},[_c('div',{staticClass:"flex flex-col gap-2 text-xs"},[_c('p',{staticClass:"font-bold uppercase text-md"},[_vm._v("Almacen")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Cajas ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bolsas ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Conservadoras / Frío ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Maples ")])]),_c('div',{staticClass:"flex flex-col gap-2 text-xs"},[_c('p',{staticClass:"font-bold uppercase text-md"},[_vm._v("Verdulería")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Cajones ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bolsas ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bultos ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bolsón Verdura ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bolsón Frutas ")]),_c('div',{staticClass:"w-32 h-16 text-center border-2 border-black border-dashed"},[_vm._v(" Bolsón Pesado ")])])])
}]

export { render, staticRenderFns }