<template>
  <div>
    <CardItem
      ref="cardItem"
      :element="buy"
      :operationPermission="operationPermission"
      :isDraggable="isDraggable"
      :componentClass="[
        buy.hasShortageItems ? 'border-t-4 border-red-300' : '',
        ...componentClass,
      ]"
      :selected="(customStore && customStore.selected) || selected"
      :isCheckable="isCheckable"
      @select="handleSelect"
    >
      <div :class="['absolute inset-0 border-l-8 rounded-lg w-8' ]" :style="{ borderColor: stc(buy.driver?.name) }"></div>
      <div class="absolute flex flex-col gap-2 py-4 my-6 h-1/2 right-4 " >
        <div v-if="isAbleToApprove(buy)" class="rounded-lg bg-purple-50">
          <div
            @click="handleApproveBuy(buy)"
            class="flex items-center justify-center w-full h-full p-1 cursor-pointer"
          >
            <b-icon class="my-1" icon="check" type="is-success"></b-icon>
          </div>
        </div>
        <div v-if="isAbleToUpdateDriver(buy)" :class="['rounded-lg bg-purple-50']">
          <div
            @click="handleUpdateDriver(buy)"
            :class="['flex items-center justify-center w-full h-full p-1 cursor-pointer', !buy.driver ? 'bg-red-500 text-white rounded-lg' : '']"
          >
          <svg  class="inline w-6 h-6 align-center" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.78 21.84a1.998 1.998 0 1 1-1.56-3.68a1.998 1.998 0 1 1 1.56 3.68M7.62 6c1.11 0 2-.89 2-2a2 2 0 0 0-2-2c-1.12 0-2 .9-2 2c0 1.11.88 2 2 2m14.43 10.34L18.2 18c.22.22.42.5.56.82c.14.33.2.68.24 1l3.83-1.64zM10.16 8.78l.74 1.81c-.24-.09-.46-.21-.64-.33c-.6-.39-1.04-.88-1.33-1.46l-.74-1.57c-.19-.35-.42-.61-.74-.79c-.29-.17-.6-.26-.92-.26c-.32 0-.62.08-.91.22c-1.4 1.1-1.75 3.14-1.75 3.14l-.34 1.57c-.09.52-.14 1.04-.14 1.57v4.96L1 20.87L2.5 22l2.77-3.75l.17-3.25l1.68 2.34V22h1.85v-6.06l-1.85-2.61v-.65c0-.44 0-.87.11-1.29l.35-1.2c.38.55.84 1.03 1.39 1.44c.45.34 1.71.94 2.9 1.23L14 17.8c.22-.22.5-.42.83-.56c.32-.14.67-.2.99-.24L12 8zm5.2 3.34l1.96 4.6l5.63-2.41L21 9.72"/></svg>
          </div>
        </div>
      </div>
      <div class="flex justify-between space-x-2 text-sm">
        <DateComponent :element="buy"></DateComponent>
        <StateComponent :element="buy"></StateComponent>
        <BuyOptions
          :buy="buy"
          :operationPermission="operationPermission"
          :driverPermission="driverPermission"
          @approve="handleApproveBuy"
          @update="handleUpdateElement"
          @receipt="handleCreateReceipt"
        ></BuyOptions>
      </div>
      <div class="grid grid-cols-8 mt-2">
        <FlagsComponent
          v-show="!compact"
          :element="buy"
          @click="handleViewDetails"
          :showInstance="showInstance"
        ></FlagsComponent>

        <div class="col-span-7 my-1.5">
          <a class="text-lg font-bold text-gray-700 dark:text-white">{{
            buy.provider.name
          }}</a>

          <ul class="mt-2 list-none">
            <LogisticComponent
              v-show="!compact"
              :element="buy"
              property="withdrawal"
              :operationPermission="operationPermission"
            ></LogisticComponent>

            <AddressComponent
              :element="buy"
              property="provider"
            ></AddressComponent>
            <UpdateWithdrawalDate
              :buy="buy"
              @update="handlePostUpdateDeliveryDate"
              :disabled="!isAbleToUpdateWithdrawalDate(buy)"
            >
              <LogisticDateComponent
                :element="buy"
                property="withdrawal"
                :operationPermission="operationPermission"
                @update="handleUpdateElement"
              ></LogisticDateComponent>
            </UpdateWithdrawalDate>
            <li
              v-show="!compact"
              v-if="buy.receipt"
              class="mt-2 text-sm text-gray-600 dark:text-gray-300"
            >
              <p class="p-1 text-gray-500">
                Comprobante #{{ buy.receipt.code }}
              </p>
            </li>
            <li
              v-if="buy.roadmap && operationPermission"
              class="flex mt-2 text-sm text-gray-600 dark:text-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="inline w-6 h-6 "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                />
              </svg>

              <span class="ml-2 truncate">#{{ buy.roadmap.number }} </span>
            </li>
            <NotesComponent
              :element="buy"
              v-show="!compact"
              @update="handleUpdateNotes"
            ></NotesComponent>
            <RoadmapNotesComponent :element="buy" @update="handleUpdateRoadmapNotes"></RoadmapNotesComponent>
          </ul>
        </div>
      </div>
      <div v-show="!compact" class="flex items-end justify-between space-x-2">
        <ItemsComponent
          :element="buy"
          @click="handleViewDetails"
        ></ItemsComponent>
        <div class="inline text-xl font-bold text-black align-center">
          {{ buy.total | currency }}
        </div>
      </div>
    </CardItem>
    <DetailsModal
      :active="isDetailsOpen"
      :element="buySelected"
      @dismiss="handleDismissDetails"
    ></DetailsModal>
    <Approve
      :active="isApproving"
      :buy="buyToApprove"
      @dismiss="isApproving = false"
      @update="handleUpdateElement"
    ></Approve>
    <NewReceipt
      :active="isCreatingNewReceipt"
      :buy="buyToCreateReceipt"
      @create="handleReceiptCreated"
      @dismiss="isCreatingNewReceipt = false"
    ></NewReceipt>
    <UpdateDriverModal :store="customStore || buyStore" :active="isUpdatingDriver" :element="buyToUpdateDriver" @dismiss="isUpdatingDriver = false" @update="handlePostUpdateDriver">
    </UpdateDriverModal>
    <AddToRoadmap
      :active="isAddToRoadmapModalActive"
      :dispatch="buy"
      @dismiss="isAddToRoadmapModalActive = false"
      @update="handleUpdateElement"
    ></AddToRoadmap>
  </div>
</template>

<script>
import { isSameDay } from 'date-fns';
import { mapState } from 'pinia';
import stc from 'string-to-color';
import useBuyStore from '../../store/buy';
import useDispatchStore from '../../store/dispatch';
import useRoadmapStore from '../../store/roadmap';
import { BUY_STATES } from '../../util/constants';
import { isBuy, isOrder } from '../../util/helpers';
import AddressComponent from '../AddressComponent.vue';
import AddToRoadmap from '../AddToRoadmap.vue';
import CardItem from '../CardItem.vue';
import DateComponent from '../DateComponent.vue';
import DetailsModal from '../DetailsModal.vue';
import FlagsComponent from '../FlagsComponent.vue';
import ItemsComponent from '../ItemsComponent.vue';
import LogisticComponent from '../LogisticComponent.vue';
import LogisticDateComponent from '../LogisticDateComponent.vue';
import NotesComponent from '../NotesComponent.vue';
import RoadmapNotesComponent from '../RoadmapNotesComponent.vue';
import StateComponent from '../StateComponent.vue';
import UpdateDriverModal from '../UpdateDriverModal.vue';
import Approve from './Approve.vue';
import BuyOptions from './BuyOptions.vue';
import NewReceipt from './NewReceipt.vue';
import UpdateWithdrawalDate from './UpdateWithdrawalDate.vue';

export default {
  name: 'BuyCard',
  components: {
    CardItem,
    DetailsModal,
    BuyOptions,
    StateComponent,
    DateComponent,
    FlagsComponent,
    Approve,
    NewReceipt,
    AddressComponent,
    NotesComponent,
    RoadmapNotesComponent,
    LogisticComponent,
    LogisticDateComponent,
    ItemsComponent,
    UpdateWithdrawalDate,
    AddToRoadmap,
    UpdateDriverModal,
  },
  props: {
    buy: {
      type: Object,
    },
    operationPermission: {
      type: Boolean,
      default: false,
    },
    driverPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
    componentClass: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      roadmapStore: useRoadmapStore(),
      dispatchStore: useDispatchStore(),
      buyStore: useBuyStore(),
      isDetailsOpen: false,
      buySelected: null,
      buyToApprove: null,
      buyToCreateReceipt: null,
      isUpdatingWithdrawalDate: false,
      isCreatingNewReceipt: false,
      isApproving: false,
      query: {},
      isUpdatingDriver: false,
      buyToUpdateDriver: null,
      fieldToFilter: 'provider',
      isAddToRoadmapModalActive: false,
    };
  },
  mounted() {},
  computed: {
    ...mapState(useBuyStore, ['buys']),
    ...mapState(useDispatchStore, ['dispatchs']),
    ...mapState(useBuyStore, ['selected']),
  },
  methods: {
    stc,
    handlePostUpdateDriver(updated) {
      this.handleUpdateElement();

      const sameDateDispatchs = this.dispatchs.filter((dispatch) => {
        if (dispatch._id === updated._id) return false;

        if (dispatch.state === 'CANCELADA' || dispatch.state === 'CANCELADO') return false;

        if (isOrder(dispatch)) {
          if (!dispatch.delivery || !updated.delivery) return false;

          return isSameDay(new Date(dispatch.delivery.date), new Date(updated.delivery.date)) && dispatch.delivery.address.street === updated.delivery.address.street;
        }
        if (isBuy(dispatch)) {
          if (!dispatch.withdrawal || !updated.withdrawal) return false;
          return isSameDay(new Date(dispatch.withdrawal.date), new Date(updated.withdrawal.date)) && dispatch.provider._id === updated.provider._id;
        }
        return false;
      });


      if (sameDateDispatchs.length) {
        this.$buefy.dialog.confirm({
          title: 'Asignar repartidor a otros envíos',
          message:
          `Encontramos ${sameDateDispatchs.length} envíos a la misma dirección para el mismo día. ¿Querés asignar el mismo repartidor? 😉`,
          confirmText: 'Si, dale',
          cancelText: 'Emm, no',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [index, dispatch] of sameDateDispatchs.entries()) {
              // eslint-disable-next-line no-await-in-loop
              const updatedDispatch = await this.orderStore.updateDriver(dispatch, {
                driver: updated.driver,
              });
              this.$buefy.toast.open({
                duration: 3000,
                message: `Envío ${index + 1}/${sameDateDispatchs.length} actualizado`,
                position: 'is-bottom',
                type: 'is-primary',
                queue: false,
              });
              this.handleUpdateElement(updatedDispatch);
            }
          },
        });
      }
    },
    handlePostUpdateDeliveryDate(updated) {
      this.handleUpdateElement(updated);

      const sameDateDispatchs = this.dispatchs.filter((dispatch) => {
        if (dispatch._id === updated._id) return false;

        if (isOrder(dispatch)) {
          if (!dispatch.delivery || !updated.delivery) return false;

          return isSameDay(new Date(dispatch.delivery.date), new Date(updated.delivery.date)) && dispatch.delivery.address.street === updated.delivery.address.street;
        }
        if (isBuy(dispatch)) {
          if (!dispatch.withdrawal || !updated.withdrawal) return false;
          return isSameDay(new Date(dispatch.withdrawal.date), new Date(updated.withdrawal.date)) && dispatch.provider._id === updated.provider._id;
        }
        return false;
      });

      if (sameDateDispatchs.length) {
        this.$buefy.dialog.confirm({
          title: 'Asignar horario a otros envíos',
          message:
          `Encontramos ${sameDateDispatchs.length} envíos a la misma dirección para el mismo día. ¿Querés asignar el mismo repartidor? 😉 <br><br> <ul>${sameDateDispatchs.map((dispatch) => `<li>- ${dispatch.customer?.name || dispatch.provider?.name} - ${dispatch.delivery?.address?.street || dispatch.withdrawal?.address.street}</li>`).join('')}</ul>`,
          confirmText: 'Si, dale',
          cancelText: 'Emm, no',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [index, dispatch] of sameDateDispatchs.entries()) {
              const { _id: id } = dispatch;
              const payload = {
                date: updated.withdrawal.date,
                startTime: updated.withdrawal.startTime,
                endTime: updated.withdrawal.endTime,
              };
              // eslint-disable-next-line no-await-in-loop
              const updatedDispatch = await this.buyStore.updateWithdrawal(id, payload);
              this.$buefy.toast.open({
                duration: 3000,
                message: `Envío ${index + 1}/${sameDateDispatchs.length} actualizado`,
                position: 'is-bottom',
                type: 'is-primary',
                queue: false,
              });
              this.handleUpdateElement(updatedDispatch);
            }
          },
        });
      }
    },
    async handleUpdateRoadmapNotes(updatedNotes) {
      const updated = await this.buyStore.patch(this.buy._id, {
        roadmapNotes: updatedNotes,
      });
      this.handleUpdateElement(updated);
    },
    async handleUpdateNotes(updatedNotes) {
      const updated = await this.buyStore.patch(this.buy._id, {
        notes: updatedNotes,
      });
      this.handleUpdateElement(updated);
    },
    isAbleToUpdateDriver({ state, withdrawal }) {
      return (
        state !== BUY_STATES.CANCELLED
        // && withdrawal.isOwnLogistic
        && withdrawal.date
        && this.operationPermission
      );
    },
    isAbleToApprove({ state, withdrawal }) {
      return (
        state === BUY_STATES.PENDING
        && withdrawal.date
        && this.operationPermission
      );
    },
    isAbleToUpdateWithdrawalDate(buy) {
      const { state } = buy;

      return (
        state !== BUY_STATES.CANCELLED
        && state !== BUY_STATES.APPROVED
        && this.operationPermission
      );
    },
    handleUpdateElement(element) {
      this.dispatchStore.updateElement(element, 'dispatchs');
      this.dispatchStore.updateElement(element, 'due');
      this.roadmapStore.updateDispatch(element);
      this.buyToApprove = null;
    },
    isAbleToAddToRoadmap() {
      return (
        false
        && this.buy.state !== BUY_STATES.CANCELLED
        && this.buy.state !== BUY_STATES.APPROVED
        && this.operationPermission
      );
    },
    handleUpdateDriver(buy) {
      this.isUpdatingDriver = true;
      this.buyToUpdateDriver = buy;
    },
    handleAddToRoadmap() {
      this.isAddToRoadmapModalActive = true;
    },
    handleSelect(isSelected, element) {
      const store = this.customStore || this.buyStore;
      this.$emit('select', isSelected, element);
      if (isSelected) {
        store.select(element);
      } else {
        store.deselect(element);
      }

      const elements = this.customStoreKey
        ? this.customStore[this.customStoreKey]
        : this.buyStore.buys;

      const key = this.customStoreKey || 'buys';
      store.updatePositions(elements, key);
    },
    async handleApproveBuy(buy) {
      this.isApproving = true;
      this.buyToApprove = buy;
    },
    handleViewDetails(buy) {
      this.buySelected = buy;
      this.isDetailsOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.buySelected = null;
    },
    handleCreateReceipt() {
      this.isCreatingNewReceipt = true;
      this.buyToCreateReceipt = this.buy;
    },
    handleReceiptCreated() {},
  },
  watch: {},
};
</script>
