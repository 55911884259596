<template>
  <div class="card" v-if="visible">
    <div class="card-content">
      <div class="mt-2 is-clearfix">
        <b-field label="Nombre">
          <b-input ref="nameInput" v-model="updated.name"></b-input>
        </b-field>

        <b-field label="Username">
          <b-input ref="nameInput" v-model="updated.username"></b-input>
        </b-field>

        <b-field label="Email">
          <b-input ref="nameInput" v-model="updated.email"></b-input>
        </b-field>

        <b-field
          :label="`${
            !updated.isThird ? 'Habilitar' : 'Deshabilitar'
          } Prestamos`"
        >
          <Button
            v-if="!updated.isThird"
            :once="true"
            type="is-success is-light"
            :onClickFn="handleUpdateThird"
            >Habilitar</Button
          >
          <Button
            v-if="updated.isThird"
            :once="true"
            type="is-danger is-light"
            :onClickFn="handleRemoveThird"
            >Deshabilitar</Button
          >
        </b-field>

        <b-field label="Rol">
          <b-select v-model="updated.role">
            <option
              v-for="option in ['ADMIN', 'AV', 'COMPRAS', 'OPERACION']"
              :value="option"
              :key="option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>

        <b-checkbox
          v-model="updated.wholesaler"
          class="is-pulled-right"
          :rounded="true"
          :outlined="true"
          type="is-success"
          :left-label="true"
        ></b-checkbox>
        <b-field label="Mayorista"> </b-field>

        <b-checkbox
          data-cy="isDriver"
          v-model="updated.isDriver"
          class="is-pulled-right"
          :rounded="true"
          :outlined="true"
          type="is-success"
          :left-label="true"
        ></b-checkbox>
        <b-field label="Repartidor"> </b-field>

        <b-field label="Telefono">
          <PhoneInput
            v-model="updated.phone"
            :phone="updated.phone"
          ></PhoneInput>
        </b-field>

        <div class="flex flex-col gap-2 p-2 border-2 rounded-md">
          <p class="text-lg font-bold">Drop Off Point</p>
          <div class="flex flex-row justify-between">
            <b-field label="Habilitado"> </b-field>
            <b-checkbox
              v-model="updated.dropOffPoint.status"
              :rounded="true"
              :outlined="true"
              type="is-success"
              :left-label="true"
            ></b-checkbox>
          </div>

          <b-field label="Nombre">
            <b-input v-model="updated.dropOffPoint.name"></b-input>
          </b-field>
          <b-field label="Precio">
            <b-numberinput
              :controls="false"
              v-model="updated.dropOffPoint.price"
            ></b-numberinput>
          </b-field>
          <AddressInput
            v-model="updated.dropOffPoint.address"
            :address="updated.dropOffPoint.address"
          />
          <DeliveryLookup
            :selectedDelivery="this.updated.dropOffPoint.delivery"
            @select="handleSelectDelivery"
            :canRemove="true"
          ></DeliveryLookup>
          <b-field label="Descripción">
            <b-input
              v-model="updated.dropOffPoint.description"
              maxlength="255"
              type="textarea"
            ></b-input>
          </b-field>
        </div>

        <div class="mt-1">
          <Button
            pulled="right"
            type="is-success"
            :disabled="isSendingRequest"
            :onClickFn="handleUpdate"
            >Guardar</Button
          >

          <Button
            v-if="partner.status"
            :disabled="isSendingRequest"
            type="is-danger"
            :onClickFn="handleDisable"
            >Deshabilitar</Button
          >

          <Button
            v-if="!partner.status"
            type="is-success"
            :disabled="isSendingRequest"
            :onClickFn="handleEnable"
            >Habilitar</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../services/ApiService';
import { disconnectedCopy, omit, validatePayload } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';
import PhoneInput from '../PhoneInput.vue';
import AddressInput from '../AddressInput.vue';
import DeliveryLookup from '../DeliveryLookup.vue';

const { UserService } = ApiService;
const {
  user: { updateUserSchema: schema },
} = schemas;

export default {
  name: 'UpdateForm',
  props: {
    partner: Object,
    visible: Boolean,
  },
  components: {
    PhoneInput,
    AddressInput,
    DeliveryLookup,
  },
  data() {
    return {
      updated: {
        ...this.partner,
        dropOffPoint: this.partner.dropOffPoint || {},
      },
      isSendingRequest: false,
      selectedDelivery: null,
    };
  },
  watch: {
    partner(partner) {
      this.updated = {
        ...partner,
        dropOffPoint: this.partner.dropOffPoint || {},
      };
    },
  },
  computed: {},
  methods: {
    handleSelectDelivery(delivery) {
      this.updated.dropOffPoint.delivery = disconnectedCopy(delivery);
    },
    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const { data: updatedUser } = await UserService.disable(id);
      this.$emit('onUpdate', updatedUser);
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const { data: updatedUser } = await UserService.enable(id);
      this.$emit('onUpdate', updatedUser);
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      const { _id: id } = this.updated;
      const payload = disconnectedCopy(
        omit(immutableProperties.partner, this.updated),
      );

      payload.dropOffPoint.delivery = this.updated.dropOffPoint.delivery
        ? this.updated.dropOffPoint.delivery._id
        : null;
      if (!validatePayload(payload, schema)) return;
      const { data: updatedUser } = await UserService.update(id, payload);
      this.$emit('onUpdate', updatedUser);
    },
    async handleUpdateThird() {
      const { _id: id } = this.updated;
      const { data: updatedUser } = await UserService.updateThird(id);
      this.$emit('onUpdate', updatedUser);
    },
    async handleRemoveThird() {
      const { _id: id } = this.updated;
      const { data: updatedUser } = await UserService.removeThird(id);
      this.$emit('onUpdate', updatedUser);
    },
  },
};
</script>
