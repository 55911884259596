<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-field label="Nombre">
            <b-input
              v-model="third.name"
              type="text"
              placeholder="Nombre"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="create-third"
            :once="true"
            type="is-success"
            :onClickFn="handleAddThird"
            >Agregar</Button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ApiService from '../../services/ApiService';

const { ThirdService } = ApiService;

export default {
  name: 'NewThird',
  props: {
    active: Boolean,
  },
  data() {
    return {
      third: {
        name: '',
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    async handleAddThird() {
      const payload = this.third;

      await ThirdService.create(payload);
      return this.$emit('created', payload, this.type);
    },
  },
};
</script>
