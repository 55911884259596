import { defineStore } from 'pinia';
import { startOfDay } from 'date-fns';
import ApiService from '../services/ApiService';
import useDispatchStore from './dispatch';
import { mapPosition } from '../util/helpers';

const { RoadmapService } = ApiService;

const useRoadmapStore = defineStore('roadmap', {
  state: () => ({
    roadmaps: [],
    selected: [],
    isLoading: {
      roadmaps: false,
    },
    isFetched: {
      roadmaps: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  getters: {
    roadmapDays: (state) => [
      ...new Set(
        state.roadmaps.map(({ date }) => startOfDay(new Date(date)).toLocaleDateString()),
      ),
    ].sort(),
  },
  actions: {
    async find(
      roadmapParams = {},
      dispatchParams,
      { merge = false, key = 'roadmaps' } = {},
    ) {
      const dispatchStore = useDispatchStore();
      this.isLoading[key] = true;
      let { data: roadmaps } = await RoadmapService.find({
        // query: this.query,
        // page: this.options.page,
        // limit: this.options.limit,
        ...roadmapParams,
      });

      await dispatchStore.find(dispatchParams, dispatchParams);
      const { dispatchs } = dispatchStore;

      roadmaps = roadmaps.map((roadmap) => ({
        ...roadmap,
        dispatchs: dispatchs.filter(
          (dispatch) => dispatch.roadmap && dispatch.roadmap._id === roadmap._id,
        ),
      }));

      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = merge ? [...this[key], ...roadmaps] : roadmaps;
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(roadmaps) {
      this.roadmaps = roadmaps;
    },
    select(roadmap) {
      this.selected.push(roadmap);
    },
    deselect(roadmap) {
      const index = this.selected.findIndex(({ _id }) => _id === roadmap._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    selectDay(day) {
      this.roadmaps
        .filter(({ date }) => date === day)
        .forEach((roadmap) => roadmap.dispatchs.forEach((dispatch) => this.select(dispatch)));
    },
    deselectDay(day) {
      this.roadmaps
        .filter(({ date }) => date === day)
        .forEach((roadmap) => roadmap.dispatchs.forEach((dispatch) => this.deselect(dispatch)));
    },
    searchIndex(roadmap) {
      return this.roadmaps.findIndex(({ _id }) => _id === roadmap._id);
    },
    updatePositions(roadmaps, key) {
      this[key] = roadmaps;
      const sortedId = this[key].map(({ _id }) => _id);
      this.selected = mapPosition(this.selected, sortedId, '_id');
    },
    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateDispatch(dispatch) {
      const roadmapToUpdate = this.roadmaps.find(
        (roadmap) => roadmap._id === dispatch.roadmap._id,
      );

      const copy = [...this.roadmaps];

      // Eliminar el dispatch de cualquier otro arreglo dispatchs si existe
      copy.forEach((roadmap) => {
        if (roadmap._id !== roadmapToUpdate._id) {
          // eslint-disable-next-line no-param-reassign
          roadmap.dispatchs = roadmap.dispatchs.filter(
            (d) => d._id !== dispatch._id,
          );
        } else {
          // eslint-disable-next-line no-param-reassign
          roadmap.dispatchs = roadmap.dispatchs.map((d) => (d._id === dispatch._id ? dispatch : d));
        }
      });

      this.roadmaps = copy;
    },
    updateElement(element, key) {
      const index = this[key].findIndex(
        (roadmap) => roadmap._id === element._id,
      );
      if (index !== -1) {
        const copy = [...this[key]];
        copy[index] = element;
        this[key] = copy;
      }
    },
    removeFrom(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this[key].splice(index, 1);
      }
    },
    addTo(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index === -1) {
        this[key].unshift(element);
      }
    },
    async disable(id) {
      const { data: updated } = await RoadmapService.disable(id);
      this.removeFrom(updated, 'roadmaps');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await RoadmapService.update(id, payload);
      this.updateElement(updated, 'roadmaps');
      return updated;
    },
    async create(payload) {
      const { data: created } = await RoadmapService.create(payload);

      this.addTo({ ...created, dispatchs: [] }, 'roadmaps');
      return created;
    },
  },
});

export default useRoadmapStore;
