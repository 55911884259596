<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <div
          class="flex flex-row p-2 text-indigo-400 bg-white border-b-2 border-indigo-400"
          @click.stop="$emit('dismiss')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
          <span>Volver</span>
        </div>
        <section class="modal-card-body">
          <b-field label="Telefono">
            <PhoneInput v-model="updated.phone" :phone="updated.phone" />
          </b-field>
          <b-field label="Nombre">
            <b-input ref="nameInput" v-model="updated.name"></b-input>
          </b-field>

          <AddressInput v-model="updated.address" :address="updated.address" />
          <b-field label="Email">
            <b-input v-model="updated.email" type="email"></b-input>
          </b-field>

          <b-field label="Email de contacto">
            <b-input v-model="updated.contactEmail" type="email"></b-input>
          </b-field>

          <div class="field">
            <b-checkbox v-model="updated.isWholesaler" type="is-success"
              >Es mayorista?</b-checkbox
            >
          </div>

          <b-field v-if="segments.length" label="Segmento">
            <b-select
              v-model="selectedSegment"
              :disabled="!updated.status"
              placeholder="Seleccionar"
            >
              <option
                v-for="segment in segments"
                :value="segment"
                :key="segment._id"
              >
                {{ segment.name }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Observaciones">
            <b-input
              v-model="updated.notes"
              maxlength="255"
              type="textarea"
            ></b-input>
          </b-field>

          <Button pulled="right" type="is-success" :onClickFn="handleUpdate"
            >Guardar</Button
          >
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import AddressInput from '../AddressInput.vue';
import PhoneInput from '../PhoneInput.vue';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';
import useCustomerStore from '../../store/customer';

export default {
  name: 'UpdateForm',
  props: {
    customer: Object,
    active: Boolean,
    segments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PhoneInput,
    AddressInput,
  },
  data() {
    return {
      customerStore: useCustomerStore(),
      updated: { ...this.customer },
      selectedSegment: null,
    };
  },
  watch: {
    customer(customer) {
      this.updated = { ...customer };
      this.selectedSegment = this.updated.segment;

      if (!this.updated.notes) {
        this.updated.notes = 'MAYORISTA: SI/NO\nPRESUPUESTO: SI/NO\nENVÍO:\nOBSERVACIONES:';
      }
    },
  },
  computed: {},
  mounted() {},
  methods: {
    async handleUpdate() {
      const { _id: id } = this.updated;
      const payload = omit(immutableProperties.customer, this.updated);

      payload.segment = this.selectedSegment?._id;

      const updated = await this.customerStore.update(id, payload);
      this.$emit('update', updated);
      this.$emit('dismiss');
    },
    handleDisable() {},
  },
};
</script>
