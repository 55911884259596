<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <b-button
          type="is-primary is-light"
          icon-left="arrow-left"
          @click="handleGoToProducts"
          >Productos</b-button
        >
        <Button
          pulled="right"
          type="is-primary"
          :onClickFn="fetchProviders"
          icon="refresh"
        ></Button>
        <b-button data-cy="new-provider" @click="handleNewProvider"
          >Nuevo Proveedor</b-button
        >
      </div>
    </hero-bar>
    <Section>
      <div class="columns">
        <div class="column is-half">
          <LoadingTable :active="!fetched" />
          <ProviderTable
            :providers="providers"
            v-if="fetched"
            :loading="loading"
            @select="handleSelectProvider"
          />
        </div>
        <div v-if="selected" class="column is-half">
          <ProviderInfo :provider="selected" @reload="handleUpdateProvider" />
        </div>
      </div>
    </Section>
    <NewProvider
      :active="newProviderModalIsActive"
      @dismiss="newProviderModalIsActive = false"
      @created="handleProviderCreated"
    ></NewProvider>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import ProviderInfo from '../components/Provider/Info.vue';
import NewProvider from '../components/Provider/NewProvider.vue';
import ApiService from '../services/ApiService';
import ProviderTable from '../components/Provider/ProviderTable.vue';
import { columns } from '../ui/config/tables/providers';
import Section from '../components/Section.vue';

const { ProviderService } = ApiService;

export default {
  name: 'Providers',
  components: {
    HeroBar,
    ProviderInfo,
    NewProvider,
    ProviderTable,
    Section,
  },
  data() {
    return {
      providers: [],
      selected: null,
      columns,
      fetched: false,
      newProviderModalIsActive: false,
      hasActions: true,
      loading: false,
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => {};
    await this.fetchProviders();
    this.fetched = true;
  },
  methods: {
    async fetchProviders() {
      this.loading = true;
      const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
      this.providers = providers;
      this.loading = false;
    },
    async handleUpdateProvider(id) {
      // TODO: Avoid this call refactoring service response
      const { data: updatedProvider } = await ProviderService.findOne(id);
      this.selected = {
        ...updatedProvider,
      };
      const index = this.providers.findIndex(
        ({ _id: providerId }) => providerId === id,
      );
      const providersCopy = [...this.providers];
      providersCopy[index] = {
        ...updatedProvider,
      };
      this.providers = providersCopy;
    },
    handleSelectProvider(provider) {
      this.selected = provider;
    },
    handleGoToProducts() {
      this.$router.push('/products');
    },
    handleNewProvider() {
      this.newProviderModalIsActive = true;
    },
    handleProviderCreated() {
      this.newProviderModalIsActive = false;
      // TODO: Avoid this call refactoring service response
      this.fetchProviders();
    },
  },
};
</script>

<style>
tr.is-danger {
  background: #ffffff;
  color: #f14668;
}
</style>
