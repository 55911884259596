<template>
  <div class="p-4 bg-white rounded-lg shadow-sm">
    <div class="flex items-center justify-between mb-4">
      <h3 class="text-xl font-bold leading-none text-gray-900">Productos</h3>
      <!-- <a href="#" class="text-sm font-medium text-blue-600 hover:underline">
        Ver mas
      </a> -->
    </div>
    <div class="flow-root">
      <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
        <li
          class="py-3 sm:py-4"
          v-for="(item, index) in cart.items"
          :key="index"
        >
          <div class="flex items-center space-x-4">
            <!-- <div class="md:flex-shrink-0 sm:hidden">
              <img
                class="w-8 h-8 rounded-full"
                :src="item.product.imageUrl"
                alt="Neil image"
              />
            </div> -->
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ item.product.name }}
              </p>
              <p class="text-sm font-bold text-gray-600 truncate">
                {{ item | friendlyUnity }}
              </p>
              <p v-if="showDetails" class="text-sm text-gray-500 truncate">
                ${{ item.detail.unitCost }} /
                {{
                 item.detail.unitQuantity
                }}
                {{ {...item, quantity:  item.detail.unitQuantity } | friendlyUnit }}
              </p>
            </div>
            <div
              class="inline-flex items-center text-base font-semibold text-gray-900 "
            >
              {{ item.price | currency }}
            </div>
            <div
              class="flex-shrink-0"
              v-if="canRemoveItems"
              @click="handleRemoveItem(index)"
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8 p-1 bg-gray-100 rounded-full shadow-md"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5
                      7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="flex flex-col gap-1">
      <!-- <div class="flex items-center justify-between">
        <h3 class="font-bold leading-none text-gray-900 text-md">Impuestos</h3>
        <a href="#" class="font-bold text-gray-900 text-md hover:underline"
          >{{ getTaxesTotal | currency }}
        </a>
      </div> -->
      <div class="flex items-center justify-between">
        <h3 class="font-bold leading-none text-gray-900 text-md">Subtotal</h3>
        <a href="#" class="font-bold text-gray-900 text-md hover:underline"
          >{{ cart.total | currency }}
        </a>
      </div>
      <div
        class="flex items-center justify-between"
        v-if="delivery && delivery.name"
      >
        <h3 class="leading-none text-gray-900 text-md">Envío</h3>
        <a href="#" class="text-gray-900 text-md hover:underline"
          >{{ delivery.charged ? `$${getCustomerCost}` : 'Bonificado' }}
        </a>
      </div>
      <div
        class="flex items-center justify-between"
        v-if="withdrawal && withdrawal.name"
      >
        <h3 class="leading-none text-gray-900 text-md">Logística</h3>
        <a href="#" class="text-gray-900 text-md hover:underline"
          >{{ withdrawal.charged ? `- $${getProviderCost}` : 'Bonificado' }}
        </a>
      </div>
      <div class="flex items-center justify-between">
        <h3 class="text-lg font-bold leading-none text-gray-900">Total</h3>
        <a href="#" class="text-lg font-bold text-gray-900 hover:underline"
          >{{ getTotal | currency }}
        </a>
      </div>
    </div>
  </div>

  <!-- <div class="card">
    <div class="card-content">
      <div class="grid grid-cols-1 divide-y-0">
        <div v-for="(item, index) in cart.items" :key="index" class="mb-2">
          <div class="columns is-mobile is-vcentered is-clearfix">
            <div class="column is-narrow">
              <p class="title is-6 is-italic">{{ index + 1 }}</p>
            </div>
            <div class="column is-5">
              <p class="title is-6">{{ item.product.name }}</p>
              <p class="subtitle is-6">{{ item | friendlyUnity }}</p>
            </div>

            <div v-if="showDetails" class="column is-3 is-hidden-mobile">
              <span class="title is-6"> ${{ item.detail.unitCost }}</span
              ><span>
                / {{ item.detail.unitQuantity }}

                <span v-if="item.detail.unitQuantity !== 'Mayorista'">
                  {{ item.product | unit }}</span
                >
              </span>
            </div>

            <div class="column is-pulled-right">
              <button
                class="ml-4 is-pulled-right delete is-small"
                v-if="canRemoveItems"
                @click="handleRemoveItem(index)"
              ></button>
              <p class="title is-6 is-pulled-right">${{ item.price }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="delivery && delivery.name">
        <div class="grid grid-cols-1 mt-4 divide-y-2 divide-yellow-500">
          <span></span>
          <span></span>
        </div>

        <div class="mt-4">
          <p class="title is-4 is-pulled-right">
            <span>{{
              delivery.charged ? `$${getCustomerCost}` : 'Bonificado'
            }}</span>
          </p>
          <p class="title is-4">Envío</p>
        </div>
      </div>
      <div v-if="withdrawal && withdrawal.name">
        <div class="grid grid-cols-1 mt-4 divide-y-2 divide-yellow-500">
          <span></span>
          <span></span>
        </div>

        <div class="mt-4">
          <p class="title is-4 is-pulled-right">
            <span>{{
              withdrawal.charged ? `- $${withdrawal.fee}` : 'Bonificado'
            }}</span>
          </p>
          <p class="title is-4">Logística</p>
        </div>
      </div>
      <div v-if="showTotal">
        <div class="grid grid-cols-1 mt-5 divide-y-2 divide-purple-500">
          <span></span>
          <span></span>
        </div>

        <div class="mt-3">
          <p class="title is-3 is-pulled-right">${{ getTotal }}</p>
          <p class="title is-3">Total</p>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { round, sum } from '../../util/helpers';

export default {
  name: 'ItemsDetails',
  props: {
    cart: { type: Object },
    delivery: { type: Object, default: null },
    withdrawal: { type: Object, default: null },
    canRemoveItems: {
      type: Boolean,
      default: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    showDiscount: {
      type: Boolean,
      default: true,
    },
    showTotal: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    getCustomerCost() {
      return this.delivery && this.delivery.charged
        ? this.delivery.customerPrice
        : 0;
    },
    getProviderCost() {
      return this.withdrawal && this.withdrawal.charged
        ? this.withdrawal.fee
        : 0;
    },
    // getTaxesTotal() {
    //   return round(
    //     sum(this.cart.items, null, (item) => item.detail.taxes.total),
    //   );
    // },
    getTotal() {
      if (this.withdrawal) {
        const { charged, fee } = this.withdrawal || {};
        const logisticPrice = charged ? fee : 0;
        return round(sum(this.cart.items, 'price') - logisticPrice);
      }
      const { charged, customerPrice } = this.delivery || {};
      const logisticPrice = charged ? customerPrice : 0;
      return round(sum(this.cart.items, 'price') + logisticPrice);
    },
  },
  methods: {
    handleRemoveItem(index) {
      this.$emit('removeItem', index);
    },
  },
  mounted() {},
};
</script>
