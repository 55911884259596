<template>
  <div class="">
    <div>
      <b-field label="Metodo de Envío" v-if="selectedDelivery">
        <div
          class="flex flex-row items-center justify-between p-1 px-3 bg-white border-2 rounded-lg"
        >
          <div>
            <p class="text-lg font-bold">{{ selectedDelivery.name }}</p>
          </div>
          <b-button
            v-if="canRemove"
            class="flex items-center justify-center border-none"
            @click="select(null)"
            ><b-icon icon="close"></b-icon
          ></b-button>
        </div>
      </b-field>

      <b-field :label="label" v-if="!selectedDelivery">
        <b-autocomplete
          data-cy="deliveries-lookup"
          v-model="deliveryToSearch"
          ref="deliveryInput"
          field="name"
          :data="filteredDeliveries"
          @select="select"
          :keep-first="true"
          icon="truck"
          :loading="!deliveries.length"
        ></b-autocomplete
      ></b-field>
    </div>
  </div>
</template>

<script>
import ApiService from '../services/ApiService';

const { DeliveryService } = ApiService;
export default {
  name: 'DeliveryLookup',
  props: {
    label: {
      type: String,
      default: 'Buscar un metodo de envío',
    },
    selectedDelivery: Object,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      deliveryToSearch:
        (this.selectedDelivery && this.selectedDelivery.username) || '',
      deliveries: [],
    };
  },

  computed: {
    filteredDeliveries() {
      if (!this.deliveryToSearch) return [];
      return [...this.deliveries]

        .filter(
          ({ name }) => name.toLowerCase().indexOf(this.deliveryToSearch.toLowerCase())
            >= 0,
        )
        .sort((a, b) => {
          const name1 = a.name.toLowerCase();
          const name2 = b.name.toLowerCase();
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        });
    },
  },
  watch: {
    selectedDelivery() {
      if (!this.selectedDelivery) {
        this.deliveryToSearch = '';
        this.$nextTick(() => {
          this.$refs.deliveryInput.focus();
        });
      }
    },
  },
  methods: {
    select(delivery) {
      this.$emit('select', JSON.parse(JSON.stringify(delivery)));
    },
  },
  async mounted() {
    const { data: deliveries } = await DeliveryService.find({ status: true });
    this.deliveries = deliveries;
  },
};
</script>
