<template>
  <b-modal :active="active" :has-modal-card="true" :can-cancel="['escape', 'outside']" @close="$emit('dismiss')">
    <div class="border-2 border-indigo-400 rounded-lg modal-card">
      <section class="modal-card-body" v-if="order.cart && !loading">
        <p class="title is-4">Preparación pedido {{ order.number }}</p>

        <div class="shadow rounded-md px-2 py-0.5 my-1" v-for="(item, index) in items" :key="`control${item.number}`">
          <div class="flex flex-col justify-between my-4 space-y-2">
            <b-checkbox class="col-span-3" v-model="selected" type="is-success" :native-value="item"
              @input="handleSelectItem(item, index)">
              <span class="text-sm"><i>{{ item.number }} -</i> {{ item.name }}</span></b-checkbox>

            <b-field>
              <b-numberinput class="is-pulled-right" controls-position="compact" controls-rounded min="0"
                :step="item.unit === 'UNITY' ? 1 : 50" type="is-primary" v-model="item.quantity"
                @input="handleChangeQuantity(item, index)"></b-numberinput>
            </b-field>
          </div>
        </div>

        <div>
          <hr class="mt-4 divider" v-if="diffs.length" />
          <p class="mt-4 title is-4">Inventario faltante</p>

          <p class="p-4 rounded-md shadow" v-if="diffs.every(({ diff }) => diff <= 0)">
            No hay faltantes <span class="ml-2">🎉</span>
          </p>

          <div v-for="{ item, diff } in diffs" :key="`info${item.number}`">
            <div v-if="diff > 0" class="shadow rounded-md px-4 py-0.5">
              <div class="flex flex-col justify-between my-4 space-y-2">
                <p class="text-xs font-bold">
                  {{ diff }} {{ item | unit }} de
                  {{ item.name }}
                </p>
                <b-field>
                  <b-checkbox v-model="item.state" type="is-warning" true-value="pending" size="is-small"
                    false-value="shortage">{{
    item.state === "pending" ? "Pendiente" : "Faltante"
  }}</b-checkbox>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" :disabled="false" @click="$emit('dismiss')">
          Cancelar
        </button>
        <Button data-cy="prepare-order" :disabled="validPrepare" :once="false" type="is-success"
          :onClickFn="handlePrepareOrder">Aceptar</Button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import useOrderStore from '../../store/order';
import useUserStore from '../../store/user';

const {
  utils: {
    helpers: { inventoryDifference, friendlyUnity },
  },
} = ActivateSDK;

export default {
  name: 'Prepare',
  props: {
    active: Boolean,
    order: Object,
    loading: Boolean,
  },
  components: {},
  data() {
    return {
      userStore: useUserStore(),
      orderStore: useOrderStore(),
      selected: [],
      itemsToPrepare: [],
      items: [],
      maxQuantities: [],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'order.number': function () {
      this.items = this.order.cart.items;

      this.items = this.items.map(
        ({
          number, product: { _id, name, unit }, quantity, isWholesaler,
        }) => ({
          _id,
          number,
          name,
          unit,
          quantity,
          max: +`${quantity}`,
          state: 'pending',
          isWholesaler,
        }),
      );

      this.itemsToPrepare = this.items;
      this.selected = this.items;
    },
  },
  computed: {
    diffs() {
      return this.items.reduce((acc, item) => {
        const originalItem = this.order.cart.items.find(
          ({ number }) => number === item.number,
        );

        const diff = originalItem.quantity - item.quantity;

        acc.push({
          item,
          diff,
        });

        return acc;
      }, []);
    },
    validPrepare() {
      return this.selected.every((item) => item.quantity === 0);
    },
  },
  methods: {
    handleChangeQuantity(item, index) {
      this.itemsToPrepare[index].quantity = item.quantity;
    },
    handleSelectItem(item, index) {
      const isSelected = this.selected.findIndex(({ number }) => number === item.number) !== -1;
      if (isSelected) {
        this.itemsToPrepare[index].quantity = item.max;
      } else {
        this.itemsToPrepare[index].quantity = 0;
      }
    },
    async handlePrepareOrder() {
      const { _id: id } = this.order;
      const payload = this.itemsToPrepare;


      const itemsDifference = inventoryDifference(this.order.cart.items, this.itemsToPrepare);

      const pendingItems = itemsDifference.filter((item) => item.state === 'pending' && item.diff > 0);

      if (pendingItems.length) {
        const pendingItemsHtml = pendingItems.map(({ name, unit, diff }) => `<b>${friendlyUnity({ product: { name, unit }, quantity: diff })}</b> - ${name}`).join('<br>');

        this.$buefy.dialog.confirm({
          title: 'Preparar con pendientes',
          message:
            `¿Estás seguro de preparar este pedido con pendientes?<br><br><b>ATENCIÓN!</b> Se va a generar un nuevo pedido con los siguientes items:<br><br>${pendingItemsHtml}`,
          confirmText: 'Preparar con pendientes',
          cancelText: 'Editar faltantes',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: async () => {
            this.$emit('dismiss');
            const updated = await this.orderStore.prepare(id, payload);
            this.$emit('update', updated);
            return updated;
          },
        });
      } else {
        const updated = await this.orderStore.prepare(id, payload);
        this.$emit('update', updated);
        this.$emit('dismiss');
      }
    },
  },
};
</script>
