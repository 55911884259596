<!-- eslint-disable max-len -->
<template>
  <b-modal
    :active="active"
    :width="640"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div
      class="p-4 mx-4 bg-white border-4 border-indigo-400 rounded-lg"
      v-if="statistics"
    >
      <section>
        <div class="grid grid-cols-1 divide-y divide-purple-500">
          <div class="pb-2">
            <p class="mb-2 text-lg font-bold">Rendiciones</p>
            <div class="flex flex-row justify-between gap-4">
              <p class="text-sm">Saldo de cuenta corriente:</p>
              <p :class="[account < 0 ? 'text-red-400' : 'text-purple-500']">
                + {{ account | currency }}
              </p>
            </div>
            <div class="flex flex-row justify-between gap-4">
              <p class="text-sm">Stock valorizado en Activate:</p>
              <p class="text-red-400">
                - {{ statistics.inventory | currency }}
              </p>
            </div>
            <div class="flex flex-row justify-between gap-4">
              <p class="text-sm">Pedidos por entregar:</p>
              <p class="text-purple-500">
                + {{ statistics.pending | currency }}
              </p>
            </div>
          </div>
          <div class="py-2">
            <div class="flex flex-row justify-between gap-4">
              <p class="text-sm">
                {{
                  `${
                    finalBalance >= 0
                      ? 'Activate te debe:'
                      : 'Activate tiene a favor:'
                  }`
                }}
              </p>
              <p
                class="font-bold"
                :class="[
                  finalBalance <= 0 ? 'text-red-400' : 'text-purple-500',
                ]"
              >
                {{ Math.abs(finalBalance) | currency }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'Agreement',
  components: {},
  props: {
    active: Boolean,
    statistics: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    account() {
      return this.statistics.debtBalance;
    },
    finalBalance() {
      return this.account - this.statistics.inventory + this.statistics.pending;
    },
  },
  methods: {},
};
</script>
