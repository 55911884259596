var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Section',[_c('b-tabs',{attrs:{"type":"is-toggle","vertical":_vm.isMobile ? true : false,"size":!_vm.isMobile ? 'is-small' : '',"animated":false},on:{"input":_vm.tabChanged},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('b-tab-item',{attrs:{"value":"pending"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-icon',{attrs:{"icon":"information-outline"}}),_c('span',[_vm._v(" Pedidos sin fecha de entrega "),_c('b-tag',{attrs:{"rounded":""}},[_vm._v(" "+_vm._s(_vm.pending.length))])],1)]},proxy:true}])},[_c('Layout',[_c('List',{attrs:{"loading":_vm.isLoading.pending}},[(_vm.pending.length)?_c('p',{staticClass:"text-2xl font-bold"},[_vm._v(" 🚨 Pedidos sin fecha de entrega ")]):_vm._e(),_vm._l((_vm.pending),function(order){return _c('OrderCard',{key:order._id,attrs:{"order":order,"isCheckable":false,"operationPermission":true}})})],2)],1)],1),_c('b-tab-item',{attrs:{"value":"history"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-icon',{attrs:{"icon":"history"}}),_c('span',[_vm._v("Historial de pedidos")])]},proxy:true}])},[_c('SearchBar',{attrs:{"store":_vm.orderStore,"placeholder":"✨ Buscá clientes y direcciones"}}),_c('Layout',[_c('List',{attrs:{"loading":_vm.isLoading.orders}},[_c('p',{staticClass:"px-2 text-2xl font-bold"},[_vm._v("🗓 Historial de pedidos")]),_c('Draggable',{staticClass:"space-y-4",attrs:{"tag":"ul","handle":".handle","animation":200},model:{value:(_vm.orders),callback:function ($$v) {_vm.orders=$$v},expression:"orders"}},_vm._l((_vm.orders),function(order){return _c('OrderCard',{key:order._id,ref:"orderCard",refInFor:true,attrs:{"order":order,"operationPermission":true,"isDraggable":false,"isCheckable":true}})}),1)],1)],1)],1)],1),_c('OrderActions',{attrs:{"selected":_vm.selected,"operationPermission":true,"actions":{
      roadmap: false,
      invoice: true,
      consignment: false,
      deliveryRoute: false,
      export: true,
      prepare: true,
    }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }