<template>
  <div
    class="grid grid-cols-1 gap-4 sm:grid-cols-2"
    v-if="shortage && critical"
  >
    <div class="bg-white rounded-lg shadow" v-if="shortage.products.length">
      <p class="p-4 text-lg font-bold text-purple-500">
        Faltantes de productos
      </p>
      <b-collapse
        animation="slide"
        :open="false"
        v-for="provider in [...shortage.providers].sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))"
        :key="provider._id"
      >
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">
              {{ provider.name }}
            </p>

            <!-- Needs to have stop propagation modifier on click event -->
            <button
              class="mt-2 mb-2 mr-2 button is-info is-light is-pulled-right"
              @click.stop="handleCreateBuy('shortage', provider)"
            >
              <b-icon icon="shopping"></b-icon>
            </button>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="my-2">
          <div class="grid grid-cols-1 border-none divide-y-0">
            <div
              v-for="product in productsByProvider('shortage', provider._id)"
              :key="product._id"
              class="flex justify-start px-4 py-1 text-sm rounded-md cursor-pointer"
              :class="[!hasStock(product) ? 'text-red-500' : '']"
              @click="handleSelectProduct(product)"
            >
              <div class="flex-grow px-2 font-medium truncate">
                <b-tooltip
                  v-if="isAutomaticInventory(product)"
                  type="is-primary"
                  label="Gestión de stock automática"
                >
                  <b-tag
                    :type="
                      isAutomaticInventory(product)
                        ? 'is-primary'
                        : 'is-warning'
                    "
                    >{{ isAutomaticInventory(product) ? 'A' : 'M' }}</b-tag
                  >
                </b-tooltip>
                <b-tooltip
                  v-if="!isAutomaticInventory(product)"
                  type="is-primary"
                  label="Gestión de stock automática"
                >
                  <b-tag
                    :type="
                      isAutomaticInventory(product)
                        ? 'is-primary'
                        : 'is-warning'
                    "
                    >{{ isAutomaticInventory(product) ? 'A' : 'M' }}</b-tag
                  >
                </b-tooltip>
                {{ product.name }}
              </div>
              <div class="flex-none font-bold tracking-tight">
                {{ product.inventory[0].theoretical }}
                {{ product | unit }}
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div class="bg-white rounded-lg shadow" v-if="critical.products.length">
      <p class="p-4 text-lg font-bold text-purple-500">Productos críticos</p>
      <b-collapse
        animation="slide"
        :open="false"
        v-for="provider in [...critical.providers].sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)"
        :key="provider._id"
      >
        <template #trigger="props">
          <div class="card-header" role="button">
            <p class="card-header-title">
              {{ provider.name }}
            </p>

            <!-- Needs to have stop propagation modifier on click event -->
            <button
              class="mt-2 mb-2 mr-2 button is-info is-light is-pulled-right"
              @click.stop="handleCreateBuy('critical', provider)"
            >
              <b-icon icon="shopping"></b-icon>
            </button>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="my-4">
          <div
            v-for="product in productsByProvider('critical', provider._id)"
            :key="product._id"
            class="flex justify-start px-4 py-1 text-sm rounded-md cursor-pointer"
            :class="[!hasStock(product) ? 'text-red-500' : '']"
            @click="handleSelectProduct(product)"
          >
            <div class="flex flex-grow gap-2 px-2 font-medium truncate">
              <b-tooltip
                v-if="isAutomaticInventory(product)"
                type="is-primary"
                label="Gestión de stock automática"
              >
                <b-tag
                  :type="
                    isAutomaticInventory(product) ? 'is-primary' : 'is-warning'
                  "
                  >{{ isAutomaticInventory(product) ? 'A' : 'M' }}</b-tag
                >
              </b-tooltip>
              <b-tooltip
                v-if="!isAutomaticInventory(product)"
                type="is-primary"
                label="Gestión de stock automática"
              >
                <b-tag
                  :type="
                    isAutomaticInventory(product) ? 'is-primary' : 'is-warning'
                  "
                  >{{ isAutomaticInventory(product) ? 'A' : 'M' }}</b-tag
                >
              </b-tooltip>
              {{ product.name }}
            </div>
            <div class="flex-none font-bold tracking-tight">
              {{ product.inventory[0].theoretical }}
              {{ product | unit }} / {{ product.inventory[0].critical }}
              {{ product | unit }}
            </div>
          </div>
        </div>
      </b-collapse>
    </div>

    <PreCart
      :active="isCreatingPrecart"
      :items="preCartItems"
      :provider="preCartProvider"
      @dismiss="isCreatingPrecart = false"
    ></PreCart>

    <GenericModal
      :active="isProductModalActive"
      @dismiss="isProductModalActive = false"
    >
      <ProductCard
        :product="selectedProduct"
        :operationPermission="true"
      ></ProductCard>
    </GenericModal>
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import ApiService from '../../services/ApiService';
import PreCart from './PreCart.vue';
import GenericModal from '../GenericModal.vue';
import ProductCard from '../Product/mobile/ProductCard.vue';
import { hasStock, isAutomaticInventory } from '../../util/helpers';

const { InventoryService } = ApiService;

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'Shortage',
  components: {
    PreCart,
    GenericModal,
    ProductCard,
  },
  data() {
    return {
      shortage: null,
      critical: null,
      preCartItems: [],
      preCartProvider: null,
      isCreatingPrecart: false,
      selectedProduct: null,
      isProductModalActive: false,
    };
  },
  computed: {},
  mounted() {
    this.fetchShortage();
  },
  methods: {
    hasStock,
    isAutomaticInventory,
    handleSelectProduct(product) {
      this.isProductModalActive = true;
      this.selectedProduct = product;
    },
    handleCreateBuy(inventory, provider) {
      // eslint-disable-next-line no-underscore-dangle
      const products = this.productsByProvider(inventory, provider._id);

      if (inventory === 'shortage') {
        this.preCartItems = products.map(
          (product) => new Item({
            product,
            quantity: -product.inventory[0].theoretical,
          }),
        );
      } else if (inventory === 'critical') {
        const shortage = this.productsByProvider('shortage', provider._id);

        this.preCartItems = products.map(
          (product) => new Item({
            product,
            quantity:
                product.inventory[0].critical
                - +product.inventory[0].theoretical,
          }),
        );

        const shortageNoCritical = shortage
          .filter((product) => !products.find((p) => p._id === product._id))
          .map(
            (product) => new Item({
              product,
              quantity: -product.inventory[0].theoretical,
            }),
          );

        this.preCartItems = [...this.preCartItems, ...shortageNoCritical];
      }

      this.preCartProvider = provider;
      this.isCreatingPrecart = true;
    },
    productsByProvider(inventory, id) {
      return this[inventory].products
        .filter(({ provider: { _id } }) => _id === id)
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    },

    async fetchShortage() {
      const {
        data: { shortage, critical },
      } = await InventoryService.findShortage();
      this.shortage = shortage;
      this.critical = critical;
      this.$emit('fetched', { shortage, critical });
    },
  },
};
</script>
