<template>
  <div>
    <p class="text-xl font-bold">Ingresar pago</p>
    <b-field label="Importe" class="mt-4">
      <b-input
        type="number"
        placeholder="0.00"
        v-model="amount"
        step="0.01"
        icon="currency-usd"
        expanded
      ></b-input>
      <p class="control">
        <Button
          data-cy="create-pay"
          type="is-success"
          label="Confirmar"
          :onClickFn="handleCreatePay"
          >Confirmar</Button
        >
      </p>
    </b-field>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

const { AccountService } = ApiService;

export default {
  name: 'NewCustomerPay',
  props: {
    account: Object,
  },
  components: {},
  data() {
    return {
      amount: 0,
    };
  },
  watch: {
    'account.customer': function () {
      this.amount = 0;
    },
  },
  computed: {},
  methods: {
    async handleCreatePay() {
      if (this.amount === 0) {
        this.$buefy.snackbar.open('Ingresar un valor distinto a 0');
        return;
      }
      const payload = {
        // eslint-disable-next-line no-underscore-dangle
        partner: this.account.partner._id || this.account.partner,
        customer: this.account.customer._id,
        amount: this.amount,
      };
      const { error, data: pay } = await AccountService.pay(payload);

      if (error) {
        console.error(error.data.code);
        return;
      }

      this.$emit('created', pay);
      this.amount = 0;
    },
    handleIngressPay() {
      this.active = true;
    },
  },
};
</script>
