<template>
  <li
    v-if="element.paymentMethod"
    class="flex mt-2 text-sm text-gray-600 dark:text-gray-300"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="flex-none inline w-6 h-6 align-center"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
      />
    </svg>

    <span class="ml-2 truncate">{{ element.paymentMethod | capitalize }}</span>
  </li>
</template>

<script>
export default {
  name: 'PaymentMethodComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
