<template>
  <div>
    <CardItem ref="cardItem" :element="order" :operationPermission="operationPermission" :isDraggable="isDraggable"
      :componentClass="[
      order.hasShortageItems ? 'border-t-4 border-red-300' : '',
      ...componentClass,
    ]" :selected="(customStore && customStore.selected) || selected" :isCheckable="isCheckable"
      @select="handleSelect">
      <div :class="['absolute inset-0 border-l-8 rounded-lg w-8']" :style="{ borderColor: stc(order.driver?.name) }"></div>
      <div class="absolute flex flex-col w-10 gap-2 py-4 my-6 h-1/2 right-4">
        <div v-if="isAbleToPrepare(order)" class="rounded-lg bg-purple-50">
          <div @click="handlePrepareOrder(order)"
            class="flex items-center justify-center w-full h-full p-1 cursor-pointer">
            <b-icon class="my-1" icon="package-variant-closed" type="is-primary"></b-icon>
          </div>
        </div>

        <div v-if="isAbleToDeliver(order)" class="rounded-lg bg-purple-50">
          <div @click="isAbleToDeliver(order) ? handleDeliverOrder(order) : () => { }"
            class="flex items-center justify-center w-full h-full p-1 cursor-pointer">
            <b-tooltip :label="order.delivery.delivered ? 'Entregado' : 'No entregado'"
              :type="order.delivery.delivered ? 'is-primary' : 'is-danger'"><b-icon class="my-1" icon="truck-outline"
                :type="order.delivery.delivered ? 'is-success' : 'is-danger'"></b-icon>
            </b-tooltip>
          </div>
        </div>

        <div v-if="isAbleToUpdateDriver(order)" :class="['rounded-lg bg-purple-50',]">
          <div @click="handleUpdateDriver(order)"
            :class="['flex items-center justify-center w-full h-full p-1 cursor-pointer', !order.driver ? 'bg-red-500 text-white rounded-md' : '']">
            <svg class="inline w-6 h-6 align-center" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
              viewBox="0 0 24 24">
              <path fill="currentColor"
                d="M16.78 21.84a1.998 1.998 0 1 1-1.56-3.68a1.998 1.998 0 1 1 1.56 3.68M7.62 6c1.11 0 2-.89 2-2a2 2 0 0 0-2-2c-1.12 0-2 .9-2 2c0 1.11.88 2 2 2m14.43 10.34L18.2 18c.22.22.42.5.56.82c.14.33.2.68.24 1l3.83-1.64zM10.16 8.78l.74 1.81c-.24-.09-.46-.21-.64-.33c-.6-.39-1.04-.88-1.33-1.46l-.74-1.57c-.19-.35-.42-.61-.74-.79c-.29-.17-.6-.26-.92-.26c-.32 0-.62.08-.91.22c-1.4 1.1-1.75 3.14-1.75 3.14l-.34 1.57c-.09.52-.14 1.04-.14 1.57v4.96L1 20.87L2.5 22l2.77-3.75l.17-3.25l1.68 2.34V22h1.85v-6.06l-1.85-2.61v-.65c0-.44 0-.87.11-1.29l.35-1.2c.38.55.84 1.03 1.39 1.44c.45.34 1.71.94 2.9 1.23L14 17.8c.22-.22.5-.42.83-.56c.32-.14.67-.2.99-.24L12 8zm5.2 3.34l1.96 4.6l5.63-2.41L21 9.72" />
            </svg>
          </div>
        </div>
      </div>

      <div class="flex justify-between space-x-2 text-sm">
        <DateComponent :element="order"></DateComponent>
        <StateComponent :element="order"></StateComponent>
        <OrderOptions :order="order" :operationPermission="operationPermission" :driverPermission="driverPermission"
          @prepare="handlePrepareOrder" @update="handleUpdateElement" @updateDriver="handleUpdateDriver(order)">
        </OrderOptions>
      </div>
      <FlagsComponent v-show="!compact" :element="order" @click="handleViewDetails" :showInstance="showInstance">
      </FlagsComponent>

      <div class="grid grid-cols-8 mt-2">
        <div class="col-span-7">
          <a @click="handleShowCustomerCard" class="text-lg font-bold text-gray-700 dark:text-white">{{
      order.customer.name }}</a>

          <a v-if="operationPermission" class="text-xs text-gray-700 dark:text-white">
            x {{ order.partner.username }}</a>

          <svg v-if="order.customer.verified" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
            class="inline w-6 h-6 ml-2">
            <path fill-rule="evenodd"
              d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
              clip-rule="evenodd" />
          </svg>

          <ul class="mt-2 list-none">
            <LogisticComponent v-show="!compact" :element="order" property="delivery"
              :operationPermission="operationPermission"></LogisticComponent>

            <AddressComponent :element="order" property="delivery" :operationPermission="operationPermission">
            </AddressComponent>
            <UpdateDeliveryDate :active="isUpdatingDeliveryDate" :order="order" @update="handlePostUpdateDeliveryDate"
              :disabled="!isAbleToUpdateDeliveryDate(order)">
              <LogisticDateComponent :element="order" property="delivery" :operationPermission="operationPermission"
                @update="handleUpdateElement"></LogisticDateComponent>
            </UpdateDeliveryDate>
            <PaymentMethodComponent :element="order" v-show="!compact"></PaymentMethodComponent>
            <li v-if="order.roadmap" class="flex mt-2 text-sm text-gray-600 dark:text-gray-300">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="inline w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z" />
              </svg>

              <span class="ml-2 truncate">#{{ order.roadmap.number }} </span>
            </li>
            <NotesComponent :element="order" v-show="!compact" @update="handleUpdateNotes"></NotesComponent>
            <RoadmapNotesComponents :element="order" v-show="!compact" @update="handleUpdateRoadmapNotes"></RoadmapNotesComponents>
          </ul>
        </div>
      </div>
      <div v-show="!compact" class="flex items-end justify-between space-x-2">
        <div class="flex flex-row items-center gap-2">
          <ItemsComponent :element="order" @click="handleViewDetails"></ItemsComponent>
          <div v-if="order.metadata && Object.values(order.metadata).some(items => shortages(items))" class="w-5 h-5 bg-red-500 rounded-full"></div>
        </div>
        <div class="inline text-xl font-bold text-black align-center">
          {{ order.total | currency }}
        </div>
      </div>
    </CardItem>
    <DetailsModal :active="isDetailsOpen" :element="orderSelected" @dismiss="handleDismissDetails"></DetailsModal>
    <UpdateDriverModal :store="customStore || orderStore" :active="isUpdatingDriver" :element="orderToUpdateDriver"
      @dismiss="isUpdatingDriver = false" @update="handlePostUpdateDriver">
    </UpdateDriverModal>
    <Prepare :active="isPreparing" :order="orderToPrepare" @dismiss="isPreparing = false" @update="handleUpdateElement">
    </Prepare>
    <GenericModal :active="isCustomerModalActive" @dismiss="isCustomerModalActive = false">
      <CustomerCard :customer="order.customer"></CustomerCard>
    </GenericModal>
    <AddToRoadmap :active="isAddToRoadmapModalActive" :dispatch="order" @dismiss="isAddToRoadmapModalActive = false"
      @update="handleUpdateElement"></AddToRoadmap>
  </div>
</template>

<script>
import { isSameDay } from 'date-fns';
import { mapState } from 'pinia';
import stc from 'string-to-color';
import useDispatchStore from '../../store/dispatch';
import useOrderStore from '../../store/order';
import useRoadmapStore from '../../store/roadmap';
import { ORDER_STATES } from '../../util/constants';
import { isBuy, isOrder } from '../../util/helpers';
import AddressComponent from '../AddressComponent.vue';
import AddToRoadmap from '../AddToRoadmap.vue';
import CardItem from '../CardItem.vue';
import CustomerCard from '../Customer/mobile/CustomerCard.vue';
import DateComponent from '../DateComponent.vue';
import DetailsModal from '../DetailsModal.vue';
import FlagsComponent from '../FlagsComponent.vue';
import GenericModal from '../GenericModal.vue';
import ItemsComponent from '../ItemsComponent.vue';
import LogisticComponent from '../LogisticComponent.vue';
import LogisticDateComponent from '../LogisticDateComponent.vue';
import NotesComponent from '../NotesComponent.vue';
import PaymentMethodComponent from '../PaymentMethodComponent.vue';
import RoadmapNotesComponents from '../RoadmapNotesComponent.vue';
import StateComponent from '../StateComponent.vue';
import UpdateDriverModal from '../UpdateDriverModal.vue';
import OrderOptions from './OrderOptions.vue';
import Prepare from './Prepare.vue';
import UpdateDeliveryDate from './UpdateDeliveryDate.vue';

export default {
  name: 'OrderCard',
  components: {
    CardItem,
    DetailsModal,
    OrderOptions,
    Prepare,
    StateComponent,
    DateComponent,
    FlagsComponent,
    AddressComponent,
    NotesComponent,
    RoadmapNotesComponents,
    PaymentMethodComponent,
    LogisticComponent,
    LogisticDateComponent,
    ItemsComponent,
    UpdateDeliveryDate,
    GenericModal,
    CustomerCard,
    AddToRoadmap,
    UpdateDriverModal,
  },
  props: {
    order: {
      type: Object,
    },
    operationPermission: {
      type: Boolean,
      default: false,
    },
    driverPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
    componentClass: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      orderStore: useOrderStore(),
      dispatchStore: useDispatchStore(),
      roadmapStore: useRoadmapStore(),
      isDetailsOpen: false,
      orderSelected: null,
      isUpdatingDeliveryDate: false,
      orderToPrepare: {},
      query: {},
      isUpdatingDriver: false,
      orderToUpdateDriver: null,
      isPreparing: false,
      isDelivering: false,
      fieldToFilter: 'customer',
      isCustomerModalActive: false,
      isAddToRoadmapModalActive: false,
    };
  },
  mounted() { },
  computed: {
    ...mapState(useOrderStore, ['orders']),
    ...mapState(useOrderStore, ['selected']),
    ...mapState(useDispatchStore, ['dispatchs']),
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  methods: {
    stc,
    handlePostUpdateDriver(updated) {
      this.handleUpdateElement();

      const sameDateDispatchs = this.dispatchs.filter((dispatch) => {
        if (dispatch._id === updated._id) return false;

        if (dispatch.state === 'CANCELADA' || dispatch.state === 'CANCELADO') return false;

        if (isOrder(dispatch)) {
          if (!dispatch.delivery || !updated.delivery) return false;

          return isSameDay(new Date(dispatch.delivery.date), new Date(updated.delivery.date)) && dispatch.delivery.address.street === updated.delivery.address.street;
        }
        if (isBuy(dispatch)) {
          if (!dispatch.withdrawal || !updated.withdrawal) return false;
          return isSameDay(new Date(dispatch.withdrawal.date), new Date(updated.withdrawal.date)) && dispatch.provider._id === updated.provider._id;
        }
        return false;
      });


      if (sameDateDispatchs.length) {
        this.$buefy.dialog.confirm({
          title: 'Asignar repartidor a otros envíos',
          message:
            `Encontramos ${sameDateDispatchs.length} envíos a la misma dirección para el mismo día. ¿Querés asignar el mismo repartidor? 😉 <br><br> <ul>${sameDateDispatchs.map((dispatch) => `<li>- ${dispatch.customer?.name || dispatch.provider?.name} - ${dispatch.delivery?.address?.street || dispatch.withdrawal?.address.street}</li>`).join('')}</ul>`,
          confirmText: 'Si, dale',
          cancelText: 'Emm, no',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [index, dispatch] of sameDateDispatchs.entries()) {
              // eslint-disable-next-line no-await-in-loop
              const updatedDispatch = await this.orderStore.updateDriver(dispatch, {
                driver: updated.driver,
              });
              this.$buefy.toast.open({
                duration: 3000,
                message: `Envío ${index + 1}/${sameDateDispatchs.length} actualizado`,
                position: 'is-bottom',
                type: 'is-primary',
                queue: false,
              });
              this.handleUpdateElement(updatedDispatch);
            }
          },
        });
      }
    },
    handlePostUpdateDeliveryDate(updated) {
      this.handleUpdateElement(updated);

      const sameDateDispatchs = this.dispatchs.filter((dispatch) => {
        if (dispatch._id === updated._id) return false;
        if (dispatch.state === 'CANCELADA' || dispatch.state === 'CANCELADO') return false;

        if (isOrder(dispatch)) {
          if (!dispatch.delivery || !updated.delivery) return false;

          return isSameDay(new Date(dispatch.delivery.date), new Date(updated.delivery.date)) && dispatch.delivery.address.street === updated.delivery.address.street;
        }
        if (isBuy(dispatch)) {
          if (!dispatch.withdrawal || !updated.withdrawal) return false;
          return isSameDay(new Date(dispatch.withdrawal.date), new Date(updated.withdrawal.date)) && dispatch.provider._id === updated.provider._id;
        }
        return false;
      });

      if (sameDateDispatchs.length) {
        this.$buefy.dialog.confirm({
          title: 'Asignar horario a otros envíos',
          message:
            `Encontramos ${sameDateDispatchs.length} envíos a la misma dirección para el mismo día. ¿Querés asignar el mismo rango horario? 😉`,
          confirmText: 'Si, dale',
          cancelText: 'Emm, no',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: async () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [index, dispatch] of sameDateDispatchs.entries()) {
              const { _id: id } = dispatch;
              const payload = {
                date: updated.delivery.date,
                startTime: updated.delivery.startTime,
                endTime: updated.delivery.endTime,
              };
              // eslint-disable-next-line no-await-in-loop
              const updatedDispatch = await this.orderStore.updateDelivery(id, payload);
              this.$buefy.toast.open({
                duration: 3000,
                message: `Envío ${index + 1}/${sameDateDispatchs.length} actualizado`,
                position: 'is-bottom',
                type: 'is-primary',
                queue: false,
              });
              this.handleUpdateElement(updatedDispatch);
            }
          },
        });
      }
    },
    async handleUpdateRoadmapNotes(updatedNotes) {
      const updated = await this.orderStore.patch(this.order._id, {
        roadmapNotes: updatedNotes,
      });

      this.handleUpdateElement(updated);
    },
    async handleUpdateNotes(updatedNotes) {
      const updated = await this.orderStore.patch(this.order._id, {
        notes: updatedNotes,
      });

      this.handleUpdateElement(updated);
    },
    isAbleToPrepare({ state, blocked }) {
      return (
        !blocked && state === ORDER_STATES.CONFIRMED && this.operationPermission
      );
    },
    hasPermissionToDeliver() {
      return this.operationPermission;
    },
    isAbleToDeliver({ state, blocked }) {
      return !blocked && state === ORDER_STATES.PREPARED && (this.operationPermission || this.driverPermission);
    },
    isAbleToUpdateDriver({ state, blocked, delivery }) {
      return delivery.isOwnLogistic && this.operationPermission && !blocked && state !== ORDER_STATES.CANCELLED && (state === ORDER_STATES.PREPARED || state === ORDER_STATES.CONFIRMED);
    },
    isAbleToUpdateDeliveryDate(element) {
      const { state, blocked } = element;
      if (
        element.partner === this.currentUser._id
        && !blocked
        && state !== ORDER_STATES.CANCELLED
        && state !== ORDER_STATES.PREPARED

      ) return true;

      return (
        !blocked && state !== ORDER_STATES.CANCELLED && (this.operationPermission || this.driverPermission)
      );
    },
    shortages(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff > 0 && quantity !== max,
      );
    },
    parsedMetadata(items) {
      return items.filter(
        ({ diff, quantity }) => (diff && diff !== 0)
          || (typeof diff === 'undefined' && quantity !== 0),
      );
    },
    handleUpdateElement(element) {
      this.dispatchStore.updateElement(element, 'dispatchs');
      this.dispatchStore.updateElement(element, 'due');
      this.roadmapStore.updateDispatch(element);
    },
    handleSelect(isSelected, element) {
      const store = this.customStore || this.orderStore;
      if (isSelected) {
        store.select(element);
      } else {
        store.deselect(element);
      }

      const elements = this.customStoreKey
        ? this.customStore[this.customStoreKey]
        : this.orderStore.orders;

      const key = this.customStoreKey || 'orders';

      store.updatePositions(elements, key);
    },
    handleUpdateDriver(order) {
      this.isUpdatingDriver = true;
      this.orderToUpdateDriver = order;
    },
    handleAddToRoadmap() {
      this.isAddToRoadmapModalActive = true;
    },
    async handleDeliverOrder(order) {
      if (this.isDelivering) return;

      this.isDelivering = true;

      if (order.delivery.delivered) {
        this.$buefy.dialog.confirm({
          title: 'Retroceder entrega',
          message: '¿Estás seguro de <b>retroceder</b> esta entrega?',
          confirmText: 'Retroceder',
          cancelText: 'Emm, no',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Se está retrocediendo la entrega',
              position: 'is-bottom',
              type: 'is-warning',
              queue: false,
            });
            const updated = await this.orderStore.rollbackDeliver(order._id);
            this.$emit('update', updated);
            this.handleUpdateElement(updated);
          },
        });
      } else {
        this.$buefy.dialog.confirm({
          title: 'Confirmar entrega',
          message:
            `¿Estás seguro de <b>confirmar la entrega</b> de este pedido?  <br><br>    <div class="flex flex-col gap-1 p-2 mb-4 border-2 border-yellow-400 rounded-md bg-yellow-50"
    >
      <span class="font-bold">IMPORTANTE:</span>
      <span class="text-md"
        >Se va a enviar un correo al cliente notificando la entrega</span
      >
    </div>`,
          confirmText: 'Entregado',
          cancelText: 'Emm, no',
          type: 'is-primary',
          hasIcon: true,
          onConfirm: async () => {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Se está entregando el pedido',
              position: 'is-bottom',
              type: 'is-warning',
              queue: false,
            });
            const updated = await this.orderStore.deliver(order._id);
            this.dispatchStore.removeFrom(updated, 'due');
            this.handleUpdateElement(updated);
          },
        });
      }

      this.isDelivering = false;
    },
    handlePrepareOrder(order) {
      this.isPreparing = true;
      this.orderToPrepare = order;
    },
    handleViewDetails(order) {
      this.orderSelected = order;
      this.isDetailsOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.orderSelected = null;
    },
    handleShowCustomerCard() {
      this.isCustomerModalActive = true;
    },
  },
  watch: {},
};
</script>
