var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-4 my-4 bg-white rounded-lg shadow"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('h3',{staticClass:"text-xl font-bold leading-none text-gray-900"},[_vm._v(" Movimientos de Inventario ")]),_c('a',{staticClass:"text-sm font-medium text-purple-500 hover:underline",attrs:{"href":"#"},on:{"click":_vm.handleFetchRecords}},[_c('b-icon',{attrs:{"icon":"refresh"}})],1)]),(_vm.isFetched && !_vm.records.length)?_c('div',[_vm._v("No se registran movimientos")]):_vm._e(),(_vm.isFetched && _vm.records.length)?_c('div',{staticClass:"grid grid-cols-2 gap-4"},[_c('ul',{staticClass:"mt-4 divide-y divide-gray-200 dark:divide-gray-700",attrs:{"role":"list"}},_vm._l((_vm.theoreticalRecords),function(element){return _c('li',{key:element._id,staticClass:"py-3 sm:py-4"},[_c('div',{staticClass:"flex items-center space-x-4"},[_c('div',{staticClass:"flex-1 min-w-0"},[_c('p',{staticClass:"text-xs text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm._f("shortDate")(element.date))+" ")]),_c('p',{staticClass:"text-sm font-bold text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm.parseInventory(element.inventory))+" ")]),_c('p',{staticClass:"text-sm font-medium text-gray-900 truncate"},[(element.metadata)?_c('span',{staticClass:"is-clickable",on:{"click":function($event){!element.third &&
                      _vm.fetchThirdDetail(element.metadata, element._id)}}},[_c('span',{staticClass:"text-purple-500"},[_vm._v(_vm._s(!element.third ? 'Ver detalle' : element.third.name))])]):_vm._e()]),_c('p',{staticClass:"text-sm text-gray-500 truncate"},[_vm._v(" "+_vm._s(_vm.parseConcept(element.concept))+" "+_vm._s(_vm.parseMetadataNumber(element.metadata))+" ")])]),_c('div',{staticClass:"inline-flex items-center text-base font-semibold text-gray-900"},[_c('p',{staticClass:"text-lg font-bold",class:[
                  element.quantity >= 0 ? `text-green-500` : 'text-red-500',
                ]},[_vm._v(" "+_vm._s(element.quantity >= 0 ? `+` : '',)+" "+_vm._s(element.quantity)+" "),_c('span',{staticClass:"font-normal text-black"},[_vm._v("/ "+_vm._s(element.current))])])])])])}),0),_c('ul',{staticClass:"mt-4 divide-y divide-gray-200 dark:divide-gray-700",attrs:{"role":"list"}},_vm._l((_vm.realRecords),function(element){return _c('li',{key:element._id,staticClass:"py-3 sm:py-4"},[_c('div',{staticClass:"flex items-center space-x-4"},[_c('div',{staticClass:"flex-1 min-w-0"},[_c('p',{staticClass:"text-xs text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm._f("shortDate")(element.date))+" ")]),_c('p',{staticClass:"text-sm font-bold text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm.parseInventory(element.inventory))+" ")]),_c('p',{staticClass:"text-sm font-medium text-gray-900 truncate"},[(element.metadata)?_c('span',{staticClass:"is-clickable",on:{"click":function($event){!element.third &&
                      _vm.fetchThirdDetail(element.metadata, element._id)}}},[_c('span',{staticClass:"text-purple-500"},[_vm._v(_vm._s(!element.third ? 'Ver detalle' : element.third.name))])]):_vm._e()]),_c('p',{staticClass:"text-sm text-gray-500 truncate"},[_vm._v(" "+_vm._s(_vm.parseConcept(element.concept))+" "+_vm._s(_vm.parseMetadataNumber(element.metadata))+" ")])]),_c('div',{staticClass:"inline-flex items-center text-base font-semibold text-gray-900"},[_c('p',{staticClass:"text-lg font-bold",class:[
                  element.quantity >= 0 ? `text-green-500` : 'text-red-500',
                ]},[_vm._v(" "+_vm._s(element.quantity >= 0 ? `+` : '',)+" "+_vm._s(element.quantity)+" "),_c('span',{staticClass:"font-normal text-black"},[_vm._v("/ "+_vm._s(element.current))])])])])])}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }