<template>
  <div>
    <Section>
      <Widgets>
        <Widget
          :title="
            dueBalance(statistics) < 0 && fetched ? 'Tenés a favor' : 'A rendir'
          "
          :value="Math.abs(dueBalance(statistics))"
          prefix="$"
        ></Widget>
      </Widgets>
    </Section>
  </div>
</template>

<script>
import Widgets from '../components/Widgets/Widgets.vue';
import Widget from '../components/Widgets/Widget.vue';
import ApiService from '../services/ApiService';
import { round } from '../util/helpers';
import Section from '../components/Section.vue';

const { StatisticsService } = ApiService;

export default {
  name: 'MyDebt',
  components: {
    Widgets,
    Widget,
    Section,
  },
  data() {
    return {
      statistics: {
        monthly: [],
      },
      fetched: false,
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => {};
    this.fetched = false;
    await this.fetchStatistics();
    this.fetched = true;
  },
  methods: {
    async fetchStatistics() {
      const { data: statistics } = await StatisticsService.myFinances();
      this.statistics = statistics;
    },
    dueBalance(partner) {
      return round(partner.dueBalance);
    },
  },
};
</script>
