import { defineStore } from 'pinia';
import { startOfDay } from 'date-fns';
import { isOrder, mapPosition } from '../util/helpers';
import useBuyStore from './buy';
import useOrderStore from './order';

import ApiService from '../services/ApiService';

const { BuyService, OrderService } = ApiService;

const useDispatchStore = defineStore('dispatch', {
  state: () => ({
    dispatchs: [],
    due: [],
    ordering: JSON.parse(localStorage.getItem('ordering')) || [],
    selected: [],
    isLoading: {
      dispatchs: false,
      due: false,
    },
    isFetched: {
      dispatchs: false,
      due: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 999,
    },
    query: null,
  }),
  getters: {
    dispatchDays: (state) => [
      ...new Set(
        state.dispatchs
        // eslint-disable-next-line consistent-return, array-callback-return
          .map(({ delivery, withdrawal }) => {
            if (delivery) return startOfDay(new Date(delivery.date)).toISOString();
            if (withdrawal) return startOfDay(new Date(withdrawal.date)).toISOString();
          }),
      ),
    ].sort(),
  },
  actions: {
    async find(orderParams, buyParams, key = 'dispatchs') {
      const orderStore = useOrderStore();
      const buyStore = useBuyStore();
      this.isLoading[key] = true;
      if (orderParams) {
        await orderStore.find({
          query: this.query,
          page: this.options.page,
          limit: this.options.limit,
          ...orderParams,
        });
      } else {
        orderStore.set([]);
      }
      if (buyParams) {
        await buyStore.find({
          query: this.query,
          page: this.options.page,
          limit: this.options.limit,
          ...buyParams,
        });
      } else {
        buyStore.set([]);
      }
      
      this.isLoading[key] = false;
      this.isFetched[key] = true;

      if (this.ordering.length) {
        const orderMap = new Map(this.ordering.map((id, index) => [id, index]));
        this[key] = [...orderStore.orders, ...buyStore.buys].sort(
          (a, b) => orderMap.get(a.number) - orderMap.get(b.number),
        );
      } else {
        this[key] = [...orderStore.orders, ...buyStore.buys].sort((a, b) => {
          const aDate = (a.delivery && a.delivery.date)
            || (a.withdrawal && a.withdrawal.date);
          const bDate = (b.delivery && b.delivery.date)
            || (b.withdrawal && b.withdrawal.date);
          return new Date(aDate) - new Date(bDate);
        });
      }
    },
    setOrdering(elements) {
      this.ordering = elements.map(({ number }) => number);
      localStorage.setItem('ordering', JSON.stringify(this.ordering));
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(dispatchs) {
      this.dispatchs = dispatchs;
    },
    selectDay(day, { dispatchMethods, isOwnLogistic }) {
      this.dispatchs
        .filter(({ state }) => state !== 'CANCELADO' && state !== 'CANCELADA')
        .filter(
          ({ delivery, withdrawal }) => (delivery
              && delivery.date === day
              && dispatchMethods[delivery._id].isOwnLogistic === isOwnLogistic)
            || (withdrawal
              && withdrawal.date === day
              && dispatchMethods[withdrawal._id].isOwnLogistic === isOwnLogistic),
        )
        .filter((dispatch) => {
          const index = this.selected.findIndex(
            ({ _id }) => _id === dispatch._id,
          );
          return index === -1;
        })
        .forEach((order) => this.select(order));
    },
    deselectDay(day, { dispatchMethods, isOwnLogistic }) {
      this.dispatchs
        .filter(({ state }) => state !== 'CANCELADO' && state !== 'CANCELADA')
        .filter(
          ({ delivery, withdrawal }) => (delivery
              && delivery.date === day
              && dispatchMethods[delivery._id].isOwnLogistic === isOwnLogistic)
            || (withdrawal
              && withdrawal.date === day
              && dispatchMethods[withdrawal._id].isOwnLogistic === isOwnLogistic),
        )
        .forEach((element) => this.deselect(element));
    },
    select(element) {
      this.selected.push(element);
    },
    removeFrom(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this[key].splice(index, 1);
      }
    },
    addTo(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index === -1) {
        this[key].unshift(element);
      }
    },
    deselect(element) {
      const index = this.selected.findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    updatePositions(elements, key, { storeOrdering = false } = {}) {
      if (storeOrdering) this.setOrdering(elements);
      this[key] = elements;
      const sortedId = this[key].map(({ _id }) => _id);
      this.selected = mapPosition(this.selected, sortedId, '_id');
    },
    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    async updateDriver(dispatch, payload) {
      const Service = isOrder(dispatch) ? OrderService : BuyService;
      const { data: updated } = await Service.updateDriver(
        dispatch._id,
        payload,
      );
      this.updateElement(updated, 'dispatchs');
      return updated;
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      if (element) {
        const index = this[key].findIndex(
          (dispatch) => dispatch._id === element._id,
        );
        if (index !== -1) {
          const dispatchsCopy = [...this[key]];
          dispatchsCopy[index] = element;
          this[key] = dispatchsCopy;
        }
      }
    },
  },
});

export default useDispatchStore;
