import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { CustomerService } = ApiService;

const useCustomerStore = defineStore('customer', {
  state: () => ({
    customers: [],

    selected: [],
    isLoading: {
      customers: false,
    },
    isFetched: {
      customers: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false } = {}) {
      this.isLoading.customers = true;
      const { data: pagination } = await CustomerService.find({
        query: this.query,
        page: this.options.page,
        limit: this.options.limit,
        ...params,
      });
      this.isLoading.customers = false;
      this.isFetched.customers = true;
      this.customers = merge
        ? [...this.customers, ...pagination.docs]
        : pagination.docs;
      this.pagination = pagination;
    },

    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(customers) {
      this.customers = customers;
    },
    select(customer) {
      this.selected.push(customer);
    },
    deselect(customer) {
      const index = this.selected.findIndex(({ _id }) => _id === customer._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(customer) {
      return this.customers.findIndex(({ _id }) => _id === customer._id);
    },
    updatePositions(customers, key) {
      this[key] = customers;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const copy = [...this[key]];
        copy[index] = element;
        this[key] = copy;
      }
    },
    async disable(id) {
      const { data: updated } = await CustomerService.disable(id);
      this.updateElement(updated, 'customers');
      return updated;
    },
    async enable(id) {
      const { data: updated } = await CustomerService.enable(id);
      this.updateElement(updated, 'customers');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await CustomerService.update(id, payload);
      this.updateElement(updated, 'customers');
      return updated;
    },
  },
});

export default useCustomerStore;
