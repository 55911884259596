<template>
  <div class="p-5 lg:px-8" @click.stop="handleCta">
    <div class="text-base text-gray-400">{{ title }}</div>
    <div class="flex items-center pt-1">
      <div v-if="loaded" class="text-2xl font-bold text-gray-900">
        {{ prefix }}{{ safeValue }}{{ suffix }}
        <b-tooltip v-if="help" :label="help" position="is-bottom">
          <b-icon icon="help-circle-outline" custom-size="small" />
        </b-tooltip>
      </div>
      <div v-if="!loaded" class="text-2xl font-bold text-gray-900">
        <div class="w-20 h-3 p-3 bg-gray-300 rounded-lg animate-pulse"></div>
      </div>
      <slot></slot>
      <span
        @click="onClickFunction"
        v-if="modal"
        class="flex items-center px-2 py-0.5 mx-2 text-sm text-purple-600 bg-purple-100 rounded-full"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75
            0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    title: String,
    prefix: String,
    value: { type: Number, default: null },
    suffix: String,
    cta: String,
    onClickFunction: { type: Function, default: () => {} },
    modal: { type: Boolean, default: false },
    help: {
      type: String,
      default: null,
    },
  },
  components: {},
  watch: {},
  data() {
    return {};
  },
  computed: {
    loaded() {
      return !(!this.value && this.value !== 0);
    },
    safeValue() {
      return this.loaded && this.value.toLocaleString('es-ES');
    },
  },
  mounted() {},
  methods: {
    handleCta() {
      if (this.cta) {
        this.$router.push(this.cta);
      }
    },
  },
};
</script>
