<template>
  <div
    class="relative p-4 my-4 text-sm list-none bg-purple-100 border-2 border-purple-400 rounded-lg"
  >
    <p class="text-lg">Datos del borrador</p>
    <li>
      <span class="font-bold">Nombre: </span>{{ draft.name }} ({{
        draft.email
      }})
    </li>
    <li><span class="font-bold">Telefono: </span>{{ draft.phone }}</li>
    <li>
      <span class="font-bold">Email de contacto: </span>{{ draft.contactEmail }} <button @click="() => handleCopyEmail()"><b-icon icon="clipboard"></b-icon></button>
    </li>
    <li>
      <span class="font-bold">Dirección de entrega: </span>{{ shortAddress(draft.address) }},
    </li>
    <li><span class="font-bold">Piso/Depto: </span>{{ draft.unit }}</li>
    <li v-if="!onlyCustomer">
      <span class="font-bold">Método de pago: </span>{{ draft.payMethod }}
    </li>
    <li v-if="!onlyCustomer">
      <span class="font-bold">Total: </span>${{ draft.total }}
    </li>
    <li v-if="draft.notes && draft.notes.length">
      <span class="font-bold"> Notas: </span>{{ draft.notes }}
    </li>
  </div>
</template>

<script>
import { shortAddress } from '../../util/helpers';

export default {
  name: 'Delivery',
  props: {
    draft: Object,
    onlyCustomer: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    shortAddress,
    async handleCopyEmail() {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'correo copiado al portapapeles',
        position: 'is-bottom',
        type: 'is-primary',
        queue: false,
      });
      await navigator.clipboard.writeText(this.draft.contactEmail);
    },
  },
};
</script>
