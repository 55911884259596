<template>
  <div id="app" style="-webkit-tap-highlight-color: transparent">
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view />
    <footer-bar />
  </div>
</template>

<script>
import AsideMenu from './components/AsideMenu.vue';
import FooterBar from './components/FooterBar.vue';
import NavBar from './components/NavBar.vue';
import Menu from './ui/Menu';
import { ROLES } from './util/constants';
import { toTitleCase } from './util/helpers';

const { admin, customer: customerMenu, provider } = Menu;

const { partner, driver } = Menu;
const { ADMIN, PARTNER } = ROLES;

export default {
  name: 'home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar,
  },
  data() {
    return {};
  },
  computed: {
    menu() {
      const user = this.$store.getters['Auth/currentUser'];
      const customer = this.$store.getters['Auth/currentCustomer'];

      if (user && user.isProvider) {
        partner[1] = partner[1].map(({ isProvider, ...rest }) => (isProvider
          ? { ...rest, label: `Pedidos de ${toTitleCase(user.name)}` }
          : rest));
      } else {
        partner[1] = partner[1].filter(({ isProvider }) => !isProvider);
      }

      if (user) {
        return {
          [`${ADMIN}`]: [...partner, ...admin],
          [`${PARTNER}`]: [...(user.isDriver ? driver : []), ...partner, ...(user.isProvider ? provider : [])],
        }[user.role];
      }
      if (customer) {
        return [...customerMenu];
      }
      return [];
    },
    isAsideVisible() {
      return this.$store.getters['Ui/isAsideVisible'];
    },
  },
  methods: {},
  mounted() {
    document.addEventListener('swiped-right', () => {
      if (this.isAsideVisible) {
        this.$store.dispatch('Ui/toggleAsideMobile', true);
      }
    });
    document.addEventListener('swiped-left', () => {
      if (this.isAsideVisible) {
        this.$store.dispatch('Ui/toggleAsideMobile', false);
      }
    });
  },
};
</script>
