<template>
  <div class="ml-2">
    <b-button
      :loading="isLoading"
      type="is-primary is-light"
      @click="downloadFile"
    >
      <b-icon icon="plus-minus" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import ActivateSDK from 'activate-schemas';

import { round, translate } from '../../util/helpers';
import ApiService from '../../services/ApiService';

const { RecordService } = ApiService;

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'ExportAdjustments',
  props: {},
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    item({ product, quantity, isWholesaler = false }) {
      return new Item({ product, quantity, isWholesaler }).billOrder();
    },
    price(product, quantity, isWholesaler) {
      return this.item({ product, quantity, isWholesaler }).price;
    },
    async downloadFile() {
      this.isLoading = true;
      const { data: { docs: records } } = await RecordService.find({
        concept: 'adjustment',
        inventory: 'REAL',
        limit: 1000,
      });
      this.isLoading = false;
      const data = [
        {
          sheet: 'Ajustes',
          columns: [
            {
              label: 'Fecha',
              value: ({ date }) => new Date(date),
              format: 'DD/MM/YYYY',
            },
            {
              label: 'Periodo',
              value: ({ date }) => new Date(date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: ({ date }) => new Date(date).getFullYear(),
            },
            { label: 'Producto', value: 'product.name' },
            {
              label: 'Unidad',
              value: ({ product }) => translate('unit', product.unit),
            },
            {
              label: 'Cantidad',
              value: ({ quantity }) => quantity,
            },
            {
              label: 'Costo',
              // eslint-disable-next-line max-len
              value: ({ product, quantity }) => {
                const cost = {
                  WEIGHT: round(product.taxBase * (quantity / 1000)),
                  UNITY: round(product.taxBase * quantity),
                }[product.unit];

                return cost;
              },
            },
            { label: 'Proveedor', value: ({ product }) => product.provider.name },
          ],
          content: records.sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
          ),
        },
      ];
      const settings = {
        fileName: `Ajustes - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
