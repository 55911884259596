<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <ExportCustomers :customers="customers" />
        <b-button data-cy="new-customer" class="ml-4" @click="handleNewCustomer"
          >Nuevo Cliente</b-button
        >
      </div>
    </hero-bar>
    <Section>
      <SearchBar :store="customerStore"></SearchBar>
      <Layout>
        <List :loading="isLoading.customers">
          <CustomerCard
            :segments="segments"
            v-for="customer in customers"
            :key="customer._id"
            :customer="customer"
          ></CustomerCard>
        </List>
      </Layout>
    </Section>
    <NewCustomer
      :active="newCustomerModalIsActive"
      @dismiss="newCustomerModalIsActive = false"
      @created="handleCustomerCreated"
    ></NewCustomer>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../components/HeroBar.vue';
import NewCustomer from '../components/Customer/NewCustomer.vue';
import ExportCustomers from '../components/Customer/ExportCustomers.vue';
import CustomerCard from '../components/Customer/mobile/CustomerCard.vue';
import SearchBar from '../components/SearchBar.vue';
import useCustomerStore from '../store/customer';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import Section from '../components/Section.vue';
import ApiService from '../services/ApiService';

const { SegmentService } = ApiService;

export default {
  name: 'Customers',
  components: {
    Section,
    HeroBar,
    NewCustomer,
    ExportCustomers,
    CustomerCard,
    SearchBar,
    Layout,
    List,
  },
  data() {
    return {
      customerStore: useCustomerStore(),
      query: {},
      newCustomerModalIsActive: false,
      hasActions: true,
      segments: [],
    };
  },
  computed: {
    ...mapState(useCustomerStore, ['options']),
    ...mapState(useCustomerStore, ['pagination']),
    ...mapState(useCustomerStore, ['isLoading']),
    ...mapState(useCustomerStore, ['isFetched']),
    ...mapState(useCustomerStore, ['unreceipted']),
    ...mapState(useCustomerStore, ['customers']),
    ...mapState(useCustomerStore, ['selected']),
  },
  async mounted() {
    this.getNext();
    await this.customerStore.find();
    this.fetchSegments();
  },
  methods: {
    async fetchSegments() {
      const { data: segments } = await SegmentService.find();
      this.segments = segments;
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.customers
          && this.pagination.hasNextPage
        ) {
          this.customerStore.changePage();
        }
      };
    },
    handleNewCustomer() {
      this.newCustomerModalIsActive = true;
    },
    handleCustomerCreated() {
      this.newCustomerModalIsActive = false;
      // TODO: Avoid this call refactoring service response
      this.fetchCustomers();
    },
  },
};
</script>
