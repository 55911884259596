import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import modules from './modules';

Vue.use(Vuex);
const debug = process.env.VUE_APP_ENV !== 'production';
export default new Vuex.Store({
  modules,
  strict: true,
  plugins: debug ? [createLogger()] : [],
});
