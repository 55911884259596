const admin = [
  'Administrador',
  [
    {
      to: '/',
      icon: 'monitor-dashboard',
      label: 'Tablero',
    },
    {
      to: '/products',
      icon: 'seed-outline',
      label: 'Productos',
    },
    {
      to: '/customers',
      icon: 'account-box-outline',
      label: 'Clientes',
    },
    {
      to: '/accounts',
      icon: 'account-multiple',
      label: 'Cuentas',
    },
    {
      to: '/buys',
      icon: 'cart-arrow-down',
      label: 'Compras',
    },
    {
      to: '/prepare',
      icon: 'basket-unfill',
      label: 'Producción',
    },
    {
      to: '/logistic',
      icon: 'truck-outline',
      label: 'Logística',
    },
    // {
    //   to: '/roadmaps',
    //   icon: 'map',
    //   label: 'Hojas de ruta',
    // },
    {
      to: '/orders',
      icon: 'clipboard-text-multiple-outline',
      label: 'Historial Pedidos',
    },
    {
      to: '/drafts',
      icon: 'coffee',
      label: 'Borradores',
    },
    {
      to: '/finances',
      icon: 'cash-register',
      label: 'Caja',
    },
    {
      to: '/statistics',
      icon: 'chart-bubble',
      label: 'Estadísticas',
    },
    // {
    //   to: '/providers',
    //   icon: 'pot-mix',
    //   label: 'Proveedores',
    // },
    // {
    //   to: '/categories',
    //   icon: 'format-list-text',
    //   label: 'Categorías',
    // },
    {
      to: '/partners',
      icon: 'handshake',
      label: 'Agentes de venta',
    },
    {
      to: '/tracker',
      icon: 'chart-bubble',
      label: 'Variación de Precios',
    },
  ],
];

const partner = [
  'Yo agente de venta',
  [
    {
      to: '/my-shop',
      label: 'Mi Tienda',
      icon: 'store-outline',
    },
    {
      to: '/my-statistics',
      label: 'Mis números',
      icon: 'chart-timeline-variant',
    },
    // {
    //   to: '/my-debt',
    //   label: 'A rendir',
    //   icon: 'clipboard-flow-outline',
    // },
    {
      to: '/cart',
      label: 'Cargar Nuevo Pedido',
      icon: 'cart',
    },
    {
      to: '/my-orders',
      label: 'Historial de pedidos',
      icon: 'clipboard-text-multiple-outline',
      updateMark: false,
    },
    // {
    //   to: '/my-drafts',
    //   label: 'Mis Pedidos',
    //   icon: 'package-variant-closed',
    //   isProvider: true,
    //   updateMark: false,
    // },
    // {
    //   to: '/products',
    //   label: 'Productos',
    //   icon: 'seed-outline',
    // },
    {
      to: '/my-community',
      label: 'Mi Comunidad',
      icon: 'account-multiple',
      updateMark: false,
    },
    // {
    //   to: '/my-receivables',
    //   label: 'Mis cobranzas',
    //   icon: 'account-cash',
    // },
  ],
];

const provider = [
  'Yo Productor',
  [
    // {
    //   to: '/my-shop',
    //   label: 'Mi Tienda',
    //   icon: 'store-outline',
    // },
    {
      to: '/provider/dashboard',
      label: 'Rendiciones',
      icon: 'monitor-dashboard',
    },
    {
      to: '/provider/orders',
      label: 'Pedidos de Activate',
      icon: 'clipboard-text-multiple-outline',
    },
    {
      to: '/provider/account',
      label: 'Mi Cuenta Corriente',
      icon: 'decagram-outline',
    },
    {
      to: '/provider/inventory',
      label: 'Mis Stock en Activate',
      icon: 'seed-outline',
    },
  ],
];

const driver = [
  'Yo Repartidor',
  [

    {
      to: '/driver/logistic',
      label: 'Logistica',
      icon: 'truck-outline',
    },
  ],
];


const customer = [
  'Cliente',
  [
    {
      to: '/catalog',
      label: 'Catalogo',
      icon: 'seed-outline',
    },
    {
      to: '/draft',
      label: 'Nuevo Pedido',
      icon: 'cart',
    },
    {
      to: '/customer/orders',
      label: 'Mis pedidos',
      icon: 'package-variant-closed',
    },
  ],
];

export default {
  admin,
  driver,
  partner,
  customer,
  provider,
};
