<template>
  <b-modal
    :active="active"
    :has-modal-card="true"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div class="border-2 border-indigo-400 rounded-lg modal-card">
      <section class="modal-card-body">
        <b-field position="is-centered">
          <p class="control">
            <b-button label="Nombre" type="is-light" />
          </p>
          <b-input placeholder="Nombre" v-model="updated.name"></b-input>
        </b-field>

        <div class="m-2 bg-white field">
          <b-checkbox v-model="updated.isOwnLogistic" type="is-success"
            >Logística propia
          </b-checkbox>
        </div>

        <b-field>
          <p class="control">
            <b-button label="AP PRO" type="is-primary is-light" />
          </p>
          <b-input
            type="number"
            placeholder="Aporte proveedor"
            icon="handshake"
            v-model="updated.fee"
          ></b-input>
        </b-field>
        <div class="m-2 bg-white field">
          <b-field>
            <p class="control">
              <b-button label="AP INT" type=" is-info is-light" />
            </p>
            <b-input
              type="number"
              placeholder="Aporte Interno"
              icon="account-box-outline"
              v-model="updated.internPrice"
            ></b-input>
          </b-field>
        </div>

        <div class="m-2 bg-white field">
          <b-field>
            <b-checkbox
              v-model="updated.shortage"
              type="is-danger"
              size="is-small"
              >Faltantes</b-checkbox
            >
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="$emit('dismiss')">Cancelar</button>
        <Button
          data-cy="update-withdrawal"
          :once="true"
          type="is-success"
          :onClickFn="handleUpdateDelivery"
          >Aceptar</Button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '../../services/ApiService';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';

const { WithdrawalService } = ApiService;

export default {
  name: 'UpdateDelivery',
  props: {
    active: Boolean,
    withdrawal: Object,
  },
  components: {},
  data() {
    return {
      updated: this.withdrawal || {},
      rolesToChoose: ['ADMIN', 'AV'],
    };
  },
  watch: {
    'withdrawal._id': function () {
      if (this.withdrawal) {
        this.updated = this.withdrawal;
      }
    },
  },
  computed: {
    availableRoles() {
      return this.rolesToChoose.filter(
        (x) => this.updated.roles && !this.updated.roles.includes(x),
      );
    },
  },
  methods: {
    async handleUpdateDelivery() {
      const { _id: id } = this.withdrawal;
      const payload = omit(immutableProperties.common, this.updated);
      await WithdrawalService.update(id, payload);
      this.$emit('update', id);
      this.$emit('dismiss');
    },
  },
};
</script>
