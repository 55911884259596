<template>
  <div>
    <b-button :loading="isLoading" type="is-primary is-light" @click.stop="downloadFile">
      <b-icon icon="file-excel" type="is-primary" /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import { ORDER_STATES, BUY_STATES } from '../../util/constants';
import {
  getDate, getLogistic, getRangeTime, dispatchsByDriver,
} from '../../util/helpers';

export default {
  name: 'ExportDeliveryRoute',
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      content: this.elements || [],
      isLoading: false,

    };
  },
  computed: {
    dispatchsByDriver() {
      return dispatchsByDriver(this.content);
    },
    driverColumns() {
      return [
        { label: 'Orden', value: (element) => this.getRangeTime(element) },
        {
          label: 'usx',
          // eslint-disable-next-line max-len
          value: (element) => (element.customer
            ? element.customer.name
            : element.provider.name),
        },
        { label: 'Total', value: 'total', format: '$0.00' },
        {
          label: 'Logística',
          value: ({ logistic, delivery, withdrawal }) => {
            if (delivery) {
              return (
                logistic + delivery.internPrice
              );
            }
            if (withdrawal) return withdrawal.internPrice + withdrawal.fee;
            return 0;
          },
        },
        {
          label: 'Pago',
          value: 'paymentMethod',
          format: '$0.00',
        },
        {
          label: 'Dirección',
          value: (element) => (this.isOrder(element) ? element.delivery.address.street : element.provider.address.street),
        },
        {
          label: 'Ciudad',
          value: (element) => (this.isOrder(element) ? element.delivery.address.city : element.provider.address.city),
        },
        { label: 'AV', value: (element) => element.partner.username },
      ];
    },

    deliveryRouteColumns() {
      return [
        {
          label: 'Día',
          value: (row) => +this.getDate(row).toLocaleDateString('es-ES', {
            day: '2-digit',
          }),
        },
        {
          label: 'Mes',
          value: (row) => this.getDate(row)
            .toLocaleDateString('es-ES', {
              month: 'long',
            })
            .toUpperCase(),
        },
        {
          label: 'Año',
          value: (row) => this.getDate(row),
          format: 'YYYY',
        },
        {
          label: 'Orden',
          value: 'position',
        },
        // {
        //   label: 'Tipo',
        //   // eslint-disable-next-line no-nested-ternary
        //   value: (element) => (element.customer
        //     ? element.cart.items.some(({ isWholesaler }) => isWholesaler)
        //       ? 'Mayorista'
        //       : 'Minorista'
        //     : 'Compra'),
        // },
        {
          label: '#',
          value: 'number',
        },
        {
          label: 'usx',
          // eslint-disable-next-line max-len
          value: (element) => (element.customer
            ? element.customer.name
            : element.provider.name),
        },
        { label: 'Total', value: 'total' },
        {
          label: 'Logística',
          value: ({ logistic, delivery, withdrawal }) => {
            if (delivery) {
              return (
                logistic + delivery.internPrice
              );
            }
            if (withdrawal) return withdrawal.internPrice + withdrawal.fee;
            return 0;
          },
        },
        {
          label: 'Pago',
          value: 'paymentMethod',
          format: '$0.00',
        },
        // {
        //   label: 'CC',
        //   value: (element) => (element.customer ? element.customer.balance : '-'),
        //   format: '$0.00',
        // },
        {
          label: '__________',
          value: '',
        },
        {
          label: 'Dirección',
          value: (element) => (this.isOrder(element) ? element.delivery.address.street : element.provider.address.street),
        },
        {
          label: 'P',
          value: (element) => (this.isOrder(element) ? element.delivery.address.unit : '-'),
        },
        {
          label: 'Ciudad',
          value: (element) => (this.isOrder(element) ? element.delivery.address.city : element.provider.address.city),
        },
        {
          label: 'Telefono',
          value: (element) => (element.delivery ? element.delivery.phone : '-'),
        },

        { label: 'AV', value: (element) => element.partner.username },
        { label: 'Observaciones', value: (element) => element.notes },
        { label: 'Rep', value: (element) => (element.driver ? element.driver.username : '') },
        { label: 'Rango horario', value: (element) => this.getRangeTime(element) },
      ];
    },
  },
  methods: {
    getDate,
    getLogistic,
    getRangeTime,
    isOrder(element) {
      return !!element.customer;
    },
    isBuy(element) {
      return !!element.provider;
    },
    // async addAccounts() {
    //   // eslint-disable-next-line no-restricted-syntax
    //   for (const [idx, element] of this.content.entries()) {
    //     if (!element.customer) return;
    //     const {
    //       data: { 0: account },
    //       // eslint-disable-next-line no-await-in-loop
    //     } = await AccountService.find({
    //       // eslint-disable-next-line no-underscore-dangle
    //       partner: element.partner._id,
    //       // eslint-disable-next-line no-underscore-dangle
    //       customer: element.customer._id,
    //     });
    //     this.content[idx].customer.balance = account.balance;
    //   }
    // },
    async downloadFile() {
      this.isLoading = true;
      this.content = [...this.elements].map((element, index) => ({
        ...element,
        position: index + 1,
      }));

      // await this.addAccounts();
      this.isLoading = false;
      const data = [
        {
          sheet: 'VICTOR',
          columns: this.driverColumns,
          content: this.content.filter(({ state }) => state !== ORDER_STATES.CANCELLED && state !== BUY_STATES.CANCELLED).sort((a, b) => this.getDate(a) - this.getDate(b)),
        },
        {
          sheet: 'Hoja de ruta',
          columns: this.deliveryRouteColumns,
          content: this.content.filter(({ state }) => state !== ORDER_STATES.CANCELLED && state !== BUY_STATES.CANCELLED).sort((a, b) => this.getDate(a) - this.getDate(b)),
        },

        ...(Object.keys(this.dispatchsByDriver).map((driver) => ({

          sheet: driver, // Sheet names cannot exceed 31 chars
          columns: this.driverColumns,
          content: this.dispatchsByDriver[driver].sort((a, b) => this.getDate(a) - this.getDate(b)),
        }))),
      ];
      const settings = {
        fileName: `Hoja_de_ruta - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
