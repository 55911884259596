const defaultMargins = (unit) => ({
  WEIGHT: [
    {
      quantity: 0,
      value: 1.15,
    },
    {
      quantity: 100,
      value: 1.8,
    },
    {
      quantity: 500,
      value: 1.6,
    },
    {
      quantity: 1000,
      value: 1.5,
    },
  ],
  UNITY: [
    {
      quantity: 0,
      value: 1.15,
    },
    {
      quantity: 1,
      value: 1.3,
    },
  ],
}[unit]);

const defaultMargin = (unit) => ({
  WEIGHT: 500,
  UNITY: 1,
}[unit]);

const defaultData = {
  name: '',
  defaultMargin: 500,
  margins: defaultMargins('WEIGHT'),
  unit: 'WEIGHT',
  taxBase: 0,
  contributions: 12.2,
  wholesalerContributions: 6.1,
  taxes: [],
  inventoryManagement: 'manual',
  inventory: {
    real: 0,
    theoretical: 0,
    critical: 0,
    pending: 0,
  },
  mixed: false,
  components: [],
  splitPackage: false,
};

module.exports = {
  defaultData,
  defaultMargins,
  defaultMargin,
};
