<template>
  <avatar
    :username="user.username"
    :background-color="color"
    :size="size"
  ></avatar>
</template>

<script>
import Avatar from 'vue-avatar';

export default {
  name: 'UserAvatar',
  components: {
    Avatar,
  },
  props: {
    user: Object,
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    color() {
      return this.user.avatarColor;
    },
  },
};
</script>
