<template>
  <div
    class="relative p-4 space-y-3 transition duration-500 bg-white shadow sm:rounded-lg "
    :class="componentClass"
  >
    <b-field v-if="isCheckable" class="absolute -right-6 -top-2">
      <b-checkbox
        v-model="isSelected"
        :native-value="element"
        size="is-large"
        @input="handleSelect(isSelected, element)"
      ></b-checkbox>
    </b-field>
    <div
      v-if="isDraggable"
      class="absolute w-10 h-10 rounded-lg handle right-3 top-16 bg-purple-50"
    >
      <div
        class="flex items-center justify-center w-full h-full cursor-pointer"
      >
        <b-icon icon="drag"></b-icon>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  components: {},
  props: {
    element: {
      type: Object,
      required: true,
    },
    operationPermission: {
      type: Boolean,
      default: false,
    },
    componentClass: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    handleSelect(isSelected, element) {
      this.$emit('select', isSelected, element);
    },
  },
  watch: {
    selected() {
      const founded = this.selected.find(({ _id }) => _id === this.element._id);
      this.isSelected = !!founded;
    },
  },
};
</script>
