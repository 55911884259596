<template>
  <div class="grid grid-cols-1 my-4 md:grid-cols-2">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  props: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
