<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportAccounts',
  props: {
    accounts: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    data() {
      return [];
    },
  },
  methods: {
    async downloadFile() {
      const data = [
        {
          sheet: 'Cuentas',
          columns: [
            { label: 'ID', value: (account) => account.customer.number },
            { label: 'Nombre', value: (account) => account.customer.name },
            {
              label: 'Dirección',
              value: (account) => account.customer.address?.street
                + account.customer.address?.unit,
            },
            {
              label: 'Localidad',
              value: (account) => account.customer.address?.city,
            },
            { label: 'Telefono', value: (account) => account.customer.phone },
            {
              label: 'Balance',
              value: (account) => account.balance,
              format: '$0.00',
            },
            {
              label: 'Mayorista?',
              value: (account) => (account.customer.isWholesaler ? 'M' : ''),
            },
            {
              label: 'AV',
              value: (account) => account.partner.username,
            },
          ],
          content: this.accounts,
        },
      ];
      const settings = {
        fileName: `Cuentas - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
