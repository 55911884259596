var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-4"},[_c('b-table',{attrs:{"data":_vm.flows,"bordered":true,"striped":true,"paginated":true,"perPage":20,"narrowed":true,"mobile-cards":true,"loading":_vm.loading},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_c('p',{staticClass:"subtitle is-3"},[_vm._v("No hay flujos de caja")])])]},proxy:true},(_vm.flows.length)?{key:"footer",fn:function(){return [_c('th',{staticClass:"is-hidden-mobile"},[_c('div',{staticClass:"th-wrap is-centered"},[_vm._v("Totales")])]),_c('th',{staticClass:"is-hidden-mobile"}),_c('th',{staticClass:"is-hidden-mobile"}),_c('th',{staticClass:"is-hidden-mobile"}),(_vm.advanced)?_c('th',{staticClass:"is-hidden-mobile"}):_vm._e(),_c('th',{staticClass:"is-hidden-mobile"},[_c('div',{staticClass:"th-wrap is-centered"},[_vm._v("$"+_vm._s(_vm.totalAmount))])]),_c('th',{staticClass:"is-hidden-mobile"}),_c('th',{staticClass:"is-hidden-mobile"})]},proxy:true}:null],null,true)},[_c('b-table-column',{attrs:{"field":"date","label":"Fecha","sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tag',{attrs:{"type":"is-primary is-light"}},[_vm._v(" "+_vm._s(_vm._f("shortDatetime")(props.row.date))+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"concept","label":"Concepto","sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tag',{attrs:{"type":`is-${
            props.row.concept === 'INGRESS' ? 'success' : 'danger'
          } is-light`}},[_vm._v(_vm._s(_vm.parseConcept(props.row.concept)))])]}}])}),_c('b-table-column',{attrs:{"width":"200px","label":"Tercero","visible":_vm.advanced,"sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.getMetadata('name', props.row))+" ")]}}])}),_c('b-table-column',{attrs:{"width":"10px","label":"X","visible":_vm.advanced,"sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.isBudget ? 'P' : 'F')+" ")]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tag',{attrs:{"type":_vm.getMetadata('type', props.row)}},[_c('b-icon',{attrs:{"icon":_vm.getMetadata('icon', props.row)}})],1)]}}])}),_c('b-table-column',{scopedSlots:_vm._u([{key:"header",fn:function({}){return [_c('b-icon',{staticClass:"mt-2 ml-4",attrs:{"icon":"tag"}})]}},{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.tag)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"paymentMethod","label":"Metodo de Pago","sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.paymentMethod)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"amount","label":"Monto","centered":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tag',{attrs:{"type":`is-${
            props.row.concept === 'INGRESS' && props.row.amount > 0
              ? 'success'
              : 'danger'
          } is-light`}},[_vm._v("$"+_vm._s(props.row.amount))])]}}])}),_c('b-table-column',{attrs:{"field":"description","label":"Descripción","sortable":true,"centered":true,"searchable":true},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.description)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(_vm.isAbleToRemove(props.row.date))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.handleRemoveCashFlow(props.row)}}},[_c('b-icon',{attrs:{"icon":"trash-can-outline","custom-size":"small"}})],1):_vm._e()]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }