<template>
  <div class="pb-2">
    <b-field
      :label="withLabel ? 'Dirección' : ''"
      expanded
      :message="invalid ? 'La dirección es inválida' : ''"
      :type="invalid ? 'is-danger' : selectedValidAddress ? 'is-success' : ''"
    >

    <vue-google-autocomplete :country="['ar']" id='map' v-model="componentAddress.label" class='w-full h-10 px-2 border rounded-sm form-control' placeholder='Buscar dirección' v-on:placechanged='getAddressData'>
    </vue-google-autocomplete>
    </b-field>
    <b-field label="Depto/Piso">
      <b-input
        data-cy="address-unit"
        @input="handleUnit"
        v-model="componentAddress.unit"
      ></b-input>
    </b-field>

    <div v-if="componentAddress.label && componentAddress.place_id" class="flex items-center gap-2 p-2 text-green-600 bg-green-200 rounded-lg">
       <b-icon icon="check"></b-icon> Dirección verificada  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="flex-none mr-2"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.6797 8.18168C15.6797 7.61448 15.6293 7.06888 15.5341 6.54568H7.99969V9.64008H12.3053C12.1205 10.6401 11.5565 11.4873 10.7093 12.0545V14.0617H13.2949C14.8069 12.6689 15.6797 10.6177 15.6797 8.18168Z" fill="#4285F4"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C10.16 16 11.9712 15.2832 13.2944 14.0616L10.7096 12.0544C9.9928 12.5344 9.0768 12.8176 8 12.8176C5.9168 12.8176 4.1528 11.4112 3.524 9.51999H0.850403V11.5928C2.1672 14.2072 4.8728 16 8 16Z" fill="#34A853"></path><mask id="mask0_3_1414" maskUnits="userSpaceOnUse" x="0" y="4" width="4" height="8"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.4075H3.52384V11.5931H0V4.4075Z" fill="white"></path></mask><g mask="url(#mask0_3_1414)"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.52379 9.5203C3.36379 9.0395 3.27259 8.5275 3.27259 8.0003C3.27259 7.4723 3.36379 6.9603 3.52379 6.4803V4.4075H0.850986C0.308586 5.4867 -0.000213623 6.7091 -0.000213623 8.0003C-0.000213623 9.2907 0.308586 10.5123 0.850986 11.5931L3.52379 9.5203Z" fill="#FBBC05"></path></g><path fill-rule="evenodd" clip-rule="evenodd" d="M8 3.18169C9.1744 3.18169 10.2288 3.58569 11.0576 4.37849L13.352 2.08329C11.9672 0.792892 10.156 9.15527e-05 8 9.15527e-05C4.8728 9.15527e-05 2.1672 1.79289 0.850403 4.40729L3.524 6.48009C4.1528 4.58889 5.9168 3.18169 8 3.18169Z" fill="#EA4335"></path></svg>
    </div>

    <div v-if="componentAddress.label && !componentAddress.place_id" class="flex items-center gap-2 p-2 text-red-600 bg-red-200 rounded-lg">
       <b-icon icon="check"></b-icon> Dirección no verificada
    </div>

    <!-- <pre>{{  address  }}</pre> -->
    <!-- <pre>{{ componentAddress }}</pre> -->
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { mapGoogleAddress, parseGoogleAddressToBvitxAddress } from '../util/address';


export default {
  name: 'AddressInput',
  props: {
    address: Object,
    withLabel: { type: Boolean, default: true },
  },
  components: {
    VueGoogleAutocomplete,
  },
  data() {
    return {
      data: [],
      selected: null,
      isFetching: false,
      selectedAddress: this.address ? this.address.label : '',
      invalid: false,
      googleAddress: null,
      selectedValidAddress: false,
      componentAddress: this.assignDefaultAddress(),
    };
  },
  watch: {
    address: {
      handler() {
        this.assignDefaultAddress();
      },
      deep: true,
    },
  },
  methods: {
    assignDefaultAddress() {
      this.componentAddress = this.address || {};
      this.selectedValidAddress = true;
      this.selectedAddress = this.componentAddress.label;
      return this.componentAddress;
    },
    handleUnit(unit) {
      if (unit.length) {
        this.$emit('input', { ...this.componentAddress, unit });
      }
    },
    getAddressData(data, place) {
      console.log(place.address_components);
      this.componentAddress = parseGoogleAddressToBvitxAddress(mapGoogleAddress(place));

      console.log(this.componentAddress);

      this.$emit('input', { ...this.componentAddress, unit: '' });
    },
  },
};
</script>
