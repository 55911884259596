<template>
  <div class="card" v-if="visible">
    <div class="card-content">
      <div class="mt-2 is-clearfix">
        <b-field label="Nombre">
          <b-input
            data-cy="category-name"
            ref="nameInput"
            v-model="updated.name"
          ></b-input>
        </b-field>
        <b-field label="Icono">
          <b-input
            data-cy="category-icon"
            ref="iconInput"
            v-model="updated.icon"
          ></b-input>
        </b-field>
        <b-field label="Slug">
          <b-input
            data-cy="category-slug"
            ref="slugInput"
            v-model="updated.slug"
          ></b-input>
        </b-field>
        <b-checkbox
          v-model="updated.splitPackage"
          class="is-pulled-right"
          :rounded="true"
          :outlined="true"
          type="is-success"
          :left-label="true"
        ></b-checkbox>
        <b-field label="Paquete Separado"> </b-field>
        <b-field label="Sección" v-if="sections.length">
          <b-select
            data-cy="category-section"
            v-model="updated.section"
            placeholder="Seleccionar una sección:"
            expanded
          >
            <option
              v-for="option in sections.map(({ attributes: { name } }) => name)"
              :value="option"
              :key="option"
            >
              {{ option | capitalize }}
            </option>
          </b-select>
        </b-field>

        <div class="mt-1">
          <Button
            v-if="updated.status"
            :disabled="isSendingRequest"
            type="is-danger"
            :onClickFn="handleDisable"
            >Deshabilitar</Button
          >

          <Button
            v-if="!updated.status"
            type="is-success"
            :disabled="isSendingRequest"
            :onClickFn="handleEnable"
            >Habilitar</Button
          >
          <Button pulled="right" type="is-success" :onClickFn="handleUpdate"
            >Guardar</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';

const { CategoryService } = ApiService;

export default {
  name: 'UpdateForm',
  props: {
    category: Object,
    sections: Array,
    visible: Boolean,
  },
  components: {},
  data() {
    return {
      updated: { ...this.category },
      isSendingRequest: false,
    };
  },
  watch: {
    category(category) {
      this.updated = { ...category };
    },
  },
  computed: {},
  methods: {
    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await CategoryService.disable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await CategoryService.enable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      const { _id: id } = this.updated;
      const payload = omit(immutableProperties.common, this.updated);
      const { error } = await CategoryService.update(id, payload);
      if (error) {
        console.error(error.data.code);
        return;
      }

      this.$emit('onUpdate');
    },
  },
};
</script>
