import { APIClient } from 'activate-schemas';

if (process.env.VUE_APP_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log(process.env);
}
const { VUE_APP_API_URL: host } = process.env;

if (!host) throw Error('VUE_APP_API_URL NOT CONFIGURED');

const ApiService = new APIClient({
  baseURL: host,
  timeout: 30000,
  jwt: JSON.parse(localStorage.getItem('token')),
});

console.log(ApiService);

export default ApiService;
