<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <div
          class="flex flex-row p-2 text-indigo-400 bg-white border-b-2 border-indigo-400"
          @click.stop="$emit('dismiss')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
          <span>Volver</span>
        </div>
        <section class="modal-card-body">
          <b-checkbox
            v-model="updated.catalog"
            class="is-pulled-right"
            :rounded="true"
            :outlined="true"
            type="is-success"
            :left-label="true"
          ></b-checkbox>
          <b-field label="Catalogo"> </b-field>

          <div v-if="updated.catalog">
            <b-field label="Titulo">
              <b-input v-model="updated.title"></b-input>
            </b-field>
            <b-field label="Subtitulo">
              <b-input v-model="updated.subtitle"></b-input>
            </b-field>
            <b-field label="Foto">
              <b-input v-model="updated.imageUrl"></b-input>
            </b-field>
            <b-field v-if="updated.options" label="Marca">
              <b-input v-model="updated.options.brand"></b-input>
            </b-field>
            <b-field v-if="updated.options" label="Contenido Neto">
              <b-input v-model="updated.options.quantity"></b-input>
            </b-field>
            <b-checkbox
              v-model="updated.isShortage"
              class="is-pulled-right"
              :rounded="true"
              :outlined="true"
              type="is-success"
              :left-label="true"
            ></b-checkbox>
            <b-field label="Sin Stock"> </b-field>
            <b-field label="Descripción">
              <b-input
                v-model="updated.description"
                maxlength="255"
                type="textarea"
              ></b-input>
            </b-field>
            <b-field label="Tags">
              <b-taginput
                v-model="updated.tags"
                readonly
                rounded
                type="is-primary"
                field="attributes.name"
              >
              </b-taginput>
            </b-field>
            <div class="columns">
              <div class="column is-12">
                <b-button
                  class="m-1"
                  v-for="tag in availableTags"
                  :key="tag.id"
                  @click="updated.tags.push(tag)"
                  size="is-small"
                  type="is-primary is-light"
                  rounded
                  >{{ tag.attributes.name }}
                </b-button>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <Button
              pulled="right"
              type="is-success"
              :disabled="isSendingRequest"
              :onClickFn="handleUpdate"
              >Guardar</Button
            >
          </div>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../../services/ApiService';
import {
  omit, depopulate, sum, validatePayload, 
} from '../../../util/helpers';
import { immutableProperties } from '../../../util/constants';
import CmsService from '../../../services/cmsServices';
import useProductStore from '../../../store/product';

const {
  product: { updateProductSchema },
} = schemas;

const { ProductService } = ApiService;

export default {
  name: 'UpdateForm',
  props: {
    product: Object,
    active: Boolean,
  },
  components: {},
  data() {
    return {
      productStore: useProductStore(),
      updated: {
        ...this.product,
        options: (this.product && this.product.options) || {},
      },
      currentRemovedMargins: [],
      currentRemovedComponents: [],
      margin: {
        quantity: 0,
        value: 0,
      },
      tags: [],
      newMarginIsWholesaler: false,
      isSendingRequest: false,
    };
  },
  computed: {
    percentage() {
      return sum(this.updated.components, 'quantity');
    },

    isPercentage() {
      return this.updated.unit !== 'UNITY';
    },
    availableTags() {
      return this.tags.filter(
        (x) => this.updated.tags && !this.updated.tags.includes(x),
      );
    },
  },
  watch: {
    product(product) {
      this.updated = { ...product };
      if (!this.updated.options) {
        this.update.options = {};
      }
      this.currentRemovedMargins = [];
    },
  },
  methods: {
    async fetchTags() {
      const {
        data: { data: tags },
      } = await CmsService.get('/tags?populate=*');
      this.tags = tags;
      this.updated.tags = this.updated.tags.map((id) => this.tags.find((x) => x.id === id));
    },
    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await ProductService.disable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await ProductService.enable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      if (this.updated.mixed && this.isPercentage && this.percentage !== 100) {
        this.$buefy.snackbar.open(
          'Los componentes no alcanzan el 100% del compuesto',
        );
        return;
      }

      const { _id: id } = this.updated;

      const productClone = JSON.parse(JSON.stringify(this.updated));

      let payload = omit(immutableProperties.product, productClone);
      payload = depopulate(['category', 'provider'], payload);

      payload.components = payload.components.map((component) => {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        component.product = component.product._id;
        return component;
      });

      payload.tags = payload.tags.map((tag) => tag.id);
      payload.taxes = payload.taxes.map(({ _id }) => _id);
      if (!validatePayload(payload, updateProductSchema)) return;

      // TODO: Factorize to receive product from response
      const { error } = await this.productStore.update(id, payload);
      if (error) {
        console.error(error.data.code);
        return;
      }

      this.$emit('onUpdate', id);
    },

    handleAddMargin(removedMargin) {
      if (removedMargin) {
        this.margin = removedMargin;
        if (this.margin.quantity === 0) this.newMarginIsWholesaler = true;
        this.currentRemovedMargins = this.currentRemovedMargins.filter(
          ({ quantity }) => quantity !== removedMargin.quantity,
        );
      }
      const { quantity, value } = this.margin;
      if ((quantity === 0 && !this.newMarginIsWholesaler) || value === 0) {
        this.$buefy.snackbar.open('No es posible agregar margen 0');
        return;
      }

      const duplicated = this.updated.margins.find(
        (margin) => margin.quantity === quantity,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un margen con esa cantidad');
        return;
      }

      if (this.newMarginIsWholesaler) this.margin.quantity = 0;

      this.newMarginIsWholesaler = false;

      this.updated.margins.push(this.margin);
      this.margin = {
        quantity: 0,
        value: 0,
      };
      this.updated.margins.sort((a, b) => a.quantity - b.quantity);
    },

    handleRemoveMargin(margin) {
      this.currentRemovedMargins.push(margin);
      this.updated.margins = this.updated.margins.filter(
        ({ quantity }) => quantity !== margin.quantity,
      );
    },
    handleAddComponent(component) {
      // eslint-disable-next-line no-underscore-dangle
      if (component.product._id === this.updated._id) {
        this.$buefy.snackbar.open(
          'Este producto no puede ser parte del compuesto',
        );
        return;
      }
      this.updated.components.push(component);
      // this.updated.components.sort((a, b) => a.quantity - b.quantity);
    },
    handleRemoveComponent(component) {
      this.currentRemovedComponents.push(component);
      this.updated.components = this.updated.components.filter(
        // eslint-disable-next-line no-underscore-dangle
        ({ product }) => product._id !== component.product._id,
      );
    },
  },
  async mounted() {
    await this.fetchTags();
  },
};
</script>
