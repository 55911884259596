<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <div
          v-if="withHeader"
          class="flex flex-row p-2 text-indigo-400 bg-white border-b-2 border-indigo-400 "
          @click.stop="$emit('dismiss')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
          <span>Volver</span>
        </div>
        <section class="modal-card-body">
          <slot></slot>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'GenericModal',
  props: {
    active: Boolean,
    withHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    handleDisable() {},
  },
};
</script>
