<!-- eslint-disable max-len -->
<template>
  <b-modal
    :active="active"
    :width="640"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div
      class="p-4 mx-4 bg-white border-4 border-indigo-400 rounded-lg"
      v-if="draft"
    >
      <section>
        <div class="flex items-center justify-between">
          <span class="text-lg font-bold"># {{ draft.number }}</span>
        </div>
        <div class="flex items-center justify-between mb-2">
          <span class="text-xl font-bold">{{ draft.name }}</span>
        </div>
        <div
          v-for="item of draft.cart.items"
          :key="item.number"
          class="flex justify-start text-xs rounded-md"
        >
          <span class="truncate">{{ item | friendlyUnity }}</span>
          <div
            class="flex-grow px-2 font-medium truncate"
            :class="[!item.product.status ? 'underline text-red-500' : '']"
          >
            {{ item.product.name }}
          </div>
          <div class="font-bold tracking-wide">${{ item.price }}</div>
        </div>

        <div class="mt-2">
          <div class="flex justify-between rounded-md text-md">
            <span class="">Logística</span>
            <div class="font-bold tracking-end">${{ draft.logistic }}</div>
          </div>
          <div class="flex justify-between text-lg rounded-md">
            <span class="font-bold">Total</span>
            <div class="font-bold tracking-end">${{ draft.total }}</div>
          </div>
        </div>
        <div class="mt-2" v-if="draft.notes && draft.notes.length">
          <div
            class="p-2 text-sm border-2 border-gray-600 border-dotted rounded-md "
          >
            <span class="font-bold">Notas:</span>
            <div>{{ draft.notes }}</div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'DraftDetails',
  components: {},
  props: {
    active: Boolean,
    draft: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
