u
<template>
  <b-datepicker
    ref="datepicker"
    :mobile-native="false"
    :unselectable-days-of-week="!disabled ? [0] : [0, 1, 2, 3, 4, 5, 6]"
    v-model="newDate"
    :min-date="minDate"
    :nearby-selectable-month-days="true"
    placeholder="Fecha de entrega"
    icon="calendar-today"
    trap-focus
    locale="es-ES"
    :events="[new Date(order.delivery.date)]"
    :close-on-click="false"
    indicators="dots"
  >
    <template v-slot:trigger>
      <slot></slot>
    </template>

    
    <div class="flex gap-2">
      <b-field label="Hora inicio" dis>
        <b-select placeholder="HH" v-model="startDeliveryHour">
          <option
            v-for="option in [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]"
          
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
        <b-select placeholder="MM" v-model="startDeliveryMinute">
          <option
            v-for="option in [0, 15, 30, 45]"
          
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Hora Fin">
        <b-select placeholder="HH" v-model="endDeliveryHour">  
          <option
            v-for="option in [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              19, 20, 21, 22, 23,
            ]"
            :value="option"
            :key="option"
            :disabled="startDeliveryHour > option"
          >
            {{ option }}
          </option>
        </b-select>
        <b-select placeholder="MM" v-model="endDeliveryMinute">
          <option
            v-for="option in [0, 15, 30, 45]"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div
      v-if="disabled"
      class="p-2 border-2 border-red-400 rounded-md bg-red-50"
    >
      <span class="text-sm"
        >No se puede modificar la fecha de entrega de este pedido</span
      >
    </div>

    <div
    v-if="!isSameDate"
      class="flex flex-col gap-1 p-2 mb-4 border-2 border-yellow-400 rounded-md bg-yellow-50"
    >
      <span class="font-bold">IMPORTANTE:</span>
      <span class="text-md"
        >Se va a enviar un correo al cliente notificando el cambio en el envío</span
      >
    </div>

    <div class="flex flex-row-reverse gap-4">
      <Button
        v-if="newDate && !disabled"
        class="button is-primary is-light"
        :onClickFn="handleUpdateDelivery"
        :paramsFn="() => newDate"
      >
        <b-icon icon="check"></b-icon>
        <span>Confirmar</span>
      </Button>
      <Button
        v-if="order.state === 'CONFIRMADO' && !disabled"
        class="button is-warning"
        :onClickFn="handleSuspendOrder"
        :paramsFn="() => order._id"
      >
        Suspender
      </Button>
    </div>
  </b-datepicker>
</template>

<script>
import { isSameDay } from 'date-fns';
import { getNextDeliveryDates, getRangeTime, shortDate } from '../../util/helpers';
import useOrderStore from '../../store/order';
import { ROLES } from '../../util/constants';

const { ADMIN } = ROLES;

export default {
  name: 'UpdateDeliveryDate',
  props: {
    active: Boolean,
    order: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    withTimeRange: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    const today = new Date();
    return {
      orderStore: useOrderStore(),
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      newDate: null,
      nextDeliveryDates: getNextDeliveryDates(),
      startDeliveryHour: this.order.delivery.startTime && new Date(this.order.delivery.startTime).getHours(),
      startDeliveryMinute: this.order.delivery.startTime && new Date(this.order.delivery.startTime).getMinutes(),
      endDeliveryHour: this.order.delivery.endTime && new Date(this.order.delivery.endTime).getHours(),
      endDeliveryMinute: this.order.delivery.endTime && new Date(this.order.delivery.endTime).getMinutes(),
    };
  },
  watch: {
    startDeliveryHour() {
      if (this.endDeliveryHour < this.startDeliveryHour) {
        this.endDeliveryHour = this.startDeliveryHour + 2;
      }
      if (!this.endDeliveryHour) {
        this.endDeliveryHour = this.startDeliveryHour + 2;
      }
      if (!this.startDeliveryMinute) {
        this.startDeliveryMinute = 0;
      }
    },
    startDeliveryMinute() {
      if (!this.endDeliveryMinute) {
        this.endDeliveryMinute = this.startDeliveryMinute;
      }
    },
  },
  computed: {
    isSameDate() {
      return isSameDay(new Date(this.order.delivery.date), this.newDate);
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    startTime() {
      const date = new Date(this.newDate);

      date.setHours(this.startDeliveryHour);
      date.setMinutes(this.startDeliveryMinute);
      return date;
    },
    endTime() {
      const date = new Date(this.newDate);


      date.setHours(this.endDeliveryHour);
      date.setMinutes(this.endDeliveryMinute);
      
      return date;
    },
  },
  methods: {
    async handleUpdateDelivery(date = this.newDate) {
      this.$buefy.dialog.confirm({
        title: 'Cambiar fecha/hora de entrega',
        message:
          `Confirma los siguientes cambios:</br></br><b>Fecha:</b> ${shortDate(date)} </br><b>Rango Horario:</b> ${getRangeTime({ delivery: { startTime: this.startTime, endTime: this.endTime } })}  ${this.isSameDate ? '' : '</br></br><b style="color: red">Se enviará un correo al cliente notificando el cambio<b>'}`,
        confirmText: 'Confirmar',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          const { _id: id } = this.order;
          const payload = {
            date,
            startTime: this.startTime,
            endTime: this.endTime,
          };

          const updated = await this.orderStore.updateDelivery(id, payload);
          this.$emit('update', updated);
          // this.$refs.datepicker.toggle();
        },
      });
    },
    async handleSuspendOrder(id) {
      const updated = await this.orderStore.suspend(id);
      this.$emit('update', updated);
      this.$refs.datepicker.toggle();
    },
    handleRemoveDeliveryDate() {
      this.newDate = null;
    },
  },
  mounted() {
    if (this.order && this.order.delivery && this.order.delivery.date) {
      this.newDate = new Date(this.order.delivery.date);
      const today = new Date();
      this.minDate = this.currentUser.role === ADMIN
        ? new Date(today.getFullYear(), today.getMonth(), today.getDate())
        : new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1,
        );
    }
  },
};
</script>
