/* eslint-disable no-param-reassign */

export default {
  namespaced: true,
  state: () => ({ providers: [], timestamp: null }),
  mutations: {
    set(state, payload) {
      state.providers = payload;
    },
    flush(state) {
      state.providers = [];
    },
  },
  actions: {
    set({ commit }, elements) {
      commit('set', elements);
    },
    flush({ commit }) {
      commit('flush');
    },
  },
  getters: {
    get(state) {
      return state.providers;
    },
  },
};
