<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <div
          class="flex flex-row p-2 text-indigo-400 bg-white border-b-2 border-indigo-400"
          @click.stop="$emit('dismiss')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
          <span>Volver</span>
        </div>
        <section class="modal-card-body">
          <b-message type="is-warning" has-icon v-if="!updated.status">
            No es posible realizar modificaciones mientras el producto está
            deshabilitado.
          </b-message>
          <b-field label="Nombre">
            <b-input
              ref="nameInput"
              v-model="updated.name"
              :disabled="!updated.status"
            ></b-input>
          </b-field>
          <b-field label="Slug">
            <b-input
              ref="slugInput"
              v-model="updated.slug"
              :disabled="!updated.status"
            ></b-input>
          </b-field>

          <b-field label="Categoría">
            <b-select
              v-model="updated.category._id"
              :disabled="!updated.status"
              placeholder="Seleccionar una categoría"
            >
              <option
                v-for="category in categories"
                :value="category._id"
                :key="category.name"
              >
                {{ category.name }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Proveedor">
            <b-select
              v-model="updated.provider._id"
              :disabled="!updated.status"
              placeholder="Seleccionar un proveedor"
            >
              <option
                v-for="option in providers"
                :value="option._id"
                :key="option.name"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

          <label for="unit"><b>Control de stock</b></label>
          <div class="block mt-4">
            <b-radio v-model="updated.inventoryManagement" name="manual" native-value="manual"
              >Manual</b-radio
            >
            <b-radio v-model="updated.inventoryManagement" name="automatic" native-value="automatic"
              >Automatico</b-radio
            >
          </div>

          <b-checkbox
            data-cy="splitPackage"
            v-model="updated.splitPackage"
            class="is-pulled-right"
            :rounded="true"
            :outlined="true"
            type="is-success"
            :left-label="true"
          ></b-checkbox>
          <b-field
            label="Paquete separado"
          >
          </b-field>


          <div class="card">
            <div class="card-content">
              <b-checkbox
                :disabled="!updated.status"
                v-model="updated.mixed"
                class="is-pulled-right"
                :rounded="true"
                :outlined="true"
                type="is-success"
                :left-label="true"
              ></b-checkbox>
              <b-field
                :label="`Compuesto ${
                  product.unit === 'UNITY' ? 'Unitario' : 'Agranel'
                }`"
              >
              </b-field>
              <Components
                :product="updated"
                @add="handleAddComponent"
                @remove="handleRemoveComponent"
              />
            </div>
          </div>

          <b-field label="Aporte Activate %">
            <b-numberinput
              :disabled="!updated.status"
              v-model="updated.contributions"
              :controls="false"
              :min="0"
              step="0.001"
            ></b-numberinput>
          </b-field>

          <b-field label="Aporte Mayorista Activate %">
            <b-numberinput
              :disabled="!updated.status"
              v-model="updated.wholesalerContributions"
              :controls="false"
              :min="0"
              step="0.001"
            ></b-numberinput>
          </b-field>

          <div class="mt-1">
            <Button
              pulled="right"
              type="is-success"
              :disabled="isSendingRequest || !updated.status"
              :onClickFn="handleUpdate"
              >Guardar</Button
            >

            <Button
              v-if="updated.status"
              :disabled="isSendingRequest"
              type="is-danger"
              :onClickFn="handleDisable"
              >Deshabilitar</Button
            >

            <Button
              v-if="!updated.status"
              type="is-success"
              :disabled="isSendingRequest"
              :onClickFn="handleEnable"
              >Habilitar</Button
            >
          </div>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../services/ApiService';

import Components from './Components.vue';
import {
  omit, depopulate, sum, validatePayload, 
} from '../../util/helpers';
import { immutableProperties } from '../../util/constants';
import useProductStore from '../../store/product';

const {
  product: { updateProductSchema },
} = schemas;

const { CategoryService, ProviderService } = ApiService;

export default {
  name: 'UpdateForm',
  props: {
    product: Object,
    active: Boolean,
  },
  components: {
    Components,
  },
  data() {
    return {
      productStore: useProductStore(),
      updated: {
        ...this.product,
        options: (this.product && this.product.options) || {},
      },
      categories: [],
      providers: [],
      currentRemovedMargins: [],
      currentRemovedComponents: [],
      margin: {
        quantity: 0,
        value: 0,
      },
      newMarginIsWholesaler: false,
      isSendingRequest: false,
    };
  },
  computed: {
    percentage() {
      return sum(this.updated.components, 'quantity');
    },

    isPercentage() {
      return this.updated.unit !== 'UNITY';
    },
  },
  watch: {
    product(product) {
      this.updated = { ...product };
      if (!this.updated.options) {
        this.update.options = {};
      }
      this.currentRemovedMargins = [];
    },
  },
  methods: {
    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const updated = await this.productStore.disable(id);
      this.$emit('update', updated);
      this.updated = updated;
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      const updated = await this.productStore.enable(id);
      this.$emit('update', updated);
      this.updated = updated;
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      if (this.updated.mixed && this.isPercentage && this.percentage !== 100) {
        this.$buefy.snackbar.open(
          'Los componentes no alcanzan el 100% del compuesto',
        );
        return;
      }

      const { _id: id } = this.updated;

      const productClone = JSON.parse(JSON.stringify(this.updated));

      let payload = omit(immutableProperties.product, productClone);
      payload = depopulate(['category', 'provider'], payload);

      payload.components = payload.components.map((component) => {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        component.product = component.product._id;
        return component;
      });

      payload.taxes = payload.taxes.map(({ _id }) => _id);
      delete payload.alsoBoughtTogether;

      if (!validatePayload(payload, updateProductSchema)) return;

      const updated = await this.productStore.update(id, payload);

      this.$emit('update', updated);
    },

    handleAddMargin(removedMargin) {
      if (removedMargin) {
        this.margin = removedMargin;
        if (this.margin.quantity === 0) this.newMarginIsWholesaler = true;
        this.currentRemovedMargins = this.currentRemovedMargins.filter(
          ({ quantity }) => quantity !== removedMargin.quantity,
        );
      }
      const { quantity, value } = this.margin;
      if ((quantity === 0 && !this.newMarginIsWholesaler) || value === 0) {
        this.$buefy.snackbar.open('No es posible agregar margen 0');
        return;
      }

      const duplicated = this.updated.margins.find(
        (margin) => margin.quantity === quantity,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un margen con esa cantidad');
        return;
      }

      if (this.newMarginIsWholesaler) this.margin.quantity = 0;

      this.newMarginIsWholesaler = false;

      this.updated.margins.push(this.margin);
      this.margin = {
        quantity: 0,
        value: 0,
      };
      this.updated.margins.sort((a, b) => a.quantity - b.quantity);
    },

    handleRemoveMargin(margin) {
      this.currentRemovedMargins.push(margin);
      this.updated.margins = this.updated.margins.filter(
        ({ quantity }) => quantity !== margin.quantity,
      );
    },
    handleAddComponent(component) {
      // eslint-disable-next-line no-underscore-dangle
      if (component.product._id === this.updated._id) {
        this.$buefy.snackbar.open(
          'Este producto no puede ser parte del compuesto',
        );
        return;
      }
      this.updated.components.push(component);
      // this.updated.components.sort((a, b) => a.quantity - b.quantity);
    },
    handleRemoveComponent(component) {
      this.currentRemovedComponents.push(component);
      this.updated.components = this.updated.components.filter(
        // eslint-disable-next-line no-underscore-dangle
        ({ product }) => product._id !== component.product._id,
      );
    },
  },
  async mounted() {
    const { data: categories } = await CategoryService.find();
    this.categories = categories;
    const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
    this.providers = providers;
  },
};
</script>
