<!-- eslint-disable max-len -->
<template>
  <div><b-modal
    :active="active"
    :width="640"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div class="p-4">
      <section class="p-1 bg-white border-2 border-indigo-400 rounded-lg">
        <div class="p-2 my-2">
          <div
            class="grid grid-cols-5 text-xs rounded-md place-content-between"
          >
            <div class="col-span-2 px-2 font-bold">Producto</div>
            <span class="w-12 font-bold">Pidieron</span>
            <div class="font-bold tracking-tight">S. Real</div>
            <div class="font-bold tracking-tight">Faltan</div>
          </div>

          <div
            v-for="[provider, products] of Object.entries(getProviders())"
            :key="provider"
            class=""
          >
            <br />
            <div
              v-for="{ product, quantity, isPrevious } of products"
              :key="product._id"
              class="grid grid-cols-5 text-xs rounded-md cursor-pointer place-content-between" @click="handleSelectProduct(product)"
            >
              <div class="relative col-span-2 px-2 pl-3 font-medium truncate" >
                <span
                  v-if="isPrevious"
                  class="absolute left-0 w-2 h-2 bg-purple-500 rounded-full top-1"
                ></span>
                {{ product.name }}
              </div>

              <div>
                <span class="col-span-3">
                  {{ { product, quantity } | friendlyUnity }}</span
                >
              </div>

              <div
                class="font-bold tracking-tight"
                :class="
                  product.inventory[0].theoretical >= 0
                    ? 'text-yellow-500'
                    : 'text-red-500'
                "
              >
                {{
                  { product, quantity: product.inventory[0].real }
                    | friendlyUnity
                }}
              </div>
              <div>
                {{
                  { product, quantity: product.inventory[0].real - quantity }
                    | friendlyUnity
                }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
  <GenericModal
      :active="isProductModalActive"
      @dismiss="isProductModalActive = false"
    >
      <ProductCard
        :product="selectedProduct"
        :operationPermission="true"
      ></ProductCard>
    </GenericModal></div>
</template>

<script>
import GenericModal from '../../GenericModal.vue';
import ProductCard from '../../Product/mobile/ProductCard.vue';

export default {
  name: 'DayShortage',
  components: {
    GenericModal,
    ProductCard,
  },
  props: {
    active: Boolean,
    products: { type: Array, default: () => [] },
  },
  data() {
    return {
      isProductModalActive: false,
      selectedProduct: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleSelectProduct(product) {
      this.isProductModalActive = true;
      this.selectedProduct = product;
    },
    getProviders() {
      return this.products.reduce((acc, { product, quantity, ...rest }) => {
        if (!acc[product.provider]) {
          acc[product.provider] = [];
        }
        acc[product.provider].push({ product, quantity, ...rest });
        return acc;
      }, {});
    },
  },
};
</script>
