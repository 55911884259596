<template>
  <div>
    <span
      class="
        p-1.5
        text-xs
        font-medium
        uppercase
        tracking-wider
        rounded-lg
        bg-opacity-50
      "
      :class="[getStateType(element.state)]"
      >{{ getStateLabel(element.state) }}</span
    >
  </div>
</template>

<script>
export default {
  name: 'StateCompoent',
  components: {},
  props: {
    element: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    getStateLabel(state) {
      return {
        'A APROBAR': 'A ENTREGAR',
        APROBADA: 'PEDIDO ENTREGADO',
        CANCELADA: 'PEDIDO CANCELADO',
        CONFIRMADO: 'CONFIRMADO',
        PREPARADO: 'PREPARADO',
        CANCELADO: 'CANCELADO',
        PENDIENTE: 'PENDIENTE',
      }[state];
    },
    getStateType(state) {
      return {
        'A APROBAR': 'text-yellow-800 bg-yellow-200',
        APROBADA: 'text-green-800 bg-green-200',
        PENDIENTE: 'text-yellow-800 bg-yellow-200',
        CANCELADA: 'text-red-800 bg-red-200',
        CONFIRMADO: 'text-blue-800 bg-blue-200',
        PREPARADO: 'text-green-800 bg-green-200',
        CANCELADO: 'text-red-800 bg-red-200',
      }[state];
    },
  },
  watch: {},
};
</script>
