<template>
  <div>
    <b-table
      :paginated="isPaginated"
      :pagination-rounded="true"
      :per-page="perPage"
      :data="cart.items"
      :striped="true"
      :narrowed="true"
      :loading="loading"
      :sortable="false"
    >
      <b-table-column
        field="number"
        label="#"
        width="100"
        centered
        numeric
        v-slot="props"
      >
        {{ props.row.number }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Producto"
        centered
        numeric
        v-slot="props"
      >
        {{ props.row.product.name }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Cantidad"
        centered
        numeric
        v-slot="props"
      >
        {{ props.row | friendlyUnity }}
      </b-table-column>
      <b-table-column
        field="price"
        label="Precio"
        centered
        numeric
        v-slot="props"
      >
        ${{ props.row.price }}
      </b-table-column>
    </b-table>
    <div v-if="delivery && delivery.charged">
      <div class="grid grid-cols-1 mt-4 divide-y-2 divide-yellow-500">
        <span></span>
        <span></span>
      </div>

      <div class="mt-4 is-clearfix">
        <p class="title is-5 is-pulled-right">${{ cart.total | rounded }}</p>
        <p class="title is-5">Subtotal</p>
        <p class="title is-5 is-pulled-right">
          <span>{{
            delivery.charged ? `$${getCustomerCost}` : "Bonificado"
          }}</span>
        </p>
        <p class="title is-5">Envío</p>
      </div>
    </div>
    <div
      v-if="hasDiscount"
      class="p-2 mb-2 text-sm text-lg font-medium text-white rounded-lg  bg-gradient-to-br from-purple-500 to-pink-500"
    >
      <p class="font-bold is-pulled-right">- ${{ discountAmount | rounded }}</p>
      <p>
        <b>Descuento de aportes</b>
      </p>
    </div>

    <div v-if="withdrawal && withdrawal.charged">
      <div class="grid grid-cols-1 mt-4 divide-y-2 divide-yellow-500">
        <span></span>
        <span></span>
      </div>

      <div class="mt-4 is-clearfix">
        <p class="title is-5 is-pulled-right">${{ cart.total | rounded }}</p>
        <p class="title is-5">Subtotal</p>
        <p class="title is-5 is-pulled-right">
          <span>{{
            withdrawal.charged ? `- $${getProviderCost}` : "Bonificado"
          }}</span>
        </p>
        <p class="title is-5">Logística</p>
      </div>
    </div>

    <div class="grid grid-cols-1 divide-y-2 divide-purple-500">
      <span></span>
      <span></span>
    </div>

    <div class="mt-4 is-clearfix">
      <p class="title is-5 is-pulled-right">
        <span v-if="hasDiscount"
          >${{ (getTotal - discountAmount) | rounded }}</span
        >
        <span v-if="!hasDiscount">${{ getTotal | rounded }}</span>
      </p>
      <p class="title is-5">Total</p>
    </div>
  </div>
</template>

<script>
import { round, sum } from '../../util/helpers';

export default {
  name: 'ItemsTable',
  props: {
    delivery: Object,
    withdrawal: Object,
    cart: Object,
    loading: Boolean,
    discount: Number,
  },
  components: {},
  data() {
    return {
      perPage: 5,
    };
  },
  watch: {},
  computed: {
    discountAmount() {
      return this.contributions * (this.discount / 100) || 0;
    },
    isPaginated() {
      return this.cart.items.length > this.perPage;
    },
    getCustomerCost() {
      return this.delivery && this.delivery.charged
        ? this.delivery.customerPrice
        : 0;
    },
    getProviderCost() {
      return this.withdrawal && this.withdrawal.charged
        ? this.withdrawal.fee
        : 0;
    },
    getTotal() {
      return round(this.cart.total + this.getCustomerCost);
    },
    getBuyTotal() {
      return round(this.cart.total - this.getProviderCost);
    },
    hasDiscount() {
      return this.discount && this.discountAmount > 0;
    },
    contributions() {
      return round(
        sum(
          this.cart.items.map((i) => i.detail),
          'contribution',
        ),
      );
    },
  },
  methods: {},
  mounted() {},
};
</script>
