<template>
  <div>
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="receipts"
      :striped="true"
      :narrowed="true"
      :loading="loading"
      :show-detail-icon="false"
      ref="table"
      detailed
      :checked-rows.sync="selectedReceipts"
      checkable
    >
      <b-table-column
        field="date"
        label="Fecha"
        centered
        sortable
        searchable
        v-slot="props"
      >
        <b-tag type="is-black is-light">{{
          props.row.date | shortDatetime
        }}</b-tag>
      </b-table-column>

      <b-table-column
        :visible="operationPermission"
        field="provider.name"
        label="Proveedor"
        centered
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.provider.name }}
      </b-table-column>

      <b-table-column
        :visible="operationPermission"
        field="type"
        label="Tipo"
        centered
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.type }}
      </b-table-column>
      <b-table-column
        :visible="operationPermission"
        field="isBudget"
        label="X"
        centered
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.isBudget ? 'P' : 'X' }}
      </b-table-column>

      <b-table-column
        field="code"
        label="#"
        centered
        sortable
        searchable
        v-slot="props"
      >
        {{ props.row.code }}
      </b-table-column>


      <b-table-column field="total" label="Total" centered v-slot="props">
        ${{ props.row.total }}
      </b-table-column>

      <b-table-column field="grossTotal" label="BI" centered v-slot="props">
        ${{ props.row.grossTotal }}
      </b-table-column>

      <b-table-column v-for="tax of uniqueTaxes()" :key="tax._id" field="tax" :label="tax.name" centered v-slot="props">

        ${{ getTax(props.row, tax) }}
      </b-table-column>

      <b-table-column
        field="buy.number"
        label="Compra"
        centered
        sortable
        searchable
        v-slot="props"
      >
        <span class="cursor-pointer" @click="handleShowBuyModal(props.row.buy)">{{ (props.row.buy && props.row.buy.number) || '-' }}</span>
      </b-table-column>

      <b-table-column field="" label="" v-slot="props">
          <button
            v-if="isAbleToRemove(props.row.date)"
            type="button"
            class=""
            @click="handleRemoveReceipt(props.row)"
          >
            <b-icon icon="trash-can-outline" custom-size="small" />
          </button>
        </b-table-column>

      <template v-if="selectedReceipts.length" slot="bottom-left">
        <ExportReceipts :receipts="selectedReceipts" :all="receipts" />
      </template>
      <template #empty>
        <div class="has-text-centered">
          <p class="subtitle is-3">No hay Comprobantes</p>
        </div>
      </template>
    </b-table>
    <NewReceipt :active="isCreatingNewReceipt" />
    <GenericModal :active="isBuyModalActive"  @dismiss="isBuyModalActive = false"><BuyCard :buy="selectedBuy"></BuyCard></GenericModal>
  </div>
</template>

<script>
import { sum, round } from '../../util/helpers';
import ExportReceipts from './ExportReceipts.vue';
import ApiService from '../../services/ApiService';
import GenericModal from '../GenericModal.vue';
import BuyCard from './BuyCard.vue';

// import { BUY_STATES } from '../../util/constants';

import NewReceipt from './NewReceipt.vue';

const { ReceiptService, BuyService } = ApiService;

export default {
  name: 'ReceiptTable',
  props: {
    receipts: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NewReceipt,
    ExportReceipts,
    GenericModal, 
    BuyCard,
  },
  data() {
    return {
      isCreatingNewReceipt: false,
      isBuyModalActive: false,
      selectedReceipts: [],
      selectedBuy: null,
    };
  },
  watch: {},
  computed: {
    totalReceipts() {
      return round(sum(this.receipts, 'total'));
    },
  },
  methods: {
    getTax(row, tax) {
      const taxed = row.taxes.find(
        (receiptTax) => receiptTax._id === tax._id,
      );

      if (taxed) return taxed.value;
      return 0;
    },
    uniqueTaxes() {
      return [
        ...new Map(
          [...this.receipts].flatMap((entry) => entry.taxes.map((tax) => [tax.name, tax])),
        ).values(),
      ].sort((a, b) => a._id.localeCompare(b._id));
    },
    isAbleToRemove(date) {
      const selectedDate = new Date(date);
      const currentDate = new Date();

      const sevenDaysToNow = new Date();
      sevenDaysToNow.setDate(currentDate.getDate() - 7);

      if (selectedDate > sevenDaysToNow) {
        return true;
      }
      return false;
    },
    handleRemoveReceipt(receipt) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Comprobante',
        message:
          '¿Estás seguro de <b>eliminar</b> este comprobante? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await ReceiptService.disable(receipt._id);
          this.$emit('remove', receipt);
        },
      });
    },
    async handleShowBuyModal(buy) {
      const { data: selectedBuy } = await BuyService.findOne(buy._id);
      this.selectedBuy = selectedBuy;
      this.isBuyModalActive = true;
    },
  },
  mounted() {},
};
</script>
