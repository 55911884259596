<template>
  <div>
    <Section>
      <Layout v-if="isFetched.drafts && drafts.length">
        <List :loading="isLoading.drafts">
          <p class="px-2 text-2xl font-bold">📒 Tus pedidos</p>
          <DraftCard
            v-for="draft in drafts"
            :key="draft._id"
            :draft="draft"
            :isCheckable="true"
          />
        </List>
      </Layout>
      <DraftActions></DraftActions>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import DraftCard from '../components/Order/mobile/DraftCard.vue';
import useDraftStore from '../store/draft';
import { DRAFT_STATES } from '../util/constants';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import DraftActions from '../components/Order/DraftActions.vue';
import Section from '../components/Section.vue';

export default {
  name: 'MyOrders',
  components: {
    DraftCard,
    Layout,
    List,
    DraftActions,
    Section,
  },
  data() {
    return {
      draftStore: useDraftStore(),
    };
  },
  computed: {
    ...mapState(useDraftStore, ['isFetched']),
    ...mapState(useDraftStore, ['isLoading']),
    ...mapState(useDraftStore, ['drafts']),
  },
  async mounted() {
    window.onscroll = () => {};
    await this.draftStore.find(
      {
        isPartner: true,
        state: JSON.stringify([DRAFT_STATES.CONFIRMED]),
      },
      { sort: 0 },
    );
  },
  methods: {
    handleUpdateDraft() {},
  },
};
</script>
