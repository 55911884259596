<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <Button
          pulled="right"
          type="is-primary"
          :onClickFn="handleRefresh"
          icon="refresh"
        ></Button>
        <b-button data-cy="new-partner" @click="handleNewPartner"
          >Nuevo Socio</b-button
        >
      </div>
    </hero-bar>
    <Section>
      <div class="columns">
        <div class="column is-half">
          <LoadingTable :active="!fetched" />
          <PartnerTable
            v-if="fetched"
            :loading="loading"
            :partners="partners"
            @select="handleSelectPartner"
          />
        </div>
        <div v-if="selected" class="column is-half">
          <PartnerInfo :partner="selected" @update="handleUpdatePartner" />
        </div>
      </div>
    </Section>
    <NewPartner
      :active="newPartnerModalIsActive"
      @dismiss="handleNewPartnerAdded"
    ></NewPartner>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import PartnerInfo from '../components/Partner/Info.vue';
import NewPartner from '../components/Partner/NewPartner.vue';
import ApiService from '../services/ApiService';
import PartnerTable from '../components/Partner/PartnerTable.vue';
import Section from '../components/Section.vue';

const { UserService } = ApiService;

export default {
  name: 'Partners',
  components: {
    Section,
    HeroBar,
    PartnerInfo,
    NewPartner,
    PartnerTable,
  },
  data() {
    return {
      partners: [],
      selected: null,
      fetched: false,
      loading: false,
      newPartnerModalIsActive: false,
      hasActions: true,
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => {};
    await this.handleRefresh();
    this.fetched = true;
  },
  methods: {
    async handleRefresh() {
      await this.fetchPartners();
    },
    async fetchPartners() {
      this.loading = true;
      const { data: { docs: partners } } = await UserService.find({ status: true, limit: 99999 });
      this.loading = false;
      this.partners = partners;
    },
    handleNewPartner() {
      this.newPartnerModalIsActive = true;
    },
    handleNewPartnerAdded() {
      this.newPartnerModalIsActive = false;
      this.fetchPartners();
    },
    handleSelectPartner(partner) {
      this.selected = partner;
    },
    async handleUpdatePartner(updatedPartner) {
      const { _id: id } = updatedPartner;
      this.selected = updatedPartner;
      const index = this.partners.findIndex(
        ({ _id: partnerId }) => partnerId === id,
      );
      const partnersCopy = [...this.partners];
      partnersCopy[index] = updatedPartner;
      this.partners = partnersCopy;
    },
  },
};
</script>
<style>
tr.is-danger {
  background: #ffffff;
  color: #f14668;
}
</style>
