<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b-field class="is-pulled-right">
          <b-input placeholder="Nombre" v-model="newDelivery.name"></b-input>
          <p class="control">
            <Button
              data-cy="create-delivery"
              type="is-primary"
              :onClickFn="handleAddDelivery"
              >Agregar</Button
            >
          </p>
        </b-field>
        <p class="title is-4">Metodos de envío</p>
      </div>
    </div>

    <div class="card" v-if="deliveries.length">
      <div class="card-content">
        <div class="grid grid-cols-1 border-none divide-y-0">
          <div v-for="delivery of deliveries" :key="delivery._id">
            <p class="is-pulled-right title is-6">
              <span class="inline-block mr-4 align-middle"></span>

              <b-button
                size="is-small"
                icon-right="pencil"
                @click="handleUpdateDelivery(delivery)"
              >
              </b-button>
              <button
                @click="handleRemoveDelivery(delivery._id)"
                class="ml-4 delete"
              ></button>
            </p>
            <p class="title is-6">
              {{ delivery.name }}
              <b-tag :type="parseContributesTagType(delivery.contributes)"
                >{{ delivery.contributes ? 'Aporta' : 'No aporta' }}
              </b-tag>
              <b-tag type="is-primary is-light">
                AP USX ${{ delivery.customerPrice }}
              </b-tag>
              <b-tag type="is-primary is-light">
                AP AV ${{ delivery.partnerPrice }}
              </b-tag>
              <b-tag
                class="ml-2"
                v-if="delivery.isWholesaler"
                type="is-warning is-light"
                >Mayorista</b-tag
              >
              <b-tag
                class="ml-2"
                v-if="delivery.isRetailer"
                type="is-info is-light"
                >Minorista</b-tag
              >
              <b-tag class="ml-2" v-if="onlyAdmin(delivery)" type="is-primary"
                >ADMIN</b-tag
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <UpdateDelivery
      :active="updateDeliveryModalIsActive"
      :delivery="deliveryToUpdate"
      @dismiss="updateDeliveryModalIsActive = false"
    />
  </div>
</template>

<script>
import { schema, defaultData } from '../../schemas/delivery';
import { validatePayload, disconnectedCopy } from '../../util/helpers';
import ApiService from '../../services/ApiService';
import UpdateDelivery from '../Delivery/UpdateDelivery.vue';

const { DeliveryService } = ApiService;

export default {
  name: 'Delivery',
  components: {
    UpdateDelivery,
  },
  data() {
    return {
      deliveries: [],
      newDelivery: disconnectedCopy(defaultData),
      updateDeliveryModalIsActive: false,
      deliveryToUpdate: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchDeliveries();
  },
  methods: {
    onlyAdmin(delivery) {
      return delivery.roles[0] === 'ADMIN' && delivery.roles.length === 1;
    },
    async fetchDeliveries() {
      const { data: deliveries } = await DeliveryService.find({ status: true });
      this.deliveries = deliveries;
    },
    parseContributesTagType(contributes) {
      return contributes ? 'is-success is-light' : 'is-danger is-light';
    },
    async handleAddDelivery() {
      const payload = this.newDelivery;
      if (!validatePayload(payload, schema)) return;
      await DeliveryService.create(payload);
      await this.fetchDeliveries();
      this.newDelivery = disconnectedCopy(defaultData);
    },
    async handleUpdateDelivery(delivery) {
      this.deliveryToUpdate = delivery;
      this.updateDeliveryModalIsActive = true;
    },
    async handleRemoveDelivery(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar metodo de envío',
        message:
          '¿Estás seguro de <b>eliminar</b> este metodo de envío? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await DeliveryService.disable(id);
          await this.fetchDeliveries();
        },
      });
    },
  },
};
</script>
