<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true" icon="seed">
      <span slot="label">
        <img
          style="width: 100%; height: 100%"
          :src="require('../assets/logo.webp')"
        />
      </span>
    </aside-tools>
    <img :src="require('../assets/waves-up.webp')" />

    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p
          class="text-sm menu-label"
          v-if="typeof menuGroup === 'string'"
          :key="index"
        >
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :key="`else-${index}`"
          @menu-click="menuClick"
          :menu="menuGroup"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import AsideTools from './AsideTools.vue';
import AsideMenuList from './AsideMenuList.vue';

export default {
  name: 'AsideMenu',
  components: { AsideMenuList, AsideTools },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isAsideVisible() {
      return this.$store.state.Ui.isNavBarVisible;
    },
  },
  methods: {
    menuClick(item) {
      this.$emit('menu-click', item);
    },
  },
};
</script>

<style>
.aside {
  overflow-y: scroll;
  /* overflow: hidden;  */
}

/*https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp*/
/* Hide scrollbar for Chrome, Safari and Opera */
.aside::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.aside {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
