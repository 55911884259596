import { render, staticRenderFns } from "./ItemsDetails.vue?vue&type=template&id=21d0512e"
import script from "./ItemsDetails.vue?vue&type=script&lang=js"
export * from "./ItemsDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports