<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :width="640"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4" v-if="account">
        <section class="border-2 border-indigo-400 rounded-lg modal-card-body">
          <div class="flex items-center justify-between mb-2">
            <span class="text-xl font-bold">Movimientos de la cuenta</span>
          </div>
          <div class="flex items-center justify-center mt-8" v-if="isLoading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 animate-spin"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div
            v-if="
              account &&
              account.transactions &&
              account.transactions.length &&
              !isLoading
            "
            class=""
          >
            <ul
              role="list"
              class="mt-4 divide-y divide-gray-200 dark:divide-gray-700"
            >
              <li
                class="py-3 sm:py-4"
                v-for="transaction in account.transactions"
                :key="transaction._id"
              >
                <div
                  class="flex flex-row justify-between"
                  v-if="transaction.doc === 'orders'"
                  @click="handleShowOrderDetails(transaction)"
                >
                  <div class="flex flex-col">
                    <p class="text-xs text-gray-900 truncate">
                      {{ transaction.date | shortDate }}
                    </p>

                    <p class="text-sm font-medium text-gray-900 truncate">
                      Pedido {{ transaction.number }}
                    </p>

                    <!-- <p class="text-sm text-gray-500 truncate">
                      {{ transaction.cart.items.length }} items
                    </p> -->
                  </div>
                  <div class="flex flex-row items-center">
                    <StateComponent
                      class="py-2"
                      :element="transaction"
                    ></StateComponent>
                    <div
                      class=""
                      v-if="isLoadingOrder && loadingOrder === transaction._id"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-6 h-6 animate-spin"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-end text-base font-semibold text-red-500"
                    :class="[]"
                  >
                    <p class="text-lg font-bold">
                      {{ transaction.total | currency }}
                    </p>
                    <p
                      class="text-xs font-medium"
                      :class="[
                        transaction.current >= 0
                          ? 'text-green-500'
                          : 'text-red-500',
                      ]"
                    >
                      {{ transaction.current | currency }}
                    </p>
                  </div>
                </div>
                <div
                  class="flex items-center space-x-4"
                  v-if="transaction.doc === 'pays'"
                >
                  <div class="flex-1 min-w-0">
                    <p class="text-xs text-gray-900 truncate">
                      {{ transaction.date | shortDate }}
                    </p>

                    <p class="text-sm font-medium text-gray-900 truncate">
                      Recibiste un pago
                    </p>
                  </div>
                  <div
                    class="flex flex-col items-end text-base font-semibold text-green-500"
                    :class="[]"
                  >
                    <p class="text-lg font-bold">
                      {{ transaction.total | currency }}
                    </p>
                    <p
                      class="text-xs font-medium"
                      :class="[
                        transaction.current >= 0
                          ? 'text-green-500'
                          : 'text-red-500',
                      ]"
                    >
                      {{ transaction.current | currency }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </b-modal>
    <OrderDetails
      :element="orderSelected"
      :active="isOrderDetailsOpen"
      @dismiss="handleDismissOrderDetails"
    ></OrderDetails>
  </section>
</template>

<script>
import OrderDetails from '../../DetailsModal.vue';
import ApiService from '../../../services/ApiService';
import StateComponent from '../../StateComponent.vue';

const { OrderService } = ApiService;

export default {
  name: 'AccountFlows',
  components: {
    OrderDetails,
    StateComponent,
  },
  props: {
    active: Boolean,
    account: Object,
  },
  data() {
    return {
      orders: [],

      pays: [],
      isLoading: false,
      isLoadingOrder: false,
      loadingOrder: null,
      isOrderDetailsOpen: false,
      orderSelected: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleDismissOrderDetails() {
      this.orderSelected = null;
      this.isOrderDetailsOpen = false;
    },

    async handleShowOrderDetails(transaction) {
      this.isLoadingOrder = true;
      this.loadingOrder = transaction._id;
      const { data: order } = await OrderService.findOne(transaction._id);
      this.isLoadingOrder = false;
      this.orderSelected = order;
      this.isOrderDetailsOpen = true;
    },

    handlePayCreated(pay) {
      this.$emit('payCreated', pay, this.element);
      this.$emit('dismiss');
    },
  },
};
</script>
