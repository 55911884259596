/* eslint-disable import/prefer-default-export */
export const columns = [
  {
    field: 'name',
    label: 'Nombre',
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'section',
    label: 'Sección',
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'splitPackage',
    label: 'Paquete separado',
    sortable: true,
    centered: true,
    searchable: true,
  },
];
