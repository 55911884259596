<template>
  <div class="ml-2">
    <b-button
      :loading="isLoading"
      type="is-primary is-light"
      @click="downloadFile"
    >
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import xlsx from 'json-as-xlsx';

import ApiService from '../../services/ApiService';
import {
  hasStock, isAutomaticInventory, round, translate,
} from '../../util/helpers';

const { ProductService } = ApiService;

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'ExportProducts',
  props: {},
  components: {},
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    uniqueTaxes(products) {
      return [
        ...new Map(
          [...products].flatMap((entry) => entry.taxes.map((tax) => [tax.name, tax])),
        ).values(),
      ].sort((a, b) => a._id.localeCompare(b._id));
    },
    item({ product, quantity, isWholesaler = false }) {
      return new Item({ product, quantity, isWholesaler }).billOrder();
    },
    price(product, quantity, isWholesaler) {
      return this.item({ product, quantity, isWholesaler }).price;
    },
    supplyItem(product, quantity) {
      return new Item({ product, quantity, isWholesaler: true }).billOrder({ isSupply: true });
    },
    async downloadFile() {
      this.isLoading = true;
      const { data: pagination } = await ProductService.find();
      const products = pagination.docs;
      this.isLoading = false;
      const data = [
        {
          sheet: 'Productos',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Nombre', value: 'name' },
            { label: 'Categoria', value: (product) => product.category.name },
            { label: 'Proveedor', value: (product) => product.provider.name },
            {
              label: 'Unidad',
              value: (product) => translate('unit', product.unit),
            },
            { label: 'BI', value: 'taxBase' },
            { label: 'Costo', value: 'unitCost' },
            {
              label: 'Precio 100gr',
              value: (product) => (product.unit === 'UNITY'
                || !product.margins.find((m) => m.quantity === 100)
                ? ''
                : this.price(product, 100)),
            },
            {
              label: 'Precio 500gr',
              value: (product) => (product.unit === 'UNITY'
                || !product.margins.find((m) => m.quantity === 500)
                ? ''
                : this.price(product, 500)),
            },
            {
              label: 'Precio 1000gr',
              value: (product) => (product.unit === 'UNITY'
                ? this.price(product, 1)
                : this.price(product, 1000)),
            },
            {
              label: 'Precio MAY',
              value: (product) => (product.unit === 'UNITY'
                ? this.price(product, 1, true)
                : this.price(product, 1000, true)),
            },
            {
              label: 'Precio SOC',
              value: (product) => (product.unit === 'UNITY'
                ? this.supplyItem(product, 1).price
                : this.supplyItem(product, 1000).price
              ),
            },
            {
              label: 'M100',
              value: (product) => {
                const margin = product.margins.find((m) => m.quantity === 100);
                if (margin) return margin.value;
                return '';
              },
            },
            {
              label: 'M500',
              value: (product) => {
                const margin = product.margins.find((m) => m.quantity === 500);
                if (margin) return margin.value;
                return '';
              },
            },
            {
              label: 'M1000',
              value: (product) => {
                const margin = product.margins.find((m) => (product.unit === 'UNITY'
                  ? m.quantity === 1
                  : m.quantity === 1000));
                if (margin) return margin.value;
                return '';
              },
            },
            {
              label: 'MAY',
              value: (product) => product.margins.find((margin) => margin.quantity === 0).value,
            },
            {
              label: 'SOC',
              value: (product) => (product.unit === 'UNITY'
                ? this.supplyItem(product, 1).detail.margin
                : this.supplyItem(product, 1000).detail.margin
              ),
            },
            {
              label: 'Aportes',
              value: 'contributions',
            },
            {
              label: 'Aportes Mayorista',
              value: 'wholesalerContributions',
            },
            {
              label: 'c min',
              value: (product) => round((this.item({ product, quantity: 1 }).detail.profit * 100) / this.item({ product, quantity: 1 }).price),
            },
            {
              label: 'c may',
              value: (product) => round((this.item({ product, quantity: 1, isWholesaler: true }).detail.profit * 100) / this.item({ product, quantity: 1, isWholesaler: true }).price),
            },
            {
              label: 'Sin stock',
              value: (product) => (hasStock(product) ? 'en stock' : 'sin stock'),
            },
            {
              label: 'Gestion de stock',
              value: (product) => (isAutomaticInventory(product) ? 'AUTOMATICO' : 'MANUAL'),
            },
            {
              label: 'Teorico',
              value: (product) => product.inventory[0].theoretical,
            },
            {
              label: 'Real',
              value: (product) => product.inventory[0].real,
            },
            {
              label: 'Pendiente',
              value: (product) => product.inventory[0].pending,
            },
            {
              label: 'Pre-aprobado',
              value: (product) => product.inventory[0].committed,
            },
            {
              label: 'Crítico',
              value: (product) => product.inventory[0].critical,
            },

            {
              label: 'Estado',
              value: (product) => (product.status ? 'Habilitado' : 'Deshabilitado'),
            },
            {
              label: 'Bulto Separado',
              value: (product) => (product.splitPackage ? 'SI' : ''),
            },
            ...this.uniqueTaxes(products).map((tax) => ({
              label: tax.name,
              value: (product) => {
                const taxed = product.taxes.find(
                  (productTax) => productTax._id === tax._id,
                );
                return taxed ? taxed.aliquot : 0;
              },
            })),

          ],
          content: products,
        },

      ];
      const settings = {
        fileName: `Productos - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
