<template>
  <div>
    <Section>
      <b-tabs
        type="is-toggle"
        @input="tabChanged"
        v-model="currentTab"
        :vertical="isMobile ? true : false"
        :size="!isMobile ? 'is-small' : ''"
        :animated="false"
      >
        <b-tab-item value="pending">
          <template #header>
            <b-icon icon="information-outline"></b-icon>
            <span>
              Pedidos sin fecha de entrega
              <b-tag rounded> {{ pending.length }}</b-tag>
            </span>
          </template>
          <Layout>
            <List :loading="isLoading.pending">
              <p v-if="pending.length" class="text-2xl font-bold">
                🚨 Pedidos sin fecha de entrega
              </p>
              <OrderCard
                v-for="order in pending"
                :key="order._id"
                :order="order"
                :isCheckable="false"
                :operationPermission="true"
              ></OrderCard>
            </List>
          </Layout>
        </b-tab-item>
        <b-tab-item value="history">
          <template #header>
            <b-icon icon="history"></b-icon>
            <span>Historial de pedidos</span>
          </template>
          <SearchBar
            :store="orderStore"
            placeholder="✨ Buscá clientes y direcciones"
          ></SearchBar>
          <Layout>
            <List :loading="isLoading.orders">
              <!-- <SelectDeliveryMethod
                :deliveries="deliveries"
                :selected="selected"
              ></SelectDeliveryMethod> -->
              <p class="px-2 text-2xl font-bold">🗓 Historial de pedidos</p>

              <Draggable
                tag="ul"
                handle=".handle"
                v-model="orders"
                class="space-y-4"
                :animation="200"
              >
                <OrderCard
                  ref="orderCard"
                  v-for="order in orders"
                  :key="order._id"
                  :order="order"
                  :operationPermission="true"
                  :isDraggable="false"
                  :isCheckable="true"
                ></OrderCard>
              </Draggable>
            </List>
          </Layout>
        </b-tab-item>
      </b-tabs>

      <OrderActions
        :selected="selected"
        :operationPermission="true"
        :actions="{
        roadmap: false,
        invoice: true,
        consignment: false,
        deliveryRoute: false,
        export: true,
        prepare: true,
      }"
      ></OrderActions>
    </Section>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapState } from 'pinia';

import OrderActions from '../components/Order/OrderActions.vue';
import SearchBar from '../components/SearchBar.vue';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import OrderCard from '../components/Order/OrderCard.vue';
import useOrderStore from '../store/order';
// import SelectDeliveryMethod from '../components/SelectDeliveryMethod.vue';
import ApiService from '../services/ApiService';
import Section from '../components/Section.vue';
import useUserStore from '../store/user';

const { DeliveryService } = ApiService;

export default {
  name: 'Orders',
  components: {
    Layout,
    Section,
    List,
    OrderCard,
    OrderActions,
    SearchBar,
    Draggable,
    // SelectDeliveryMethod,
  },
  data() {
    return {
      userStore: useUserStore(),
      orderStore: useOrderStore(),
      query: {},
      orderedElements: [],
      currentTab: 'pending',
      deliveries: [],
    };
  },
  async mounted() {
    this.getNext();
    this.orderStore.setQuery(null);
    await this.orderStore.find();
    await this.orderStore.findPending();

    if (!this.pending.length) this.currentTab = 'history';
    await this.userStore.find({ isDriver: true }, { key: 'drivers' });
    // this.fetchDeliveries();
  },
  computed: {
    ...mapState(useOrderStore, ['options']),
    ...mapState(useOrderStore, ['pagination']),
    ...mapState(useOrderStore, ['isLoading']),
    ...mapState(useOrderStore, ['pending']),
    ...mapState(useOrderStore, ['orders']),
    ...mapState(useOrderStore, ['selected']),
    orders: {
      get() {
        return this.orderStore.orders;
      },
      set(value) {
        return this.orderStore.updatePositions(value, 'orders');
      },
    },
    isMobile() {
      return (
        Math.min(window.screen.width, window.screen.height) < 768
        || navigator.userAgent.indexOf('Mobi') > -1
      );
    },
  },
  methods: {
    async fetchDeliveries() {
      const { data: deliveries } = await DeliveryService.find({ status: true });
      this.deliveries = deliveries;
    },
    tabChanged(type) {
      this.currentTab = type;
    },
    handleChangePage() {
      this.orderStore.changePage();
    },
    handleCreateNewOrder() {
      this.$router.push('/cart');
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.orders
          && this.pagination.hasNextPage
          && this.currentTab === 'history'
        ) {
          this.handleChangePage();
        }
      };
    },
  },
};
</script>
