/* eslint-disable import/prefer-default-export */

export const ROLES = {
  ADMIN: 'ADMIN',
  PARTNER: 'AV',
};

export const ORDER_STATES = {
  PENDING: 'PENDIENTE',
  CONFIRMED: 'CONFIRMADO',
  PREPARED: 'PREPARADO',
  CANCELLED: 'CANCELADO',
};

export const DRAFT_STATES = {
  OPEN: 'OPEN',
  CONFIRMED: 'CONFIRMED',
  CLOSED: 'CLOSED',
};

export const BUY_STATES = {
  PENDING: 'A APROBAR',
  APPROVED: 'APROBADA',
  CANCELLED: 'CANCELADA',
};

export const UNITS = {
  WEIGHT: 'PESO',
  UNITY: 'UNIDAD',
};

const commonImmutableProperties = [
  'number',
  'status',
  '_id',
  'id',
  'partner',
  'createdAt',
  'updatedAt',
  '__v',
];

export const immutableProperties = {
  common: commonImmutableProperties,
  customer: [
    ...commonImmutableProperties,
    'main',
    'verified',
    'friendlyName',
    'picture',
  ],
  provider: [...commonImmutableProperties, 'partner'],
  partner: [
    ...commonImmutableProperties,
    'isConfirmed',
    'password',
    'isThird',
    'isProvider',
  ],
  user: [
    ...commonImmutableProperties,
    'isConfirmed',
    'role',
    'account',
    'userSince',
    'garage',
    'wholesaler',
    'isProvider',
    'slug',
  ],
  product: [...commonImmutableProperties, 'inventory'],
};
