<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b-field class="is-pulled-right">
          <b-input
            placeholder="Nombre"
            v-model="newChargeMethod.name"
          ></b-input>
          <p class="control">
            <Button
              data-cy="create-chargeMethod"
              type="is-primary"
              :onClickFn="handleAddChargeMethod"
              >Agregar</Button
            >
          </p>
        </b-field>
        <p class="title is-4">Metodos de cobro</p>
      </div>
    </div>

    <div class="card" v-if="chargeMethods.length">
      <div class="card-content">
        <div class="grid grid-cols-1 border-none divide-y-0">
          <div v-for="chargeMethod of chargeMethods" :key="chargeMethod._id">
            <p class="is-pulled-right title is-6">
              <b-button
                size="is-small"
                icon-right="pencil"
                @click="handleUpdateChargeMethod(chargeMethod)"
              >
              </b-button>
              <button
                @click="handleRemoveChargeMethod(chargeMethod._id)"
                class="ml-4 delete"
              ></button>
            </p>
            <p class="title is-6">
              {{ chargeMethod.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <UpdateChargeMethod
      :active="updateChargeMethodModalIsActive"
      :chargeMethod="chargeMethodToUpdate"
      @dismiss="updateChargeMethodModalIsActive = false"
    />
  </div>
</template>

<script>
import { schema, defaultData } from '../../schemas/chargeMethod2';
import { validatePayload, disconnectedCopy } from '../../util/helpers';
import ApiService from '../../services/ApiService';
import UpdateChargeMethod from '../ChargeMethod/UpdateChargeMethod.vue';

const { ChargeMethodService } = ApiService;

export default {
  name: 'ChargeMethod',
  components: {
    UpdateChargeMethod,
  },
  data() {
    return {
      chargeMethods: [],
      newChargeMethod: disconnectedCopy(defaultData),
      updateChargeMethodModalIsActive: false,
      chargeMethodToUpdate: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchChargeMethods();
  },
  methods: {
    async fetchChargeMethods() {
      const { data: chargeMethods } = await ChargeMethodService.find({
        status: true,
      });
      this.chargeMethods = chargeMethods;
    },
    parseContributesTagType(contributes) {
      return contributes ? 'is-success is-light' : 'is-danger is-light';
    },
    async handleAddChargeMethod() {
      const payload = this.newChargeMethod;
      if (!validatePayload(payload, schema)) return;
      await ChargeMethodService.create(payload);
      await this.fetchChargeMethods();
      this.newChargeMethod = disconnectedCopy(defaultData);
    },
    async handleUpdateChargeMethod(chargeMethod) {
      this.chargeMethodToUpdate = chargeMethod;
      this.updateChargeMethodModalIsActive = true;
    },
    async handleRemoveChargeMethod(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar metodo de pago',
        message:
          '¿Estás seguro de <b>eliminar</b> este metodo de cobro? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await ChargeMethodService.disable(id);
          await this.fetchChargeMethods();
        },
      });
    },
  },
};
</script>
