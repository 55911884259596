<template>
  <vue-loadmore
    loosing-text="Soltá para refrescar"
    error-text="Hubo un error"
    finished-text="Terminado"
    pulling-text="Tirá un poco más"
    :show-success-text="false"
    :on-refresh="(done) => onRefresh(...params).then(done())"
  >
    <slot></slot>
  </vue-loadmore>
</template>

<script>
import VueLoadmore from 'vuejs-loadmore';

export default {
  name: 'PullToRefresh',
  components: {
    VueLoadmore,
  },
  props: {
    onRefresh: {
      type: Function,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
