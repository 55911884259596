/* eslint-disable no-param-reassign */

export default {
  namespaced: true,
  state: () => ({ categories: [], timestamp: null }),
  mutations: {
    set(state, payload) {
      state.categories = payload;
    },
    flush(state) {
      state.categories = [];
    },
  },
  actions: {
    set({ commit }, elements) {
      commit('set', elements);
    },
    flush({ commit }) {
      commit('flush');
    },
  },
  getters: {
    get(state) {
      return state.categories;
    },
  },
};
