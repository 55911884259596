<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-field label="Nombre">
            <b-input v-model="provider.name" type="text"></b-input>
          </b-field>
          <div class="columns">
            <div class="column is-one-third">
              <b-field label="Dirección">
                <b-input
                  v-model="provider.address.street"
                  type="text"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field label="Ciudad">
                <b-input v-model="provider.address.city" type="text"></b-input>
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field label="Provincia">
                <b-input
                  v-model="provider.address.province"
                  type="text"
                ></b-input>
              </b-field>
            </div>
          </div>
          <b-field label="Precio de Envío">
            <b-input v-model="provider.logisticPrice" type="number"></b-input>
          </b-field>
          <b-field label="Mínimo de compra">
            <b-input v-model="provider.minimumPurchase" type="number"></b-input>
          </b-field>
          <b-checkbox
            v-model="provider.account"
            class="is-pulled-right"
            :rounded="true"
            :outlined="true"
            type="is-success"
            :left-label="true"
          ></b-checkbox>
          <b-field label="Cuenta Corriente"> </b-field>
          <b-field label="CUIT">
            <b-input v-model="provider.cuit" type="text"></b-input>
          </b-field>
          <b-field label="Telefono">
            <PhoneInput v-model="provider.phone" />
          </b-field>
          <b-field label="Observaciones">
            <b-input
              v-model="provider.notes"
              maxlength="255"
              type="textarea"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="create-provider"
            type="is-success"
            :onClickFn="handleAddProvider"
            >Agregar</Button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ApiService from '../../services/ApiService';
import { schema, defaultData } from '../../schemas/provider';
import { validatePayload, disconnectedCopy } from '../../util/helpers';
import PhoneInput from '../PhoneInput.vue';

const { ProviderService } = ApiService;

export default {
  name: 'NewProvider',
  props: {
    active: Boolean,
  },
  components: {
    PhoneInput,
  },
  data() {
    return {
      provider: disconnectedCopy(defaultData),
    };
  },
  watch: {
    active() {
      if (!this.active) return;
      if (!this.provider.notes) {
        this.provider.notes = 'PRESUPUESTO: SI/NO\nLOGÍSTICA:\nOBSERVACIONES:';
      }
    },
  },  
  computed: {},
  methods: {
    async handleAddProvider() {
      const payload = this.provider;
      if (!validatePayload(payload, schema)) return;
      const { error, data: provider } = await ProviderService.create(payload);
      if (error) {
        console.error(error.data.code);
        return;
      }
      this.$emit('created', provider);
      this.provider = disconnectedCopy(defaultData);
    },
  },
};
</script>
