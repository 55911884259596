<template>
  <VuePhoneNumberInput
    data-cy="phone"
    v-model="value"
    :default-country-code="defaultCountry"
    :preferred-countries="preferredCountries"
    :border-radius="5"
    :translations="{
      countrySelectorLabel: 'Codigo de país',
      countrySelectorError: 'Seleccionar un país',
      phoneNumberLabel: 'Numero de teléfono',
      example: 'Ejemplo:',
    }"
    color="#8C67EF"
    valid-color="#48C775"
    error-color="#F14668"
    :error="isInvalidPhone && value && !!value.length"
    @update="handleInput"
    @phone-number-blur="handleCheckPhone"
  />
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'PhoneInput',
  components: {
    VuePhoneNumberInput,
  },
  props: {
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.assignDefaultPhone(this.phone),
      originalNumber: null,
      phoneObject: {},
      isInvalidPhone: false,
      defaultCountry: 'AR',
      preferredCountries: [
        'AR',
        'PY',
        'BR',
        'BO',
        'PE',
        'EC',
        'VE',
        'CO',
        'CL',
        'MX',
        'ES',
        'FR',
        'IT',
        'GB',
        'DE',
        'AU',
        'US',
      ],
    };
  },
  watch: {
    phone() {
      if (this.phone) {
        this.value = this.phone;
      }
    },
  },
  methods: {
    handleCheckPhone() {
      this.isInvalidPhone = !this.phoneObject.isValid;
    },
    assignDefaultPhone(phone) {
      if (phone && phone.length) {
        this.originalNumber = phone;
      }
      return phone;
    },
    handleInput(value) {
      this.isInvalidPhone = false;
      this.phoneObject = value;
      const { e164, isValid } = value;
      if (isValid) {
        this.$emit('input', e164);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
