<!-- eslint-disable max-len -->
<template>
  <section>
    <b-modal
      :active="active"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="p-4" v-if="buy">
        <section class="border-2 border-indigo-400 rounded-lg modal-card-body">
          <div class="flex items-center justify-between">
            <span class="text-lg font-bold"># {{ buy.number }}</span>
          </div>
          <div class="flex items-center justify-between mb-2">
            <span class="text-xl font-bold">{{ buy.provider.name }}</span>
          </div>
          <div
            v-for="item of buy.cart.items"
            :key="item.number"
            class="flex justify-start text-xs rounded-md"
          >
            <span class="truncate">{{ item | friendlyUnity }}</span>
            <div class="flex-grow px-2 font-medium truncate">
              {{ item.product.name }}
            </div>
            <div class="font-bold tracking-wide">${{ item.price }}</div>
          </div>

          <div class="mt-2">
            <div class="flex justify-between text-md">
              <span class="font-bold">Subtotal</span>
              <span class="font-bold">{{ buy.cart.total | currency }}</span>
            </div>
            <div class="flex justify-between text-md">
              <span class="font-bold">Logística</span>
              <span v-if="buy.withdrawal.charged" class="font-bold">{{
                -buy.withdrawal.fee | currency
              }}</span>
              <span v-if="!buy.withdrawal.charged" class="font-bold"
                >Bonificado</span
              >
            </div>
            <div class="flex justify-between text-lg rounded-md">
              <span class="font-bold">Total</span>
              <div class="font-bold tracking-end">
                {{ buy.total || buy.cart.total | currency }}
              </div>
            </div>
          </div>
          <div class="mt-2" v-if="buy.notes && buy.notes.length">
            <div
              class="p-2 text-sm border-2 border-gray-600 border-dotted rounded-md"
            >
              <span class="text-lg font-bold">Notas</span>
              <div>{{ buy.notes }}</div>
            </div>
          </div>
          <div
            v-if="buy.metadata && Object.entries(buy.metadata).length"
            class="p-2 mt-2 border-2 rounded-lg"
          >
            <p class="text-lg font-bold">Modificaciones de la compra</p>

            <div
              v-for="[event, items] in Object.entries(buy.metadata)"
              :key="event"
            >
              <div class="text-xs">{{ event | shortDate }}</div>
              <p v-if="shortages(items).length" class="mt-1 text-xs font-bold">
                Faltantes
              </p>
              <div v-for="item of shortages(items)" :key="item._id">
                <!-- <div v-if="!item.state">
                  <p class="mt-2 text-xs font-bold" v-if="item.quantity < 0">
                    Se quitó en una modificación
                  </p>
                </div> -->

                <div class="flex justify-start px-2 text-xs">
                  <div class="flex-grow truncate">
                    {{ item.state ? item.name : item.product.name }}
                  </div>
                  <div
                    class="font-bold tracking-wide truncate"
                    :class="[
                      item.state === 'pending' && item.diff > 0
                        ? 'text-yellow-400'
                        : '',
                      item.state === 'shortage' ? 'text-red-400' : '',
                    ]"
                  >
                    <div v-if="item.state">
                      {{ -item.diff }} {{ item | unit }}
                    </div>
                    <div v-if="!item.state">
                      {{ item | friendlyUnity }}
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="changes(items).length" class="mt-2 text-xs font-bold">
                Modificaciones
              </p>
              <div v-for="item of changes(items)" :key="item._id">
                <!-- <div v-if="!item.state">
                  <p class="mt-2 text-xs font-bold" v-if="item.quantity > 0">
                    Se agregó en una modificación
                  </p>

                  <p class="mt-2 text-xs font-bold" v-if="item.quantity < 0">
                    Se quitó en una modificación
                  </p>
                </div> -->

                <div class="flex justify-start px-2 text-xs">
                  <div class="flex-grow truncate">
                    {{ item.state ? item.name : item.product.name }}
                  </div>
                  <div
                    class="font-bold tracking-wide truncate"
                    :class="[
                      item.state === 'pending' && item.diff > 0
                        ? 'text-yellow-400'
                        : '',
                      item.state === 'shortage' ? 'text-red-400' : '',
                      item.diff < 0 ? 'text-green-400' : '',
                    ]"
                  >
                    <div v-if="item.state">
                      {{ -item.diff }} {{ item | unit }}
                    </div>
                    <div v-if="!item.state">
                      {{ item | friendlyUnity }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  name: 'BuyDetails',
  components: {},
  props: {
    active: Boolean,
    buy: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    shortages(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff > 0 && quantity !== max,
      );
    },
    changes(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff < 0 || quantity === max,
      );
    },
    parsedMetadata(items) {
      return items.filter(
        ({ diff, quantity }) => (diff && diff !== 0)
          || (typeof diff === 'undefined' && quantity !== 0),
      );
    },
  },
};
</script>
