<template>
  <card-component>
    <div class="level is-mobile">
      <div class="level-item">
        <div class="is-widget-label">
          <h3 class="subtitle is-spaced">
            {{ label }}
          </h3>
          <b-skeleton
            width="40%"
            :animated="true"
            v-if="isFetching"
          ></b-skeleton>
          <h1 class="title" v-if="!isFetching">
            <b-tooltip
              :type="type"
              v-if="text"
              :label="text"
              position="is-bottom"
            >
              <growing-number
                :value="roundedNumber"
                :prefix="prefix"
                :suffix="suffix"
              />
            </b-tooltip>
          </h1>
        </div>
      </div>
      <div v-if="icon" class="level-item has-widget-icon">
        <div class="is-widget-icon">
          <b-icon :icon="icon" size="is-large" :type="type" />
        </div>

        <b-dropdown
          aria-role="list"
          v-if="monthly.length && monthlyKey"
          position="is-bottom-left"
        >
          <template #trigger>
            <b-icon icon="chevron-down" />
          </template>

          <b-dropdown-item
            v-for="item in monthly.slice(0, 5)"
            :key="item._id"
            aria-role="listitem"
          >
            <span class="is-pulled-right">{{
              item[monthlyKey] | currency
            }}</span>
            {{ item.month }}/{{ item.year }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </card-component>
</template>

<script>
import CardComponent from './CardComponent.vue';
import GrowingNumber from './GrowingNumber.vue';
import { round } from '../util/helpers';

export default {
  name: 'CardWidget',
  components: { GrowingNumber, CardComponent },
  props: {
    icon: {
      type: String,
      default: null,
    },
    number: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      default: null,
    },
    monthly: {
      type: Array,
      default: () => [],
    },
    monthlyKey: {
      type: String,
      default: '',
    },
  },
  watch: {
    monthly() {
      return this;
    },
  },
  computed: {
    roundedNumber() {
      return round(this.number);
    },
  },
};
</script>
