<template>
  <div>
    <Section>
      <div class="container px-2 mx-auto md:px-12">
        <div class="flex flex-col my-2" v-if="products.length">
          <div class="p-4 border-2 border-red-300 rounded-lg">
            <p>
              {{
                products.filter(({ title, catalog }) => !title && catalog)
                  .length
              }}
              productos sin titulo
            </p>
            <p>
              {{
                products.filter(({ subtitle, catalog }) => !subtitle && catalog)
                  .length
              }}
              productos sin subtitulo
            </p>
            <p>
              {{
                products.filter(
                  ({ options, catalog }) =>
                    options && !options.brand && catalog,
                ).length
              }}
              productos sin marca
            </p>
          </div>
        </div>
        <b-field>
          <b-input
            icon="magnify"
            type="search"
            placeholder="Buscar"
            @input="handleSearchProducts"
            :loading="isFiltering"
          ></b-input>
        </b-field>

        <div class="flex flex-col">
          <div
            v-for="product of foundedProducts"
            :key="product._id"
            class="p-2 my-1 border-2 border-purple-300 rounded-md"
          >
            <div class="grid grid-cols-2 gap-2 justify-items-end">
              <div class="md:flex md:flex-row justify-self-start">
                <p class="p-1 m-1 text-xs border-2 rounded-lg">
                  {{ product.name }}
                </p>
                <p class="p-1 m-1 text-xs border-2 rounded-lg">
                  {{ product.title }}
                </p>
                <p class="p-1 m-1 text-xs bg-purple-200 border-2 rounded-lg">
                  {{ product.subtitle }}
                </p>
              </div>

              <div class="md:flex md:flex-row">
                <p
                  v-if="product.catalog"
                  class="m-1 text-white bg-green-500 border-2 rounded-lg"
                >
                  <b-icon icon="bookmark-check-outline" custom-size="small" />
                </p>
                <p
                  v-if="!product.catalog"
                  class="m-1 text-white bg-red-500 border-2 rounded-lg"
                >
                  <b-icon icon="block-helper" custom-size="small" />
                </p>
                <p class="p-1 m-1 text-xs bg-yellow-200 border-2 rounded-lg">
                  {{ product.category.name }}
                </p>
                <p class="p-1 m-1 text-xs bg-purple-200 border-2 rounded-lg">
                  {{ product.options && product.options.brand }}
                </p>
                <!-- <b-button
                  size="is-small"
                  rounded
                  @click="handleEditProduct(product)"
                >
                  <b-icon icon="dots-vertical" custom-size="small" />
                </b-button> -->
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="typing" class="flex flex-wrap items-stretch -mx-1 lg:-mx-4">
          <div
            v-for="i in Math.floor(Math.random() * (8 - 5 + 1) + 5)"
            :key="i"
            class="w-full px-1 my-1 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/4"
          >
            <div class="media-content">
              <div class="content">
                <p>
                  <b-skeleton active height="40px"></b-skeleton>
                  <b-skeleton height="100px"></b-skeleton>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!typing" class="flex flex-wrap items-stretch -mx-1 lg:-mx-4">
          <Product
            v-for="product in foundedProducts"
            :key="product._id"
            :product="product"
            @add="handleAddItemToCart"
          />
        </div> -->

        <div
          v-if="!typing && !foundedProducts.length && productToSearch.length"
        >
          <p>No encontramos ningún producto con ese nombre</p>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
// import CatalogTable from '../components/Catalog/CatalogTable.vue';
import ApiService from '../services/ApiService';
import { columns } from '../ui/config/tables/products';
// import Carrousel from '../components/Catalog/Carrousel.vue';
// import Product from '../components/Catalog/Product.vue';
import Section from '../components/Section.vue';

const { ProductService } = ApiService;

export default {
  name: 'Catalog',
  components: {
    Section,
    // CatalogTable,
    // Carrousel,
    // Product,
  },
  data() {
    return {
      productToSearch: '',
      products: [],
      selected: null,
      columns,
      items: [],
      newProductModalIsActive: false,
      isFiltering: false,
      debounce: null,
      typing: false,
      notificationToDraft: false,
      hasActions: true,
    };
  },
  computed: {
    foundedProducts() {
      if (this.productToSearch === '') return this.products;
      const products = this.filter();

      return products;
    },
    currentCart() {
      return { ...(this.$store.getters['Cart/currentCart'] || { items: [] }) };
    },
  },
  async mounted() {
    this.$store.dispatch('Ui/showBars');
    await this.fetchProducts();
  },
  methods: {
    handleSearchProducts(value) {
      this.typing = true;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.productToSearch = value;
      }, 500);
      // this.typing = false;
    },
    filter() {
      return this.products.filter(
        ({ name }) => name.toLowerCase().indexOf(this.productToSearch.toLowerCase()) >= 0,
      );
    },
    async fetchProducts() {
      const { data: pagination } = await ProductService.find({
        status: true,
      });
      this.products = pagination.docs;
    },
    handleEditProduct() {},
    handleAddItemToCart(item) {
      this.$store.dispatch('Cart/push', item);
      this.$buefy.notification.open({
        queue: false,
        message: 'Producto agregado correctamente',
        position: 'is-bottom',
        type: 'is-success',
        hasIcon: true,
      });
    },
    handlePreCartOrder() {
      this.$router.push('/draft');
    },
  },
};
</script>
