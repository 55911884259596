

const defaultData = {
  name: null,
  address: null,
  phone: null,
  segment: null,
  notes: null,
};

module.exports = {
  defaultData,
};
