<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-field label="Nombre">
            <b-input
              data-cy="category-name"
              v-model="category.name"
              type="text"
            ></b-input>
          </b-field>
          <b-field label="Icono">
            <b-input
              data-cy="category-icon"
              ref="iconInput"
              v-model="category.icon"
            ></b-input>
          </b-field>
          <b-field label="Slug">
            <b-input
              data-cy="category-slug"
              v-model="category.slug"
              type="text"
              placeholder="slug"
            ></b-input>
          </b-field>
          <b-field label="Sección" v-if="sections.length">
            <b-select
              data-cy="category-section"
              v-model="category.section"
              placeholder="Seleccionar una sección:"
              expanded
            >
              <option
                v-for="option in sections.map(
                  ({ attributes: { name } }) => name,
                )"
                :value="option"
                :key="option"
              >
                {{ option | capitalize }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="create-category"
            type="is-success"
            :onClickFn="handleAddCategory"
            >Aceptar</Button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import ApiService from '../../services/ApiService';
import { schema, defaultData } from '../../schemas/category';
import { validatePayload, disconnectedCopy } from '../../util/helpers';

const { CategoryService } = ApiService;

export default {
  name: 'NewCategory',
  props: {
    active: Boolean,
    sections: Array,
  },
  data() {
    return {
      category: disconnectedCopy(defaultData),
    };
  },
  watch: {
    'category.name': {
      handler() {
        this.category.slug = this.category.name && this.category.name.toLowerCase().replace(/\s/g, '-');
      },
      immediate: true,
    },
  },
  computed: {},
  methods: {
    async handleAddCategory() {
      const payload = this.category;
      if (!validatePayload(payload, schema)) return;
      const { error, data: category } = await CategoryService.create(payload);

      if (error) {
        console.error(error.data.code);
        return;
      }

      this.$emit('created', category);
      this.category = disconnectedCopy(defaultData);
    },
  },
};
</script>
