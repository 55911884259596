const defaultData = {
  name: null,
  username: null,
  email: null,
  phone: null,
  wholesaler: false,
};

module.exports = {
  defaultData,
};
