var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-modal',{attrs:{"active":_vm.active,"width":640,"can-cancel":['escape', 'outside']},on:{"close":function($event){return _vm.$emit('dismiss')}}},[(_vm.account)?_c('div',{staticClass:"p-4"},[_c('section',{staticClass:"border-2 border-indigo-400 rounded-lg modal-card-body"},[_c('div',{staticClass:"flex items-center justify-between mb-2"},[_c('span',{staticClass:"text-xl font-bold"},[_vm._v("Movimientos de la cuenta")])]),(_vm.isLoading)?_c('div',{staticClass:"flex items-center justify-center mt-8"},[_c('svg',{staticClass:"w-6 h-6 animate-spin",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e(),(
            _vm.account &&
            _vm.account.transactions &&
            _vm.account.transactions.length &&
            !_vm.isLoading
          )?_c('div',{},[_c('ul',{staticClass:"mt-4 divide-y divide-gray-200 dark:divide-gray-700",attrs:{"role":"list"}},_vm._l((_vm.account.transactions),function(transaction){return _c('li',{key:transaction._id,staticClass:"py-3 sm:py-4"},[(transaction.doc === 'orders')?_c('div',{staticClass:"flex flex-row justify-between",on:{"click":function($event){return _vm.handleShowOrderDetails(transaction)}}},[_c('div',{staticClass:"flex flex-col"},[_c('p',{staticClass:"text-xs text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm._f("shortDate")(transaction.date))+" ")]),_c('p',{staticClass:"text-sm font-medium text-gray-900 truncate"},[_vm._v(" Pedido "+_vm._s(transaction.number)+" ")])]),_c('div',{staticClass:"flex flex-row items-center"},[_c('StateComponent',{staticClass:"py-2",attrs:{"element":transaction}}),(_vm.isLoadingOrder && _vm.loadingOrder === transaction._id)?_c('div',{},[_c('svg',{staticClass:"w-6 h-6 animate-spin",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor","stroke-width":"2"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"}})])]):_vm._e()],1),_c('div',{staticClass:"flex flex-col items-end text-base font-semibold text-red-500",class:[]},[_c('p',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(transaction.total))+" ")]),_c('p',{staticClass:"text-xs font-medium",class:[
                      transaction.current >= 0
                        ? 'text-green-500'
                        : 'text-red-500',
                    ]},[_vm._v(" "+_vm._s(_vm._f("currency")(transaction.current))+" ")])])]):_vm._e(),(transaction.doc === 'pays')?_c('div',{staticClass:"flex items-center space-x-4"},[_c('div',{staticClass:"flex-1 min-w-0"},[_c('p',{staticClass:"text-xs text-gray-900 truncate"},[_vm._v(" "+_vm._s(_vm._f("shortDate")(transaction.date))+" ")]),_c('p',{staticClass:"text-sm font-medium text-gray-900 truncate"},[_vm._v(" Recibiste un pago ")])]),_c('div',{staticClass:"flex flex-col items-end text-base font-semibold text-green-500",class:[]},[_c('p',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(transaction.total))+" ")]),_c('p',{staticClass:"text-xs font-medium",class:[
                      transaction.current >= 0
                        ? 'text-green-500'
                        : 'text-red-500',
                    ]},[_vm._v(" "+_vm._s(_vm._f("currency")(transaction.current))+" ")])])]):_vm._e()])}),0)]):_vm._e()])]):_vm._e()]),_c('OrderDetails',{attrs:{"element":_vm.orderSelected,"active":_vm.isOrderDetailsOpen},on:{"dismiss":_vm.handleDismissOrderDetails}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }