<template>
  <div v-if="selected.length" class="fixed px-4 py-2 bg-white border-2 border-purple-500 rounded-full bottom-5 right-3">
    <div class="flex flex-row-reverse gap-2">
      <div class="flex items-center justify-center px-2 bg-purple-400 border-2 border-purple-500 rounded-full">
        <span class="font-bold text-white">
          {{ selected.length }}
        </span>
      </div>
      <div v-if="selected.length && operationPermission">
        <b-button type="is-danger is-light" @click="handleDeselectAll">
          <b-icon icon="checkbox-blank-off-outline"></b-icon></b-button>
      </div>

      <div v-if="selected.length && operationPermission && actions.prepare">
        <b-button type="is-success is-light" @click="handlePrepareSelected">
          <b-icon icon="package-variant-closed" type="is-success" /></b-button>
      </div>

      <ExportOrders v-if="selected.length && operationPermission && actions.export" :orders="selected"></ExportOrders>
      <PrintDeliveryRoute v-if="selected.length && operationPermission" :elements="selected">
      </PrintDeliveryRoute>
      <ExportDeliveryRoute v-if="selected.length && operationPermission && actions.deliveryRoute" :elements="selected">
      </ExportDeliveryRoute>
      <!-- <BulkToRoadmap
        v-if="selected.length && operationPermission && actions.roadmap"
        :dispatchs="selected"
      ></BulkToRoadmap> -->
      <PrintInvoices v-if="selected.length && operationPermission && actions.invoice"
        :orders="selected.filter((element) => isOrder(element))"></PrintInvoices>
      <PrintConsignments v-if="selected.length && operationPermission && actions.consignment"
        :orders="selected.filter((element) => isOrder(element))"></PrintConsignments>
      <PrintSplittedCategories v-if="selected.length && operationPermission && actions.splittedCategories"
        :orders="selected.filter((element) => isOrder(element))"></PrintSplittedCategories>
    </div>
  </div>
</template>

<script>
import ExportOrders from './ExportOrders.vue';
// import BulkToRoadmap from './BulkToRoadmap.vue';
import ExportDeliveryRoute from './ExportDeliveryRoute.vue';
import PrintInvoices from './PrintInvoices.vue';
import { ORDER_STATES } from '../../util/constants';
import useOrderStore from '../../store/order';
import { isOrder } from '../../util/helpers';
import PrintConsignments from './PrintConsignments.vue';
import PrintSplittedCategories from './PrintSplittedCategories.vue';
import PrintDeliveryRoute from './PrintDeliveryRoute2.vue';

const { CONFIRMED } = ORDER_STATES;
export default {
  name: 'OrderActions',
  props: {
    selected: Array,
    operationPermission: { type: Boolean, default: false },
    actions: {
      type: Object,
      default: () => ({
        splittedCategories: false,
        roadmap: false,
        invoice: true,
        consignment: true,
        deliveryRoute: true,
        export: true,
        prepare: true,
      }),
    },
  },
  components: {
    ExportOrders,
    ExportDeliveryRoute,
    PrintInvoices,
    PrintConsignments,
    PrintSplittedCategories,
    PrintDeliveryRoute,
    // BulkToRoadmap,
  },
  data() {
    return {
      orderStore: useOrderStore(),
      vertical: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    isOrder,
    async handlePrepareSelected() {
      this.$buefy.dialog.confirm({
        title: 'Preparar pedidos',
        message:
          '¿Estás seguro de <b>preparar</b> todos los pedidos seleccionados?',
        confirmText: 'Preparar',
        cancelText: 'Emm, no',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: async () => {
          // eslint-disable-next-line no-restricted-syntax
          for (const order of this.selected.filter(
            ({ state }) => state === CONFIRMED,
          )) {
            this.$buefy.toast.open({
              duration: 3000,
              message: `Preparando pedido ${order.number}`,
              position: 'is-bottom',
              type: 'is-warning',
              queue: false,
            });
            const { items } = order.cart;

            const payload = items.map(
              ({
                number,
                product: { _id, name, unit },
                quantity,
                isWholesaler,
              }) => ({
                _id,
                number,
                name,
                unit,
                quantity,
                max: +`${quantity}`,
                state: 'pending',
                isWholesaler,
              }),
            );
            // eslint-disable-next-line no-await-in-loop, no-underscore-dangle
            await this.orderStore.prepare(order._id, payload);
          }
        },
      });
    },

    handleDeselectAll() {
      this.orderStore.deselectAll();
      this.$emit('deselectAll');
    },
  },
  mounted() { },
};
</script>
