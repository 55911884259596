<template>
  <div v-if="element.date" class="text-gray-500">
    {{ element.date | shortDate }}
  </div>
</template>

<script>
export default {
  name: 'DateComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
};
</script>
