<template>
  <div
    v-if="selected.length"
    class="fixed px-4 py-2 bg-white border-2 border-purple-500 rounded-full  bottom-5 right-3"
  >
    <div class="flex flex-row-reverse gap-2">
      <div
        class="flex items-center justify-center px-2 bg-purple-400 border-2 border-purple-500 rounded-full "
      >
        <span class="font-bold text-white">
          {{ selected.length }}
        </span>
      </div>
      <div v-if="selected.length && operationPermission">
        <b-button type="is-danger is-light" @click="handleDeselectAll">
          <b-icon icon="checkbox-blank-off-outline"></b-icon
        ></b-button>
      </div>

      <ExportBuys :buys="selected" />
    </div>
  </div>
</template>

<script>
import ExportBuys from './ExportBuys.vue';
import useBuyStore from '../../store/buy';

export default {
  name: 'BuyActions',
  props: {
    selected: Array,
    operationPermission: { type: Boolean, default: false },
  },
  components: {
    ExportBuys,
  },
  data() {
    return {
      buyStore: useBuyStore(),
      vertical: true,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleSendUpdateNotification(id) {
      return this.$emit('update', id);
    },
    handleDeselectAll() {
      this.buyStore.deselectAll();
      this.$emit('deselectAll');
    },
  },
  mounted() {},
};
</script>
