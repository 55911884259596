<template>
  <div>
    <div class="card" v-if="validatingKey">
      <div class="card-content">
        <p class="title">Validando...</p>
      </div>
    </div>
    <div class="card" v-if="!validatingKey">
      <div class="card-content">
        <b-field label="Numero" :type="showError ? 'is-danger' : ''">
          <b-input
            v-model="phone"
            type="tel"
            maxlength="10"
            pattern="[0-9]+"
          ></b-input>
          <p class="control">
            <Button
              type="is-primary"
              :onClickFn="handleGetOtp"
              :disabled="otpGenerationBlocked"
              >Enviar</Button
            >
          </p>
        </b-field>
        <p
          v-if="!otpSended"
          class="has-text-primary is-clickable"
          @click="otpSended = true"
        >
          Tengo una clave
        </p>
        <b-field
          v-if="otpSended"
          label="Clave"
          message="Es la clave que recibiste por WhatsApp"
        >
          <b-input
            size="is-large"
            v-model="key"
            type="text"
            :maxlength="5"
            @keydown.enter.native="handleLogin"
          ></b-input>
        </b-field>
        <div v-if="otpSended" class="buttons is-pulled-right">
          <b-button
            :loading="isLoading"
            @click="handleGetToken"
            type="is-dark"
            :disabled="key.length !== 5 || phone.length < 9"
            >Ingresar</b-button
          >
        </div>
        <b-button
          class="mt-4 is-pulled-right"
          size="is-small"
          type="is-light"
          clickable
          @click="handleReturnLogin"
          >Ingresar como agente de venta</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '../services/ApiService';

const { AuthService } = ApiService;

export default {
  name: 'Pair',
  data() {
    return {
      phone: '',
      key: '',
      isLoading: false,
      otpSended: false,
      showError: false,
      validatingKey: false,
      otpGenerationBlocked: false,
      selectedCountry: {
        code: '+54',
        name: 'AR',
      },
    };
  },
  methods: {
    handleReturnLogin() {
      this.$router.push('/login');
    },
    async handleGetOtp() {
      const { phone: rawPhone } = this;
      const phone = `${this.selectedCountry.code}${rawPhone}`;
      if (!phone) {
        this.otpSended = false;
        this.showError = true;
        return;
      }
      const payload = {
        phone,
      };
      const {
        data: { token, customer },
      } = await AuthService.getOtp(payload);

      if (token && customer) {
        this.handleSetCustomerAuth({ token, customer });
      }

      this.otpGenerationBlocked = true;
      setTimeout(() => {
        this.otpGenerationBlocked = false;
      }, 60000);
      this.showError = false;
      this.otpSended = true;
    },
    async handleSetCustomerAuth({ customer, token }) {
      this.validatingKey = true;
      await this.$store
        .dispatch('Auth/setCustomerAuth', { customer, token })
        .then(() => {
          this.$router.push('/catalog');
          this.$store.dispatch('Ui/showBars');
        })
        .catch(() => {
          this.$store.dispatch('Ui/errorMessage', {
            message: 'Error en la validación. Ingresa tu numero nuevamente',
          });
          this.$router.push('/pair');
        })
        .finally(() => {
          this.validatingKey = false;
        });
    },
    async handleGetToken() {
      this.validatingKey = true;
      const { key } = this;
      await this.$store
        .dispatch('Auth/authPair', key)
        .then(() => {
          this.$router.push('/catalog');
          this.$store.dispatch('Ui/showBars');
        })
        .catch(() => {
          this.$store.dispatch('Ui/errorMessage', {
            message: 'Error en la validación. Ingresa tu numero nuevamente',
          });
          this.$router.push('/pair');
        })
        .finally(() => {
          this.validatingKey = false;
        });
    },
  },
  watch: {
    key() {
      if (!this.key) return;
      this.key = this.key.toUpperCase();
    },
  },
  computed: {
    message() {
      if (this.otpGenerationBlocked) return 'Podrás reintentar en 30 segundos';
      return '';
    },
  },
  async mounted() {
    const { key } = this.$route.params;
    if (!key) return;
    this.key = key;
    await this.handleGetToken();
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card {
  max-width: 350px !important;
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
</style>
