<template>
  <div>
    <Section>
      <div
        class="p-5 mb-4 bg-white shadow-sm sm:rounded-xl lg:px-8"
        v-if="hasShortage"
        @click.stop="handleGoToInvetory"
      >
        <p class="text-sm font-bold text-center text-indigo-600">
          Ups, tenemos faltantes de tus productos. Pronto vas a estar recibiendo
          nuestro pedido
        </p>
      </div>
      <Widgets>
        <!-- <Widget
          title="Pedidos pendientes de entrega"
          :value="pending.length"
          cta="/provider/orders"
        ></Widget> -->
        <Widget
          :modal="true"
          :onClickFunction="handleOpenAgreement"
          :title="
            agreement >= 0 ? 'Activate te debe' : 'Activate tiene a favor'
          "
          :value="Math.abs(agreement)"
          prefix="$"
        ></Widget>
      </Widgets>
      <Agreement
        :active="agreementIsActive"
        :statistics="statistics"
        @dismiss="agreementIsActive = false"
      ></Agreement>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import ApiService from '../../services/ApiService';
import Widgets from '../../components/Widgets/Widgets.vue';
import Widget from '../../components/Widgets/Widget.vue';
import { round } from '../../util/helpers';
import useBuyStore from '../../store/buy';
import Agreement from '../../components/Widgets/Agreement.vue';
import Section from '../../components/Section.vue';

const { ProviderService, StatisticsService } = ApiService;

export default {
  name: 'ProviderDashboard',
  components: {
    Widgets,
    Widget,
    Agreement,
    Section,
  },
  data() {
    return {
      buyStore: useBuyStore(),
      buys: [],
      provider: null,
      loading: false,
      statistics: {},
      fetched: false,
      products: [],
      agreementIsActive: false,
    };
  },
  computed: {
    ...mapState(useBuyStore, ['pending']),
    agreement() {
      return this.account - this.statistics.inventory + this.statistics.pending;
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    hasShortage() {
      return this.products.some(
        (product) => product.inventory[0].theoretical < 0,
      );
    },
    account() {
      return this.statistics.debtBalance;
    },
  },
  async mounted() {
    await this.fetchProvider();
    this.fetched = false;
    this.buyStore.findPending({ provider: this.provider._id });
    this.fetchStatistics();
    this.fetched = true;
  },
  methods: {
    handleOpenAgreement() {
      this.agreementIsActive = true;
    },

    async fetchProvider() {
      const { data: provider } = await ProviderService.findOne('own');
      this.provider = provider;
      return provider;
    },
    async fetchStatistics() {
      const { data: statistics } = await StatisticsService.providerBalance(
        // eslint-disable-next-line no-underscore-dangle
        this.provider._id,
      );
      this.statistics = statistics;
    },

    dueBalance(partner) {
      return round(partner.dueBalance);
    },
    handleGoToInvetory() {
      this.$router.push('/provider/inventory');
    },
  },
};
</script>
