
<template>
  <div>
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="providers"
      :striped="true"
      :loading="loading"
      :narrowed="true"
      @select="handleSelectProvider"
      :selected.sync="selected"
      :row-class="(row, index) => row.status === false && 'is-danger'"
    >
      <b-table-column
        field="number"
        label="#"
        centered
        sortable
        numeric
        :searchable="advancedTable"
        v-slot="props"
      >
        {{ props.row.number }}
      </b-table-column>
      <b-table-column
        field="name"
        label="Nombre"
        centered
        sortable
        :searchable="advancedTable"
        v-slot="props"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        field="address.street"
        label="Calle"
        width="30%"
        centered
        sortable
        :searchable="advancedTable"
        v-slot="props"
      >
        {{ props.row.address.street }}
      </b-table-column>
      <b-table-column
        field="address.city"
        label="Ciudad"
        centered
        sortable
        :searchable="advancedTable"
        v-slot="props"
      >
        {{ props.row.address.street }}
      </b-table-column>
      <b-table-column width="20%" centered>
        <template v-slot:header="">
          <b-icon icon="account-supervisor-circle" />
        </template>
        <template v-slot="props">
          <b-tooltip
            label="Cuenta corriente"
            type="is-primary is-light"
            v-if="props.row.account"
          >
            <b-tag class="ml-2" type="is-primary is-light">
              <b-icon icon="account-check" />
            </b-tag>
          </b-tooltip>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'ProviderTable',
  props: {
    providers: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      selected: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleSelectProvider(partner) {
      this.$emit('select', partner);
    },
  },
  mounted() {},
};
</script>

<style>
tr.is-danger {
  background: #ffffff;
  color: #f14668;
}
</style>
