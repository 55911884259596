<template>
  <div class="mt-4">
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="creditors"
      :striped="true"
      :narrowed="true"
      :loading="loading"
      :show-detail-icon="false"
      detailed
    >
      <b-table-column
        field="third.name"
        label="Tercero"
        centered
        sortable
        v-slot="props"
      >
        {{ props.row.third.name }}
      </b-table-column>
      <b-table-column
        field="ingress"
        label="Ingresos"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.ingress | rounded }}
      </b-table-column>
      <b-table-column
        field="egress"
        label="Egresos"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.egress | rounded }}
      </b-table-column>
      <b-table-column
        field="total"
        label="Total"
        centered
        sortable
        v-slot="props"
      >
        ${{ (props.row.ingress - props.row.egress) | rounded }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'CreditorsTable',
  props: {
    creditors: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
  mounted() {},
};
</script>
