<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b-field class="is-pulled-right">
          <b-input placeholder="Nombre" v-model="newWithdrawal.name"></b-input>
          <p class="control">
            <Button
              data-cy="create-withdrawal"
              type="is-primary"
              :onClickFn="handleAddWithdrawal"
              >Agregar</Button
            >
          </p>
        </b-field>
        <p class="title is-4">Metodos de retiro</p>
      </div>
    </div>

    <div class="card" v-if="withdrawals.length">
      <div class="card-content">
        <div class="grid grid-cols-1 border-none divide-y-0">
          <div v-for="withdrawal of withdrawals" :key="withdrawal._id">
            <p class="is-pulled-right title is-6">
              <span class="inline-block mr-4 align-middle"></span>

              <b-button
                size="is-small"
                icon-right="pencil"
                @click="handleUpdateWithdrawal(withdrawal)"
              >
              </b-button>
              <button
                @click="handleRemoveWithdrawal(withdrawal._id)"
                class="ml-4 delete"
              ></button>
            </p>
            <p class="title is-6">
              {{ withdrawal.name }}

              <b-tag type="is-primary is-light">
                AP PRO ${{ withdrawal.fee }}
              </b-tag>

              <b-tag class="ml-2" v-if="withdrawal.shortage" type="is-danger">
                Faltantes
              </b-tag>
            </p>
          </div>
        </div>
      </div>
    </div>
    <UpdateWithdrawal
      :active="updateWithdrawalModalIsActive"
      :withdrawal="withdrawalToUpdate"
      @dismiss="updateWithdrawalModalIsActive = false"
    />
  </div>
</template>

<script>
import { schema, defaultData } from '../../schemas/withdrawal';
import { validatePayload, disconnectedCopy } from '../../util/helpers';
import ApiService from '../../services/ApiService';
import UpdateWithdrawal from '../Withdrawal/UpdateWithdrawal2.vue';

const { WithdrawalService } = ApiService;

export default {
  name: 'Withdrawal',
  components: {
    UpdateWithdrawal,
  },
  data() {
    return {
      withdrawals: [],
      newWithdrawal: disconnectedCopy(defaultData),
      updateWithdrawalModalIsActive: false,
      withdrawalToUpdate: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchWithdrawals();
  },
  methods: {
    onlyAdmin(withdrawal) {
      return withdrawal.roles[0] === 'ADMIN' && withdrawal.roles.length === 1;
    },
    async fetchWithdrawals() {
      const { data: withdrawals } = await WithdrawalService.find({
        status: true,
      });
      this.withdrawals = withdrawals;
    },
    parseContributesTagType(contributes) {
      return contributes ? 'is-success is-light' : 'is-danger is-light';
    },
    async handleAddWithdrawal() {
      const payload = this.newWithdrawal;
      if (!validatePayload(payload, schema)) return;
      await WithdrawalService.create(payload);
      await this.fetchWithdrawals();
      this.newWithdrawal = disconnectedCopy(defaultData);
    },
    async handleUpdateWithdrawal(withdrawal) {
      this.withdrawalToUpdate = withdrawal;
      this.updateWithdrawalModalIsActive = true;
    },
    async handleRemoveWithdrawal(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar método de entrega',
        message:
          '¿Estás seguro de <b>eliminar</b> este método de entrega? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await WithdrawalService.disable(id);
          await this.fetchWithdrawals();
        },
      });
    },
  },
};
</script>
