<template>
  <div class="card" v-if="provider">
    <div class="card-content">
      <button
        class="is-pulled-right delete is-small"
        v-if="canRemove"
        @click="handleRemoveProvider"
      ></button>
      <b class="is-pulled-right"></b>
      <p class="title is-5">{{ provider.name }}</p>
      <p v-if="provider.address">
        <b>Dirección:</b>
        {{ provider.address.street }},
        {{ provider.address.city }}
      </p>
      <p v-if="provider.phone">
        <b>Telefono:</b>
        {{ provider.phone }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProviderInfo',
  props: {
    provider: Object,
    canRemove: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    handleRemoveProvider() {
      this.$emit('removeProvider');
    },
  },
  mounted() {},
};
</script>
