<template>
  <div v-if="product.mixed">
    <b-table :data="product.components" :narrowed="true" :striped="true">
      <b-table-column
        field="product.name"
        label="Componentes"
        :centered="true"
        :numeric="true"
        v-slot="props"
      >
        {{ props.row.product.name }}
      </b-table-column>
      <b-table-column
        field="product.unitCost"
        label="Costo unitario"
        :centered="true"
        :numeric="true"
        v-slot="props"
      >
        $ {{ props.row.product.unitCost }}
      </b-table-column>
      <b-table-column
        field="quantity"
        label="Cantidad"
        :centered="true"
        :numeric="true"
        v-slot="props"
      >
        {{ props.row.quantity }}
        {{
          product.unit === "UNITY"
            ? props.row.product.unit === "UNITY"
              ? "unid"
              : "grs"
            : "%"
        }}
      </b-table-column>
      <b-table-column
        label="Costo %"
        :centered="true"
        :numeric="true"
        v-slot="props"
      >
        $
        {{ calculateMixedUnitCost(props.row, product) | rounded }}
      </b-table-column>

      <b-table-column :centered="true">
        <template v-slot="props">
          <button
            class="is-pulled-right delete is-small"
            @click="handleRemoveComponent(props.row)"
          ></button>
        </template>
      </b-table-column>
      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered"></div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">
            {{ isPercentage ? `${percentage} %` : "" }}
          </div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ mixedUnitCost | rounded }}</div>
        </th>
      </template>
    </b-table>
    <b-field>
      <div
        class="mt-2 columns is-centered"
        v-if="!isPercentage || (isPercentage && percentage !== 100)"
      >
        <div class="column is-two-fifths">
          <ProductLookup
            label="Buscar un componente"
            :product="component.product"
            :unitFilter="computeUnit"
            @select="handleSelectComponent"
          />
        </div>
        <div class="column is-two-fifths">
          <b-field
            :label="
              product.unit === 'UNITY'
                ? component.product && component.product.unit !== 'UNITY'
                  ? 'Grs'
                  : 'Unidades'
                : '%'
            "
          >
            <b-numberinput
              ref="componentQuantityInput"
              v-model="component.quantity"
              :controls="false"
              :min="0"
              step="0.001"
              @focus="$event.target.select()"
            ></b-numberinput>
          </b-field>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Agregar">
            <b-button
              type="is-success is-light"
              icon-right="plus"
              @click="handleAddComponent()"
            />
          </b-field>
        </div>
      </div>
    </b-field>
  </div>
</template>

<script>
import { sum, calculateMixedUnitCost } from '../../util/helpers';
import ProductLookup from '../Cart/ProductLookup.vue';

export default {
  name: 'Components',
  props: {
    product: Object,
  },
  components: {
    ProductLookup,
  },
  data() {
    return {
      component: {
        product: null,
        quantity: 0,
      },
    };
  },
  watch: {
    'product.unit': function () {
      this.component = {
        product: null,
        quantity: 0,
      };
    },
  },
  computed: {
    computeUnit() {
      if (!this.product) return null;
      return this.product.unit === 'UNITY' ? null : 'WEIGHT';
    },
    percentage() {
      if (!this.product.components.length) return 0;
      return sum(this.product.components, 'quantity');
    },
    isPercentage() {
      return this.product.unit !== 'UNITY';
    },
    mixedUnitCost() {
      if (!this.product.mixed) return '';
      const { components } = this.product;

      return sum(components, null, calculateMixedUnitCost, this.product);
    },
  },
  methods: {
    calculateMixedUnitCost(product, mainProduct) {
      return calculateMixedUnitCost(product, mainProduct);
    },
    handleSelectComponent(product) {
      this.component.product = product;
      this.$nextTick(() => {
        this.$refs.componentQuantityInput.focus();
      });
    },
    handleAddComponent() {
      if (this.component.quantity <= 0) {
        this.$buefy.snackbar.open('La cantidad debe ser mayor a 0');
        return;
      }

      if (this.isPercentage) {
        if (this.percentage + this.component.quantity > 100) {
          this.$buefy.snackbar.open(
            'La suma de los componentes no puede ser mayor a 100%',
          );
          return;
        }
      }
      this.$emit('add', this.component);
      this.component = {
        product: null,
        quantity: 0,
      };
    },

    handleRemoveComponent(component) {
      this.$emit('remove', component);
    },
  },
};
</script>
