import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { AuditService } = ApiService;

const useAccountStore = defineStore('account', {
  state: () => ({
    accounts: [],
    selected: [],
    isLoading: {
      accounts: false,
    },
    isFetched: {
      accounts: false,
    },
    query: null,
    options: {
      page: 1,
      limit: 12,
    },
  }),
  actions: {
    async find(params = {}, { merge = false } = {}) {
      this.isLoading.accounts = true;
      const { data: result } = await AuditService.getCustomerAudit({
        transactionsLimit: 50,
        limit: 9999,
        query: this.query,
        ...params,
      });
      this.isLoading.accounts = false;
      this.isFetched.accounts = true;
      this.accounts = merge
        ? [...this.accounts, ...result.docs]
        : result.docs;

      this.accounts.sort((a, b) => a.balance - b.balance);
    },
    async performCustomerAudit(params = {}) {
      this.isLoading.accounts = true;
      await AuditService.performCustomerAudit({
        query: this.query,
        ...params,
      });
      await this.find(params);
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(accounts) {
      this.accounts = accounts;
    },
    select(account) {
      this.selected.push(account);
    },
    deselect(account) {
      const index = this.selected.findIndex(({ _id }) => _id === account._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(account) {
      return this.accounts.findIndex(({ _id }) => _id === account._id);
    },
    updatePositions(accounts, key) {
      this[key] = accounts;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      if (!element.customer) {
        // eslint-disable-next-line no-param-reassign
        element = {
          customer: element,
        };
      }

      const index = this[key].findIndex(
        (account) => account.customer._id === element.customer._id
          && account.partner._id === element.partner._id,
      );

      if (index !== -1) {
        const copy = [...this[key]];
        copy[index] = element;
        this[key] = copy;
      }
    },
    // async disable(id) {
    //   const { data: updated } = await AccountService.disable(id);
    //   this.updateElement(updated, 'accounts');
    //   return updated;
    // },
    // async enable(id) {
    //   const { data: updated } = await AccountService.enable(id);
    //   this.updateElement(updated, 'accounts');
    //   return updated;
    // },
    async update(params) {
      const { data: result } = await AuditService.getCustomerAudit({
        transactionsLimit: 50,
        limit: 9999,
        query: this.query,
        populatePartner: true,
        populateCustomer: true,
        ...params,
      });

      this.updateElement(result.docs[0], 'accounts');
    },
  },
});

export default useAccountStore;
