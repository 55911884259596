<template>
  <div>
    <div id="splittedCategories" style="display: none">
      <div v-for="addressId in Object.keys(splittedCategories)" :key="addressId" class="printable-item">
        <div class="flex flex-col">
          <div class="p-8">
            <div class="my-2">
              <div class="flex items-center justify-between font-bold">
                <p :style="{ fontSize: '32px' }"
                  class="flex items-center justify-start w-auto h-auto px-2 text-center justify-self-start">
                  #{{ splittedCategories[addressId].number }}
                </p>
                <p :style="{ fontSize: '48px' }"
                  class="flex items-center justify-center w-auto h-auto px-2 text-center border-2 border-black justify-self-center">
                  PROD
                </p>
                <p></p>
              </div>
              <div class="flex justify-between gap-8 space-x-4">
                <div>
                  <p v-if="!splittedCategories[addressId].delivery.isDropOff">
                    <span class="font-bold" :style="{ fontSize: '48px' }">
                      {{ splittedCategories[addressId].customer.name }}</span>
                  </p>
                  <p v-if="splittedCategories[addressId].delivery.isDropOff">
                    <span class="font-bold" :style="{ fontSize: '48px' }">NODO -
                      {{ splittedCategories[addressId].partner.username }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Fecha:</span>
                    {{ dayOfWeek(splittedCategories[addressId].date) }} {{ splittedCategories[addressId].date |
        shortDate }}
                  </p>
                  <p>
                    <span class="font-bold">Repartidor:</span>
                    {{ splittedCategories[addressId].driver.name }}
                  </p>
                  <p>
                    <span class="font-bold">Rango horario:</span>
                    {{ getRangeTime(splittedCategories[addressId]) }}
                  </p>
                  <p>
                    <span class="font-bold">Dirección:</span>
                    {{ shortAddress(splittedCategories[addressId].address) }}
                  </p>
                </div>
                <div class="flex gap-2">
                  <div class="flex flex-col gap-2 text-xs">
                    <p class="font-bold uppercase text-md">ORDEN EN CAMARA</p>
                    <div class="w-32 h-16 text-center border-2 border-black border-dashed">

                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div class="flex flex-col" v-for="category of Object.keys(
        groupItemsByCategory(
          Object.values(
            getSplittedPackagesFromOrders(
              splittedCategories[addressId].orders
            )
          )
        )
      ).sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))" :key="category">
              <p class="font-bold">{{ category | capitalize }}</p>

              <div class="ml-6" v-for="aggregate of groupItemsByCategory(
          Object.values(
            getSplittedPackagesFromOrders(
              splittedCategories[addressId].orders
            )
          )
        )[category].sort((a, b) =>
          a.product.name.toLowerCase() < b.product.name.toLowerCase()
            ? -1
            : 1
        )" :key="aggregate.product._id">
                <div class="flex items-center justify-between gap-2 text-xs">
                  <div class="flex items-center gap-2">
                    <p class="font-bold">{{ aggregate | friendlyUnity }}</p>
                    <p>
                      <span v-if="aggregate.product.title">{{ aggregate.product.title }} -
                        {{ aggregate.product.subtitle }}</span>
                      <span v-if="!aggregate.product.title">{{
        aggregate.product.name
      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="p-2 rounded-lg shadow">
              <div
                v-for="item of [...consignment.cart.items].sort((a, b) => (a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1))"
                :key="item.number"
                class="grid grid-cols-4 text-sm rounded-md"
                style="grid-template-columns: 56px 2fr 144px 72px"
              >
                <span>{{ item | friendlyUnity }}</span>
                <div>
                  {{ item.product.name }}
                </div>
              </div>

            </div> -->
          </div>
        </div>
      </div>
    </div>

    <b-button type="is-primary is-light" @click="print">
      <b-icon icon="carrot" type="is-primary" /></b-button>
  </div>
</template>

<script>
// import Consignment from './Consignment2.vue';
import { dayOfWeek, getRangeTime, shortAddress } from '../../util/helpers';

export default {
  name: 'PrintSplittedCategories',
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // Consignment,
  },
  data() {
    return {};
  },
  mounted() {
    // add a page break before each item except the first one
    const printableItems = document.querySelectorAll('.printable-item');
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < printableItems.length; i++) {
      printableItems[i].style.pageBreakBefore = 'always';
    }
  },
  computed: {
    ordersByDeliveryAddress() {
      const orders = this.orders
        .filter((order) => order.cart.items.some((item) => item.product.category.splitPackage))
        .reduce((acc, order) => {
          const addressId = order.delivery.address.label;

          if (!acc[addressId]) {
            acc[addressId] = {
              number: null,
              orders: [],
              partner: null,
              customer: null,
              date: null,
              address: null,
              delivery: null,
              driver: null,
            };
          }

          acc[addressId].number = order.number;
          acc[addressId].date = order.delivery.date || [];
          acc[addressId].partner = order.partner || [];
          acc[addressId].customer = order.customer || [];
          acc[addressId].address = order.delivery.address;
          acc[addressId].delivery = order.delivery;
          acc[addressId].driver = order.driver || [];
          acc[addressId].orders.push(order);

          return acc;
        }, {});

      return orders;
    },
    splittedCategories() {
      return this.ordersByDeliveryAddress;
    },
  },
  methods: {
    dayOfWeek,
    getRangeTime,
    groupItemsByCategory(items) {
      return items.reduce((acc, item) => {
        if (item.product.category.splitPackage) {
          acc[item.product.category.name] = acc[item.product.category.name] || [];
          acc[item.product.category.name].push(item);
        }
        return acc;
      }, {});
    },
    shortAddress,
    getSplittedPackagesFromOrders(orders) {
      return orders.reduce((acc, order) => {
        const splittedItems = order.cart.items.filter(
          ({ product }) => product.category.splitPackage,
        );

        splittedItems.forEach((item) => {
          const { product } = item;
          const { quantity } = item;

          acc[product._id] = acc[product._id] || {
            quantity: 0,
            amount: 0,
            product: null,
          };
          acc[product._id].quantity += quantity;
          acc[product._id].amount += 1;
          acc[product._id].product = product;
        }, {});

        return acc;
      }, {});
    },
    async print() {
      await this.$htmlToPaper('splittedCategories');
    },
  },
};
</script>

<style scoped>
@media print {
  .printable-item {
    page-break-inside: avoid;
  }

}
</style>
