<!-- eslint-disable max-len -->
<template>
  <div>
    <CardItem
      ref="cardItem"
      :element="product"
      :operationPermission="operationPermission"
      :isDraggable="isDraggable"
      :componentClass="[
        !product.status ? 'border-2 border-red-300 bg-red-50' : '',
      ]"
      :isCheckable="isCheckable"
    >
      <div class="flex justify-between text-sm">
        <p class="text-lg font-bold capitalize">{{ product.name }}</p>

        <b-tooltip multilined type="is-light" position="is-left">
          <template v-slot:content>
            <b-icon
              :icon="
                !product.catalog
                  ? 'close'
                  : product.status
                  ? 'check'
                  : 'alert-decagram'
              "
              :type="
                !product.catalog
                  ? 'is-danger'
                  : product.status
                  ? 'is-success'
                  : 'is-warning'
              "
            ></b-icon>
            <p>
              {{
                !product.catalog
                  ? ' El producto no está en el catálogo'
                  : product.status
                  ? ' El producto está en el catálogo'
                  : ' El producto está deshabilitado. No aparecerá en el catalogo'
              }}
            </p>
          </template>
          <p
            class="h-8 p-1 font-bold capitalize border-2 rounded-lg text-2xs"
            :class="[
              !product.catalog
                ? 'bg-red-100 border-red-500'
                : product.status
                ? 'bg-green-100 border-green-500'
                : 'bg-yellow-100 border-yellow-500',
            ]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
              />
            </svg>
          </p>
        </b-tooltip>
      </div>
      <div class="flex justify-start gap-2 py-1 pr-1 mt-2">
        <b-tag>{{ product.category.name }}</b-tag>
        <b-tag v-if="operationPermission">{{ product.provider.name }}</b-tag>
        <b-tag v-if="!hasStock(product)" type="is-danger">Sin Stock</b-tag>
        <b-tag v-if="product.splitPackage" type="is-warning">Bulto</b-tag>
        <b-tooltip
              type="is-primary"
              label="Gestión de stock automática"
            >
            <b-tag v-if="isAutomaticInventory(product)" :type="isAutomaticInventory(product) ? 'is-primary' : 'is-warning'">{{ isAutomaticInventory(product) ? 'A' : 'M' }}</b-tag>
            </b-tooltip>
       
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-lg"
        v-if="product.status"
      >
        <div class="flex-grow font-bold text-green-600 truncate">
          <span>Pago a productor (BI)</span>
        </div>
        <div class="font-bold tracking-wide text-green-600">
          <span>{{ product.taxBase | currency }}</span>
        </div>
      </div>
      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status"
      >
        <div class="flex-grow font-bold text-red-600 truncate">
          <span>Precio Final</span>
        </div>
        <div class="font-bold tracking-wide text-red-600">
          <span>{{ buyedItem.price | currency }}</span>
        </div>
      </div>
      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status"
      >
        <b-tooltip
          class="flex-grow"
          label="Stock en Activate + Pedidos pendientes de entrega"
        >
          <div class="font-bold text-purple-500 truncate">
            <span>Stock para la venta </span>

            <b-icon icon="help-circle-outline" custom-size="small" />
          </div>
        </b-tooltip>

        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].theoretical }}</span>
        </div>
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && operationPermission"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock en el depósito</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].real }}</span>
        </div>
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && operationPermission"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock pendiente</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].pending }}</span>
        </div>
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && !operationPermission"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock pendiente de entrega</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].pending }}</span>
        </div>
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && operationPermission"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock pre-aprobado</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].committed  || 0}}</span>
        </div>
      </div>

      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && !operationPermission"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock pre-aprobado</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].committed  || 0}}</span>
        </div>
      </div>



      <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status && !operationPermission"
      >
        <b-tooltip
          class="flex-grow"
          :label="`${product.inventory[0].theoretical} x ${currency(
            product.taxBase,
          )}`"
        >
          <div class="font-bold text-purple-500 truncate">
            <span>Stock valorizado </span>

            <b-icon icon="help-circle-outline" custom-size="small" />
          </div>
        </b-tooltip>

        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ priceStock >= 0 ? priceStock : 0 | currency }}</span>
        </div>
      </div>
      <!-- <div
        class="flex justify-start py-1 pr-1 mt-2 text-sm"
        v-if="product.status"
      >
        <div class="flex-grow font-bold text-purple-500 truncate">
          <span>Stock en el depósito</span>
        </div>
        <div class="font-bold tracking-wide text-purple-500">
          <span>{{ product.inventory[0].real }}</span>
        </div>
      </div> -->

      <div class="flex justify-between mt-4">
        <button
          @click="handleSelectProduct(product)"
          class="inline text-sm font-medium text-black align-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 border-2 rounded-lg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        <button
          v-if="operationPermission"
          @click="handleEditProduct(product)"
          class="inline text-sm font-medium text-black align-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 border-2 rounded-lg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
        <button
          v-if="(operationPermission && product.status) || providerPermission"
          @click="handleEditPrice(product)"
          class="inline text-sm font-medium text-black align-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 border-2 rounded-lg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
        <button
          v-if="operationPermission && product.status"
          @click="handleEditCatalog(product)"
          class="inline text-sm font-medium text-black align-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 border-2 rounded-lg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
            />
          </svg>
        </button>
        <button
          v-if="product.status"
          @click="handleEditStock(product)"
          class="inline p-1 text-sm font-medium text-black border-2 rounded-lg align-center"
        >
          Stock
        </button>
      </div>
    </CardItem>

    <UpdateForm
      v-if="productSelected && isEditOpen"
      :active="isEditOpen"
      :product="productSelected"
      @dismiss="isEditOpen = false"
    ></UpdateForm>
    <ProductCatalog
      v-if="productSelected && isCatalogOpen"
      :active="isCatalogOpen"
      :product="productSelected"
      @dismiss="isCatalogOpen = false"
    ></ProductCatalog>
    <ProductStock
      v-if="productSelected && isStockOpen"
      :active="isStockOpen"
      :product="productSelected"
      :operationPermission="operationPermission"
      @dismiss="isStockOpen = false"
    ></ProductStock>
    <ProductPrice
      v-if="productSelected && isPriceOpen"
      :active="isPriceOpen"
      :product="productSelected"
      :providerPermission="providerPermission"
      @dismiss="isPriceOpen = false"
    ></ProductPrice>
    <ProductDetails
      v-if="productSelected && isDetailsOpen"
      :active="isDetailsOpen"
      :product="productSelected"
      @dismiss="handleDismissDetails"
    ></ProductDetails>
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import ProductDetails from './ProductDetails.vue';
import UpdateForm from '../UpdateForm.vue';
import ProductStock from './ProductStock.vue';
import ProductPrice from './ProductPrice.vue';
import ProductCatalog from './ProductCatalog.vue';

import CardItem from '../../CardItem.vue';

import { currency, hasStock, isAutomaticInventory } from '../../../util/helpers';

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'ProductCard',
  props: {
    product: Object,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    providerPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
  },
  components: {
    ProductDetails,
    UpdateForm,
    CardItem,
    ProductStock,
    ProductPrice,
    ProductCatalog,
  },
  data() {
    return {
      isEditOpen: false,
      isCatalogOpen: false,
      isPriceOpen: false,
      isStockOpen: false,
      isDetailsOpen: false,
      productSelected: null,
    };
  },
  computed: {
    priceStock() {
      return (
        (this.product.inventory[0].theoretical * this.product.taxBase)
        / (this.product.unit === 'UNITY' ? 1 : 1000)
      );
    },
    wholesalerMargin() {
      return this.product.margins.find(({ quantity }) => quantity === 0);
    },
    retailerMargin() {
      return this.product.margins.find(
        ({ quantity }) => quantity === (this.product.unit === 'UNITY' ? 1 : 1000),
      );
    },
    wholesalerBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: true,
      });
    },
    retailerBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: false,
      });
    },
    supplyBilledItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
        isWholesaler: false,
      }).billOrder({ isSupply: true });
    },
    buyedItem() {
      return this.item({
        product: this.product,
        quantity: this.product.unit === 'UNITY' ? 1 : 1000,
      }).billBuy({ isBudget: false });
    },
  },
  mounted() {},
  methods: {
    currency,
    hasStock,
    isAutomaticInventory,
    handleSelectProduct(product) {
      this.productSelected = product;
      this.isDetailsOpen = true;
    },
    handleEditProduct(product) {
      this.productSelected = product;
      this.isEditOpen = true;
    },
    handleEditStock(product) {
      this.productSelected = product;
      this.isStockOpen = true;
    },
    handleEditPrice(product) {
      this.productSelected = product;
      this.isPriceOpen = true;
    },
    handleEditCatalog(product) {
      this.productSelected = product;
      this.isCatalogOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.productSelected = null;
    },
    item({ product, quantity, isWholesaler = false }) {
      return new Item({ product, quantity, isWholesaler }).billOrder();
    },
    price(product, quantity, isWholesaler) {
      return this.item({ product, quantity, isWholesaler }).price;
    },
  },
  watch: {},
};
</script>
