<template>
  <div>
    <div v-if="withdrawals.length">
      <b-field label="Logística a cargo de:">
        <b-select
          v-model="componentWithdrawal.name"
          placeholder="Seleccionar tipo de retiro:"
          expanded
          :disabled="!canChangeWithdrawal"
        >
          <option
            v-for="option in withdrawalsToChoose.map(({ name }) => name)"
            :value="option"
            :key="option"
          >
            {{ option | capitalize }}
          </option>
        </b-select>
      </b-field>

      <div class="field">
        <b-tag v-if="withdrawal.name" class="ml-2"
          >Proveedor <b> ${{ getProviderCost }}</b></b-tag
        >
        <b-tooltip label="Aporte de activate">
          <b-tag v-if="withdrawal.name" class="ml-2"
            >Activate <b> ${{ getInternCost }}</b></b-tag
          >
        </b-tooltip>
      </div>
      <div class="field" v-if="isWithdrawalBonusable">
        <b-checkbox
          v-model="componentWithdrawal.charged"
          type="is-success"
          :true-value="false"
          :false-value="true"
          >Bonificar costo de entrega</b-checkbox
        >
      </div>

      <b-field label="Fecha de entrega:">
        <b-datepicker
          :mobile-native="false"
          :unselectable-days-of-week="[0]"
          v-model="componentWithdrawal.date"
          :min-date="minDate"
          :nearby-selectable-month-days="true"
          placeholder="Fecha de entrega"
          icon="calendar-today"
          trap-focus
          locale="es-ES"
        >
          <hr v-if="withdrawal.date" style="margin-top: 15px" />
          <button
            v-if="withdrawal.date"
            class="button is-danger"
            style="margin-top: -0.5em"
            @click="handleRemoveWithdrawalDate"
          >
            <b-icon icon="close"></b-icon>
            <span>Borrar</span>
          </button>
        </b-datepicker>
      </b-field>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

const { WithdrawalService } = ApiService;

export default {
  name: 'Withdrawal',
  props: {
    withdrawal: Object,
    canChangeWithdrawal: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    const today = new Date();
    return {
      componentWithdrawal: this.withdrawal || { charged: false },
      withdrawals: [],
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
    };
  },
  watch: {
    customer: {
      handler() {
        this.componentWithdrawal = { ...this.withdrawal };
      },
      deep: true,
    },
    componentWithdrawal: {
      handler(componentWithdrawal) {
        this.$emit('input', { ...this.withdrawal, ...componentWithdrawal });
      },
      deep: true,
    },
    // eslint-disable-next-line func-names
    'withdrawal.name': function () {
      this.componentWithdrawal = this.withdrawal;
    },
    // eslint-disable-next-line func-names
    'componentWithdrawal.name': function () {
      this.componentWithdrawal = this.withdrawal;

      const withdrawalChoosed = this.withdrawals.find(
        (withdrawal) => withdrawal.name === this.componentWithdrawal.name,
      );

      this.componentWithdrawal = {
        ...this.componentWithdrawal,
        ...withdrawalChoosed,
      };
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    getProviderCost() {
      return this.withdrawal.charged ? this.withdrawal.fee : 0;
    },
    getInternCost() {
      return this.withdrawal.charged
        ? this.withdrawal.internPrice
        : this.withdrawal.internPrice + this.withdrawal.fee;
    },
    isWithdrawalBonusable() {
      return this.withdrawal.fee > 0;
    },
    withdrawalsToChoose() {
      return this.withdrawals;
    },
  },
  methods: {
    handleRemoveWithdrawalDate() {
      this.componentWithdrawal.date = null;
    },
    async fetchWithdrawals() {
      const { data: withdrawals } = await WithdrawalService.find({
        status: true,
      });
      this.withdrawals = withdrawals.filter(({ shortage }) => !shortage);
    },
  },
  async mounted() {
    await this.fetchWithdrawals();
  },
};
</script>
