<template>
  <section>
    <b-modal
      :active="active"
      :has-modal-card="true"
      :can-cancel="['escape', 'outside']"
      @close="$emit('dismiss')"
    >
      <div class="border-2 border-indigo-400 rounded-lg modal-card">
        <section class="modal-card-body">
          <b-field label="Nombre">
            <b-input
              v-model="product.name"
              type="text"
              placeholder="Nombre"
            ></b-input>
          </b-field>

          <b-field label="Slug">
            <b-input
              v-model="product.slug"
              type="text"
              placeholder="slug"
            ></b-input>
          </b-field>

          <b-field label="Categoría">
            <b-select
              data-cy="category"
              v-model="product.category"
              placeholder="Seleccionar una categoría"
            >
              <option
                data-cy="category-option"
                v-for="category in categories"
                :value="category._id"
                :key="category.number"
              >
                {{ category.name }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Proveedor">
            <b-select
              data-cy="provider"
              v-model="product.provider"
              placeholder="Seleccionar un proveedor"
            >
              <option
                data-cy="provider-option"
                v-for="provider in providers"
                :value="provider._id"
                :key="provider.number"
              >
                {{ provider.name }}
              </option>
            </b-select>
          </b-field>

          <label for="unit"><b>Medida</b></label>
          <div class="block mt-4">
            <b-radio v-model="product.unit" name="name" native-value="WEIGHT"
              >Peso</b-radio
            >
            <b-radio v-model="product.unit" native-value="UNITY"
              >Unidad</b-radio
            >
          </div>

          <label for="unit"><b>Control de stock</b></label>
          <div class="block mt-4">
            <b-radio v-model="product.inventoryManagement" name="manual" native-value="manual"
              >Manual</b-radio
            >
            <b-radio v-model="product.inventoryManagement" name="automatic" native-value="automatic"
              >Automatico</b-radio
            >
          </div>

          <b-checkbox
            data-cy="splitPackage"
            v-model="product.splitPackage"
            class="is-pulled-right"
            :rounded="true"
            :outlined="true"
            type="is-success"
            :left-label="true"
            passive-type="is-danger"
          ></b-checkbox>
          <b-field
            label="Paquete separado"
          >
          </b-field>

          <b-checkbox
            data-cy="mixed"
            v-model="product.mixed"
            class="is-pulled-right"
            :rounded="true"
            :outlined="true"
            type="is-success"
            :left-label="true"
            passive-type="is-danger"
          ></b-checkbox>
          <b-field
            :label="`Compuesto ${
              product.unit === 'UNITY' ? 'Unitario' : 'Agranel'
            }`"
          >
          </b-field>

          <Components
            :product="product"
            :components="components"
            @add="handleAddComponent"
            @remove="handleRemoveComponent"
          />

          <b-field
            v-if="!product.mixed"
            :label="`Costo ${product.unit === 'UNITY' ? 'unidad' : '1000 grs'}`"
          >
            <b-numberinput
              data-cy="unitCost"
              v-model="product.taxBase"
              :controls="false"
              :min="0"
              step="0.001"
            ></b-numberinput>
          </b-field>

          <b-field label="Aporte Activate %">
            <b-numberinput
              data-cy="contributions"
              v-model="product.contributions"
              :controls="false"
              :min="0"
              step="0.001"
            ></b-numberinput>
          </b-field>

          <b-field label="Aporte Mayorista Activate %">
            <b-numberinput
              data-cy="contributions"
              v-model="product.wholesalerContributions"
              :controls="false"
              :min="0"
              step="0.001"
            ></b-numberinput>
          </b-field>

          <b-field label="Margen Predeterminado">
            <b-numberinput
              data-cy="defaultMargin"
              v-model="product.defaultMargin"
              :controls="false"
              :min="0"
            ></b-numberinput>
          </b-field>

          <label for="margins"><b>Margenes</b></label>
          <b-table :data="product.margins" :narrowed="true" :striped="true">
            <b-table-column
              field="quantity"
              :label="product.unit === 'UNITY' ? 'Unid.' : 'Grs'"
              :striped="true"
              :centered="true"
              :numeric="true"
            >
              <template v-slot="props">
                {{
                  props.row.quantity === 0 ? 'Mayorista' : props.row.quantity
                }}
              </template>
            </b-table-column>
            <b-table-column
              field="quantity"
              label="%"
              :centered="true"
              :numeric="true"
            >
              <template v-slot="props">
                {{ props.row.value }}
              </template>
            </b-table-column>
            <b-table-column :centered="true">
              <template v-slot="props">
                <button
                  class="is-pulled-right delete is-small"
                  @click="handleRemoveMargin(props.row)"
                ></button>
              </template>
            </b-table-column>
          </b-table>
          <div class="mt-2 columns is-centered">
            <div class="column is-two-fifths">
              <b-field label="Cantidad">
                <b-numberinput
                  v-if="!isWholesaler"
                  v-model="margin.quantity"
                  :controls="false"
                  :min="0"
                ></b-numberinput>
                <b-input
                  v-if="isWholesaler"
                  :disabled="true"
                  value="Mayorista"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-two-fifths">
              <b-checkbox
                v-model="isWholesaler"
                class="is-pulled-right"
                :rounded="true"
                :outlined="true"
                size="is-small"
                type="is-danger"
                :left-label="true"
                >{{ isWholesaler ? 'Mayorista' : 'Minorista' }}</b-checkbox
              >
              <b-field label="%">
                <b-numberinput
                  v-model="margin.value"
                  :controls="false"
                  :min="0"
                  step="0.001"
                ></b-numberinput>
              </b-field>
            </div>
            <div class="column is-one-fifth">
              <b-field label="Agregar">
                <b-button
                  type="is-success is-light"
                  icon-right="plus"
                  @click="handleAddMargin"
                />
              </b-field>
            </div>
          </div>
          <b-field label="Inventario Crítico">
            <b-numberinput
              v-model="product.inventory.critical"
              :controls="false"
              :min="0"
            ></b-numberinput>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="$emit('dismiss')">Cancelar</button>
          <Button
            data-cy="create-product"
            type="is-success"
            :onClickFn="handleAddProduct"
            >Agregar</Button
          >
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../services/ApiService';
import Components from './Components.vue';
import {
  defaultData,
  defaultMargins,
  defaultMargin,
} from '../../schemas/product';
import { validatePayload, disconnectedCopy, sum } from '../../util/helpers';

const {
  product: { newProductSchema },
} = schemas;

const { CategoryService, ProductService, ProviderService } = ApiService;

export default {
  name: 'NewProduct',
  props: {
    active: Boolean,
    components: Array,
  },
  components: {
    Components,
  },
  data() {
    return {
      product: disconnectedCopy(defaultData),
      categories: [],
      providers: [],
      isWholesaler: false,
      margin: {
        quantity: 0,
        value: 0,
      },
      currentRemovedComponents: [],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'product.unit': function () {
      this.product.margins = defaultMargins(this.product.unit);
      this.product.defaultMargin = defaultMargin(this.product.unit);
      this.product.components = [];
    },
  },
  computed: {
    percentage() {
      return sum(this.product.components, 'quantity');
    },

    isPercentage() {
      return this.product.unit !== 'UNITY';
    },
  },
  methods: {
    handleAddMargin() {
      const { quantity, value } = this.margin;
      if ((quantity === 0 && !this.isWholesaler) || value === 0) {
        this.$buefy.snackbar.open('No es posible agregar margen 0');
        return;
      }

      const duplicated = this.product.margins.find(
        (margin) => margin.quantity === quantity,
      );

      if (duplicated) {
        this.$buefy.snackbar.open('Ya existe un margen igual');
        return;
      }

      if (this.isWholesaler) this.margin.quantity = 0;

      this.product.margins.push(this.margin);
      this.margin = {
        quantity: 0,
        value: 0,
      };
      this.product.margins.sort((a, b) => a.quantity - b.quantity);
    },
    handleRemoveMargin(margin) {
      this.product.margins = this.product.margins.filter(
        ({ quantity }) => quantity !== margin.quantity,
      );
    },
    handleAddComponent(component) {
      this.product.components.push(component);
    },
    handleRemoveComponent(component) {
      this.currentRemovedComponents.push(component);
      this.product.components = this.product.components.filter(
        // eslint-disable-next-line no-underscore-dangle
        ({ product }) => product._id !== component.product._id,
      );
    },
    async handleAddProduct() {
      if (this.product.mixed && this.isPercentage && this.percentage !== 100) {
        this.$buefy.snackbar.open(
          'Los componentes no alcanzan el 100% del compuesto',
        );
        return;
      }
      const payload = this.product;

      payload.components = payload.components.map((component) => {
        // eslint-disable-next-line no-param-reassign, no-underscore-dangle
        component.product = component.product._id;
        return component;
      });

      if (!validatePayload(payload, newProductSchema)) return;

      const { error, data: product } = await ProductService.create(payload);

      if (error) {
        console.error(error.data.code);
        return;
      }
      this.$emit('created', product);
      this.product = disconnectedCopy(defaultData);
    },
  },
  async created() {
    const { data: categories } = await CategoryService.find({ status: true });
    this.categories = categories;
    const { data: { docs: providers } } = await ProviderService.find({ status: true, limit: 99999 });
    this.providers = providers;
  },
};
</script>
