<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <div class="control" v-if="currentCustomer.main">
          <b-taglist attached>
            <b-tag type="is-dark">Agente de venta</b-tag>
            <b-tag type="is-primary">{{ currentCustomer.main.name }}</b-tag>
          </b-taglist>
        </div>
      </div>
    </hero-bar>
    <Section>
      <div class="columns">
        <div class="column is-two-thirds">
          <ProductLookup
            @select="handleSelectProduct"
            :product="selectedProduct"
          ></ProductLookup>
          <AddItemToCart
            :product="selectedProduct"
            @add="handleAddItemToCart"
          />
          <ItemsDetails
            v-if="cart.items.length"
            :cart="cart"
            :canRemoveItems="true"
            :showDiscount="false"
            :showDetails="false"
            :showTotal="true"
            @removeItem="handleRemoveItem"
          ></ItemsDetails>
        </div>
        <div class="column is-one-third">
          <CustomerInfo
            :customer="currentCustomer"
            :canRemove="false"
          ></CustomerInfo>

          <div class="field">
            <label class="label">Confirmar dirección de entrega:</label>
            <AddressInput
              v-model="address"
              :address="address"
              :withLabel="false"
              mode="string"
            />
          </div>
          <b-field label="Notas para el repartidor:">
            <b-input
              v-model="notes"
              pattern="[a-z]*"
              maxlength="200"
              type="textarea"
              placeholder="Tocar timbre, llamar por telefono..."
            ></b-input>
          </b-field>
          <div v-if="cart.items.length">
            <div class="buttons is-pulled-right">
              <Button
                data-cy="create-draft"
                v-if="cart.items.length"
                type="is-dark"
                :onClickFn="handleConfirmDraft"
                >Confirmar</Button
              >
            </div>
          </div>
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';
import HeroBar from '../components/HeroBar.vue';
import ProductLookup from '../components/Cart/ProductLookup.vue';
import AddItemToCart from '../components/Cart/AddItemToCart.vue';
import ItemsDetails from '../components/Cart/ItemsDetails.vue';
import CustomerInfo from '../components/Cart/CustomerInfo.vue';
import AddressInput from '../components/AddressInput.vue';
import Section from '../components/Section.vue';
import ApiService from '../services/ApiService';

const { OrderService } = ApiService;

const {
  models: { Cart },
} = ActivateSDK;

export default {
  name: 'Draft',
  props: {},
  components: {
    Section,
    HeroBar,
    ProductLookup,
    AddItemToCart,
    ItemsDetails,
    CustomerInfo,
    AddressInput,
  },
  data() {
    const { address } = this.$store.getters['Auth/currentCustomer'];

    return {
      products: [],
      cart: new Cart(),
      address,
      notes: null,
      selectedProduct: null,
      hasActions: true,
    };
  },
  watch: {},
  computed: {
    currentCart() {
      return this.$store.getters['Cart/currentCart'];
    },
    currentCustomer() {
      return this.$store.getters['Auth/currentCustomer'] || {};
    },
  },
  async mounted() {
    this.$store.dispatch('Ui/showBars');
    if (!this.$store.getters['Auth/currentCustomer']) {
      await this.$store.dispatch('Auth/expirePair');
    }
    const cart = this.currentCart || { items: [] };
    if (cart.items.length) {
      this.cart = new Cart(cart.items);
    }
  },
  methods: {
    handleSelectProduct(product) {
      this.selectedProduct = product;
    },
    handleUpdateCartState(cart) {
      this.$store.dispatch('Cart/update', cart);
    },
    handleRemoveItem(index) {
      this.cart.removeItem(index);
      this.handleUpdateCartState(this.cart);
    },
    handleAddItemToCart(item) {
      this.cart.addItem(item, 'order');
      this.selectedProduct = null;
      this.handleUpdateCartState(this.cart);
    },
    handleConfirmDraft() {
      this.$buefy.dialog.confirm({
        title: 'Confirmar pedido',
        message: `Confirmar pedido a la dirección <b>${this.address.label}</b>`,
        confirmText: 'Confirmar',
        type: 'is-primary',
        hasIcon: true,
        onConfirm: () => this.createDraft(),
      });
    },
    async createDraft() {
      const { main } = this.currentCustomer;
      // eslint-disable-next-line no-underscore-dangle
      const partner = main ? main._id : null;
      const { _id: customer } = this.currentCustomer;
      const { cart, address, notes } = this;

      const clonedCart = new Cart(cart.items, 'order');

      clonedCart.depopulateProducts();

      const payload = {
        cart: clonedCart,
        customer,
        partner,
        delivery: { address },
        notes,
      };

      await OrderService.createDraft(payload);

      await this.$store.dispatch('Cart/remove');

      this.$buefy.notification.open({
        message:
          '<p class="subtitle is-5">Tu pedido fue cargado correctamente &#128588</p><p class="title is-5">Gracias por comprar en Activate &#128156 </p>',
        type: 'is-success',
        'has-icon': true,
        queue: false,
        duration: 10000,
        position: 'is-top-right',
      });

      this.$router.push('/catalog');
    },
  },
};
</script>
