<template>
  <div class="flex flex-col" :class="componentClass">
    <slot></slot>

    <div class="flex items-center justify-center mt-8" v-if="loading">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-6 h-6 animate-spin"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    componentClass: {
      type: Array,
      default: () => ['gap-4'],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
