<template>
  <section class="py-4 sm:px-4">
    <slot></slot>
  </section>
</template>

<script>
export default {
  name: 'Section',
  props: {},
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
  watch: {},
};
</script>
