<template>
  <b-modal
    :active="active"
    :has-modal-card="true"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div class="border-2 border-indigo-400 rounded-lg modal-card">
      <section class="modal-card-body">
        <b-field position="is-centered">
          <p class="control">
            <b-button label="Nombre" type="is-light" />
          </p>
          <b-input placeholder="Nombre" v-model="updated.name"></b-input>
        </b-field>
        <div class="m-2 bg-white field">
          <b-field grouped>
            <b-checkbox v-model="updated.contributes" type="is-success"
              >Aporta
            </b-checkbox>
            <b-field position="is-centered">
              <p class="control">
                <b-button label="AP USX" type="is-primary is-light" />
              </p>
              <b-input
                type="number"
                placeholder="Aporte Usuario"
                icon="account-box-outline"
                v-model="updated.customerPrice"
              ></b-input>
            </b-field>
          </b-field>
        </div>

        <div class="m-2 bg-white field">
          <b-checkbox v-model="updated.isWholesaler" type="is-success"
            >Mayorista
          </b-checkbox>
          <b-checkbox v-model="updated.isRetailer" type="is-success"
            >Minorista
          </b-checkbox>
        </div>

        <div class="m-2 bg-white field">
          <b-checkbox v-model="updated.isOwnLogistic" type="is-success"
            >Logística propia
          </b-checkbox>
        </div>

        <b-field>
          <p class="control">
            <b-button label="Roles" type="is-light" />
          </p>
          <b-taginput v-model="updated.roles" rounded type="is-primary">
          </b-taginput>
          <b-button
            class="ml-2"
            v-for="role in availableRoles"
            :key="role"
            @click="updated.roles.push(role)"
            size="is-small"
            type="is-danger is-light"
            rounded
            >{{ role }}
          </b-button>
        </b-field>

        <b-field position="is-centered">
          <p class="control">
            <b-button label="AP AV" type="is-success is-light" />
          </p>
          <b-input
            type="number"
            placeholder="Aporte Socio"
            icon="handshake"
            v-model="updated.partnerPrice"
          ></b-input>
        </b-field>

        <b-field position="is-centered">
          <p class="control">
            <b-button label="AP INT" type="is-info is-light" />
          </p>
          <b-input
            type="number"
            placeholder="Precio Activate"
            icon="seed"
            v-model="updated.internPrice"
          ></b-input>
        </b-field>
        <b-field label="Mensaje">
          <b-input
            v-model="updated.message"
            maxlength="1000"
            type="textarea"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="$emit('dismiss')">Cancelar</button>
        <Button
          data-cy="update-delivery"
          :once="true"
          type="is-success"
          :onClickFn="handleUpdateDelivery"
          >Aceptar</Button
        >
      </footer>
    </div>
  </b-modal>
</template>

<script>
import ApiService from '../../services/ApiService';
import { omit } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';

const { DeliveryService } = ApiService;

export default {
  name: 'UpdateDelivery',
  props: {
    active: Boolean,
    delivery: Object,
  },
  components: {},
  data() {
    return {
      updated: this.delivery || {},
      rolesToChoose: ['ADMIN', 'AV'],
    };
  },
  watch: {
    'delivery._id': function () {
      if (this.delivery) {
        this.updated = this.delivery;
      }
    },
  },
  computed: {
    availableRoles() {
      return this.rolesToChoose.filter(
        (x) => this.updated.roles && !this.updated.roles.includes(x),
      );
    },
  },
  methods: {
    async handleUpdateDelivery() {
      const { _id: id } = this.delivery;
      const payload = omit(immutableProperties.common, this.updated);
      await DeliveryService.update(id, payload);
      this.$emit('update', id);
      this.$emit('dismiss');
    },
  },
};
</script>
