const defaultData = {
  grossTotal: 0,
  total: 0,
  type: 'FC',
  code: null,
  buy: null,
  provider: null,
  isBudget: false,
  taxes: [],
  logisticCreditNote: false,
  description: null,
};

module.exports = {
  defaultData,
};
