<template>
  <div class="">
      <div v-for="(day, index) in entriesDays" :key="index">
        <p class="px-2 py-2 text-xl font-bold">{{ day | longDate }}</p>

          <List :componentClass="['gap-2']">
            <div v-for="entry in getEntriesByDay(day)" :key="entry._id">
              <template>
                <FlowCard
                  v-if="isFlow(entry)"
                  :flow="entry"
                  :operationPermission="true"
                ></FlowCard>
                <OrderDebtCard
                  v-if="isOrder(entry)"
                  :order="entry"
                  :operationPermission="true"
                ></OrderDebtCard>
              </template>
            </div>
          </List>
   
      </div>
  </div>
</template>

<script>

import { startOfDay, isSameDay } from 'date-fns';
import FlowCard from './FlowCard.vue';
import OrderDebtCard from './OrderDebtCard.vue';
import List from './List.vue';



export default {
  name: 'MyIncomes',
  components: {

    FlowCard,
    OrderDebtCard,

    List,

  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

    };
  },
  computed: {
    entriesDays() {
      return this.transactions
        .map(({ date }) => startOfDay(new Date(date)))
        .filter(
          (date, i, self) => self.findIndex((d) => d.getTime() === date.getTime()) === i,
        )
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    },
  },


  methods: {

    getEntriesByDay(day) {
      return this.transactions.filter(({ date }) => isSameDay(new Date(date), day));
    },
    isFlow(entry) {
      return entry.doc === 'partnercashflows';
    },
    isOrder(entry) {
      return entry.doc === 'orders';
    },




  },
};
</script>

