<template>
  <GenericModal :active="active" @dismiss="handleDismiss">
    <div class="flex flex-col justify-between h-96">
      <b-field label="Repartidor">
        <b-autocomplete v-model="driverToSearch" ref="input" field="name" :keep-first="true" :append-to-body="false"
          :data="drivers" :open-on-focus="true" @select="handleSelect" :loading="!drivers.length">
          <template slot-scope="props">
            <div class="media">
              <div class="media-left"></div>
              <div class="media-content">
                {{ props.option.name }}
              </div>
            </div>
          </template></b-autocomplete>
      </b-field>

      <div class="grid grid-cols-3 gap-4" >
        <button v-for="driver of drivers" :key="driver._id" class="w-24 h-24 gap-2 px-2 py-1 border-2 aspect-square rounded-xl" :class="[ element && element.driver && driver._id === element.driver._id ? 'bg-purple-100' : '' ]"  @click="() => {
          handleSelect(driver)
          handleUpdateDriver()
          handleDismiss()
        }">
        {{ driver.name }}</button>
      </div>



      <Button type="is-success" :onClickFn="handleUpdateDriver">Actualizar</Button>
    </div>
  </GenericModal>
</template>

<script>
import { mapState } from 'pinia';
import GenericModal from './GenericModal.vue';
import useUserStore from '../store/user';


export default {
  name: 'UpdateDriverModal',
  props: {
    active: Boolean,
    element: Object,
    store: Object,

  },
  components: {
    GenericModal,
  },
  data() {
    return {
  
      updated: this.element,
      driverToSearch: '',
    };
  },
  watch: {
    order() {
      if (this.element) {
        this.driverToSearch = this.element.driver.name;
      }
    },
  },
  computed: {
    ...mapState(useUserStore, ['drivers']),
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss');
    },
    async handleUpdateDriver() {
      const {
        driver: { _id: driver },

      } = this;


      await this.store.updateDriver(this.element, { driver });
      this.handleDismiss();
      this.$emit('update', { ...this.element, driver });
    },
    handleSelect(driver) {
      this.driver = driver;
    },
  },
  mounted() {
    
  },
};
</script>
