<template>
  <div>
    <div :id="id" style="display: none" class="m-4">
      <div v-for="driverName of driverNames" :key="driverName">
        <div class="relative flex flex-col gap-0.5 p-4 text-xl printable-item">
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p class="font-bold">
                FECHA:
                {{
      dispatchsByDriverGroupedByAddress(driverName)[0]?.delivery
        ?.date ||
      dispatchsByDriverGroupedByAddress(driverName)[0]?.withdrawal
        ?.date | shortDate
    }}
              </p>
              <p class="font-bold">
                PEDIDOS:
                {{ filterIsOrder(dispatchsByDriver(driverName)).length }}
              </p>
              <p class="font-bold">
                COMPRAS:
                {{ filterIsBuy(dispatchsByDriver(driverName)).length }}
              </p>
            </div>
            <p :style="{ fontSize: '32px' }"
              class="absolute flex items-center justify-center px-2 font-bold text-center border-2 border-black w-14 h-14 justify-self-center top-4 left-1/2">
              HR
            </p>
            <div class="flex flex-col text-right">
              <p class="font-bold">
                REPARTIDOR: {{ driverName?.toUpperCase() }}
              </p>
              <p class="font-bold">
                VIAJES:
                {{ dispatchsByDriverGroupedByAddress(driverName).length }}
              </p>
              <p class="font-bold">
                TOTAL: {{ logisticTotalByDriver(driverName) | currency }}
              </p>
            </div>
          </div>

          <table v-if="dispatchsByDriver(driverName).length > 0"
            class="w-full text-xl border border-collapse table-fixed border-slate-500">
            <thead>
              <tr>
                <th class="w-32 border border-slate-600">RANGO</th>
                <th class="w-6 border border-slate-600"></th>
                <th class="w-48 border border-slate-600">USX</th>
                <th class="border w-28 border-slate-600">TOTAL</th>
                <th class="w-24 border border-slate-600"></th>
                <th class="w-16 border border-slate-600">PAGO</th>
                <th class="w-10 border border-slate-600">Q.</th>
                <th class="w-auto border border-slate-600">DIRECCION</th>
                <th class="w-32 border border-slate-600">CIUDAD</th>
              </tr>
            </thead>

            <tbody>
              <div v-for="dispatch in dispatchsByDriverGroupedByAddress(
      driverName
    )" :key="dispatch._id">
                <tr>
                  <td class="border border-slate-600">
                    {{ getRangeTime(dispatch) }}
                  </td>

                  <td class="border border-slate-600">
                    {{ isOrder(dispatch) ? 'P' : 'C' }}
                  </td>

                  <td class="truncate border border-slate-600">
                    <span>
                      {{
      dispatch.delivery?.isDropOff
        ? `NODO - ${dispatch.partner?.username}`
        : `${dispatch.customer?.name || dispatch.provider?.name
        }`
    }}</span>
                  </td>
                  <!-- <td
                  class="truncate border border-slate-600"
                  v-if="
                    dispatch
                      .delivery?.isDropOff
                  "
                >
                  <span
                    > <span class="font-bold">NODO </span>
                    - {{
                      dispatch
                        .partner?.username
                    }}</span
                  >
                </td> -->

                  <td class="border border-slate-600">
                    {{ dispatch.total | currency }}
                  </td>

                  <td class="border border-slate-600"></td>

                  <td class="truncate border border-slate-600">
                    <span>
                      {{ dispatch.paymentMethod }}
                    </span>
                  </td>

                  <td class="border border-slate-600" :class="[dispatch.elements > 1 ? 'font-bold text-xl' : '']">
                    {{ dispatch.elements }}
                  </td>

                  <td class="border border-slate-600">
                    <span>
                      {{
      dispatch.delivery?.address?.street ||
      dispatch.provider?.address?.street
    }}</span>

                    <span class="font-bold" v-if="dispatch.delivery?.address?.unit ||
      dispatch.provider?.address?.unit
      ">
                      -
                      {{
      dispatch.delivery?.address?.unit ||
      dispatch.provider?.address?.unit
    }}
                    </span>
                  </td>
                  <td class="truncate border border-slate-600">
                    {{
        dispatch.delivery?.city === 'Ciudad de Buenos Aires'
          ? 'CABA'
                    : dispatch.delivery?.address.city ||
                    dispatch.provider.address.city
                    }}
                  </td>
                </tr>
                <tr v-if="dispatch?.roadmapNotes">
                  <td colspan="9" class="px-2 text-lg font-bold">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="flex-none inline w-6 h-6 align-center">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                      </svg>
                      {{ dispatch?.roadmapNotes }}
                    </div>
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-button type="is-primary is-light" @click="print">
      <b-icon icon="alpha-h-box-outline" type="is-primary" /></b-button>
  </div>
</template>

<script>
import {
  formatAddress,
  getDate,
  getRangeTime,
  isBuy,
  isOrder,
  shortAddress,
  sum,
} from '../../util/helpers';

export default {
  name: 'Printroutes',
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: 'routes',
    },
  },
  components: {
    // Consignment,
  },
  data() {
    return {};
  },
  mounted() {
    // add a page break before each item except the first one
    const printableItems = document.querySelectorAll('.printable-item');
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < printableItems.length; i++) {
      printableItems[i].style.pageBreakBefore = 'always';
      printableItems[i].style.pageBreakAfter = 'always';
      printableItems[i].style.clear = 'both';
    }
  },
  computed: {
    driverNames() {
      return this.elements
        .map((order) => order.driver?.name)
        .filter((name, index, self) => self.indexOf(name) === index);
    },
  },
  methods: {
    sum,
    isOrder,
    isBuy,
    formatAddress,
    logisticTotalByDriver(driverName) {
      return +sum(
        this.dispatchsByDriver(driverName),
        null,
        ({ logistic, delivery, withdrawal }) => {
          if (delivery) {
            return logistic + delivery.internPrice;
          }
          if (withdrawal) return withdrawal.internPrice + withdrawal.fee;
          return 0;
        },
      );
    },
    dispatchsByDriver(driverName) {
      return this.elements
        .filter((order) => order.driver?.name === driverName)
        .sort((a, b) => this.getDate(a) - this.getDate(b));
    },
    filterIsOrder(dispatchs) {
      return dispatchs.filter((dispatch) => isOrder(dispatch));
    },
    filterIsBuy(dispatchs) {
      return dispatchs.filter((dispatch) => isBuy(dispatch));
    },
    // should be an array of dispatchs. if delivery.address.label is the same. then group them
    dispatchsByDriverGroupedByAddress(driverName) {
      return this.dispatchsByDriver(driverName).reduce((acc, dispatch) => {
        const alreadyExistsIndex = acc.findIndex(
          (item) => item.delivery?.address?.label
            === dispatch.delivery?.address?.label,
        );

        if (alreadyExistsIndex === -1 || this.isBuy(dispatch)) {
          acc.push({ ...dispatch, elements: 1 });
        } else {
          acc[alreadyExistsIndex].elements += 1;
        }

        return acc;
      }, []);
    },
    getDate,
    getRangeTime,
    shortAddress,

    async print() {
      await this.$htmlToPaper(this.id);
    },
  },
};
</script>

<style scoped>
@media print {
  .printable-item {
    page-break-inside: avoid;
  }
}
</style>
