<template>
  <div class="ml-2">
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportPartnerIncomes',
  props: {
    entries: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    data() {
      return [];
    },
    sortedData() {
      return [...this.entries].sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
    },
  },
  methods: {
    isFlow(entry) {
      return entry.doc === 'partnercashflows';
    },
    isOrder(entry) {
      return entry.doc === 'orders';
    },
    getType(entry) {
      if (this.isOrder(entry)) return 'pedido';
      if (this.isFlow(entry)) return 'pago';
      return 'Desconocido';
    },
    getTotal(entry) {
      if (this.isOrder(entry)) return entry.toPay;
      if (this.isFlow(entry)) return -entry.total;
      return entry.total;
    },
    getPaymentMethod(entry) {
      if (this.isOrder(entry)) return '';
      if (this.isFlow(entry)) return entry.paymentMethod;
      return 'N/A';
    },  
    getProfit(entry) {
      if (this.isOrder(entry)) return entry.profit;
      if (this.isFlow(entry)) return '';
      return 'N/A';
    },  
    getDescription(entry) {
      if (this.isOrder(entry)) return `Pedido de ${entry.customer.name}`;
      if (this.isFlow(entry)) return entry.description;
      return 'N/A';
    },

    async downloadFile() {
      const data = [
        {
          sheet: 'Mis números',
          columns: [
            {
              label: 'Día',
              value: (row) => new Date(row.date),
              format: 'DD',
            },
            {
              label: 'Mes',
              value: (row) => new Date(row.date)
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'Año',
              value: (row) => new Date(row.date),
              format: 'YYYY',
            },
            {
              label: 'Concepto',
              value: (element) => this.getType(element),
            },
            {
              label: 'Método',
              value: (element) => this.getPaymentMethod(element),
            },
            {
              label: 'Descripción',
              value: (element) => this.getDescription(element),
            },
            // {
            //   label: 'Concepto',
            //   // eslint-disable-next-line no-nested-ternary
            //   value: (element) => (element.entryType === 'flow'
            //     ? 'Recibiste un pago de Activate'
            //     : element.isBudget
            //       ? 'PRESUPUESTO'
            //       : this.getInvoiceLabel(element)),
            // },
            {
              label: 'Monto',
              value: (element) => this.getTotal(element),
              format: '$0.00',
            },
            {
              label: 'Actualizado',
              value: (element) => element.current,
              format: '$0.00',
            },
            {
              label: 'Ganancia',
              value: (element) => this.getProfit(element),
              format: '$0.00',
            },
          ],
          content: this.sortedData,
        },
      ];
      const settings = {
        fileName: `Mis Números - ${new Date().toLocaleDateString(
          'es',
        )}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
