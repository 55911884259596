<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <!-- <span
        class="navbar-item has-text-dark is-hidden-desktop has-text-weight-bold"
      >
        {{ currentPage }}</span
      >
      <span class="navbar-item has-text-dark is-hidden-mobile title">
        {{ currentPage }}</span
      > -->
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end">
        <nav-bar-menu
          class="has-divider has-user-avatar"
          v-if="currentUser.username"
        >
          <user-avatar :user="currentUser" />
          <!-- <div class="is-user-name">
            <span>{{ currentUser.username }}</span>
          </div> -->
          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>Mi perfil</span>
            </router-link>
            <router-link
              to="/settings"
              v-if="isAdmin"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="settings" custom-size="default"></b-icon>
              <span>Configuración</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <!-- <a
          class="navbar-item has-divider is-desktop-icon-only"
          title="Dark mode"
          @click="darkModeToggle"
        >
          <b-icon :icon="darkModeToggleIcon" custom-size="default" />
          <span>Dark mode</span>
        </a> -->
        <a
          class="navbar-item is-desktop-icon-only"
          title="Salir"
          @click="logout"
        >
          <b-icon icon="logout" custom-size="default" />
          <span>Salir</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import NavBarMenu from './NavBarMenu.vue';
import UserAvatar from './UserAvatar.vue';

export default {
  name: 'NavBar',
  components: {
    UserAvatar,
    NavBarMenu,
  },
  data() {
    return {
      isMenuNavBarActive: false,
    };
  },
  computed: {
    currentPage() {
      const { hasCart, prefixNew, prefixUpdate } = this.$route.meta;
      // eslint-disable-next-line no-underscore-dangle
      const isUpdating = this.$route.params._id || this.$route.params.id;

      // eslint-disable-next-line no-nested-ternary
      const prefix = hasCart ? (isUpdating ? prefixUpdate : prefixNew) : '';
      return `${prefix}${this.$route.meta.title}`;
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentCustomer() {
      return this.$store.getters['Auth/currentCustomer'] || {};
    },
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? 'close' : 'dots-vertical';
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger';
    },
    darkModeToggleIcon() {
      return this.isDarkModeActive ? 'white-balance-sunny' : 'weather-night';
    },
    isNavBarVisible() {
      return this.$store.state.Ui.isNavBarVisible;
    },
    isAsideMobileExpanded() {
      return this.$store.state.Ui.isAsideMobileExpanded;
    },
    isDarkModeActive() {
      return this.$store.state.Ui.isDarkModeActive;
    },
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false;
    });
  },
  methods: {
    menuToggleMobile() {
      this.$store.dispatch('Ui/toggleAsideMobile');
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    darkModeToggle() {
      this.$store.commit('Ui/darkModeToggle');
    },
    async logout() {
      if (this.currentUser) await this.$store.dispatch('Auth/logout');
      if (this.currentCustomer) await this.$store.dispatch('Auth/expirePair');
    },
  },
};
</script>
