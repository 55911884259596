<template>
  <div class="py-2" v-if="product">
    <div class="columns is-vcentered" v-if="product && !product.status">
      <div class="column is-12">
        <b-notification type="is-warning is-light" :closable="false" has-icon>
          <span class="is-pulled-right" style="font-size: 30px">&#128561;</span>
          <span class="title is-4">Este producto no está disponible</span>
        </b-notification>
      </div>
    </div>
    <div class="flex flex-col is-clearfix" v-if="product && product.status">
      <b-field :label="`Cantidad (${longUnit})`">
        <b-numberinput
          v-model="quantity"
          :min="0"
          :max="maxQuantity"
          :step="stepQuantity"
          pattern="\d*"
          ref="quantityInput"
          @focus="$event.target.select()"
        ></b-numberinput>
      </b-field>
      <div class="flex justify-between">
        <p class="">
          <b>Precio:</b>
        </p>

        <p class="text-lg font-bold">{{ item.price | currency }}</p>
      </div>
      <div v-if="product">
        <Keypress key-event="keydown" :key-code="13" @success="onKeyEnter" />
      </div>

      <div class="flex flex-row-reverse">
        <b-button
          data-cy="add-item-to-cart"
          class="mt-2 is-primary is-clearfix"
          :disabled="quantity === 0"
          @click="handleAddToCart"
          >Agregar al pedido</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ActivateSDK from 'activate-schemas';

const {
  models: { Item },
} = ActivateSDK;

export default {
  name: 'AddItemToCart',
  props: {
    product: Object,
    isWholesaler: Boolean,
    isBudget: Boolean,
    type: String,
  },
  components: {
    Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
      listeningKeys: false,
      quantity: 0,
      item: {
        quantity: 0,
      },
    };
  },
  watch: {
    isWholesaler() {
      if (!this.product) return;
      this.billItem({ defaultQuantity: false });
    },
    isBudget() {
      if (!this.product) return;
      this.billItem({ defaultQuantity: false });
    },
    product() {
      if (!this.product) return;
      this.billItem();
    },
    quantity() {
      this.item.quantity = this.quantity;
      // eslint-disable-next-line no-unused-expressions
      this.type === 'buy'
        ? this.item.billBuy({ isBudget: this.isBudget })
        : this.item.billOrder({ isBudget: this.isBudget });
    },
  },
  computed: {
    defaultQuantities() {
      return {
        WEIGHT: [100, 250, 500, 1000],
        UNITY: [1, 5, 10, 50],
      }[this.product.unit];
    },
    maxQuantity() {
      return {
        WEIGHT: 10000,
        UNITY: 1000,
      }[this.product.unit];
    },
    stepQuantity() {
      return {
        WEIGHT: 50,
        UNITY: 1,
      }[this.product.unit];
    },
    longUnit() {
      return {
        WEIGHT: 'grs',
        UNITY: 'unidades',
      }[this.product.unit];
    },
    friendlyUnit() {
      return {
        WEIGHT: ' grs',
        UNITY: ' unid',
      }[this.product.unit];
    },
  },
  methods: {
    billItem({ defaultQuantity = true } = {}) {
      this.item = new Item({
        product: this.product,
        isWholesaler: this.isWholesaler,
      });
      this.quantity = defaultQuantity
        ? this.defaultQuantities[0]
        : this.quantity;
      this.item.quantity = this.quantity;
      // eslint-disable-next-line no-unused-expressions
      this.type === 'buy'
        ? this.item.billBuy({ isBudget: this.isBudget })
        : this.item.billOrder({ isBudget: this.isBudget });
    },
    onKeyEnter() {
      if (this.listeningKeys) {
        this.handleAddToCart();
      }
    },
    handleAddToCart() {
      if (this.item.quantity > 0) {
        this.$emit('add', this.item);
      }
      this.$nextTick(() => {
        this.listeningKeys = false;
      });
    },
    setFocusQuantity() {
      this.$nextTick(() => {
        this.$refs.quantityInput.focus();
      });
      setTimeout(() => {
        this.listeningKeys = true;
      }, 200);
    },
  },
  mounted() {},
};
</script>
