<template>
  <div>
    <Section>
      <Widgets>
        <Widget
          title="Pedidos pendientes de entrega"
          :value="pending.length"
        ></Widget>
      </Widgets>
      <Layout>
        <List :loading="isLoading.buys">
          <BuyCard
            v-for="buy in pending"
            :key="buy._id"
            :buy="buy"
            :operationPermission="false"
          ></BuyCard>
        </List>
      </Layout>
      <Widgets>
        <Widget
          title="Todos los pedidos"
          :value="pagination.totalDocs"
        ></Widget>
      </Widgets>
      <Layout>
        <List :loading="isLoading.buys">
          <BuyCard
            v-for="buy in buys"
            :key="buy._id"
            :buy="buy"
            :operationPermission="false"
          ></BuyCard>
        </List>
      </Layout>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import Widgets from '../../components/Widgets/Widgets.vue';
import Widget from '../../components/Widgets/Widget.vue';
import ApiService from '../../services/ApiService';
import Layout from '../../components/Layout.vue';
import List from '../../components/List.vue';
import BuyCard from '../../components/Buy/BuyCard.vue';
import useBuyStore from '../../store/buy';
import Section from '../../components/Section.vue';

const { ProviderService } = ApiService;

export default {
  name: 'ProviderOrders',
  components: {
    Widgets,
    Widget,
    Layout,
    List,
    BuyCard,
    Section,
  },
  data() {
    return {
      buyStore: useBuyStore(),
      provider: null,
      params: {
        provider: null,
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    ...mapState(useBuyStore, ['options']),
    ...mapState(useBuyStore, ['pagination']),
    ...mapState(useBuyStore, ['isLoading']),
    ...mapState(useBuyStore, ['selected']),
    ...mapState(useBuyStore, ['pending']),
    ...mapState(useBuyStore, ['buys']),
    buys: {
      get() {
        return this.buyStore.buys;
      },
      set(value) {
        return this.buyStore.updatePositions(value, 'buys');
      },
    },
  },
  async mounted() {
    this.getNext();
    await this.fetchProvider();
    this.buyStore.findPending({ provider: this.provider._id });
    this.buyStore.find({ provider: this.provider._id });
  },
  methods: {
    async fetchProvider() {
      const { data: provider } = await ProviderService.findOne('own');
      this.provider = provider;
      this.params.provider = provider._id;
      return provider;
    },

    handleChangePage() {
      this.buyStore.changePage(this.params);
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.buys
          && this.pagination.hasNextPage
        ) {
          this.handleChangePage();
        }
      };
    },
  },
};
</script>
