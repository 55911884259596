<template>
  <div>
    <b-button type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';

export default {
  name: 'ExportDraft',
  props: {
    drafts: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    content() {
      return this.drafts.reduce((acc, draft) => {
        const {
          cart: { items },
        } = draft;
        acc.push(
          ...items.map((item) => ({
            number: draft.number,
            date: new Date(draft.date),
            customer: draft.name,
            product: item.product.name,
            raw: item.product,
            quantity: item.quantity,
            price: item.price,
            total: draft.total,
          })),
        );
        return acc;
      }, []);
    },
  },
  methods: {
    downloadFile() {
      const data = [
        {
          sheet: 'Pedidos',
          columns: [
            { label: 'ID', value: 'number' },
            {
              label: 'Fecha',
              value: (row) => row.date,
              format: 'DD',
            },
            {
              label: 'Periodo',
              value: (row) => row.date
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            { label: 'Cliente', value: 'customer' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
          ],
          content: [...this.content].sort((a, b) => a.number - b.number),
        },
        {
          sheet: 'Preparar',
          columns: [
            { label: 'ID', value: 'number' },
            { label: 'Cliente', value: 'customer' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
          ],
          content: [...this.content].sort((a, b) => (a.product < b.product ? -1 : 1)),
        },
      ];
      const settings = {
        fileName: `Pedidos - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
    },
  },
};
</script>
