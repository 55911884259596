<template>
  <div>
    <Section>
      <SearchBar
        :store="orderStore"
        :params="params"
        placeholder="✨ Buscá clientes y direcciones"
      ></SearchBar>
      <div class="flex justify-end">
        <ExportMyOrders></ExportMyOrders>
      </div> 
      <Layout>
        <List :loading="isLoading.orders">
          <OrderCard
            v-for="order in orders"
            :key="order._id"
            :order="order"
            :isCheckable="false"
            :operationPermission="false"
          ></OrderCard>
        </List>
      </Layout>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';

import useOrderStore from '../store/order';
import SearchBar from '../components/SearchBar.vue';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import OrderCard from '../components/Order/OrderCard.vue';
import Section from '../components/Section.vue';
import ExportMyOrders from '../components/Order/ExportMyOrders.vue';

export default {
  name: 'MyOrders',
  components: {
    SearchBar,
    Layout,
    List,
    OrderCard,
    Section,
    ExportMyOrders,
  },
  data() {
    return {
      orderStore: useOrderStore(),
      params: { noAdmin: true },
    };
  },
  computed: {
    ...mapState(useOrderStore, ['options']),
    ...mapState(useOrderStore, ['pagination']),
    ...mapState(useOrderStore, ['isLoading']),
    ...mapState(useOrderStore, ['orders']),
    orders: {
      get() {
        return this.orderStore.orders;
      },
      set(value) {
        return this.orderStore.updatePositions(value, 'orders');
      },
    },
  },
  async mounted() {
    this.getNext();
    this.orderStore.setQuery(null);
    await this.orderStore.find(this.params);
  },
  methods: {
    handleChangePage() {
      this.orderStore.changePage(this.params);
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.orders
          && this.pagination.hasNextPage
        ) {
          this.handleChangePage();
        }
      };
    },
  },
};
</script>

