import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { DeliveryService } = ApiService;

const useDeliveryStore = defineStore('delivery', {
  state: () => ({
    deliveries: [],
    selected: [],
    isLoading: {
      deliveries: false,
    },
    isFetched: {
      deliveries: false,
    },
    query: null,
    options: {
      page: 1,
      limit: 12,
    },
  }),
  actions: {
    async find(params = {}, { merge = false } = {}) {
      this.isLoading.deliveries = true;
      const { data: deliveries } = await DeliveryService.find({
        query: this.query,
        ...params,
      });
      this.isLoading.deliveries = false;
      this.isFetched.deliveries = true;
      this.deliveries = merge
        ? [...this.deliveries, ...deliveries]
        : deliveries;
    },

    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(deliveries) {
      this.deliveries = deliveries;
    },
    select(account) {
      this.selected.push(account);
    },
    deselect(account) {
      const index = this.selected.findIndex(({ _id }) => _id === account._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(account) {
      return this.deliveries.findIndex(({ _id }) => _id === account._id);
    },
    updatePositions(deliveries, key) {
      this[key] = deliveries;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const deliveriesCopy = [...this[key]];
        deliveriesCopy[index] = element;
        this[key] = deliveriesCopy;
      }
    },
    async disable(id) {
      const { data: updated } = await DeliveryService.disable(id);
      this.updateElement(updated, 'deliveries');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await DeliveryService.update(id, payload);
      this.updateElement(updated, 'deliveries');
      return updated;
    },
  },
});

export default useDeliveryStore;
