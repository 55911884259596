<template>
  <div>
    <Section>
      <p class="px-2 pb-4 text-xl">
        🗒 Pedidos borradores de <span class="font-bold">activate.ar</span>
      </p>
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <p
          class="p-4 bg-white rounded-lg shadow"
          v-if="isFetched.drafts && !drafts.length"
        >
          No tenés pedidos borradores para confirmar.
          <a
            class="text-purple-500 hover:text-purple-500"
            @click="() => $router.push('/my-orders')"
          >
            Ir a <b>Mis Pedidos</b></a
          >
        </p>
      </div>
      <Layout v-if="isFetched.drafts && drafts.length">
        <List :loading="isLoading.drafts">
          <DraftCard
            v-for="draft in drafts"
            :key="draft._id"
            :draft="draft"
            :isCheckable="false"
          />
        </List>
      </Layout>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';

// import SearchBar from '../components/SearchBar.vue';
import DraftCard from '../components/Order/mobile/DraftCard.vue';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
// import EmptySVG from '../assets/emtpy.svg';
import useDraftStore from '../store/draft';
import { DRAFT_STATES } from '../util/constants';
import Section from '../components/Section.vue';

export default {
  name: 'Drafts',
  components: {
    Section,
    // SearchBar,
    DraftCard,
    Layout,
    List,
  },
  data() {
    return {
      draftStore: useDraftStore(),
      loading: true,
      query: {},
      operationPermission: true,
      pagination: {},
      fetched: false,
      selected: [],
      options: {
        page: 1,
        limit: 12,
      },
      hasActions: false,
    };
  },
  computed: {
    ...mapState(useDraftStore, ['isFetched']),
    ...mapState(useDraftStore, ['isLoading']),
    ...mapState(useDraftStore, ['drafts']),
  },
  async mounted() {
    this.getNext();
    await this.draftStore.find({
      state: JSON.stringify([DRAFT_STATES.OPEN]),
    });
    this.fetched = true;
  },
  methods: {
    // handleSearch(results, query) {
    //   this.loading = false;
    //   this.query.query = query;
    //   this.orders = results.docs;
    //   this.options.page = results.page;
    //   this.pagination = results;
    // },
    getNext() {
      window.onscroll = () => {};
    },
  },
};
</script>
