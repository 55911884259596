/* eslint-disable import/prefer-default-export */
export const columns = [
  {
    field: 'number',
    label: '#',
    numeric: true,
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'name',
    label: 'Nombre',
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'address.street',
    label: 'Calle',
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'address.city',
    label: 'Ciudad',
    sortable: true,
    centered: true,
    searchable: true,
  },
  {
    field: 'address.province',
    label: 'Provincia',
    sortable: true,
    centered: true,
    searchable: true,
  },
];
