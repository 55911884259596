<template>
  <b-dropdown position="is-bottom-left">
    <b-button type="is-text" rounded size="is-small" slot="trigger">
      <b-icon icon="dots-vertical" />
    </b-button>
    <b-dropdown-item @click="handleCopyInvoice(order)" class="flex gap-2 flex-items-center"
      ><b-icon icon="whatsapp"></b-icon>
      Enviar comprobante
    </b-dropdown-item>
    <b-dropdown-item @click="handleSendAndCopyLogisticMessage(order)" class="flex gap-2 flex-items-center"
      ><b-icon icon="truck"></b-icon>
      Enviar aviso de logística
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAbleToUpdateDriver(order)"
      @click="handleUpdateDriver(order)"
      >Asignar repartidor
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAbleToUpdate(order)"
      @click="handleUpdateOrder(order)"
      >Editar pedido
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAbleToSuspend(order)"
      @click="handleSuspendOrder(order)"
      >Suspender pedido
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAbleToCancel(order)"
      @click="handleCancelOrder(order)"
      >Cancelar pedido
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAbleToRollback(order)"
      @click="handleRollbackOrder(order)"
    >
      <b-icon icon="undo"></b-icon>
      <span class="has-text-info"><b> Retroceder</b></span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { createInvoice, createLogisticMessage } from '../../util/helpers';
import { ORDER_STATES } from '../../util/constants';
import ApiService from '../../services/ApiService';
import useOrderStore from '../../store/order';

const { DeliveryService, OrderService } = ApiService;
const {
  PENDING, CONFIRMED, CANCELLED, PREPARED, 
} = ORDER_STATES;
export default {
  name: 'OrderOptions',
  props: {
    order: Object,
    operationPermission: { type: Boolean, default: false },
    driverPermission: { type: Boolean, default: false },
  },
  components: {},
  data() {
    return {
      orderStore: useOrderStore(),
    };
  },
  watch: {},
  computed: {},
  methods: {
    isAbleToUpdate({ state, blocked }) {
      return (
        !blocked
        && state !== CANCELLED
        && state !== PREPARED
        && !this.driverPermission
      );
    },
    isAbleToUpdateDeliveryDate({ state, blocked }) {
      return !blocked && state !== CANCELLED && state !== PREPARED && !this.driverPermission;
    },
    isAbleToClone() {
      return process.env.VUE_APP_ENV === 'local';
    },
    isAbleToPrepare({ state, blocked }) {
      return !blocked && state === CONFIRMED && this.operationPermission;
    },
    isAbleToUpdateDriver({ state, blocked, delivery }) {
      return delivery.isOwnLogistic && this.operationPermission && !blocked && state !== CANCELLED && (state === PREPARED || state === CONFIRMED);
    },
    isAbleToSuspend({ state, blocked }) {
      return !blocked && state === CONFIRMED && !this.driverPermission;
    },
    isAbleToCancel({ state, blocked }) {
      return !blocked && state !== CANCELLED && state !== PREPARED && !this.driverPermission;
    },
    isAbleToRollback({ state, blocked }) {
      return (
        !blocked
        && state !== CANCELLED
        && state !== PENDING
        && state === PREPARED
        // TODO: This should be only admin. not by operationPermission
        // Could lead on error if not props binded well
        && this.operationPermission
      );
    },
    async handleSendAndCopyLogisticMessage(order) {
      const logisticMessage = createLogisticMessage(order);

      await navigator.clipboard.writeText(logisticMessage);

      window
        .open(
          `https://wa.me/${order.delivery.phone}?text=${encodeURIComponent(
            logisticMessage,
          )}`,
          '_blank',
        )
        .focus();
    },
    async handleCopyInvoice(order) {
      const {
        data: { message },
      } = await DeliveryService.findMessage(
        // eslint-disable-next-line no-underscore-dangle
        order.delivery._id,
      );

      const invoice = createInvoice(order, message);

      this.$buefy.dialog.confirm({
        message: `¿Querés mandar el comprobante por whatsapp a ${order.customer.name}?`,
        cancelText: 'Em, nop',
        confirmText: 'vamo a darle',
        onConfirm: async () => {
          await navigator.clipboard.writeText(invoice);

          window
            .open(
              `https://wa.me/${order.delivery.phone}?text=${encodeURIComponent(
                invoice,
              )}`,
              '_blank',
            )
            .focus();
        },
      });
    },
    async handlePrepareOrder(order) {
      const orderIsNotFetched = order.cart.items.some(
        ({ product }) => typeof product === 'string' || product instanceof String,
      );

      if (orderIsNotFetched) {
        const {
          data: { cart },
          // eslint-disable-next-line no-underscore-dangle
        } = await OrderService.findOne(order._id, {
          onlyCart: true,
        });

        // eslint-disable-next-line no-param-reassign
        order.cart = cart;
      }

      this.orderToPrepare = order;

      this.$emit('prepare', order);
    },

    handleUpdateDriver(id) {
      this.$emit('updateDriver', id);
    },
    async handleOrderPrepared(id) {
      return this.handleSendUpdateNotification(id);
    },
    handleCloneOrder(order) {
      this.$router.push({ name: 'Cart', params: { ...order, isNew: true } });
    },
    async handleUpdateOrder(order) {
      const orderIsNotFetched = order.cart.items.some(
        ({ product }) => typeof product === 'string' || product instanceof String,
      );

      if (orderIsNotFetched) {
        const {
          data: { cart },
          // eslint-disable-next-line no-underscore-dangle
        } = await OrderService.findOne(order._id, {
          onlyCart: true,
        });

        // eslint-disable-next-line no-param-reassign
        order.cart = cart;
      }
      this.$router.push({ name: 'Cart', params: order });
    },
    async handleCancelOrder({ _id: id }) {
      this.$buefy.dialog.confirm({
        title: 'Cancelar pedido',
        message:
          '¿Estás seguro de <b>cancelar</b> este pedido? Esta acción no se puede retroceder',
        confirmText: 'Cancelar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          const updated = await this.orderStore.cancel(id);
          this.$emit('update', updated);
        },
      });
    },

    async handleSuspendOrder({ _id: id }) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Se está suspendiendo el pedido',
        position: 'is-bottom',
        type: 'is-warning',
        queue: false,
      });
      const updated = await this.orderStore.suspend(id);
      this.$emit('update', updated);
    },
    async handleRollbackOrder({ _id: id, state }) {
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Se está retrocediendo el pedido',
        position: 'is-bottom',
        type: 'is-warning',
        queue: false,
      });
      if (state === CONFIRMED) {
        const updated = await this.orderStore.suspend(id);
        this.$emit('update', updated);
      }
      if (state === PREPARED) {
        const updated = await this.orderStore.rollbackPrepare(id);
        this.$emit('update', updated);
      }
    },
  },
  mounted() {},
};
</script>
