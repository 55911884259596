<template>
  <div>
    <div class="card">
      <div class="card-content">
        <b-tag
          class="is-pulled-right"
          :type="(provider.status ? 'is-success' : 'is-danger') + ' is-light'"
        >
          {{ provider.status ? "Habilitado" : "Deshabilitado" }}</b-tag
        >
        <b-tag
          v-if="provider.account"
          class="mr-2 is-pulled-right"
          type="is-primary"
        >
          Cuenta Corriente</b-tag
        >
        <p class="title is-4">{{ provider.name }}</p>

        <p v-if="provider.cuit" class="title is-6">CUIT: {{ provider.cuit }}</p>
        <p class="is-italic subtitle is-5">
          {{ provider.address.street }}, {{ provider.address.city }}
        </p>
      </div>
      <footer class="card-footer" v-if="!isUpdating">
        <a @click="handleUpdate()" class="card-footer-item">Editar</a>
      </footer>
      <footer class="card-footer" v-if="isUpdating">
        <a @click="handleCancel()" class="card-footer-item">Cancelar edición</a>
      </footer>
    </div>

    <UpdateForm
      :provider="provider"
      :visible="isUpdating"
      @onUpdate="handleOnUpdate"
    />
  </div>
</template>

<script>
import UpdateForm from './UpdateForm.vue';

export default {
  name: 'ProviderInfo',
  props: {
    provider: Object,
  },
  components: {
    UpdateForm,
  },
  data() {
    return {
      isUpdating: false,
      isMarginsActive: false,
      openPrices: false,
      updated: null,
    };
  },
  computed: {},
  methods: {
    handleOnUpdate(provider) {
      this.$emit('reload', provider);
      this.isUpdating = false;
    },
    handleCancel() {
      this.isUpdating = false;
    },
    handleUpdate() {
      this.isUpdating = true;
    },
  },
};
</script>
