<template>
  <div>
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="drafts"
      :narrowed="true"
      :striped="true"
      :loading="loading"
      :show-detail-icon="false"
      ref="table"
      detailed
    >
      <b-table-column
        :visible="!partner"
        field="partner.name"
        label="Socio"
        centered
        sortable
        v-slot="props"
      >
        {{ props.row.partner.name }}
      </b-table-column>

      <b-table-column
        :visible="partner"
        field="customer.name"
        label="Cliente"
        centered
        sortable
        v-slot="props"
      >
        {{ props.row.customer.name }}
      </b-table-column>

      <b-table-column
        field="delivery.address"
        label="Dirección"
        centered
        v-slot="props"
      >
        {{ props.row.delivery.address.label }}
      </b-table-column>

      <b-table-column field="state" label="Estado" centered sortable>
        <b-tag type="is-warning is-light">Pendiente</b-tag>
      </b-table-column>

      <b-table-column
        field="total"
        label="Total"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.cart.total }}
      </b-table-column>

      <b-table-column
        field="cart.items.length"
        label="Items"
        centered
        sortable
        v-slot="props"
      >
        <b-button size="is-small" @click="toggleItems(props.row)"
          >{{ props.row.cart.items.length }} producto{{
            props.row.cart.items.length > 1 ? "s" : ""
          }}</b-button
        >
      </b-table-column>

      <b-table-column field="control" v-slot="props" :visible="partner">
        <b-dropdown position="is-bottom-left">
          <b-button type="is-text" rounded size="is-small" slot="trigger">
            <b-icon icon="dots-vertical" />
          </b-button>

          <b-dropdown-item @click="handleConfirmDraft(props.row)"
            >Confirmar borrador</b-dropdown-item
          >
        </b-dropdown>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <div class="columns">
          <div class="column is-6">
            <ItemsTable :cart="props.row.cart"> </ItemsTable>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import ItemsTable from './ItemsTable.vue';

export default {
  name: 'OrderTable',
  props: {
    drafts: Array,
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemsTable,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    toggleItems(row) {
      this.$refs.table.toggleDetails(row);
    },
    handleConfirmDraft(draft) {
      this.$router.push({
        name: 'Cart',
        params: { ...draft, isDraft: true, isNew: true },
      });
    },
  },
  mounted() {},
};
</script>
