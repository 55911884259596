/* eslint-disable prefer-destructuring */
import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';

const { AuditService } = ApiService;

const useAuditStore = defineStore('audit', {
  state: () => ({
    partnersAudit: [],
    partnerAudit: { transactions: [] },
    providerAudit: { transactions: [] },
    selected: [],
    isLoading: {
      partnerAudit: false,
      providerAudit: false,
    },
    isFetched: {
      providerAudit: false,
      partnerAudit: false,
    },
    query: null,
  }),
  actions: {
    async getPartnersAudit(params = {}, key = 'partnersAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getPartnerAudit({
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = result.docs.sort((a, b) => a.name.localeCompare(b.name));
    },
    async getPartnerAudit(params = {}, key = 'partnerAudit') {
      this.isLoading[key] = true;
      const { data: result } = await AuditService.getPartnerAudit({
        ...params,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = result.docs[0];
    },
    async performPartnerAudit(params = {}) {
      this.isLoading.partnerAudit = true;
      await AuditService.performPartnerAudit({
        ...params,
      });
      await this.getPartnerAudit(params);
    },
    async getProviderAudit(params = {}) {
      this.isLoading.providerAudit = true;
      const { data: result } = await AuditService.getProviderAudit({
        ...params,
      });
      this.isLoading.providerAudit = false;
      this.isFetched.providerAudit = true;
      this.providerAudit = result.docs[0];
    },
    async performProviderAudit(params = {}) {
      this.isLoading.providerAudit = true;
      await AuditService.performProviderAudit({
        ...params,
      });
      await this.getProviderAudit(params);
    },
  },
});

export default useAuditStore;
