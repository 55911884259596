<template>
  <div>
    <li
      class="mt-2 text-sm text-gray-600 cursor-pointer dark:text-gray-300"
      @click="handleSelect(element)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="inline w-6 h-6 align-center"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
      <span v-if="element[property] && element[property].date" class="ml-2">{{
        element[property].date | shortDate
      }}</span>
      <span v-if="!element[property] || !element[property].date" class="ml-2">{{
        "Sin fecha"
      }}</span>

      <span v-if="element[property].startTime && element[property].endTime">
      (
        <span class="">{{ new Date(element[property].startTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) }}</span> - 
        <span class="">{{ new Date(element[property].endTime).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) }}</span>
      )
    </span>
    <span v-if="!element[property].startTime && !element[property].endTime" class="text-red-500">
      (
      00:00 - 00:00
      )
    </span>
      <svg
        v-if="isAbleToUpdateLogisticDate(element)"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="inline w-4 h-4 mb-1 ml-2 text-indigo-600 align-center"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
        />
      </svg>
    </li>
  </div>
</template>

<script>
import { ORDER_STATES, BUY_STATES } from '../util/constants';
import { isOrder, isBuy } from '../util/helpers';

export default {
  name: 'LogisticDateComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
    property: {
      type: String,
      default: 'delivery',
    },
    operationPermission: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {},
  data() {
    return {
      isUpdatingDeliveryDate: false,
      isUpdatingWithdrawalDate: false,
    };
  },
  computed: {
    isOrder() {
      return isOrder(this.element);
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  methods: {
    handleUpdate(element) {
      this.$emit('update', element);
    },
    handleDismiss() {
      this.isUpdatingDeliveryDate = false;
      this.isUpdatingWithdrawalDate = false;
    },
    handleSelect(element) {
      if (!this.isAbleToUpdateLogisticDate(element)) return;
      if (isOrder(element)) {
        this.isUpdatingDeliveryDate = true;
      } else {
        this.isUpdatingWithdrawalDate = true;
      }
    },
    isAbleToUpdateLogisticDate(element) {
      const { state, blocked } = element;
      if (isOrder(element)) {
        if (
          (element.partner._id === this.currentUser._id
            || element.partner === this.currentUser._id)
          && !blocked
          && state !== ORDER_STATES.CANCELLED
          && state !== ORDER_STATES.PREPARED
        ) return true;

        return (
          !blocked
          && state !== ORDER_STATES.CANCELLED
          && this.operationPermission
        );
      }
      if (isBuy(element)) {
        return (
          !blocked
          && state !== BUY_STATES.CANCELLED
          && state !== BUY_STATES.APPROVED
          && this.operationPermission
        );
      }
      return false;
    },
  },
  watch: {},
};
</script>
