<template>
  <div>
    <b-button :loading="isLoading" type="is-primary is-light" @click="downloadFile">
      <b-icon icon="export-variant" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import xlsx from 'json-as-xlsx';
import { ORDER_STATES } from '../../util/constants';
import ApiService from '../../services/ApiService';
import { sum } from '../../util/helpers';

const { OrderService } = ApiService;

const { CANCELLED } = ORDER_STATES;

export default {
  name: 'ExportMyOrders',
  props: {},
  components: {},
  data() {
    return {
      orders: [],
      isLoading: false,
    };
  },
  computed: {
    analyticColumns() {
      return [
        { label: 'PEDIDO', value: (order) => this.getOrderType(order) },
        { label: 'ID', value: 'number' },
        {
          label: 'Fecha',
          value: (order) => new Date(order.date),
          format: 'DD',
        },
        {
          label: 'Periodo',
          value: (order) => new Date(order.date)
            .toLocaleDateString('es-ES', {
              month: 'long',
            })
            .toUpperCase(),
        },
        {
          label: 'AÑO',
          value: (order) => new Date(order.date).toLocaleDateString('es-ES', {
            year: 'numeric',
          }),
        },
        { label: 'Cliente', value: (order) => order.customer.name },
        { label: 'Vendedor', value: (order) => order.partner.username },
        {
          label: 'BI',
          value: (order) => this.setDefaultIfCancelled(order, 'cost'),
        },
        {
          label: 'Aportes',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            order.contribution - order.delivery.internPrice,
          ),
        },
        {
          label: 'Logística',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            order.logistic + order.delivery.internPrice,
          ),
        },

        {
          label: 'IMP',
          value: (order) => this.setDefaultIfCancelled(
            order,
            null,
            sum(order.cart.items, null, (item) => (item.detail.taxes ? item.detail.taxes.total : 0)),
          ),
        },

        {
          label: 'Total a rendir',
          value: (order) => this.setDefaultIfCancelled(order, 'toPay'),
        },
        {
          label: 'Ganancia',
          value: (order) => this.setDefaultIfCancelled(order, 'profit'),
        },
        {
          label: 'Total',
          value: (order) => this.setDefaultIfCancelled(order, 'total'),
        },
        { label: 'Tipo de envio', value: (order) => order.delivery.name },
      ];
    },
    content() {
      return this.orders.reduce((acc, order) => {
        const {
          cart: { items },
        } = order;
        acc.push(
          ...items.map((item) => ({
            type: this.getOrderType(order),
            number: order.number,
            date: new Date(order.date),
            customer: order.customer.name,
            product: item.product.name,
            raw: item.product,
            quantity: item.quantity,
            price: item.price,
            cost: item.detail.cost,
            taxBase: item.detail.taxBase,
            taxes: item.detail.taxes ? item.detail.taxes.total : 0,
            contribution: item.detail.contribution + order.delivery.internPrice,
            profit: item.detail.profit,
            logistic: order.logistic - order.delivery.internPrice,
            partner: order.partner.username,
            deliveryDate: new Date(order.delivery.date),
            deliveryMethod: order.delivery.name,
            total: order.total,
          })),
        );
        return acc;
      }, []);
    },
  },
  methods: {
    async fetchOrders() {
      const { data: pagination } = await OrderService.find({
        page: 1,
        limit: 99999,
      });

      this.orders = pagination.docs;

      return pagination.docs;
    },
    setDefaultIfCancelled(order, key, defaultValue = 0) {
      // eslint-disable-next-line no-nested-ternary
      return order.state === CANCELLED ? 0 : key ? order[key] : defaultValue;
    },
    getOrderType(order) {
      if (order.isBudget) return 'PRES';
      if (order.cart.items.some(({ isWholesaler }) => isWholesaler)) {
        return 'MAY';
      }
      if (order.cart.items.some(({ isWholesaler }) => !isWholesaler)) {
        return 'MIN';
      }
      return 'UNKWOWN';
    },
    async downloadFile() {
      this.isLoading = true;
      await this.fetchOrders();
      const data = [
        {
          sheet: 'v1',
          columns: [
            { label: 'ID', value: 'number' },
            {
              label: 'Fecha',
              value: (row) => row.date,
              format: 'DD',
            },
            {
              label: 'Periodo',
              value: (row) => row.date
                .toLocaleDateString('es-ES', {
                  month: 'long',
                })
                .toUpperCase(),
            },
            {
              label: 'AÑO',
              value: (row) => row.date.toLocaleDateString('es-ES', {
                year: 'numeric',
              }),
            },
            { label: 'Cliente', value: 'customer' },
            { label: 'Producto', value: 'product' },
            { label: 'Cantidad', value: 'quantity' },
            { label: 'Precio', value: 'price' },
            { label: 'OK', value: '' },
            { label: 'Aportes', value: 'contribution' },
            { label: 'Ganancia', value: 'profit' },
            { label: 'Vendedor', value: 'partner' },
            {
              label: 'Fecha de entrega',
              value: (row) => row.deliveryDate
                .toLocaleDateString('es-ES', {
                  weekday: 'long',
                })
                .toUpperCase(),
            },
            { label: 'Metodo de entrega', value: 'deliveryMethod' },
          ],
          content: [...this.content].sort((a, b) => b.number - a.number),
        },
        {
          sheet: 'Analítico',
          columns: this.analyticColumns,
          content: [...this.orders].sort((a, b) => b.number - a.number),
        },
        
      ];
      const settings = {
        fileName: `Pedidos - ${new Date().toLocaleDateString('es')}`,
      };
      xlsx(data, settings);
      this.isLoading = false;
    },
  },
};
</script>
