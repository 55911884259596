import axios from 'axios';

const {
  VUE_APP_CMS_API_URL: baseURL,
  VUE_APP_CMS_API_KEY: apiKey,
} = process.env;

if (!baseURL) throw Error('VUE_APP_CMS_API_URL NOT CONFIGURED');

const cmsService = axios.create({
  baseURL,
  timeout: 5000,
  headers: {
    Authorization: `Bearer ${apiKey} `,
  },
});

export default cmsService;
