<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <Delivery />
      <Withdrawal />
      <PayMethod class="mt-8" />
      <ChargeMethod class="mt-8" />
    </Section>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import PayMethod from '../components/Settings/PayMethod.vue';
import Delivery from '../components/Settings/Delivery.vue';
import Withdrawal from '../components/Settings/Withdrawal.vue';
import ChargeMethod from '../components/Settings/ChargeMethod.vue';
import Section from '../components/Section.vue';

export default {
  name: 'Settings',
  components: {
    HeroBar,
    PayMethod,
    Delivery,
    Withdrawal,
    ChargeMethod,
    Section,
  },
  data() {
    return {
      hasActions: false,
    };
  },
  computed: {},
  mounted() {
    window.onscroll = () => {};
  },
  methods: {},
};
</script>
