import Vue from 'vue';

import { formatAddress, getDayOfWeek, round } from './util/helpers';

Vue.filter('friendlyUnity', ({ quantity, product }) => {
  if (!product) return '';
  // eslint-disable-next-line no-param-reassign
  quantity = round(quantity);
  return {
    UNITY: `${quantity} ${quantity > 1 ? 'u' : 'u'}`,
    WEIGHT: `${quantity >= 1000 ? `${quantity / 1000} Kg` : `${quantity} g`} `,
  }[product.unit];
});

Vue.filter('friendlyUnit', ({ quantity, product }) => {
  if (!product) return '';
  return {
    UNITY: 'unid',
    WEIGHT: `${quantity >= 1000 ? 'Kg' : ' grs'} `,
  }[product.unit];
});

Vue.filter('unit', ({ unit }) => {
  if (!unit) return '';
  return {
    UNITY: ' unid',
    WEIGHT: ' grs',
  }[unit];
});

Vue.filter('formatAddress', formatAddress);

Vue.filter('rounded', (value) => round(value));

Vue.filter('capitalize', (value) => {
  if (!value) return '';

  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
});

Vue.filter('currency', (value, minimumFractionDigits = 2) => {
  if (!value && value !== 0) return '';

  return round(value).toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits,
  });
});

Vue.filter('dayOfWeek', (value) => getDayOfWeek(value));

Vue.filter('completeDate', (value) => {
  if (!value) return '';
  const date = new Date(value);
  let completeDate = {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miercoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sabado',
    7: 'Domingo',
  }[date.getDay()];

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate()
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  };

  const isTomorrow = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() + 1
      && someDate.getMonth() === today.getMonth()
      && someDate.getFullYear() === today.getFullYear()
    );
  };

  if (isToday(date)) {
    completeDate = `Hoy - ${completeDate}`;
  }
  if (isTomorrow(date)) {
    completeDate = `Mañana - ${completeDate}`;
  }
  return completeDate;
});

Vue.filter('shortDate', (value) => {
  if (!value) return '';
  return new Date(value).toLocaleDateString('es');
});
Vue.filter('longDate', (value) => {
  if (!value) return '';
  return new Date(value).toLocaleDateString('es', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
});

Vue.filter('truncate', (value, length, clamp) => (value.length > length ? value.slice(0, length) + clamp : value));

Vue.filter('shortDatetime', (value) => {
  if (!value) return '';
  return new Date(value).toLocaleString('es');
});
