<!-- eslint-disable max-len -->
<template>
  <div class="p-2 bg-white border-l-2 border-green-400 shadow-lg sm:rounded-lg">
    <div class="grid grid-cols-3 gap-4 rounded-lg">
      <div class="col-span-2">
        <p class="p-1 text-xs">{{ flow.date | longDate }}</p>
        <p class="flex items-center py-1 text-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="flex-shrink-0 w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
            />
          </svg>
          <span class="flex-grow ml-2 text-sm font-bold"
            >{{ getTitle(flow) }}{{ flow.paymentMethod }}</span
          >
        </p>

        <p class="flex items-center py-1 text-md" v-if="flow.description">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="flex-none inline w-6 h-6 align-center"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 
                    2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <span class="flex-grow ml-2 text-sm truncate">{{
            flow.description
          }}</span>
        </p>

        <!-- <div v-if="element.entryType === 'receipt'">
            <p class="flex items-center py-1 text-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>

              <span class="flex-grow ml-2 text-sm font-bold"
                >Factura emitida</span
              >
            </p>
          </div>
          <div v-if="element.code" class="col-span-4 my-2 text-sm">
            <span>Comprobante N°</span>
            <a
              class="
                text-md text-gray-900
                dark:text-white
                hover:underline
                border-2 border-purple-400
                rounded-md
                p-0.5
                px-1
                mx-1
              "
              @click="element.buy && handleSelect(element.buy)"
              ># {{ element.code }}</a
            >
          </div> -->

        <!-- <p class="flex items-center py-1 text-md" v-if="element.buy">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>
              <span class="flex-grow ml-2 "
                ># {{ element.buy.number }}</span
              >
            </p> -->
      </div>
      <div class="flex flex-col items-end justify-center">
        <p class="ml-2 text-xl font-bold text-purple-500">
          {{ flow.total | currency }}
        </p>
        <p class="text-sm">
          {{ flow.current | currency }}
        </p>
      </div>
    </div>

    <!-- <Details
      :active="isDetailsOpen"
      :buy="elementSelected"
      @dismiss="handleDismissDetails"
    ></Details> -->
  </div>
</template>

<script>
// import Details from '../../../components/Buy/mobile/Details.vue';

export default {
  name: 'FlowCard',
  props: {
    flow: { type: Object },
    operationPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
  },
  components: {
    // Details,
  },
  data() {
    return {
      details: false,
      isDetailsOpen: false,
      elementSelected: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getDescription(flow) {
      return {
        'commercial.partner': 'Pagaste en',
      }[flow.type];
    },
    getTitle(flow) {
      return {
        'commercial.partner': 'Rendiste en ',
      }[flow.type];
    },
    handleSelect(element) {
      this.elementSelected = element;
      this.isDetailsOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.elementSelected = null;
    },
  },
  watch: {},
};
</script>
