<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <Widgets>
        <Widget
          title="Stock valorizado en Activate"
          :value="statistics.inventory"
          prefix="$"
          help="Stocks para la venta x Pago a productor"
        ></Widget>
      </Widgets>
      <Layout>
        <List :loading="isLoading.products">
          <!-- <p class="px-2 text-2xl font-bold">Productos</p> -->
          <ProductCard
            v-for="product in products"
            :key="product._id"
            :product="product"
            :isCheckable="false"
            :operationPermission="false"
            :providerPermission="true"
          ></ProductCard>
        </List>
      </Layout>
    </Section>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import Widgets from '../../components/Widgets/Widgets.vue';
import Widget from '../../components/Widgets/Widget.vue';
import HeroBar from '../../components/HeroBar.vue';
import ApiService from '../../services/ApiService';
import Layout from '../../components/Layout.vue';
import List from '../../components/List.vue';
import ProductCard from '../../components/Product/mobile/ProductCard.vue';
import useProductStore from '../../store/product';
import Section from '../../components/Section.vue';

const { ProviderService } = ApiService;

const { StatisticsService } = ApiService;

export default {
  name: 'ProviderInventory',
  components: {
    HeroBar,
    Widgets,
    Widget,
    Layout,
    List,
    ProductCard,
    Section,
  },
  data() {
    return {
      productStore: useProductStore(),
      hasActions: false,
      provider: null,
      loading: false,
      statistics: {},
    };
  },
  computed: {
    ...mapState(useProductStore, ['products']),
    ...mapState(useProductStore, ['isLoading']),
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
    hasShortage() {
      return this.products.some(
        (product) => product.inventory[0].theoretical > 0,
      );
    },
  },
  async mounted() {
    await this.fetchProvider();
    await this.productStore.find({
      limit: 999,
      provider: this.provider._id,
    });
    await this.fetchStatistics();
  },
  methods: {
    async fetchProvider() {
      const { data: provider } = await ProviderService.findOne('own');
      this.provider = provider;
      return provider;
    },
    async fetchStatistics() {
      const { data: statistics } = await StatisticsService.providerBalance(
        // eslint-disable-next-line no-underscore-dangle
        this.provider._id,
      );
      this.statistics = statistics;
    },
  },
};
</script>
