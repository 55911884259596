<template>
  <div>
    <div id="invoices" style="display: none">
      <div v-for="order in orders" :key="order._id" class="printable-item">
        <div class="column is-12">
          <Invoice :order="order" />
        </div>
      </div>
    </div>

    <b-button type="is-primary is-light" @click="print">
      <b-icon icon="receipt" type="is-primary"
    /></b-button>
  </div>
</template>

<script>
import Invoice from './Invoice.vue';

export default {
  name: 'PrintInvoices',
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Invoice,
  },
  data() {
    return {};
  },
  mounted() {
    // add a page break before each item except the first one
    const printableItems = document.querySelectorAll('.printable-item');
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < printableItems.length; i++) {
      printableItems[i].style.pageBreakBefore = 'always';
    }
  },
  computed: {},
  methods: {
    async print() {
      await this.$htmlToPaper('invoices');
    },
  },
};
</script>

<style scoped>
@media print {
  .printable-item {
    page-break-inside: avoid;
  }
}
</style>
