const Joi = require('@hapi/joi');

const schema = Joi.object({
  name: Joi.string()
    .min(1)
    .max(100)
    .required(),
});

const defaultData = {
  name: null,
  price: null,
};

module.exports = {
  schema,
  defaultData,
};
