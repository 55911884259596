<template>
  <div class="card" v-if="visible">
    <div class="card-content">
      <div class="mt-2 is-clearfix">
        <b-field label="Nombre">
          <b-input ref="nameInput" v-model="updated.name"></b-input>
        </b-field>

        <div class="columns">
          <div class="column is-one-third">
            <b-field label="Dirección">
              <b-input v-model="updated.address.street" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Ciudad">
              <b-input v-model="updated.address.city" type="text"></b-input>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Provincia">
              <b-input v-model="updated.address.province" type="text"></b-input>
            </b-field>
          </div>
        </div>
        <b-field label="Mínimo de compra">
          <b-numberinput
            :controls="false"
            v-model="updated.minimumPurchase"
            type="number"
          ></b-numberinput>
        </b-field>
        <b-field label="Costo de envío">
          <b-numberinput
            :controls="false"
            v-model="updated.logisticPrice"
            type="number"
          ></b-numberinput>
        </b-field>
        <b-field label="Descripción">
          <b-input
            maxlength="200"
            type="textarea"
            v-model="updated.description"
          ></b-input>
        </b-field>
        <b-field label="Mensaje destacado">
          <b-input type="text" v-model="updated.featuredMessage"></b-input>
        </b-field>
        <b-checkbox
          v-model="updated.account"
          class="is-pulled-right"
          :rounded="true"
          :outlined="true"
          type="is-success"
          :left-label="true"
        ></b-checkbox>
        <b-field label="Cuenta Corriente"> </b-field>
        <b-field label="CUIT">
          <b-input v-model="updated.cuit" type="text"></b-input>
        </b-field>
        <b-field label="Telefono">
          <PhoneInput v-model="updated.phone" :phone="updated.phone" />
        </b-field>
        <b-checkbox
          v-model="updated.catalog"
          class="is-pulled-right"
          :rounded="true"
          :outlined="true"
          type="is-success"
          :left-label="true"
        ></b-checkbox>
        <b-field label="Catalogo"> </b-field>

        <b-field label="Slug">
          <b-input v-model="updated.slug" type="text"></b-input>
        </b-field>

        <b-field label="Imagen">
          <b-input v-model="updated.imageUrl" type="text"></b-input>
        </b-field>
        <b-field label="Color">
          <b-input v-model="updated.color" type="text"></b-input>
        </b-field>

        <b-field
          v-for="link of updated.links"
          :key="link.name"
          :label="link.name"
        >
          <b-input v-model="link.url" type="text"></b-input>
        </b-field>

        <b-field label="Observaciones">
            <b-input
              v-model="updated.notes"
              maxlength="255"
              type="textarea"
            ></b-input>
          </b-field>


        <div class="mt-1">
          <Button
            v-if="updated.status"
            :disabled="isSendingRequest"
            type="is-danger"
            :onClickFn="handleDisable"
            >Deshabilitar</Button
          >

          <Button
            v-if="!updated.status"
            type="is-success"
            :disabled="isSendingRequest"
            :onClickFn="handleEnable"
            >Habilitar</Button
          >
          <Button
            class="is-pulled-right"
            type="is-success"
            :onClickFn="handleUpdate"
            >Guardar</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { schemas } from 'activate-schemas';
import ApiService from '../../services/ApiService';
import { omit, validatePayload } from '../../util/helpers';
import { immutableProperties } from '../../util/constants';
import PhoneInput from '../PhoneInput.vue';

const { ProviderService } = ApiService;

const {
  provider: { providerSchema: schema },
} = schemas;

export default {
  name: 'UpdateForm',
  props: {
    provider: Object,
    visible: Boolean,
  },
  components: {
    PhoneInput,
  },
  data() {
    return {
      updated: {
        ...this.provider,
        links: this.provider.links.length
          ? this.provider.links
          : [
            { name: 'instagram', url: null },
            { name: 'facebook', url: null },
            { name: 'phone', url: null },
            { name: 'email', url: null },
            { name: 'url', url: null },
          ],
      },
      isSendingRequest: false,
    };
  },
  watch: {
    provider(provider) {
      this.updated = { ...provider };
      if (!this.updated.notes) {
        this.updated.notes = 'PRESUPUESTO: SI/NO\nLOGÍSTICA:\nOBSERVACIONES:';
      }
    },
    'updated.slug': function () {
      this.updated.slug = this.textToUrlSlug(this.updated.slug);
    },

    visible(visible) {
      if (visible) {
        if (!this.updated.notes) {
          this.updated.notes = 'PRESUPUESTO: SI/NO\nLOGÍSTICA:\nOBSERVACIONES:';
        }
      }
    },
  },
  computed: {},
  methods: {
    textToUrlSlug(text) {
      // Convert to lowercase
      let slug = text.toLowerCase();

      // Replace accented characters with unaccented counterparts
      slug = slug.replace(/[áéíóú]/g, (char) => {
        const unaccentedChar = {
          á: 'a',
          é: 'e',
          í: 'i',
          ó: 'o',
          ú: 'u',
        };
        return unaccentedChar[char];
      });

      // Replace ñ with n
      slug = slug.replace(/ñ/g, 'n');

      // Replace non-alphanumeric characters with dashes
      slug = slug.replace(/[^a-zA-Z0-9-]/g, '-');

      // Remove consecutive dashes
      slug = slug.replace(/-+/g, '-');

      // Remove leading and trailing dashes
      slug = slug.replace(/^-+|-+$/g, '');

      return slug;
    },

    async handleDisable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await ProviderService.disable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleEnable() {
      this.isSendingRequest = true;
      const { _id: id } = this.updated;
      await ProviderService.enable(id);
      this.$emit('onUpdate', id);
      this.isSendingRequest = false;
    },
    async handleUpdate() {
      const { _id: id } = this.updated;
      const payload = omit(immutableProperties.provider, this.updated);
      if (!validatePayload(payload, schema)) return;
      // TODO: Factorize to receive provider from response
      try {
        const { error } = await ProviderService.update(id, payload);

        if (error) {
          console.error(error.data.code);
          return;
        }
      } catch (err) {
        return;
      }

      this.$emit('onUpdate', id);
    },
  },
};
</script>
