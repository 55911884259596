import { defineStore } from 'pinia';
import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { UserService } = ApiService;

const useUserStore = defineStore('user', {
  state: () => ({
    users: [],
    drivers: [],
    selected: [],
    isLoading: {
      users: false,
      drivers: false,
    },
    isFetched: {
      users: false,
      drivers: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false, key = 'users' } = {}) {
      this.isLoading[key] = true;
      const { data: { docs: users } } = await UserService.find({
        ...params,
        page: 1,
        limit: 99999,
      });
      this.isLoading[key] = false;
      this.isFetched[key] = true;
      this[key] = merge ? [...this[key], ...users] : users;
    },
    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(users) {
      this.users = users;
    },
    select(user) {
      this.selected.push(user);
    },
    deselect(user) {
      const index = this.selected.findIndex(({ _id }) => _id === user._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(user) {
      return this.users.findIndex(({ _id }) => _id === user._id);
    },
    updatePositions(users, key) {
      this[key] = users;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    changePage(params) {
      this.options.page += 1;
      this.find(
        { query: this.query, page: this.options.page, ...params },
        { merge: true },
      );
    },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const copy = [...this[key]];
        copy[index] = element;
        this[key] = copy;
      }
    },
    async disable(id) {
      const { data: updated } = await UserService.disable(id);
      this.updateElement(updated, 'users');
      return updated;
    },
    async enable(id) {
      const { data: updated } = await UserService.enable(id);
      this.updateElement(updated, 'users');
      return updated;
    },
    async update(id, payload) {
      const { data: updated } = await UserService.update(id, payload);
      this.updateElement(updated, 'users');
      return updated;
    },
  },
});

export default useUserStore;
