import { render, staticRenderFns } from "./UpdateChargeMethod.vue?vue&type=template&id=4e0b60ba"
import script from "./UpdateChargeMethod.vue?vue&type=script&lang=js"
export * from "./UpdateChargeMethod.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports