import { defineStore } from 'pinia';

import ApiService from '../services/ApiService';
import { mapPosition } from '../util/helpers';

const { OrderService } = ApiService;

const useDraftService = defineStore('draft', {
  state: () => ({
    drafts: [],
    selected: [],
    isLoading: {
      drafts: false,
    },
    isFetched: {
      drafts: false,
    },
    pagination: {},
    options: {
      page: 1,
      limit: 12,
    },
    query: null,
  }),
  actions: {
    async find(params = {}, { merge = false, sort = 1 } = {}) {
      this.isLoading.drafts = true;
      const { data: { docs: drafts } } = await OrderService.findDraft({
        ...params,
        limit: 999999,
      });
      this.isLoading.drafts = false;
      this.isFetched.drafts = true;
      this.drafts = merge ? [...this.drafts, ...drafts] : drafts;

      this.drafts = this.drafts.sort((a, b) => (sort ? a.number - b.number : b.number - a.number));
    },

    setPage(value) {
      this.options.page = value;
    },
    setKey(key, value) {
      this[key] = value;
    },
    setQuery(value) {
      this.query = value;
    },
    set(drafts) {
      this.drafts = drafts;
    },
    // selectDay(day) {
    //   this.drafts
    //     .filter(({ delivery }) => delivery.date === day)
    //     .forEach((draft) => this.select(draft));
    // },
    // deselectDay(day) {
    //   this.drafts
    //     .filter(({ delivery }) => delivery.date === day)
    //     .forEach((draft) => this.deselect(draft));
    // },
    select(draft) {
      this.selected.push(draft);
    },
    deselect(draft) {
      const index = this.selected.findIndex(({ _id }) => _id === draft._id);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    },
    searchIndex(draft) {
      return this.drafts.findIndex(({ _id }) => _id === draft._id);
    },
    updatePositions(drafts, key) {
      this[key] = drafts;

      const sortedId = this[key].map(({ _id }) => _id);

      this.selected = mapPosition(this.selected, sortedId, '_id');
    },

    // changePage(params) {
    //   this.options.page += 1;
    //   this.find(
    //     { query: this.query, page: this.options.page, ...params },
    //     { merge: true },
    //   );
    // },
    deselectAll() {
      this.selected = [];
    },
    updateElement(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        const draftsCopy = [...this[key]];
        draftsCopy[index] = element;
        this[key] = draftsCopy;
      }
    },
    removeFrom(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index !== -1) {
        this[key].splice(index, 1);
      }
    },
    addTo(element, key) {
      const index = this[key].findIndex(({ _id }) => _id === element._id);
      if (index === -1) {
        this[key].unshift(element);
      }
    },
    async confirm(id) {
      const { data: updated } = await OrderService.confirmDraft(id);
      this.removeFrom(updated, 'drafts');
      return updated;
    },

    async remove(id) {
      const { data: updated } = await OrderService.removeDraft(id);
      this.removeFrom(updated, 'drafts');
      this.removeFrom(updated, 'selected');
      return updated;
    },

    // async cancel(id) {
    //   const { data: updated } = await OrderService.closeDraft(id);
    //   this.updateElement(updated, 'drafts');

    //   return updated;
    // },
  },
});

export default useDraftService;
