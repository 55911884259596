<template>
  <div class="relative">
    <button
      type="button"
      class="absolute top-0 right-0 z-50 bg-white border-2 border-purple-500 rounded-lg"
      @click="fetchCashBalances"
    >
      <b-icon icon="refresh"></b-icon>
    </button>
    <tiles v-if="cashBalances.length">
      <card-widget
        v-for="balance of cashBalances"
        :key="balance.paymentMethod"
        class="tile is-child"
        type="is-primary"
        icon="credit-card-outline"
        :number="round(balance.balance)"
        prefix="$"
        :label="balance.paymentMethod"
        :text="`Balance de ${balance.paymentMethod}`"
        :isFetching="isLoading"
      />
    </tiles>
  </div>
</template>

<script>
import Tiles from '../Tiles.vue';
import CardWidget from '../CardWidget.vue';
import ApiService from '../../services/ApiService';
import { round } from '../../util/helpers';

const { StatisticsService } = ApiService;

export default {
  name: 'CashBalancesWidgets',
  components: {
    Tiles,
    CardWidget,
  },
  data() {
    return {
      cashBalances: [],
      isFetched: false,
      isLoading: false,
    };
  },
  computed: {},
  async mounted() {
    await this.fetchCashBalances();
    this.isFetched = true;
  },
  methods: {
    round,
    async fetchCashBalances() {
      this.isLoading = true;
      const { data: balances } = await StatisticsService.cashBalances();
      this.cashBalances = balances.sort((a, b) => a.paymentMethod.localeCompare(b.paymentMethod));
      this.isLoading = false;
    },
  },
};
</script>
