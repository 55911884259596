<template>
  <footer v-show="isFooterBarVisible" class="footer b-0">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div class="footer-copyright">
              <b class="text-xs"
                >Hecho con ♡ por
                <img
                  class=""
                  src="https://activate-s3.s3.amazonaws.com/catalog/bvitx.png"
                  width="28"
                  alt="bvitx"
                />
              </b>
              <!-- &copy; {{ year }} -->
              &mdash;
              <span class="tag">v{{ version }}</span>
            </div>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="logo">
              <a href="https://activate.ar"><b>activate.ar</b></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'FooterBar',
  computed: {
    year() {
      return dayjs().year();
    },
    isFooterBarVisible() {
      return this.$store.state.Ui.isFooterBarVisible;
    },
    version() {
      return process.env.VERSION;
    },
  },
  mounted() {},
};
</script>
