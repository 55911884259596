<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b-field class="is-pulled-right">
          <b-input placeholder="Nombre" v-model="newPayMethod.name"></b-input>
          <p class="control">
            <Button
              data-cy="create-payMethod"
              type="is-primary"
              :onClickFn="handleAddPayMethod"
              >Agregar</Button
            >
          </p>
        </b-field>
        <p class="title is-4">Metodos de pago</p>
      </div>
    </div>

    <div class="card" v-if="payMethods.length">
      <div class="card-content">
        <div class="grid grid-cols-1 border-none divide-y-0">
          <div v-for="payMethod of payMethods" :key="payMethod._id">
            <p class="is-pulled-right title is-6">
              <b-button
                size="is-small"
                icon-right="pencil"
                @click="handleUpdatePayMethod(payMethod)"
              >
              </b-button>
              <button
                @click="handleRemovePayMethod(payMethod._id)"
                class="ml-4 delete"
              ></button>
            </p>
            <p class="title is-6">
              {{ payMethod.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <UpdatePayMethod
      :active="updatePayMethodModalIsActive"
      :payMethod="payMethodToUpdate"
      @dismiss="updatePayMethodModalIsActive = false"
    />
  </div>
</template>

<script>
import { schema, defaultData } from '../../schemas/payMethod';
import { validatePayload, disconnectedCopy } from '../../util/helpers';
import ApiService from '../../services/ApiService';
import UpdatePayMethod from '../PayMethod/UpdatePayMethod.vue';

const { PayMethodService } = ApiService;

export default {
  name: 'PayMethod',
  components: {
    UpdatePayMethod,
  },
  data() {
    return {
      payMethods: [],
      newPayMethod: disconnectedCopy(defaultData),
      updatePayMethodModalIsActive: false,
      payMethodToUpdate: null,
    };
  },
  computed: {},
  mounted() {
    this.fetchPayMethods();
  },
  methods: {
    async fetchPayMethods() {
      const { data: payMethods } = await PayMethodService.find({
        status: true,
      });
      this.payMethods = payMethods;
    },
    parseContributesTagType(contributes) {
      return contributes ? 'is-success is-light' : 'is-danger is-light';
    },
    async handleAddPayMethod() {
      const payload = this.newPayMethod;
      if (!validatePayload(payload, schema)) return;
      await PayMethodService.create(payload);
      await this.fetchPayMethods();
      this.newPayMethod = disconnectedCopy(defaultData);
    },
    async handleUpdatePayMethod(payMethod) {
      this.payMethodToUpdate = payMethod;
      this.updatePayMethodModalIsActive = true;
    },
    async handleRemovePayMethod(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar metodo de pago',
        message:
          '¿Estás seguro de <b>eliminar</b> este metodo de pago? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await PayMethodService.disable(id);
          await this.fetchPayMethods();
        },
      });
    },
  },
};
</script>
