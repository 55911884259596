<template>
  <b-dropdown position="is-bottom-left">
    <b-button type="is-text" rounded size="is-small" slot="trigger">
      <b-icon icon="dots-vertical" />
    </b-button>
    <b-dropdown-item @click="handleCopyInvoice(buy)"
      ><b-icon icon="whatsapp"></b-icon>Enviar comprobante
    </b-dropdown-item>
    <b-dropdown-item @click="handleSendAndCopyLogisticMessage(buy)" class="flex gap-2 flex-items-center"
      ><b-icon icon="truck"></b-icon>
      Enviar aviso de logística
    </b-dropdown-item>
    
    <b-dropdown-item v-if="isAbleToApprove(buy)" @click="handleApproveBuy(buy)"
      >Aprobar compra</b-dropdown-item
    >
    <b-dropdown-item
      v-if="isAbleToCreateReceipt(buy)"
      @click="handleCreateReceipt(buy)"
      >Generar comprobante</b-dropdown-item
    >



    <b-dropdown-item v-if="isAbleToUpdate(buy)" @click="handleUpdateBuy(buy)"
      >Editar compra</b-dropdown-item
    >
    <b-dropdown-item v-if="isAbleToCommit(buy)" @click="handleCommitBuy(buy)"
      >Pre-aprobar stock</b-dropdown-item
    >
    <b-dropdown-item v-if="isAbleToCancel(buy)" @click="handleCancelBuy(buy)"
      >Cancelar compra</b-dropdown-item
    >
    <b-dropdown-item
      v-if="isAbleToRollback(buy)"
      @click="handleRollbackBuy(buy)"
    >
      <b-icon icon="undo"></b-icon>
      <span class="has-text-info"><b> Retroceder</b></span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { createBuyInvoice, createBuyLogisticMessage } from '../../util/helpers';
import { BUY_STATES } from '../../util/constants';
import useBuyStore from '../../store/buy';
import ApiService from '../../services/ApiService';

const { ReceiptService } = ApiService;
const { PENDING, CANCELLED, APPROVED } = BUY_STATES;
export default {
  name: 'BuyOptions',
  props: {
    buy: Object,
    operationPermission: { type: Boolean, default: false },
    driverPermission: { type: Boolean, default: false },
  },
  components: {},
  data() {
    return {
      buyStore: useBuyStore(),
    };
  },
  watch: {},
  computed: {},
  methods: {
    async handleApproveBuy(buy) {
      this.isApproving = true;
      this.buyToApprove = buy;
      this.$emit('approve', buy);
    },
    async handleSendAndCopyLogisticMessage(buy) {
      const logisticMessage = createBuyLogisticMessage(buy);

      await navigator.clipboard.writeText(logisticMessage);

      window
        .open(
          `https://wa.me/${buy.provider.phone}?text=${encodeURIComponent(
            logisticMessage,
          )}`,
          '_blank',
        )
        .focus();
    },
    handleUpdateBuy(buy) {
      this.$router.push({
        name: 'BuyCart',

        params: {
          // eslint-disable-next-line no-underscore-dangle
          id: buy._id,
          items: buy.cart.items,
          provider: buy.provider,
          withdrawal: buy.withdrawal,
          isBudget: buy.isBudget,
          notes: buy.notes,
        },
      });
    },
    
    async handleCommitBuy({ _id: id }) {
      this.$buefy.dialog.confirm({
        title: 'Pre-aprobar stock',
        message:
          '¿Estás seguro de <b>pre-aprobar</b> este el stock de compra? Esta acción no se puede retroceder',
        confirmText: 'Pre aprobar',
        cancelText: 'Emm, no',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => {
          const updated = await this.buyStore.commit(id);
          this.$emit('update', updated);
        },
      });
    },
    async handleCancelBuy({ _id: id }) {
      this.$buefy.dialog.confirm({
        title: 'Cancelar compra',
        message:
          '¿Estás seguro de <b>cancelar</b> este compra? Esta acción no se puede retroceder',
        confirmText: 'Cancelar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          const updated = await this.buyStore.cancel(id);
          this.$emit('update', updated);
        },
      });
    },
    async handleRollbackBuy({ state, _id: id }) {
      if (state === APPROVED) {
        this.$buefy.dialog.confirm({
          title: 'Retroceder aprobación de compra',
          message:
            '¿Estás seguro de <b>retroceder</b> la aprobación de esta compra?',
          confirmText: 'Retroceder',
          cancelText: 'Emm, no',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'Se está retrocediendo la compra',
              position: 'is-bottom',
              type: 'is-warning',
              queue: false,
            });
            const updated = await this.buyStore.rollbackApprove(id);
            this.$emit('update', updated);
          },
        });
      }
    },
    async handleCreateReceipt(buy) {
      const { data: { docs: receipts } } = await ReceiptService.find({
        // eslint-disable-next-line no-underscore-dangle
        buy: buy._id,
      });
      if (receipts.length) {
        this.$emit('dismiss');
        this.$buefy.notification.open({
          duration: 5000,
          message: `Está compra ya tiene un comprobante asignado.<br><b>#${
            receipts[0].code
          }</b> - <b>${new Date(receipts[0].date).toLocaleDateString(
            'es',
          )}</b>`,
          position: 'is-bottom-right',
          type: 'is-warning',
          hasIcon: true,
        });
        return;
      }
      this.$emit('receipt');
    },
    isAbleToCommit({ state, committed }) {
      return (
        state === PENDING && !committed && this.operationPermission
      );
    },
    isAbleToUpdate({ state }) {
      return (
        state !== CANCELLED && state !== APPROVED && this.operationPermission
      );
    },
    isAbleToCreateReceipt({ state }) {
      return state === APPROVED && this.operationPermission;
    },
    isAbleToApprove({ state, withdrawal }) {
      return state === PENDING && withdrawal.date && this.operationPermission;
    },
    isAbleToCancel({ state }) {
      return (
        state !== CANCELLED && state !== APPROVED && this.operationPermission
      );
    },
    isAbleToRollback({ state }) {
      return (
        state !== CANCELLED && state !== PENDING && this.operationPermission
      );
    },
    async handleCopyInvoice(buy) {
      const invoice = createBuyInvoice(buy);
      await navigator.clipboard.writeText(invoice);

      window
        .open(
          `https://wa.me/${buy.provider.phone}?text=${encodeURIComponent(
            invoice,
          )}`,
          '_blank',
        )
        .focus();
    },
  },
  mounted() {},
};
</script>
