<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <div class="card">
        <div class="card-content">
          <Button
            pulled="right"
            type="is-primary"
            :onClickFn="fetchPartnersBoard"
            icon="refresh"
          ></Button>

          <p class="title">Balance de AVs</p>
          <LoadingTable :active="!isFetched.partners" />
          <PartnersTable
            v-if="isFetched.partners"
            :loading="isLoading.partners"
            :finances="partnerBalances"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <Button
            pulled="right"
            type="is-primary"
            :onClickFn="fetchProviderBalances"
            icon="refresh"
          ></Button>
          <p class="title">Balance de proveedores</p>
          <LoadingTable :active="!isFetched.providers" />
          <ProviderBalances
            v-if="isFetched.providers"
            :loading="isLoading.providers"
            :balances="providerBalances"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <Button
            pulled="right"
            type="is-primary"
            :onClickFn="fetchCashBalances"
            icon="refresh"
          ></Button>
          <p class="title">Saldos de caja</p>
          <LoadingTable :active="!isFetched.balances" />
          <CashBalances
            v-if="isFetched.balances"
            :loading="isLoading.balances"
            :balances="cashBalances"
          />
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <Button
            pulled="right"
            type="is-primary"
            :onClickFn="fetchCreditors"
            icon="refresh"
          ></Button>
          <p class="title">Tabla de acreedores</p>
          <LoadingTable :active="!isFetched.creditors" />
          <CreditorsTable
            v-if="isFetched.creditors"
            :loading="isLoading.creditors"
            :creditors="creditors"
          />
        </div>
      </div>
    </Section>
  </div>
</template>

<script>
import HeroBar from '../components/HeroBar.vue';
import ApiService from '../services/ApiService';
import PartnersTable from '../components/Finances/PartnersTable.vue';
import ProviderBalances from '../components/Statistics/ProviderBalances.vue';
import CashBalances from '../components/Statistics/CashBalances.vue';
import CreditorsTable from '../components/Statistics/CreditorsTable.vue';
import Section from '../components/Section.vue';

const { StatisticsService, FinanceService } = ApiService;

export default {
  name: 'Statistics',
  components: {
    HeroBar,
    PartnersTable,
    ProviderBalances,
    CashBalances,
    CreditorsTable,
    Section,
  },
  data() {
    return {
      partnerBalances: [],
      providerBalances: [],
      cashBalances: [],
      creditors: [],
      hasActions: false,
      isLoading: {
        providers: false,
        partners: false,
        balances: false,
        creditors: false,
      },
      isFetched: {
        providers: false,
        partners: false,
        balances: false,
        creditors: false,
      },
    };
  },
  computed: {},
  async mounted() {
    window.onscroll = () => {};
    await this.fetchPartnersBoard();
    this.isFetched.partners = true;
    await this.fetchProviderBalances();
    this.isFetched.providers = true;
    await this.fetchCashBalances();
    this.isFetched.balances = true;
    await this.fetchCreditors();
    this.isFetched.creditors = true;
  },
  methods: {
    async fetchCreditors() {
      this.isLoading.creditors = true;
      const { data: creditors } = await StatisticsService.creditors();
      this.creditors = creditors.sort((a, b) => a.third.name.localeCompare(b.third.name));
      this.isLoading.creditors = false;
    },
    async fetchCashBalances() {
      this.isLoading.balances = true;
      const { data: balances } = await StatisticsService.cashBalances();
      this.cashBalances = balances;
      this.isLoading.balances = false;
    },
    async fetchPartnersBoard() {
      this.isLoading.partners = true;
      const { data: balances } = await FinanceService.partnersBoard();
      this.partnerBalances = balances.sort((a, b) => a.partner.name.localeCompare(b.partner.name));
      this.isLoading.partners = false;
    },
    async fetchProviderBalances() {
      this.isLoading.providers = true;
      const { data: balances } = await StatisticsService.providerBalances();
      this.providerBalances = balances.sort((a, b) => a.name.localeCompare(b.name));
      this.isLoading.providers = false;
    },
  },
};
</script>
