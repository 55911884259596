<template>
  <div>
    <div class="card">
      <div class="card-content">
        <b-tag
          v-if="!partner.status"
          class="is-pulled-right"
          type="is-danger is-light"
        >
          Inhabilitado</b-tag
        >
        <b-tag
          class="mr-2 is-pulled-right"
          :type="
            (partner.wholesaler ? 'is-success' : 'is-primary') + ' is-light'
          "
        >
          {{ partner.wholesaler ? "Mayorista" : "Minorista" }}</b-tag
        >
        <p class="title is-4">
          {{ partner.name }}
        </p>

        <p class="is-italic subtitle is-5 is-pulled-left">
          {{ partner.username }}, {{ partner.role }}
        </p>
      </div>
      <footer class="card-footer" v-if="!isUpdating">
        <a @click="handleUpdate()" class="card-footer-item">Editar</a>
      </footer>
      <footer class="card-footer" v-if="isUpdating">
        <a @click="handleCancel()" class="card-footer-item">Cancelar edición</a>
      </footer>
    </div>

    <UpdateForm
      :partner="partner"
      :visible="isUpdating"
      @onUpdate="handleOnUpdate"
    />
  </div>
</template>

<script>
import UpdateForm from './UpdateForm.vue';

export default {
  name: 'ProductInfo',
  props: {
    partner: Object,
  },
  components: {
    UpdateForm,
  },
  data() {
    return {
      isUpdating: false,
      isMarginsActive: false,
      openPrices: false,
      updated: null,
    };
  },
  computed: {},
  methods: {
    handleOnUpdate(updatedUser) {
      this.$emit('update', updatedUser);
      this.isUpdating = false;
    },
    handleCancel() {
      this.isUpdating = false;
    },
    handleUpdate() {
      this.isUpdating = true;
    },
  },
};
</script>
