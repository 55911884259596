<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions"> </hero-bar>
    <Section>
      <div class="grid grid-cols-1 space-x-4 lg:grid-cols-3">
        <div class="flex flex-col col-span-2 gap-4">
          <ProductLookup
            v-if="provider"
            ref="productLookup"
            @select="handleSelectProduct"
            :selectedProduct="selectedProduct"
            :provider="provider._id"
          ></ProductLookup>
          <AddItemToCart
            ref="addItemsToCart"
            :product="selectedProduct"
            :isBudget="isBudget"
            type="buy"
            @add="handleAddItemToCart"
          />
          <ItemsDetails
            v-if="cart.items.length"
            :cart="cart"
            :showTotal="true"
            :canRemoveItems="true"
            :withdrawal="withdrawal"
            :showDetails="true"
            @removeItem="handleRemoveItem"
          ></ItemsDetails>
        </div>
        <div class="flex flex-col gap-4">
          <div v-if="isAdmin">
            <b-field :label="isBudget ? 'Presupuesto ' : 'Facturado'">
              <b-switch
                v-model="isBudget"
                :rounded="true"
                :outlined="true"
                type="is-success"
                :left-label="true"
                passive-type="is-primary"
              ></b-switch>
            </b-field>
          </div>

        <div class="p-4 border-2 border-purple-400 rounded-lg" v-if="provider && provider.notes && provider.notes.length">
          <div v-for="(note, index) of provider.notes.split('\n')" :key="index">
          <p>{{ note }}</p></div>
        </div>
          <ProviderLookup
            @select="handleSelectProvider"
            v-if="!provider"
          ></ProviderLookup>
          <ProviderInfo
            :provider="provider"
            :canRemove="!isUpdatingBuy"
            @removeProvider="handleRemoveProvider"
            v-if="provider"
          ></ProviderInfo>
          <Withdrawal v-model="withdrawal" :withdrawal="withdrawal" />
          <b-field label="Observaciones de la compra" class="mt-2">
            <b-input
              v-model="notes"
              pattern="[a-z]*"
              maxlength="200"
              type="textarea"
            ></b-input>
          </b-field>
          <div class="is-clearfix" v-if="cart.items.length">
            <div class="buttons is-pulled-right">
              <Button
                data-cy="create-buy"
                type="is-dark"
                :onClickFn="isUpdatingBuy ? handleUpdateBuy : handleCheckoutBuy"
                >Confirmar</Button
              >
            </div>
          </div>
        </div>
      </div>
    </Section>
    <!-- <pre>{{ isUpdatingBuy }}</pre> -->
  </div>
</template>

<script>
import ActivateSDK, { schemas } from 'activate-schemas';
import Section from '../components/Section.vue';
import HeroBar from '../components/HeroBar.vue';
import ProductLookup from '../components/Cart/ProductLookup.vue';
import ProviderLookup from '../components/Cart/ProviderLookup.vue';
import AddItemToCart from '../components/Cart/AddItemToCart.vue';
import ItemsDetails from '../components/Cart/ItemsDetails.vue';
import ProviderInfo from '../components/Cart/ProviderInfo.vue';

import Withdrawal from '../components/Cart/Withdrawal.vue';
import ApiService from '../services/ApiService';
import { disconnectedCopy, validatePayload } from '../util/helpers';

const { BuyService } = ApiService;

const {
  buy: { buySchema: schema },
} = schemas;

const {
  models: { Cart },
} = ActivateSDK;

export default {
  name: 'BuyCart',
  props: {},
  components: {
    Section,
    HeroBar,
    ProductLookup,
    ProviderLookup,
    AddItemToCart,
    ItemsDetails,
    ProviderInfo,
    Withdrawal,
  },
  data() {
    return {
      products: [],
      cart: new Cart([], 'buy', this.isBudget),
      withdrawal: {
        name: null,
        date: null,
        charged: true,
        fee: 0,
      },
      total: 0,
      selectedProduct: null,
      provider: null,
      isUpdatingBuy: false,
      buyId: null,
      notes: null,
      isEditing: false,
      hasActions: false,
      isBudget: true,
    };
  },
  watch: {
    isBudget() {
      this.cart.billItems('buy', false, {
        isBudget: this.isBudget,
      });
    },
  },
  computed: {
    isAdmin() {
      return this.currentUser.role === 'ADMIN';
    },
    currentUser() {
      return this.$store.getters['Auth/currentUser'] || {};
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      if (
        !window.confirm(
          ' Se van a descartar los cambios \n¿Estás seguro que querés salir?',
        )
      ) {
        return;
      }
    }
    next();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNav);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav);
    });
  },
  mounted() {
    window.onscroll = () => {};
    this.isPreCart();
    this.isUpdateBuy();
  },
  methods: {
    handleRemoveItem(index) {
      this.isEditing = true;
      this.cart.removeItem(index, 'buy');
      this.cart.billItems('buy', false, {
        isBudget: this.isBudget,
      });
    },
    isPreCart() {
      if (!this.$route.params.items && !this.$route.params.provider) return;
      const { items, provider } = this.$route.params;

      this.cart = new Cart(
        items.filter(({ quantity }) => quantity > 0),
        'buy',
        this.isBudget,
      );
      this.provider = provider;
    },
    isUpdateBuy() {
      if (!this.$route.params.id) return;
      this.isUpdatingBuy = true;
      const {
        id, items, provider, withdrawal, notes, isBudget, 
      } = this.$route.params;

      this.buyId = id;
      this.cart = new Cart(items, 'buy', this.isBudget);
      this.provider = provider;
      this.withdrawal = withdrawal;
      this.isBudget = isBudget;
      this.withdrawal.date = this.withdrawal.date && new Date(this.withdrawal.date);
      this.notes = notes;
    },
    handleSelectProduct(product) {
      if (!product) return;
      this.isEditing = true;
      this.selectedProduct = disconnectedCopy(product);

      this.$refs.addItemsToCart.setFocusQuantity();
    },
    handleRemoveProvider() {
      this.isEditing = true;
      this.provider = null;
      this.cart = new Cart([], 'buy', this.isBudget);
    },
    handleSelectProvider(provider) {
      this.isEditing = true;
      this.provider = provider;
    },
    handleAddItemToCart(item) {
      this.isEditing = true;
      this.cart.addItem(item, 'buy');
      this.cart.billItems('buy', false, {
        isBudget: this.isBudget,
      });
      this.$nextTick(() => {
        this.selectedProduct = null;
      });
    },
    async handleCheckoutBuy() {
      const {
        cart,
        provider: { _id: provider },
        withdrawal,
        isBudget,
        notes,
      } = this;

      const clonedCart = new Cart(cart.items, 'buy', this.isBudget);

      clonedCart.depopulateProducts();

      const payload = {
        cart: clonedCart,
        provider,
        withdrawal,
        isBudget,
        notes,
      };

      if (!validatePayload(payload, schema)) return;

      await BuyService.create(payload);
      this.isEditing = false;
      this.$router.push('/buys');
    },
    async handleUpdateBuy() {
      const {
        cart, notes, withdrawal, isBudget, 
      } = this;

      cart.depopulateProducts();

      const payload = {
        cart,
        notes,
        isBudget,
        withdrawal,
      };

      await BuyService.update(this.buyId, payload);
      this.isEditing = false;
      this.$router.push('/buys');
    },
  },
};
</script>
