<template>
  <div class="">
    <div>
      <b-field label="Socio" v-if="selectedUser">
        <div
          class="flex flex-row items-center justify-between p-1 px-3 bg-white border-2 rounded-lg"
        >
          <div>
            <p class="text-lg font-bold">{{ selectedUser.username }}</p>
            <p class="text-xs">{{ selectedUser.name }}</p>
          </div>
          <b-button
            v-if="canRemove"
            class="flex items-center justify-center border-none"
            @click="select(null)"
            ><b-icon icon="close"></b-icon
          ></b-button>
        </div>
      </b-field>

      <b-field :label="label" v-if="!selectedUser">
        <b-autocomplete
          data-cy="users-lookup"
          v-model="userToSearch"
          ref="userNameInput"
          field="username"
          :data="filteredUsers"
          @select="select"
          :keep-first="true"
          icon="account"
          :loading="!users.length"
        ></b-autocomplete
      ></b-field>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';

const { UserService } = ApiService;
export default {
  name: 'UserLookup',
  props: {
    label: {
      type: String,
      default: 'Buscar un socio',
    },
    selectedUser: Object,
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      userToSearch: (this.selectedUser && this.selectedUser.username) || '',
      users: [],
    };
  },

  computed: {
    filteredUsers() {
      if (!this.userToSearch) return [];
      return [...this.users]

        .filter(
          ({ username, name }) => `${username} ${name}`
            .toLowerCase()
            .indexOf(this.userToSearch.toLowerCase()) >= 0,
        )
        .sort((a, b) => {
          const name1 = a.username.toLowerCase();
          const name2 = b.username.toLowerCase();
          if (name1 < name2) return -1;
          if (name1 > name2) return 1;
          return 0;
        });
    },
  },
  watch: {
    selectedUser() {
      if (!this.selectedUser) {
        this.userToSearch = '';
        this.$nextTick(() => {
          this.$refs.userNameInput.focus();
        });
      }
    },
  },
  methods: {
    select(user) {
      this.$emit('select', JSON.parse(JSON.stringify(user)));
    },
  },
  async mounted() {
    const { data: { docs: users } } = await UserService.find({ status: true, limit: 99999 });
    this.users = users;
  },
};
</script>
