var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:`grid grid-cols-1 divide-y-2 divide-${
      _vm.open ? 'yellow' : 'purple'
    }-${_vm.open ? '500' : '400'} mt-5`},[_c('span'),_c('span')]),_c('div',{staticClass:"mt-4 is-clearfix",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('b-icon',{staticClass:"is-pulled-right",staticStyle:{"padding-bottom":"7px"},attrs:{"icon":_vm.open ? 'chevron-up' : 'chevron-down'}}),_c('p',{staticClass:"title is-6"},[_vm._v("Detalle")])],1),(_vm.open)?_c('div',{staticClass:"is-clearfix"},[_c('section',{staticClass:"mt-4 mr-2"},[(_vm.hasDiscount)?_c('div',{staticClass:"p-2 mb-2 text-lg font-medium text-white rounded-lg bg-gradient-to-br from-purple-500 to-pink-500"},[_c('p',{staticClass:"font-bold is-pulled-right"},[_vm._v(" - "+_vm._s(_vm._f("currency")(_vm.discountAmount))+" ")]),_vm._m(0)]):_vm._e(),_c('p',{staticClass:"is-pulled-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.costs)))]),_vm._m(1),_c('p',{staticClass:"is-pulled-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.contributions - _vm.discountAmount)))+" ")]),_vm._m(2),_c('p',{staticClass:"is-pulled-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.taxes)))]),_vm._m(3),_c('p',{staticClass:"is-pulled-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.logistic)))]),_vm._m(4),_c('p',{staticClass:"is-pulled-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.toPay - _vm.discountAmount)))+" ")]),_vm._m(5),_c('p',{staticClass:"is-pulled-right"},[_vm._v(_vm._s(_vm._f("currency")(_vm.profits)))]),_vm._m(6),_c('div',{staticClass:"mt-2 is-clearfix"},[_c('p',{staticClass:"title is-pulled-right"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.total - _vm.discountAmount)))+" ")]),_c('p',{staticClass:"title is-pulled-left"},[_vm._v("Total")])])])]):_vm._e(),(_vm.open)?_c('div',{staticClass:"grid grid-cols-1 divide-y-2 divide-purple-400"},[_c('span'),_c('span')]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Descuento de aportes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Costo:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Aportes Activate:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Impuestos:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Logística:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Total rendición:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Ganancia:")])])
}]

export { render, staticRenderFns }