<template>
  <GenericModal :active="active" @dismiss="handleDismiss">
    <b-field label="Día">
      <b-datepicker
        :mobile-native="false"
        position="is-bottom-left"
        placeholder="Fecha"
        locale="es-ES"
        v-model="date"
        :nearby-selectable-month-days="true"
      >
      </b-datepicker>
    </b-field>
    <b-field label="Repartidor">
      <b-autocomplete
        v-model="driverToSearch"
        ref="input"
        field="name"
        :keep-first="true"
        :append-to-body="false"
        :open-on-focus="true"
        :data="drivers"
        @select="handleSelect"
        :loading="!drivers.length"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-left"></div>
            <div class="media-content">
              {{ props.option.name }}
            </div>
          </div>
        </template></b-autocomplete
      >
    </b-field>
    <b-field label="Notas">
      <b-input
        v-model="notes"
        pattern="[a-z]*"
        maxlength="200"
        type="textarea"
      ></b-input>
    </b-field>
    <Button type="is-success" :onClickFn="handleCreateRoadmap">Generar</Button>
  </GenericModal>
</template>

<script>
import { mapState } from 'pinia';
import GenericModal from './GenericModal.vue';
import useUserStore from '../store/user';
import useRoadmapStore from '../store/roadmap';

export default {
  name: 'NewRoadmapModal',
  props: {
    active: Boolean,
  },
  components: {
    GenericModal,
  },
  data() {
    return {
      roadmapStore: useRoadmapStore(),
      driverToSearch: '',
      driver: null,
      notes: null,
      date: new Date(),
    };
  },
  watch: {},
  computed: {
    ...mapState(useUserStore, ['drivers']),
  },
  methods: {
    handleDismiss() {
      this.$emit('dismiss');
    },
    async handleCreateRoadmap() {
      const {
        driver: { _id: driver },
        date,
        notes,
      } = this;
      await this.roadmapStore.create({ driver, notes, date });

      this.driverToSearch = '';
      this.driver = null;
      this.notes = null;
      this.handleDismiss();
    },
    handleSelect(driver) {
      this.driver = driver;
    },
  },
  mounted() {},
};
</script>
