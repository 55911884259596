<template>
  <b-modal
    :active="active"
    :width="640"
    :can-cancel="['escape', 'outside']"
    @close="$emit('dismiss')"
  >
    <div class="p-4" v-if="element">
      <section class="border-2 border-indigo-400 rounded-lg modal-card-body">
        <div class="flex items-center justify-between my-1">
          <div class="flex flex-col">
            <span class="text-lg font-bold"># {{ element.number }} </span>
            <span
              v-if="element.delivery && element.delivery.delivered"
              class="text-sm text-indigo-500"
              >(Entregado - {{ element.delivery.date | shortDate }})</span
            >
          </div>
          <div class="flex flex-col items-end">
            <span
              class="text-lg font-medium text-green-500"
              v-if="element.isSupply"
              >Socio</span
            >
            <span
              class="text-lg font-medium text-yellow-500"
              v-if="!element.isSupply && element.cart.items.some(({ isWholesaler }) => isWholesaler)"
              >Mayorista</span
            >
            <span
              v-if="element.isBudget"
              class="text-lg font-medium text-indigo-500"
              >Presupuesto</span
            >
          </div>
        </div>
        <a
              v-if="element.committed"
              class="text-xs text-white-700 font-bold bg-blue-200 dark:text-white hover:underline border-2 border-blue-500 rounded-md p-0.5 px-1 mr-1"
              >PRE-APROBADO</a
            >
        <div v-if="isOrder" class="flex items-center justify-between my-2">
          <span class="text-xl font-bold"
            >{{ isOrder ? element.customer.name : element.provider.name }}
          </span>
        </div>

        <div class="py-4 my-2">
          <div
            v-for="item of [...element.cart.items].sort((a, b) => (a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1))"
            :key="item.number"
            class="flex flex-row justify-start gap-2 text-xs rounded-md"
          >
            <span class="flex-none w-10">{{ item | friendlyUnity }}</span>

            <div class="flex-grow px-2 font-medium truncate">
              {{ item.product.name }}
            </div>
            <span class="flex-none" v-if="isOrder"
              >${{ `${item.detail.unitCost} x `
              }}{{
                {
                  ...item,
                  quantity: item.detail.unitQuantity,
                } | friendlyUnity
              }}</span
            >

            <span class="flex-none" v-if="isBuy"
              >${{ `${item.detail.metadata.taxBase} x u/kg`
              }}</span
            >
        
            <div class="font-bold tracking-tight">
              {{ item.price | currency }}
            </div>
          </div>
          <div class="flex flex-col pt-4 gap-0.5 text-sm">
            <template v-if="isBuy">
              <div class="flex justify-between">
                <span class="font-bold">Base imponible</span>
                <span class="font-bold">{{ taxBase | currency }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-bold">Impuestos</span>
                <span class="font-bold">{{ taxes | currency }}</span>
              </div>
            </template>
            <div class="flex justify-between">
              <span class="font-bold">Subtotal</span>
              <span class="font-bold">{{ element.cart.total | currency }}</span>
            </div>
            <div
              class="flex justify-between font-bold"
              v-if="isOrder && discountAmount"
            >
              <p>Descuento</p>
              <p>-{{ discountAmount | currency }}</p>
            </div>
            <!-- <div class="flex justify-between">
              <p>Impuestos</p>
              <p>{{ taxes | currency }}</p>
            </div> -->
            <div v-if="element.delivery" class="flex justify-between font-bold">
              <span>Envío</span>
              <span v-if="element.delivery.charged">{{
                element.delivery.customerPrice | currency
              }}</span>
              <span v-if="!element.delivery.charged">Bonificado</span>
            </div>
            <div
              v-if="element.withdrawal"
              class="flex justify-between font-bold"
            >
              <span>Envío</span>
              <span v-if="element.withdrawal.charged"
                >- {{ element.withdrawal.fee | currency }}</span
              >
              <span v-if="!element.withdrawal.charged">Bonificado</span>
            </div>
            <div class="flex justify-between text-xl font-bold">
              <span>Total</span>
              <span> {{ element.total | currency }}</span>
            </div>
          </div>
        </div>

        <div class="py-4 my-2" v-if="isOrder">
          <p class="text-lg font-bold">Detalle del pedido</p>
          <div class="flex flex-col py-1 gap-0.5 text-sm">
            <div class="flex justify-between">
              <p>
                Costo de mercadería
                <b-tooltip label="Esto es lo que se le paga a los productores">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>+ {{ element.cost | currency }}</p>
            </div>
            <div class="flex justify-between" v-if="element.contribution">
              <p>
                Comisión de Activate
                <b-tooltip label="Con esto Activate se financia">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>
                +
                {{
                  (element.contribution - element.delivery.internPrice)
                    | currency
                }}
              </p>
            </div>
            <div class="flex justify-between">
              <p>
                Impuestos
                <b-tooltip label="Esto le corresponde al fisco">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>+ {{ taxes | currency }}</p>
            </div>
            <div class="flex justify-between" v-if="isOrder">
              <p>
                Logística
                <b-tooltip label="Esto se le paga a quien entrega">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>
                +
                {{
                  (element.logistic + element.delivery.internPrice) | currency
                }}
              </p>
            </div>
            <div class="flex justify-between text-lg font-bold" v-if="isOrder">
              <p>
                Suma de rendiciones:
                <b-tooltip label="El total de lo que pagamos a cada actor">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>= {{ element.toPay | currency }}</p>
            </div>
          </div>
        </div>

        <div class="py-4 my-2" v-if="isOrder">
          <div class="flex flex-col py-1 gap-0.5 text-xl font-bold">
            <div class="flex justify-between" v-if="isOrder">
              <p>
                Ganancia
                <b-tooltip label="¡Esta es tu ganancia!"> 🤩 </b-tooltip>
              </p>
              <p>{{ element.profit | currency }}</p>
            </div>
            <div class="flex justify-between">
              <p>
                Total
                <b-tooltip label="Lo que paga el cliente">
                  <b-icon icon="help-circle-outline" custom-size="small" />
                </b-tooltip>
              </p>
              <p>{{ element.total | currency }}</p>
            </div>
          </div>
        </div>

        <div class="" v-if="element.notes && element.notes.length">
          <div class="p-4 my-2 text-sm rounded-md shadow">
            <span class="text-lg font-bold">Notas</span>
            <div>{{ element.notes }}</div>
          </div>
        </div>

        <div
          v-if="element.metadata && Object.entries(element.metadata).length"
          class="p-2 my-2 rounded-lg shadow"
        >
          <p class="text-lg font-bold">
            Modificaciones {{ isOrder ? ' del pedido' : 'de la compra' }}
          </p>

          <div
            v-for="[event, items] in Object.entries(element.metadata)"
            :key="event"
            class="p-2 my-2 rounded-lg shadow"
          >
            <div class="text-xs">
              {{ event | shortDatetime }}
            </div>
            <p v-if="shortages(items).length" class="mt-1 text-xs font-bold">
              Faltantes
            </p>
            <div v-for="item of shortages(items)" :key="item._id">
              <!-- <div v-if="!item.state">
                  <p class="mt-2 text-xs font-bold" v-if="item.quantity < 0">
                    Se quitó en una modificación
                  </p>
                </div> -->

              <div class="flex justify-start px-2 text-xs">
                <div class="flex-grow truncate">
                  {{ item.state ? item.name : item.product.name }}
                </div>
                <div
                  class="font-bold tracking-wide truncate"
                  :class="[
                    item.state === 'pending' && item.diff > 0
                      ? 'text-yellow-400'
                      : '',
                    item.state === 'shortage' ? 'text-red-400' : '',
                  ]"
                >
                  <div v-if="item.state">
                    {{ -item.diff }} {{ item | unit }}
                  </div>
                  <div v-if="!item.state">
                    {{ item | friendlyUnity }}
                  </div>
                </div>
              </div>
            </div>
            <p v-if="changes(items).length" class="mt-2 text-xs font-bold">
              Modificaciones
            </p>
            <div v-for="item of changes(items)" :key="item._id">
              <div class="flex justify-start px-2 text-xs">
                <div class="flex-grow truncate">
                  {{ item.state ? item.name : item.product.name }}
                </div>
                <div
                  class="font-bold tracking-wide truncate"
                  :class="[
                    item.state === 'pending' && item.diff > 0
                      ? 'text-yellow-400'
                      : '',
                    item.state === 'shortage' ? 'text-red-400' : '',
                    item.diff < 0 ? 'text-green-400' : '',
                  ]"
                >
                  <div v-if="item.state">
                    {{ -item.diff }} {{ item | unit }}
                  </div>
                  <div v-if="!item.state">
                    {{ item | friendlyUnity }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </b-modal>
</template>

<script>
import {
  isOrder, isBuy, round, sum, 
} from '../util/helpers';

export default {
  name: 'DetailsModal',
  components: {},
  props: {
    active: Boolean,
    element: Object,
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    isOrder() {
      return isOrder(this.element);
    },
    isBuy() {
      return isBuy(this.element);
    },
    discountAmount() {
      return round(this.contributions * (this.element.discount / 100) || 0);
    },
    taxes() {
      return round(
        sum(
          this.element.cart.items.map(
            (i) => (i.detail && i.detail.taxes) || {},
          ),
          'total',
        ),
      );
    },
    taxBase() {
      return round(
        sum(
          this.element.cart.items.map((i) => i.detail),
          null,
          ({ taxBase } = {}) => taxBase,
        ),
      );
    },
    contributions() {
      return round(
        sum(
          this.element.cart.items.map((i) => i.detail),
          'contribution',
        ),
      );
    },
    hasDiscount() {
      return this.discountAmount > 0;
    },
  },

  methods: {
    shortages(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff > 0 && quantity !== max,
      );
    },
    changes(items) {
      return this.parsedMetadata(items).filter(
        ({ diff, quantity, max }) => diff < 0 || quantity === max,
      );
    },
    parsedMetadata(items) {
      return items.filter(
        ({ diff, quantity }) => (diff && diff !== 0)
          || (typeof diff === 'undefined' && quantity !== 0),
      );
    },
  },
};
</script>
