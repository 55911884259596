<script>
import chunk from 'lodash/chunk';

export default {
  name: 'Tiles',
  props: {
    maxPerRow: {
      type: Number,
      default: 5,
    },
  },
  render(createElement) {
    if (this.$slots.default.length <= this.maxPerRow) {
      return this.renderAncestor(createElement, this.$slots.default);
    }
    return createElement(
      'div',
      { attrs: { class: 'is-tiles-wrapper' } },
      // eslint-disable-next-line max-len
      chunk(this.$slots.default, this.maxPerRow).map((group) => this.renderAncestor(createElement, group)),
    );
  },
  methods: {
    renderAncestor(createElement, elements) {
      return createElement(
        'div',
        { attrs: { class: 'tile is-ancestor' } },
        elements.map((element) => createElement('div', { attrs: { class: 'tile is-parent' } }, [
          element,
        ])),
      );
    },
  },
};
</script>
