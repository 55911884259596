<template>
  <div>
    <hero-bar v-if="hasActions" :has-right-visible="hasActions">
      <div class="buttons" slot="right">
        <b-button type="is-primary is-light" @click="handleCreateReceipt"
          >Generar comprobante</b-button
        >
        <b-button data-cy="new-buy" @click="handleNewBuy"
          >Nueva compra</b-button
        >
      </div>
    </hero-bar>

    <Section>
      <b-tabs
        type="is-toggle"
        class="flex flex-col"
        @input="tabChanged"
        :vertical="isMobile ? true : false"
        :size="!isMobile ? 'is-small' : ''"
        :animated="false"
      >
        <b-tab-item v-if="hasShortage" value="shortage">
          <template #header>
            <b-icon icon="cart-off"></b-icon>
            <span> Faltantes de productos </span>
          </template>
          <LoadingTable :active="!shortageFetched" />
          <Shortage @fetched="handleShortageFetched" />
        </b-tab-item>
        <b-tab-item value="pending">
          <template #header>
            <b-icon icon="information-outline"></b-icon>
            <span>
              Compras por aprobar
              <b-tag rounded> {{ pending.length }}</b-tag>
            </span>
          </template>
          <p class="my-4 text-2xl font-bold">
            🫰 Total: {{ totalPendingBuys | currency }}
          </p>
          <Layout
            v-if="isFetched.pending && pendingBuysWithoutWithdrawalDate.length"
          >
            <List :loading="isLoading.pending">
              <p class="px-2 text-2xl font-bold">
                {{
                  `⚠️ Sin fecha de retiro - ${
                    pendingBuysWithoutWithdrawalDate.length
                  } compra${
                    pendingBuysWithoutWithdrawalDate.length > 1 ? 's' : ''
                  }`
                }}
              </p>

              <div
                v-for="buy in pendingBuysWithoutWithdrawalDate"
                :key="buy._id"
              >
                <BuyCard
                  :buy="buy"
                  :operationPermission="true"
                  :isDraggable="false"
                  :isCheckable="false"
                  :showInstance="true"
                  :customStore="buyStore"
                  customStoreKey="pending"
                ></BuyCard>
              </div>
            </List>
          </Layout>
          <div v-for="day of buyDays" :key="day" :id="day">
            <div
              class="relative p-2 px-4 my-2 rounded-lg shadow bg-purple-50"
              @click="collapseDay(day)"
            >
              <button
                class="absolute top-2 right-5 no-focus"
                type="is-text"
                size="is-small"
              >
                <b-icon
                  :icon="
                    collapsedDays.includes(day) ? 'chevron-up' : 'chevron-down'
                  "
                />
              </button>
              <div class="grid items-center grid-cols-2">
                <div class="">
                  <p class="text-lg font-bold text-indigo-600">
                    {{ day | completeDate }}
                  </p>
                  <p class="text-sm">
                    {{ day | shortDate }}
                  </p>
                  <p class="text-sm">
                    Compras:
                    <span class="font-bold">{{
                      getBuysByDate(day).length
                    }}</span>
                  </p>
                  <!-- <p class="text-sm">
                    Items:
                    <span class="font-bold">
                      {{ itemsQuantityByDay(day) }}</span
                    >
                  </p> -->
                </div>
              </div>
            </div>

            <Layout v-show="!collapsedDays.includes(day)">
              <List :loading="isLoading.pending">
                <BuyCard
                  v-for="buy in getBuysByDate(day)"
                  :key="buy._id"
                  :buy="buy"
                  :operationPermission="true"
                  :isCheckable="true"
                ></BuyCard>
              </List>
            </Layout>
          </div>
        </b-tab-item>
        <b-tab-item value="unreceipted">
          <template #header>
            <b-icon icon="information-outline"></b-icon>
            <span>
              Compras sin comprobantes
              <b-tag rounded> {{ unreceipted.length }}</b-tag>
            </span>
          </template>
          <Layout>
            <List :loading="isLoading.unreceipted">
              <p class="px-2 text-2xl font-bold">🔔 Compras sin comprobantes</p>
              <BuyCard
                v-for="buy in unreceipted"
                :key="buy._id"
                :buy="buy"
                :operationPermission="true"
              ></BuyCard>
            </List>
          </Layout>
        </b-tab-item>
        <b-tab-item value="history">
          <template #header>
            <b-icon icon="history"></b-icon>
            <span> Historial de compras</span>
          </template>

          <SearchBar
            :store="buyStore"
            :options="options"
            placeholder="✨ Buscá proveedores"
          ></SearchBar>

          <!-- <PullToRefresh :onRefresh="buyStore.find"> -->
          <Layout>
            <List :loading="isLoading.buys">
              <p class="px-2 text-2xl font-bold">🗓 Historial de compras</p>
              <BuyCard
                v-for="buy in buys"
                :key="buy._id"
                :buy="buy"
                :operationPermission="true"
                :isCheckable="true"
              ></BuyCard>
            </List>
          </Layout>
          <!-- </PullToRefresh> -->
        </b-tab-item>
        <b-tab-item v-if="receipts.length">
          <template #header>
            <b-icon icon="account-supervisor-circle"></b-icon>
            <span>Comprobantes de proveedores </span>
          </template>
          <ReceiptTable :receipts="filteredReceipts" :operationPermission="true"  @remove="handleReceiptRemoved">
          </ReceiptTable>
        </b-tab-item>
      </b-tabs>
    </Section>
    <NewReceipt
      :active="isCreatingNewReceipt"
      @created="handleReceiptCreated"
      @dismiss="isCreatingNewReceipt = false"
    />
    <BuysActions :selected="selected" :operationPermission="true"></BuysActions>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import HeroBar from '../components/HeroBar.vue';
import ApiService from '../services/ApiService';
import ReceiptTable from '../components/Buy/ReceiptTable.vue';
import NewReceipt from '../components/Buy/NewReceipt.vue';
import Shortage from '../components/Inventory/Shortage.vue';
// import { BUY_STATES } from '../util/constants';
import BuysActions from '../components/Buy/BuyActions.vue';
import SearchBar from '../components/SearchBar.vue';
import { round, sum } from '../util/helpers';
import Layout from '../components/Layout.vue';
import List from '../components/List.vue';
import BuyCard from '../components/Buy/BuyCard.vue';
import useBuyStore from '../store/buy';
import Section from '../components/Section.vue';
import useUserStore from '../store/user';

const { ReceiptService } = ApiService;

export default {
  name: 'Buys',
  components: {
    Section,
    HeroBar,
    Shortage,
    ReceiptTable,
    NewReceipt,
    BuysActions,
    SearchBar,
    Layout,
    List,
    BuyCard,
  },
  data() {
    return {
      userStore: useUserStore(),
      buyStore: useBuyStore(),
      loading: true,
      hasShortage: true, // setted to show Shortage component at mount.
      shortageFetched: false,
      query: {},
      isCreatingNewReceipt: false,
      receipts: [],
      collapsedDays: [],
      hasActions: true,
      currentTab: 'shortage',
      removedReceipts: [],
    };
  },
  computed: {
    filteredReceipts() {
      return this.receipts.filter((receipt) => !this.removedReceipts.includes(receipt._id));
    },
  

    buyDays() {
      return this.getBuyDays();
    },
    totalPendingBuys() {
      return round(sum(this.pending, 'total'));
    },
    ...mapState(useBuyStore, ['options']),
    ...mapState(useBuyStore, ['pagination']),
    ...mapState(useBuyStore, ['isLoading']),
    ...mapState(useBuyStore, ['isFetched']),
    ...mapState(useBuyStore, ['pending']),
    ...mapState(useBuyStore, ['unreceipted']),
    ...mapState(useBuyStore, ['buys']),
    ...mapState(useBuyStore, ['selected']),
    buys: {
      get() {
        return this.buyStore.buys;
      },
      set(value) {
        return this.buyStore.updatePositions(value, 'buys');
      },
    },
    pendingBuysWithoutWithdrawalDate() {
      return this.pending.filter(({ withdrawal }) => !withdrawal.date);
    },
    isMobile() {
      return (
        Math.min(window.screen.width, window.screen.height) < 768
        || navigator.userAgent.indexOf('Mobi') > -1
      );
    },
  },
  async mounted() {
    this.getNext();

    await this.buyStore.find();
    await this.buyStore.findPending();
    await this.buyStore.findUnreceipted();
    await this.fetchReceipts();
    this.collapsedDays = this.getBuyDays();
    await this.userStore.find({ isDriver: true }, { key: 'drivers' });
  },
  watch: {},
  methods: {
    getBuyDays() {
      return [
        ...new Set(
          this.pending
            .map(({ withdrawal }) => withdrawal.date)
            .filter((date) => date)
            .sort(),
        ),
      ];
    },
    collapseDay(day) {
      const index = this.collapsedDays.indexOf(day);
      if (index === -1) {
        this.collapsedDays.push(day);
      } else {
        this.collapsedDays.splice(index, 1);
      }
    },
    getBuysByDate(day) {
      return this.pending.filter(({ withdrawal }) => withdrawal.date === day);
    },
    tabChanged(type) {
      this.currentTab = type;
    },

    handleNewBuy() {
      this.$router.push('buy/cart');
    },
    handleCreateReceipt() {
      this.isCreatingNewReceipt = true;
    },
    handleReceiptCreated() {
      this.fetchReceipts();
    },
    handleReceiptRemoved(removedReceipt) {
      this.removedReceipts.push(removedReceipt._id);
    },
    handleShortageFetched({ shortage, critical }) {
      this.shortageFetched = true;
      if (!shortage.providers.length && !critical.providers.length) {
        this.hasShortage = false;
      }
    },
    async fetchReceipts() {
      const { data: { docs: receipts } } = await ReceiptService.find({ status: true, limit: 400 });
      this.receipts = receipts;
    },
    handleChangePage() {
      this.buyStore.changePage();
    },
    getNext() {
      window.onscroll = () => {
        const offset = document.documentElement.offsetHeight
          - (document.documentElement.scrollTop + window.innerHeight);
        const bottomOfWindow = offset < 500;

        if (
          bottomOfWindow
          && !this.isLoading.buys
          && this.pagination.hasNextPage
          && this.currentTab === 'history'
        ) {
          this.handleChangePage();
        }
      };
    },
  },
};
</script>
