<template>
  <div>
    <div class="mt-4">
      <b-table
        :data="flows"
        :bordered="true"
        :striped="true"
        :paginated="true"
        :perPage="20"
        :narrowed="true"
        :mobile-cards="true"
        :loading="loading"
      >
        <b-table-column
          field="date"
          label="Fecha"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          <b-tag type="is-primary is-light">
            {{ props.row.date | shortDatetime }}
          </b-tag>
        </b-table-column>

        <b-table-column
          field="concept"
          label="Concepto"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          <b-tag
            :type="`is-${
              props.row.concept === 'INGRESS' ? 'success' : 'danger'
            } is-light`"
            >{{ parseConcept(props.row.concept) }}</b-tag
          >
        </b-table-column>

        <b-table-column
          width="200px"
          label="Tercero"
          :visible="advanced"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          {{ getMetadata('name', props.row) }}
        </b-table-column>
        <b-table-column
          width="10px"
          label="X"
          :visible="advanced"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          {{ props.row.isBudget ? 'P' : 'F' }}
        </b-table-column>

        <b-table-column v-slot="props">
          <b-tag :type="getMetadata('type', props.row)"
            ><b-icon :icon="getMetadata('icon', props.row)"></b-icon
          ></b-tag>
        </b-table-column>

        <b-table-column>
          <template v-slot:header="{}">
            <b-icon icon="tag" class="mt-2 ml-4" />
          </template>
          <template v-slot="props">
            {{ props.row.tag }}
          </template>
        </b-table-column>

        <b-table-column
          field="paymentMethod"
          label="Metodo de Pago"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          {{ props.row.paymentMethod }}
        </b-table-column>
        <b-table-column
          field="amount"
          label="Monto"
          :centered="true"
          v-slot="props"
        >
          <b-tag
            :type="`is-${
              props.row.concept === 'INGRESS' && props.row.amount > 0
                ? 'success'
                : 'danger'
            } is-light`"
            >${{ props.row.amount }}</b-tag
          >
        </b-table-column>

        <b-table-column
          field="description"
          label="Descripción"
          :sortable="true"
          :centered="true"
          :searchable="true"
          v-slot="props"
        >
          {{ props.row.description }}
        </b-table-column>
        <b-table-column field="" label="" v-slot="props">
          <button
            v-if="isAbleToRemove(props.row.date)"
            type="button"
            class=""
            @click="handleRemoveCashFlow(props.row)"
          >
            <b-icon icon="trash-can-outline" custom-size="small" />
          </button>
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">
            <p class="subtitle is-3">No hay flujos de caja</p>
          </div>
        </template>
        <template #footer v-if="flows.length">
          <th class="is-hidden-mobile">
            <div class="th-wrap is-centered">Totales</div>
          </th>
          <th class="is-hidden-mobile"></th>
          <th class="is-hidden-mobile"></th>
          <th class="is-hidden-mobile"></th>
          <th v-if="advanced" class="is-hidden-mobile"></th>
          <th class="is-hidden-mobile">
            <div class="th-wrap is-centered">${{ totalAmount }}</div>
          </th>
          <th class="is-hidden-mobile"></th>
          <th class="is-hidden-mobile"></th>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { sum, round } from '../../util/helpers';
import ApiService from '../../services/ApiService';

const { CashFlowService } = ApiService;

export default {
  name: 'CommonCashFlowTable',
  props: {
    flows: Array,
    advanced: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      isEditCashFlowsModalActive: false,
      updatedCashFlow: null,
    };
  },
  watch: {},
  computed: {
    totalAmount() {
      return round(
        sum(this.flows, null, (cashflow) => (cashflow.concept === 'EGRESS' ? -cashflow.amount : cashflow.amount)),
      );
    },

    getTagType() {
      return {
        partner: 'is-primary is-light',
        provider: 'is-danger is-light',
      }[this.type];
    },
    getTagLabel() {
      return {
        partner: 'AV',
        provider: 'Proveedor',
      }[this.type];
    },
  },
  methods: {
    isAbleToRemove(date) {
      const selectedDate = new Date(date);
      const currentDate = new Date();

      const sevenDaysToNow = new Date();
      sevenDaysToNow.setDate(currentDate.getDate() - 7);

      if (selectedDate > sevenDaysToNow) {
        return true;
      }
      return false;
    },
    handleRemoveCashFlow(flow) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar Flujo',
        message:
          '¿Estás seguro de <b>eliminar</b> este flujo? Esta acción no se puede retroceder',
        confirmText: 'Eliminar',
        cancelText: 'Emm, no',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          await CashFlowService.disable(flow.type, flow._id);
          this.$emit('remove', flow);
        },
      });
    },
    getMetadata(type, { provider, partner, third }) {
      let key = null;

      if (provider) {
        key = 'provider';
      } else if (partner) {
        key = 'partner';
      } else if (third) {
        key = 'external';
      } else key = 'internal';

      return {
        provider: {
          name: provider && provider.name,
          icon: 'truck-outline',
          type: 'is-danger is-light',
        },
        partner: {
          name: partner && partner.name,
          icon: 'handshake',
          type: 'is-primary is-light',
        },
        external: {
          icon: 'account',
          name: third && third.name,
        },
        internal: {
          icon: 'plus-minus',
        },
      }[key][type];
    },
    parseConcept(concept) {
      return {
        EGRESS: 'Egreso',
        INGRESS: 'Ingreso',
      }[concept];
    },
    parseMetadataNumber(metadata) {
      if (metadata && metadata.number) {
        return metadata.number;
      }
      return '';
    },
  },
};
</script>
