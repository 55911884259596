<!-- eslint-disable max-len -->
<template>
  <div>
    <CardItem
      ref="cardItem"
      :element="customer"
      :operationPermission="operationPermission"
    >
      <div class="flex flex-col content-around space-y-3 sm:col-end-2">
        <div class="flex flex-row justify-between">
          <p class="text-lg font-bold capitalize">
            {{ customer.name }}
            <span class="text-xs font-light"
              >(desde {{ customer.createdAt | shortDate }})</span
            >

            <a
              v-if="customer.isWholesaler"
              class="text-xs text-white-700 font-bold bg-yellow-200 dark:text-white border-2 border-yellow-500 rounded-md p-0.5 px-1 ml-2"
              >M</a
            >
          </p>

          <p
            v-if="customer.segment"
            class="flex items-center text-center text-xs text-white-700 font-bold bg-gray-200 dark:text-white border-2 border-gray-500 rounded-md p-0.5 px-1 ml-2"
          >
            {{ customer.segment.name }}
          </p>
        </div>

        <div class="">
          <div class="text-sm">
            <div class="flex flex-col gap-2 py-2">
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate"
                  >Email de contacto</span
                >
                <span v-if="customer.contactEmail" class="tracking-wide">{{
                  customer.contactEmail
                }}</span>
                <span class="text-xs font-light" v-if="!customer.contactEmail"
                  >Sin correo de contacto</span
                >
              </div>
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate">Email</span>
                <span v-if="customer.email" class="tracking-wide"
                  >{{ customer.email }}
                  <svg
                    v-if="customer.verified"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="inline w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                </span>
                <span v-if="!customer.email" class="tracking-wide">
                  <span class="mr-2 text-xs font-light"
                    >Usuario sin autenticación</span
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="inline w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                  </svg>
                </span>
              </div>
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate">Calle</span>
                <span v-if="customer.address" class="tracking-wide">{{
                  customer.address.street
                }}</span>
              </div>
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate">Ciudad</span>
                <span v-if="customer.address" class="tracking-wide">{{
                  customer.address.city
                }}</span>
              </div>
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate">Província</span>
                <span v-if="customer.address" class="tracking-wide">{{
                  customer.address.province
                }}</span>
              </div>
              <div class="flex items-center">
                <span class="flex-grow font-bold truncate">Telefono</span>
                <span v-if="customer.phone" class="tracking-wide">{{
                  customer.phone
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-end justify-between h-full space-x-2 text-sm">
          <!-- <button
            @click="handleSelect(customer)"
            class="inline text-sm font-medium text-black align-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 p-0.5 shadow rounded-lg"
              fill="none"
              viewBox="0 0 24 24 "
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button> -->
          <button
            @click="handleEdit(customer)"
            class="inline text-sm font-medium text-black align-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 p-0.5 shadow rounded-lg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
          <button
            class="inline text-sm font-medium text-black align-center"
            v-if="customer.phone"
          >
            <a :href="`tel:${customer.phone}`"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 p-0.5 rounded-lg shadow"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </a>
          </button>
          <button
            v-if="customer.phone"
            @click="handleSendMessage(customer)"
            class="inline text-sm font-medium text-black align-center"
          >
            <img
              class="w-6 h-6 rounded-lg shadow"
              :src="require('../../../assets/whatsapp.svg')"
            />
          </button>
        </div>
      </div>
    </CardItem>

    <UpdateForm
      :segments="segments"
      :active="isEditOpen"
      :customer="customerSelected"
      @dismiss="isEditOpen = false"
    ></UpdateForm>
    <Details
      :active="isDetailsOpen"
      :element="customerSelected"
      @dismiss="handleDismissDetails"
    ></Details>
  </div>
</template>

<script>
import CardItem from '../../CardItem.vue';
import Details from './Details.vue';
import UpdateForm from '../UpdateForm.vue';

export default {
  name: 'CustomerCard',
  props: {
    customer: Object,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isCheckable: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
    showInstance: {
      type: Boolean,
      default: false,
    },
    customStore: {
      type: Object,
      default: null,
    },
    customStoreKey: {
      type: String,
      default: null,
    },
    segments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CardItem,
    Details,
    UpdateForm,
  },
  data() {
    return {
      isEditOpen: false,
      isDetailsOpen: false,
      customerSelected: null,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleSelect(customer) {
      this.customerSelected = customer;
      this.isDetailsOpen = true;
    },
    handleEdit(customer) {
      this.customerSelected = customer;
      this.isEditOpen = true;
    },
    handleDismissDetails() {
      this.isDetailsOpen = false;
      this.customerSelected = null;
    },
    handleSendMessage(customer) {
      window.open(`https://wa.me/${customer.phone}`, '_blank').focus();
    },

    handleCopyPhone(phone) {
      this.$buefy.dialog.confirm({
        message: `${phone}`,
        cancelText: 'Volver',
        confirmText: 'Abrir en WhatsApp',
        onConfirm: () => {
          window.open(`https://wa.me/${phone}`, '_blank').focus();
        },
      });
    },
  },
  watch: {},
};
</script>
