<template>
  <li
    v-if="element[property]"
    class="mt-2 text-sm text-gray-600 dark:text-gray-300"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="inline w-6 h-6 align-center"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
      />
    </svg>
    <span class="ml-2">{{ element[property].name | capitalize }}</span>

    <b-tooltip
      label="Bonificado"
      type="is-success"
      v-if="!element[property].charged"
    >
      <a
        class="
        text-xs text-white-700
        font-bold
        bg-green-200
        dark:text-white
        hover:underline
        border-2 border-green-500
        rounded-md
        p-0.5
        px-1
        ml-2
      "
        >B</a
      >
    </b-tooltip>

    <span class="ml-1" v-if="element.driver"> - <svg  class="inline w-6 h-6 align-center" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.78 21.84a1.998 1.998 0 1 1-1.56-3.68a1.998 1.998 0 1 1 1.56 3.68M7.62 6c1.11 0 2-.89 2-2a2 2 0 0 0-2-2c-1.12 0-2 .9-2 2c0 1.11.88 2 2 2m14.43 10.34L18.2 18c.22.22.42.5.56.82c.14.33.2.68.24 1l3.83-1.64zM10.16 8.78l.74 1.81c-.24-.09-.46-.21-.64-.33c-.6-.39-1.04-.88-1.33-1.46l-.74-1.57c-.19-.35-.42-.61-.74-.79c-.29-.17-.6-.26-.92-.26c-.32 0-.62.08-.91.22c-1.4 1.1-1.75 3.14-1.75 3.14l-.34 1.57c-.09.52-.14 1.04-.14 1.57v4.96L1 20.87L2.5 22l2.77-3.75l.17-3.25l1.68 2.34V22h1.85v-6.06l-1.85-2.61v-.65c0-.44 0-.87.11-1.29l.35-1.2c.38.55.84 1.03 1.39 1.44c.45.34 1.71.94 2.9 1.23L14 17.8c.22-.22.5-.42.83-.56c.32-.14.67-.2.99-.24L12 8zm5.2 3.34l1.96 4.6l5.63-2.41L21 9.72"/></svg>  {{ element.driver.name }}</span>


  </li>
</template>

<script>
import { formatAddress } from '../util/helpers';

export default {
  name: 'LogisticComponent',
  components: {},
  props: {
    element: {
      type: Object,
    },
    property: {
      type: String,
      default: 'delivery',
    },
  },
  mounted() {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    formatAddress,
  },
  watch: {},
};
</script>
