<template>
  <div class="mt-4">
    <b-table
      :paginated="isPaginated"
      :perPage="perPage"
      :data="balances"
      :striped="true"
      :narrowed="true"
      :loading="loading"
      :show-detail-icon="false"
      ref="table"
      detailed
    >
      <b-table-column
        field="name"
        label="Proveedor"
        centered
        sortable
        v-slot="props"
      >
       <span class="cursor-pointer" @click="handleShowAuditProviderModal(props.row)"> {{ props.row.name }}</span>
      </b-table-column>
      <b-table-column
        field="receipts"
        label="Facturas"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.receipts | rounded }}
      </b-table-column>
      <b-table-column
        field="cashflows"
        label="Pagos"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.cashflows | rounded }}
      </b-table-column>
      <b-table-column
        field="debtBalances"
        label="Saldo de deuda"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.debtBalance | rounded }}
      </b-table-column>

      <b-table-column
        field="inventory"
        label="Stock"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.inventory | rounded }}
      </b-table-column>
      <b-table-column
        field="finalBalance"
        label="Saldo Activate"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.finalBalance | rounded }}
      </b-table-column>
      <b-table-column
        field="pendingBuys"
        label="Compras Pendientes"
        centered
        sortable
        v-slot="props"
      >
        ${{ props.row.pendingBuys | rounded }}
      </b-table-column>

      <b-table-column
        field=""
        label="Saldo final"
        centered
        sortable
        v-slot="props"
      >
        ${{ (props.row.finalBalance + props.row.pendingBuys) | rounded }}
      </b-table-column>
      <template #footer>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">Totales</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalReceipts }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalCashFlows }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalDebtBalance }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalInventory }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalFinalBalance }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalPendingBuys }}</div>
        </th>
        <th class="is-hidden-mobile">
          <div class="th-wrap is-centered">$ {{ totalBalanceFinal }}</div>
        </th>

        <th class="is-hidden-mobile"></th>
      </template>
    </b-table>

    <GenericModal :active="isAuditProviderModalActive" @dismiss="isAuditProviderModalActive = false">
      <div

                v-if="isLoading.providerAudit"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
      <List v-if="!isLoading.providerAudit && providerAudit" :data="providerAudit.transactions" :operationPermission="false">
      </List></GenericModal>

  </div>
</template>

<script>
import { mapState } from 'pinia';
import { round, sum } from '../../util/helpers';
import GenericModal from '../GenericModal.vue';
import useAuditStore from '../../store/audit';
import List from '../../views/provider/account/List.vue';

export default {
  name: 'ProviderBalances',
  props: {
    balances: Array,
    operationPermission: {
      type: Boolean,
      default: false,
    },
    isPaginated: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    advancedTable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GenericModal,
    List,
  },
  data() {
    return {
      auditStore: useAuditStore(),

      isAuditProviderModalActive: false,
    };
  },
  watch: {},
  computed: {
    ...mapState(useAuditStore, ['providerAudit']),
    ...mapState(useAuditStore, ['isLoading']),
    totalReceipts() {
      return round(sum(this.balances, 'receipts'));
    },
    totalCashFlows() {
      return round(sum(this.balances, 'cashflows'));
    },
    totalDebtBalance() {
      return round(sum(this.balances, 'debtBalance'));
    },
    totalInventory() {
      return round(sum(this.balances, 'inventory'));
    },
    totalFinalBalance() {
      return round(sum(this.balances, 'finalBalance'));
    },
    totalPendingBuys() {
      return round(sum(this.balances, 'pendingBuys'));
    },
    totalBalanceFinal() {
      return round(
        sum(this.balances, 'pendingBuys') + sum(this.balances, 'finalBalance'),
      );
    },
  },
  methods: {
    toPay(partner) {
      return round(partner.toPayBalance);
    },
    async handleShowAuditProviderModal(provider) {
      this.isAuditProviderModalActive = true;
      await this.auditStore.getProviderAudit({
        provider: provider._id,
        transactionsLimit: 50,
      });
    },
  },
  mounted() {},
};
</script>
