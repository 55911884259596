<template>
  <div>
    <div class="mb-4" v-if="fetched && !accounts.length">
      <b-notification
        type="is-primary is-light"
        aria-close-label="Close notification"
        :closable="false"
      >
        <p class="title is-5 has-text-primary">Agregá tu primer cliente 👆</p>
      </b-notification>
      <p class="title is-4"></p>
    </div>
    <div>
      <b-field :label="label">
        <b-autocomplete
          data-cy="customer-lookup"
          :append-to-body="true"
          v-model="customerToSearch"
          :clearable="true"
          :data="filteredCustomers"
          :keep-first="true"
          field="name"
          @select="select"
          icon="account-circle"
          :loading="!accounts.length"
        >
          <template slot-scope="props">
            <div class="media">
              <div class="media-left"></div>
              <div class="media-content" data-cy="customer-options">
                <div class="flex items-center gap-2">
                  {{ props.option.name }}
                  <span class="text-xs font-bold">{{
                    props.option.balance | currency
                  }}</span>
                  <svg
                    v-if="props.option.verified"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="inline w-5 h-5 mb-1 mr-1"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                    />
                  </svg>
                </div>

                <div class="flex flex-col gap-1">
                  <p
                    class="text-xs"
                    v-if="props.option && props.option.address"
                  >
                    {{ props.option.address.street }},
                    <b>{{ props.option.address.city }}</b>
                  </p>

                  <p class="text-xs">{{ props.option.email }}</p>
                  <p class="text-xs">{{ props.option.phone }}</p>
                </div>
              </div>
            </div>
          </template>
          <template slot="empty"
            >No encontramos nada con {{ customerToSearch }}</template
          >

          <template slot="header">
            <a @click="newCustomerModalIsActive = true">
              <span>Agregar cliente <b-icon icon="account" /></span>
            </a>
          </template>
        </b-autocomplete>
      </b-field>
      <div
        class="bg-white shadow sm:rounded-lg"
        v-if="accountSuggestion.length"
      >
        <div class="px-4 py-5 sm:p-6">
          <h3 class="text-base font-semibold leading-6 text-gray-900">
            ¿Es este el cliente que buscas? ({{
              discardedAccounts.length + 1
            }}/{{ accountSuggestion.length + discardedAccounts.length }})
          </h3>
          <div class="mt-5">
            <div
              class="px-6 py-5 rounded-md bg-gray-50 sm:flex sm:items-start sm:justify-between"
            >
              <div class="sm:flex sm:items-center">
                <img
                  class="w-auto h-14 sm:h-14 sm:flex-shrink-0"
                  :src="
                    accountSuggestion[0].customer.picture ||
                    `https://robohash.org/${accountSuggestion[0].customer.email}`
                  "
                  alt=""
                />

                <div class="mt-3 sm:ml-4 sm:mt-0">
                  <div class="text-sm font-medium text-gray-900">
                    {{ accountSuggestion[0].customer.name }}
                    <svg
                      v-if="accountSuggestion[0].customer.verified"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="inline w-6 h-6 mb-1 mr-1"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                      />
                    </svg>
                  </div>
                  <div
                    class="mt-1 text-sm text-gray-600 sm:flex sm:items-center"
                  >
                    <div>
                      <p class="font-bold">
                        {{ accountSuggestion[0].customer.email }}
                      </p>
                      <p v-if="accountSuggestion[0].customer.address">{{ accountSuggestion[0].customer.address.label }}</p>
                    </div>
                    <span class="hidden sm:mx-2 sm:inline" aria-hidden="true"
                      >&middot;</span
                    >
                    <div class="mt-1 font-bold sm:mt-0">
                      {{ accountSuggestion[0].customer.phone }}
                    </div>
                  </div>
                  <div
                    class="mt-1 text-sm text-gray-600 sm:flex sm:items-center"
                  >
                    <b>Balance: </b>
                    {{ accountSuggestion[0].balance | currency }}
                  </div>
                </div>
              </div>
              <div class="flex gap-2 mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0">
                <button
                  type="button"
                  @click="select(accountSuggestion[0].customer)"
                  class="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Confirmar
                </button>
                <button
                  type="button"
                  @click="
                    discardedAccounts.push(accountSuggestion[0].customer._id)
                  "
                  class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewCustomer
        :draft="draft"
        :active="newCustomerModalIsActive"
        @created="handleCustomerCreated"
        @dismiss="newCustomerModalIsActive = false"
      ></NewCustomer>
    </div>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import NewCustomer from '../Customer/NewCustomer.vue';

const { AuditService, CustomerService } = ApiService;

export default {
  name: 'CustomerLookup',
  props: {
    partner: {
      type: String,
      default: null,
    },
    draft: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: 'Buscar un cliente',
    },
    onlyWithPartner: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NewCustomer,
  },
  data() {
    return {
      customerToSearch: '',
      accounts: [],
      fetched: false,
      customerFoundedByEmail: null,
      newCustomerModalIsActive: false,
      discardedAccounts: [],
    };
  },
  watch: {
    partner() {
      if (this.partner) {
        this.fetchAccounts();
      }
    },
  },
  computed: {
    accountSuggestion() {
      if (this.isDraftingOrder || !this.draft) return [];
      return [...(this.customerFoundedByEmail ? [{ customer: this.customerFoundedByEmail }] : []), ...this.accounts.filter((account) => {
        if (this.discardedAccounts.includes(account.customer._id)) return false;
        if (
          account.customer.email === this.draft.email
          || account.customer.email === this.draft.contactEmail
        ) return true;
        if (account.customer.contactEmail === this.draft.contactEmail) return true;

        if (account.customer.phone === this.draft.phone) return true;
        return false;
      })];
    },
    filteredCustomers() {
      return [...this.accounts]
        .map(({ customer, balance }) => ({ ...customer, balance }))
        .reverse()
        .filter(
          ({ name }) => name.toLowerCase().indexOf(this.customerToSearch.toLowerCase())
            >= 0,
        );
    },
  },
  methods: {
    select(customer) {
      this.$emit('select', customer);
    },
    handleCustomerCreated({ customer }) {
      this.select(customer);
    },
    handleRemoveCustomer() {
      this.customerToSearch = '';
      this.$emit('remove');
    },
    async fetchAccounts() {
      if (!this.onlyWithPartner && !this.partner) return;
      this.fetched = false;
      const {
        data: { docs },
      } = await AuditService.getCustomerAudit({
        partner: this.partner,
        page: 1,
        limit: 999999,
        transactionsLimit: 1,
        populateCustomer: true,
      });
      this.accounts = docs;
      this.fetched = true;
    },
  },
  async mounted() {
    await this.fetchAccounts();

    if (this.draft && this.draft.contactEmail) {
      const { data: { docs } } = await CustomerService.find({
        email: this.draft.contactEmail,
      });
      if (docs.length) {
        // eslint-disable-next-line prefer-destructuring
        this.customerFoundedByEmail = docs[0]; 
      }
    }
  },
};
</script>
